import React, { useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import UilAngleLeft from '@iconscout/react-unicons/icons/uil-angle-left';
import { UilAngleDoubleLeft } from '@iconscout/react-unicons';
import UilAngleRight from '@iconscout/react-unicons/icons/uil-angle-right';
import { UilAngleDoubleRight } from '@iconscout/react-unicons';
import './styles.scss';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { getNavbarStatus } from 'features/sidebar-nav/sidebarNavSlice';
import { propTypes } from 'react-bootstrap/esm/Image';

const iconMap = {
  RIGHT: {
    chevronTop: <UilAngleLeft size="28" className="angle-icon" />,
    chevronBottom: <UilAngleDoubleLeft size="28" className="angle-icon" />
  },
  CENTER: {
    chevronTop: <UilAngleRight size="28" className="angle-icon" />,
    chevronBottom: <UilAngleLeft size="28" className="angle-icon" />
  },
  LEFT: {
    chevronTop: <UilAngleRight size="28" className="angle-icon" />,
    chevronBottom: <UilAngleDoubleRight size="28" className="angle-icon" />
  }
};

const SidebarRight = ({
  open,
  handleOpen,
  children,
  disabled = false,
  hideButtons = false,
  singleButton = false,
  defaultWidth = ''
}) => {
  const [fullWidthCollapsible, setCollapsibleWidth] = useState(false);
  const { navbar: navigation } = useSelector(getNavbarStatus);
  const showSidebarHandler = () => {
    if (fullWidthCollapsible) {
      setCollapsibleWidth(false);
      handleOpen(true);
    } else {
      handleOpen(!open);
    }
  };

  const fullWidthHandler = () => {
    handleOpen(!open);
    setCollapsibleWidth(true);
    if (fullWidthCollapsible) {
      setCollapsibleWidth(false);
      handleOpen(false);
    } else if (!fullWidthCollapsible) {
      setCollapsibleWidth(true);
    } else {
      setCollapsibleWidth(false);
    }
  };

  const renderNavigation = () => {
    if (!open && !fullWidthCollapsible) {
      return 'RIGHT';
    } else if (open && !fullWidthCollapsible) {
      return 'CENTER';
    } else if (fullWidthCollapsible) {
      return 'LEFT';
    }
  };
  return (
    <Navbar
      id="sidebar-right"
      className={`sidebar-nav sidebar-nav-${open ? 'open active' : 'close'} w-${
        open ? defaultWidth : ''
      } ${fullWidthCollapsible && 'collapsible-full-width'}`}
    >
      {isMobile || singleButton ? (
        <a
          href="#"
          className={`navbar-ham-right ${disabled && 'disabled-icon'}`}
          style={{
            top: '85px',
            display: !open && singleButton ? 'none' : 'block'
          }}
          onClick={showSidebarHandler}
        >
          {open ? (
            <UilAngleRight
              size="28"
              className={`angle-icon ${open && 'icon-transformation-left'}`}
            />
          ) : (
            <UilAngleLeft
              size="28"
              className={`angle-icon ${!open && 'icon-transformation-right'}`}
            />
          )}
        </a>
      ) : renderNavigation() === 'RIGHT' && hideButtons ? null : (
        <>
          <a
            href="#"
            className={`navbar-ham-right ${disabled && 'disabled-icon'}`}
            style={{ top: '85px' }}
            onClick={showSidebarHandler}
          >
            {iconMap[renderNavigation()].chevronTop}
          </a>
          <a
            href="#"
            className={`navbar-ham-right ${disabled && 'disabled-icon'}`}
            style={{ top: '125px' }}
            onClick={fullWidthHandler}
          >
            {iconMap[renderNavigation()].chevronBottom}
          </a>
        </>
      )}
      <div
        className="sidebar-wrapper"
        // style={{ display: 'grid', gridTemplateColumns: '1fr 25fr 1fr' }}
        // style={
        //   open || fullWidthCollapsible
        //     ? { display: 'grid', gridTemplateColumns: '1fr 25fr 1fr' }
        //     : { display: 'unset' }
        // }
      >
        {React.cloneElement(children, { fullWidthCollapsible })}
      </div>
    </Navbar>
  );
};

export default SidebarRight;
