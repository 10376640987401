import { Enums } from 'helpers/dropdown-enums';
import ChatHistory from 'pages/portal/messaging/History/chatHistory';
import storage from 'helpers/storage';
import {
  ChatColumns,
  CompaniesColumns,
  companyUserColumns,
  LeadsColumns,
  MyReportsColumns,
  ProvisionColumns,
  QueReportsColumns
} from './tableColumns';

const defaultVisibleCols = {
  // companies default visible columns
  companies: {
    [Enums.Relationship.Administrator]: [
      'id',
      'companyName',
      'companyKey',
      'active',
      'hostName',
      'hasInvalidEmails'
    ],
    [Enums.Relationship.Partner]: [
      'id',
      'companyName',
      'companyKey',
      'active',
      'hostName',
      'hasInvalidEmails'
    ],
    [Enums.Relationship.Customer]: [
      'id',
      'companyName',
      'companyKey',
      'active',
      'hostName',
      'hasInvalidEmails'
    ]
  },
  chats: {
    [Enums.Relationship.Administrator]: [
      'id',
      'companyName',
      'companyKey',
      'pickedUpByAgentUsername',
      'createdOn',
      'modifiedOn',
      'assignedToAgentUsername',
      'initiatedBy',
      'isBlank',
      'appointmentStatus'
    ],
    [Enums.Relationship.Partner]: [
      'id',
      'companyName',
      'companyKey',
      'createdOn',
      'assignedToAgentUsername',
      'isLead',
      'leadType'
    ],
    [Enums.Relationship.Customer]: [
      'id',
      'createdOn',
      'isLead',
      'leadType',
      'appointmentStatus'
    ]
  },
  leads: {
    [Enums.Relationship.Administrator]: [
      'id',
      'companyName',
      'companyKey',
      'name',
      'phone',
      'email',
      'createdOn',
      'leadType',
      'initiatedBy',
      'hostName',
      'appointmentStatus'
    ],
    [Enums.Relationship.Partner]: [
      'id',
      'companyName',
      'companyKey',
      'name',
      'phone',
      'email',
      'processedByCustomer',
      'createdOn',
      'leadType',
      'initiatedBy',
      'leadCategoryName',
      'hostName'
    ],
    [Enums.Relationship.Customer]: [
      'id',
      'name',
      'phone',
      'email',
      'processedByCustomer',
      'createdOn',
      'leadType',
      'initiatedBy',
      'leadCategoryName',
      'appointmentStatus'
    ]
  },
  widgetCompanies: {
    [Enums.Relationship.Administrator]: [
      'id',
      'companyName',
      'companyKey',
      'active'
    ],
    [Enums.Relationship.Partner]: ['id', 'companyName', 'companyKey', 'active'],
    [Enums.Relationship.Customer]: ['id', 'companyName', 'companyKey', 'active']
  }
};

const generateActionButtons = Comp => ({
  name: 'actions',
  header: '',
  minWidth: 130,
  defaultFlex: 2,
  sortable: false,
  visible: true,
  render: value => {
    return (
      <div className="d-flex">{Comp ? <Comp value={value.data} /> : <></>}</div>
    );
  }
});

// visible columns by relation
const visibleByRelation = (
  columns,
  relation,
  forGrid = '',
  showAll = false
) => {
  let visibleCols = defaultVisibleCols?.[forGrid]?.[relation] || [];

  let updatedCols = columns.map(col => ({
    ...col,
    visible: visibleCols.includes(col.name) || showAll ? true : false
  }));

  return updatedCols;
};

// companies columns config
export const companiesTableConfig = Comp => {
  const { CompanyRelationship } = JSON.parse(storage.getItem('Profile'));

  // filter columns by isEnable || relation
  let columns = [...CompaniesColumns(CompanyRelationship)].filter(
    col => col.isEnable
  );

  // set visibleColums
  columns = visibleByRelation(
    columns,
    CompanyRelationship,
    'companies',
    CompanyRelationship === Enums.Relationship.Customer
  );

  // add action Buttons
  if (Comp) columns.push(generateActionButtons(Comp));

  return columns;
};

// chat columns config
export const chatTableConfig = (
  isAppointment = false,
  Comp = null,
  filter = true
) => {
  const { CompanyRelationship } = JSON.parse(storage.getItem('Profile'));

  // filter columns by isEnable || relation
  let columns = [...ChatColumns(CompanyRelationship, isAppointment)].filter(
    col => (filter ? col.isEnable : true)
  );

  // set visibleColums
  columns = visibleByRelation(columns, CompanyRelationship, 'chats');

  // add action Buttons
  if (Comp) columns.push(generateActionButtons(Comp));

  return columns;
};

// lead columns config
export const leadsTableConfig = (isEncrypt = false, Comp) => {
  const { CompanyRelationship } = JSON.parse(storage.getItem('Profile'));

  // filter columns by isEnable || relation
  let columns = [...LeadsColumns(CompanyRelationship, isEncrypt)].filter(
    col => col.isEnable
  );

  // set visibleColums
  columns = visibleByRelation(columns, CompanyRelationship, 'leads');

  // add action Buttons
  if (Comp) columns.push(generateActionButtons(Comp));

  return columns;
};

// widget setting companies columns config
export const widgetCompaniesTableConfig = Comp => {
  const { CompanyRelationship } = JSON.parse(storage.getItem('Profile'));

  // filter columns by isEnable || relation
  let columns = [...CompaniesColumns(CompanyRelationship)].filter(
    col => col.isEnable
  );

  // set visibleColums
  columns = visibleByRelation(columns, CompanyRelationship, 'companies');

  // add action Buttons
  if (Comp) columns.push(generateActionButtons(Comp));

  return columns;
};

export const historyTableConfig = [
  {
    name: 'companyName',
    header: '',
    defaultFlex: 1,
    // defaultWidth: 440,
    filterDelay: 800,
    // minWidth: 280,
    enableFilter: true,
    filterType: 'string',
    defaultVissible: true,
    className: 'hide-filter-button',
    render: ({ value }) => <ChatHistory companyName={value} />
  }
];

// provision columns config
export const provisionTableConfig = Comp => {
  const { CompanyRelationship } = JSON.parse(storage.getItem('Profile'));

  // filter columns by isEnable || relation
  let columns = [...ProvisionColumns(CompanyRelationship)];

  // set visibleColums
  columns = visibleByRelation(columns, CompanyRelationship, 'provision', true);

  // add action Buttons
  if (Comp) columns.push(generateActionButtons(Comp));

  return columns;
};

// my reports columns config
export const myReportTableConfig = Comp => {
  const { CompanyRelationship } = JSON.parse(storage.getItem('Profile'));

  // filter columns by isEnable || relation
  let columns = [...MyReportsColumns(CompanyRelationship)];

  columns = visibleByRelation(columns, CompanyRelationship, 'myReports', true);
  if (Comp) columns.push(generateActionButtons(Comp));

  return columns;
};

// que reports columns config
export const queuedReportTableConfig = Comp => {
  const { CompanyRelationship } = JSON.parse(storage.getItem('Profile'));

  // filter columns by isEnable || relation
  let columns = [...QueReportsColumns(CompanyRelationship)];

  columns = visibleByRelation(columns, CompanyRelationship, 'queReports', true);

  if (Comp) columns.push(generateActionButtons(Comp));

  return columns;
};

export const usersTableConfig = Comp => {
  const { CompanyRelationship } = JSON.parse(storage.getItem('Profile'));

  // filter columns by isEnable || relation
  let columns = [...companyUserColumns(CompanyRelationship)];

  columns = visibleByRelation(
    columns,
    CompanyRelationship,
    'companyUsers',
    true
  );

  if (Comp) columns.push(generateActionButtons(Comp));

  return columns;
};

// export const getKeysByFilterType = (fields = [], type = '') => {
//   fields.filter(c => c.filterType === type).map(c => c.name);
// };
