import { Form } from 'react-bootstrap';

const LocationFields = ({ form, handleOnChange }) => {
  return (
    <>
      <Form.Group controlId="city" className="normal-form-group mb-3">
        <Form.Control
          label="city"
          name="city"
          onChange={e => handleOnChange(e)}
          value={form.values['city'] || ''}
          placeholder="City"
        />
        {form.errors['city'] && form.touched['city'] && (
          <p className="text-red-1 field-error">{form.errors['city']}</p>
        )}
      </Form.Group>
      <div className="grid-column">
        <Form.Group controlId="state" className="normal-form-group mb-0">
          <Form.Control
            label="state"
            name="state"
            onChange={e => handleOnChange(e)}
            value={form.values['state'] || ''}
            placeholder="State"
          />
          {form.errors['state'] && form.touched['state'] && (
            <p className="text-red-1 field-error">{form.errors['state']}</p>
          )}
        </Form.Group>
        <Form.Group controlId="zip" className="normal-form-group mb-0">
          <Form.Control
            label="zip"
            name="zip"
            onChange={e => handleOnChange(e)}
            value={form.values['zip'] || ''}
            placeholder="Zip Code"
          />
          {form.errors['zip'] && form.touched['zip'] && (
            <p className="text-red-1 field-error">{form.errors['zip']}</p>
          )}
        </Form.Group>
      </div>
    </>
  );
};

export default LocationFields;
