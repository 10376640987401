import {
  getOptInLeads,
  incrementTotalUnreadMessagesCount,
  setMessagesRecived,
  setOptInLeads
} from 'features/messagingChats/messagingChats';
import { logIt } from 'helpers/logger';
import storage from 'helpers/storage';
import store from 'store';
import RTC from '../rtc';
import { db } from '../services/indexedDbService';

const Subscribe_Opt_In_Leads = (meta, callbacks) => {
  const user = JSON.parse(storage.getItem('Profile'));
  RTC().Subscribe({
    channels: ['/optinleads/' + user?.CompanyID],
    meta: meta,
    callbacks: callbacks
  });
};

const indexedDb = db();
export const Init_Opt_In_Leads = () => {
  //fetch from indexedDb
  Subscribe_Opt_In_Leads(
    {
      // meta
      contactIds: []
    },
    {
      onSuccess: function (args) {
        var data = args.getMeta();
        // data?.forEach(y => {
        //   indexedDb.optInLeadsDbService.insertRow(y.chatId, {
        //     chatId: y.chatId,
        //     leadId: y.leadId,
        //     contactId: y.contactId,
        //     jsonData: y
        //   });
        // });

        store.dispatch(setOptInLeads(data));
        if (data && data.length) {
          var totalUnreadLeads = 0;
          data.forEach(t => {
            if (!t.isRead) {
              totalUnreadLeads++;
            }
          });
          store.dispatch(
            incrementTotalUnreadMessagesCount({ totalCount: totalUnreadLeads })
          );
        }
        logIt('log', 'onSuccess::OptInLeads', args);
      },
      onFailure: function (args) {
        logIt('log', 'onFailure::OptInLeads', args);
      },
      onReceive: function (args) {
        var incomingOptInLead = args.getData();
        var state = store.getState();
        var optInLeads = [...state.MessagingChats.optInLeads];
        //see if it already exists
        var isPresent = optInLeads.find(
          x => x.chatId == incomingOptInLead.chatId
        );
        if (!isPresent) {
          optInLeads.unshift(incomingOptInLead);
          store.dispatch(setOptInLeads(optInLeads));
          store.dispatch(setMessagesRecived(true));
          store.dispatch(
            incrementTotalUnreadMessagesCount({
              chatId: incomingOptInLead.chatId
            })
          );
          //save in indexedDb
          // indexedDb.optInLeadsDbService.insertRow(incomingOptInLead.chatId, {
          //   chatId: incomingOptInLead.chatId,
          //   leadId: incomingOptInLead.leadId,
          //   contactId: incomingOptInLead.contactId,
          //   jsonData: incomingOptInLead
          // });

          setTimeout(() => {
            store.dispatch(setMessagesRecived(false));
          }, 1000);
        }
        logIt('log', 'onReceive::', args.getData().text);
      }
    }
  );
};
