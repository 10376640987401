import React from 'react';
import { Button, Card, OverlayTrigger } from 'react-bootstrap';
import UilInfoCircle from '@iconscout/react-unicons/icons/uil-info-circle';
import UilArrowLeft from '@iconscout/react-unicons/icons/uil-arrow-left';
import UilMapMarker from '@iconscout/react-unicons/icons/uil-map-marker';
import { useDispatch, useSelector } from 'react-redux';
import { formatTime, truncateText } from 'helpers/generic.methods';
import renderToolTip from 'helpers/ui-controls/renderToolTip';
import ChatEndButton from 'components/chat/ChatEndButton';
import ToggleRightPane from 'components/chat/ToggleRightPane';
import TruncateWithToolTip from 'components/TruncateWithToolTip';
import { Enums } from 'helpers/dropdown-enums';
import {
  selectChatDetailsRightPane,
  toggleChatDetailsRightPane
} from 'features/chat/chatSlice';
import { resetLeadFormUserCredentials } from 'features/lead/leadSlice';
import {
  getLastClosedChatFromStack,
  removeFromChatStack,
  removeSelectedChat,
  setSelectedChat
} from 'features/messagingChats/messagingChats';
import { UnSubscribeChat } from 'app/communication/services/chat/pub_sub_chat';

const ChatDetailHeader = ({
  onBackButtonHandler,
  selectedChat,
  isChatEnded,
  setToggleChatSuggestion,
  setToggleChatDetail,
  setToggleActiveChats,
  isMobile
}) => {
  const dispatch = useDispatch();
  let togglePanel = useSelector(selectChatDetailsRightPane);
  const lastClosedChat = useSelector(getLastClosedChatFromStack);
  const onCloseChat = () => {
    // UnSubscribeChat(selectedChat.getId());
    dispatch(resetLeadFormUserCredentials());

    dispatch(removeFromChatStack(selectedChat.getId()));
    if (lastClosedChat) {
      console.log('lastClosedChat', lastClosedChat);
      dispatch(setSelectedChat(lastClosedChat));
    } else {
      setToggleChatSuggestion(false);
      setToggleChatDetail(false);
      dispatch(
        toggleChatDetailsRightPane({
          show: false
        })
      );
      // RemoveChannelsFromRTCCleint(selectedChat.getId());
      if (isMobile) {
        setToggleActiveChats(false);
      }

      dispatch(removeSelectedChat());
    }
  };

  return (
    <Card.Header className="border-bottom-0 chat-details-header-container">
      <div className={`chat-details-header d-flex justify-content-between`}>
        <div className="chat-details-header-left d-flex align-items-center">
          {isMobile && (
            // <span className="chat-back-arrow" onClick={onBackButtonHandler}>
            //   <UilArrowLeft />
            // </span>
            <Button
              variant={'outline-secondary'}
              size="sm"
              className={'chat-back-arrow mr-2'}
              onClick={onBackButtonHandler}
            >
              <i className="uil uil-arrow-left arrow-left-icon"></i>
            </Button>
          )}

          <div className="chat-details-user-info">
            <div className="chat-header-visitor-name">
              {(selectedChat?.data?.status == Enums.ChatStatus.ClientHandling ||
                selectedChat?.data?.isOptInConsentGiven) &&
                truncateText(
                  `${
                    selectedChat?.data?.visitorInfoName ||
                    selectedChat?.getCompanyName()
                  }`,
                  isMobile ? 15 : 25
                )}
              {selectedChat?.data?.status !== Enums.ChatStatus.ClientHandling &&
                !selectedChat?.data?.isOptInConsentGiven &&
                truncateText(
                  `${selectedChat?.getCompanyName()}`,
                  isMobile ? 15 : 25
                )}
            </div>

            <div className="chat-header-start-time">
              <span className="id-span mr-1">{selectedChat?.getId()}</span>
              {!isMobile && (
                <>
                  <span className="d-inline-flex mr-1">
                    | <LocationText location={selectedChat?.getLocation()} />
                  </span>
                  <span>
                    |{' '}
                    <b>
                      Started at {formatTime(selectedChat?.getData().createdOn)}
                    </b>
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="chat-details-header-right">
          <OverlayTrigger
            placement={'left'}
            overlay={renderToolTip(
              'All the chats can be seen inside Chat section'
            )}
          >
            <UilInfoCircle className="list-icon-after-text mr-2" />
          </OverlayTrigger>
          {selectedChat?.data.status == Enums.ChatStatus.ClientHandling ||
          (selectedChat?.data.status == Enums.ChatStatus.Inactive &&
            selectedChat?.data.isOptInConsentGiven) ? (
            <Button
              variant={'outline-danger'}
              size="sm"
              style={{ padding: '5px 10px' }}
              className={'chat-close-btn ml-2'}
              onClick={() => {
                setToggleChatSuggestion(true);
                setToggleChatDetail(true);
                dispatch(
                  toggleChatDetailsRightPane({
                    show: !togglePanel.show,
                    activeParentTab: 'user-details'
                  })
                );
              }}
            >
              Lead Details
            </Button>
          ) : (
            <ChatEndButton
              selectedChat={selectedChat}
              isDisabled={isChatEnded}
              setToggleChatSuggestion={setToggleChatSuggestion}
              setToggleChatDetail={setToggleChatDetail}
              setToggleActiveChats={setToggleActiveChats}
            />
          )}

          <Button
            variant={'outline-danger'}
            size="sm"
            className={'chat-close-btn ml-2'}
            onClick={onCloseChat}
          >
            <i className="uil uil-times cross-icon"></i>
          </Button>
          <ToggleRightPane
            setToggleChatSuggestion={setToggleChatSuggestion}
            setToggleChatDetail={setToggleChatDetail}
          />
        </div>
        {/* {isMobile && (
                <span
                  className="chat-suggestion-btn"
                  onClick={onSuggestionButtonHandler}
                >
                  <UilInfoCircle />
                </span>
              )} */}
      </div>
    </Card.Header>
  );
};

export default ChatDetailHeader;

const LocationText = ({ location }) => {
  return (
    <span className="d-flex align-items-center ml-1">
      <UilMapMarker size="14" style={{ marginRight: '1px' }} />
      <TruncateWithToolTip
        text={location}
        tooltip={location.length > 18}
        limit={18}
      />
    </span>
  );
};
