export let EventProvider = function (publisher) {
  var me = this;
  return {
    InitPublisher: function (publish) {
      me.publish = publish;
      return this;
    },

    CustomEvent: {
      // NOTE only allowed to send dynamic data as label or dynamic action
      Publish: eventParameters => {
        const _eventParameters = {
          ...eventParameters,
          ...{
            nonInteraction: true, // optional, true/false
            transport: 'xhr'
          }
        };
        me.publish(_eventParameters);
      }
    },
    Redirection: {
      SwitchToLegacyPortal: () => {
        console.log('SwitchToLegacyPortal::');
        me.publish(
          'Redirection',
          'SwitchTo',
          window.location.pathname + ' > Old Portal'
        );
      },
      SwitchToLegacyDashboard: () => {
        me.publish(
          'Redirection',
          'SwitchTo',
          window.location.pathname + ' > Old Dashboard'
        );
      },
      RedirectedTo404: prevPath => {
        me.publish('Redirection', '404 Page', 'Previous path: ' + prevPath);
      }
    },
    Export: {
      Publish: (category, action, label, value) => {
        me.publish(category, action, label, value);
      }
    },
    Messaging: {
      ChatConnectionEstablished: (action, label) => {
        me.publish('Messaging', `Connection Established | ${action}`, label);
      }
    },
    Authentication: {
      Login: (action, label) => {
        me.publish('Authentication', `Login | ${action}`, label);
      },
      SsoViaJWT: (action, label) => {
        me.publish('Authentication', `SSO Authentication | ${action}`, label);
      }
    }
  }.InitPublisher(publisher);
};
