import React from 'react';

const AnimateListItem = ({ children, id }) => {
  const prevNodeRef = React.useRef();

  React.useEffect(() => {
    animateAndTransform();
    handleNodePlacement();
  }, [id]);

  const handleNodePlacement = () => {
    var domNode = document.getElementById(id);
    var boundingBox = domNode.getBoundingClientRect();
    prevNodeRef.current = boundingBox;
  };

  const animateAndTransform = function () {
    var domNode = document.getElementById(id);
    var [dX, dY] = getPositionDelta(domNode, id);
    if (domNode) {
      requestAnimationFrame(function () {
        domNode['style'].transition = 'transform 0s';
        domNode['style'].transform = 'translate(' + dX + 'px, ' + dY + 'px)';
        requestAnimationFrame(function () {
          domNode['style'].transform = '';
          domNode['style'].transition = 'transform 400ms';
        });
      });
    }
  };

  const getPositionDelta = function (domNode) {
    var newBox = domNode?.getBoundingClientRect();
    var oldBox = prevNodeRef.current;
    return oldBox
      ? [oldBox.left - newBox.left, oldBox.top - newBox.top]
      : [0, 0];
  };
  return React.cloneElement(children, { id });
};

export default AnimateListItem;
