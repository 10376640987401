import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Form, Spinner, Badge } from 'react-bootstrap';
import {
  UilCopy,
  UilEnvelopeUpload,
  UilCheckCircle
} from '@iconscout/react-unicons';
import UILInfoCircle from '@iconscout/react-unicons/icons/uil-info-circle';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import storage from 'helpers/storage';
import { useFormik } from 'formik';
import { widgetEditorCode } from 'helpers/schemas/widgetEditorSchemas/initialValues';
import { sendCode } from 'features/widget-editor/widgetEditorSlice';
import { toast } from 'react-toastify';
import CustomAutoCompleteFilter from 'pages/portal/dashboard/filters/custom-auto-complete-filter';
import { useSelector } from 'react-redux';
import { selectRoles } from 'features/roles/rolesSlice';
import renderToolTip from 'helpers/ui-controls/renderToolTip';
import { widgetCodeSchemas } from 'helpers/schemas/widgetEditorSchemas/schemas';
import { duration, success } from 'helpers/variables';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useViewport from 'helpers/viewport-hook';
import { useTranslation } from 'react-i18next';
import { ToastTemplate } from 'helpers/generic.methods';

const useStyles = makeStyles(theme => ({
  inputClass: { width: '100% !important' },
  hint: { fontSize: '13px', marginLeft: '14px' },
  errorClass: ({ isSmall }) => ({
    position: isSmall ? 'static' : 'absolute',
    top: '100%'
  })
}));

const IntegrateWidgetCode = ({
  companyKey = '',
  companyId = '',
  HostDomain = '',
  apiLoading = false
}) => {
  let user = JSON.parse(storage.getItem('Profile'));
  const screen = useViewport();
  const classes = useStyles({ isSmall: screen.sm });
  const [loading, setLoading] = useState();
  const { roles } = useSelector(selectRoles);
  const { t } = useTranslation();
  const [copiedToClipBoard, setCopiedToClipBoard] = useState(false);

  const handleFormSubmit = e => {
    let data = {
      developerEmails: e.developerEmails.join(','),
      widgetCode: getSource()
    };

    setLoading(true);
    sendCode(data)
      .then(response => {
        if (response) {
          setLoading(false);
          widgetCodeFormIk.resetForm();
          toast.success(success.sendSuccess('Email'));
        }
      })
      .catch(error => {
        setLoading(false);
        toast.error(
          ToastTemplate.error(error?.response?.data?.Message || error.message)
        );
      });
  };

  const widgetCodeFormIk = useFormik({
    enableReinitialize: true,
    initialValues: widgetEditorCode,
    validationSchema: widgetCodeSchemas,
    onSubmit: handleFormSubmit
  });

  useEffect(() => {
    widgetCodeFormIk.setValues({
      ...widgetCodeFormIk.values
    });
  }, []);

  useEffect(() => {
    if (copiedToClipBoard) {
      setTimeout(() => {
        setCopiedToClipBoard(false);
      }, 800);
    }
  }, [copiedToClipBoard]);

  const onChangeEmail = (data, e) => {
    if (data.length <= 3) {
      widgetCodeFormIk.setValues({
        ...widgetCodeFormIk.values,
        developerEmails: data || []
      });
    } else {
      widgetCodeFormIk.setErrors({
        developerEmails: 'Maximum 3 emails are allowed at a time.'
      });
    }
  };

  const copyWidgetCode = e => {
    let widgetCodeText = document.getElementById(
      `${companyId}-widget-code`
    ).textContent;
    if (widgetCodeText) {
      navigator.clipboard.writeText(widgetCodeText);
    }
    setCopiedToClipBoard(true);
  };

  const codeSnippet = () => {
    return (
      <span>
        <span style={{ color: '#2e21ff' }}>&lt;</span>
        <span style={{ color: '#ad6467' }}>script</span>{' '}
        <span style={{ color: '#ff4657' }}>src</span>
        <span style={{ color: '#2e21ff' }}>="{getSource()}"</span>
        <span style={{ color: '#ff4657' }}> async</span>
        <span style={{ color: '#2e21ff' }}>=""&gt;</span>
        <span style={{ color: '#2e21ff' }}>&lt;/</span>
        <span style={{ color: '#ad6467' }}>script</span>
        <span style={{ color: '#2e21ff' }}>&gt;</span>
      </span>
    );
  };

  const getSource = () => {
    let src = `${HostDomain}scripts/invitation.ashx?company=${companyKey}`;
    return src;
  };

  return (
    <>
      <Row className="integrate-code-container">
        <Col>
          <Card className="border-0">
            <Card.Body>
              <Row>
                <Col>
                  <div className="mb-3 msg-textarea-field">
                    <div className="d-flex align-items-center mb-3">
                      <span className="text-primary fw-700 ">
                        {t('widget.widget_script')}
                      </span>
                      <OverlayTrigger
                        placement="auto"
                        overlay={renderToolTip(
                          'You need to add the code snippet below into every page of your website, just before the closing </body> tag.'
                        )}
                      >
                        <UILInfoCircle
                          className="list-icon-after-text ml-1"
                          size={22}
                        />
                      </OverlayTrigger>
                      {apiLoading ? (
                        <Spinner
                          className="ml-2"
                          animation="border"
                          size="sm"
                          variant="primary"
                        />
                      ) : null}
                    </div>
                    <Form.Control
                      id={`${companyId}-widget-code`}
                      as="div"
                      name={'widgetCode'}
                      className="mb-3 h-auto"
                      disabled={apiLoading}
                    >
                      {codeSnippet()}
                    </Form.Control>
                    <div className="d-flex" style={{ alignItems: 'baseline' }}>
                      <button
                        type="button"
                        className="btn-md d-flex align-items-center btn btn-primary"
                        onClick={copyWidgetCode}
                        disabled={apiLoading}
                        style={{ width: '130px' }}
                      >
                        {copiedToClipBoard ? (
                          <>
                            <UilCheckCircle
                              className="mr-1"
                              style={{ height: 20, width: 20 }}
                            />
                            Copied
                          </>
                        ) : (
                          <>
                            <UilCopy
                              className="mr-1"
                              style={{ height: 20, width: 20 }}
                            />
                            {t('widget.copy_snippet')}
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                  {roles.settings.widgetEditorPluginLink.view && (
                    <>
                      <div className="d-flex justify-content-between align-items-center mt-3">
                        <p className="m-0 fw-700">{t('widget.widget_or')}</p>
                        <hr
                          style={{
                            width: '97%'
                          }}
                        />
                      </div>
                      <p className="mx-0 my-2 fw-400">
                        {t('widget.easy_install')}{' '}
                        <a
                          className="text-primary fw-600 wordpress-link"
                          href={
                            'https://www.apexchat.com/faqs/install-apexchat-wordpress-plugin/'
                          }
                          target={'_blank'}
                        >
                          {t('widget.wordpress_plugin')}
                        </a>
                      </p>
                    </>
                  )}
                  <div className="d-flex justify-content-between align-items-center mt-3">
                    <p className="m-0 fw-700"> {t('widget.widget_or')}</p>
                    <hr
                      style={{
                        width: '97%'
                      }}
                    />
                  </div>
                  <Form
                    className="form"
                    onSubmit={widgetCodeFormIk.handleSubmit}
                    onKeyPress={e => {
                      e.key === 'Enter' && e.preventDefault();
                    }}
                    autoComplete="off"
                  >
                    <Row className="my-2">
                      <Col md={12} className="pb-3">
                        <span className="d-block mb-1">
                          {t('widget.send_developer')}
                        </span>
                        <div className="d-flex align-items-center">
                          <CustomAutoCompleteFilter
                            editable={!apiLoading}
                            setFunction={onChangeEmail}
                            handleTextChange={text =>
                              console.log('textchange', text)
                            }
                            options={[]}
                            loading={false}
                            name={'developerEmails'}
                            selected={
                              widgetCodeFormIk.values['developerEmails']
                            }
                            error={
                              (widgetCodeFormIk.values['developerEmails']
                                .length ||
                                widgetCodeFormIk.touched['developerEmails']) &&
                              widgetCodeFormIk.errors['developerEmails']
                            }
                            handleBlur={widgetCodeFormIk.handleBlur}
                            liStyles={{ alignSelf: 'end', width: '100%' }}
                            variant="outlined"
                            isFilterWrapper={false}
                            isMultiSelectEnable={true}
                            freeSolo={true}
                            placeholder={t('widget.designer_email')}
                            styles={{
                              marginBottom: '0px'
                            }}
                            inputClass={classes.inputClass}
                            errorClass={classes.errorClass}
                          />

                          <OverlayTrigger
                            placement="auto"
                            overlay={renderToolTip(
                              t('widget.widget_error_info')
                            )}
                          >
                            <UILInfoCircle className="list-icon-after-text ml-1" />
                          </OverlayTrigger>
                        </div>
                      </Col>
                      <Col className="d-flex align-items-center mt-2">
                        <button
                          disabled={!widgetCodeFormIk.isValid || loading}
                          type="button"
                          className="btn-md d-flex align-items-center btn btn-primary"
                          onClick={widgetCodeFormIk.handleSubmit}
                        >
                          {loading ? (
                            <i className="uil uil-spinner spinner mr-1"></i>
                          ) : (
                            <UilEnvelopeUpload
                              className="mr-1"
                              style={{ height: 20, width: 20 }}
                            />
                          )}
                          {t('widget.send_instruction')}
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default IntegrateWidgetCode;
