import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import './style.scss';
import HomeImage from 'static/images/home.png';
import {
  UilCommentAltLines,
  UilCommentAlt,
  UilUsdCircle,
  UilArrowGrowth
} from '@iconscout/react-unicons';
import { useEffect, useState } from 'react';
import { getQuickStats } from 'features/analytics/analyticSlice';
import {
  Enums,
  getObjectKeyByValue,
  TimeIntervalsEnum
} from 'helpers/dropdown-enums';
import CardComponent from './card-component';
import { useHistory } from 'react-router';
import ScrollBar from 'components/Scrollbars';
import { useSelector } from 'react-redux';
import { selectRoles } from 'features/roles/rolesSlice';
import { useTranslation } from 'react-i18next';
import { AppMetaProvider } from 'components/Sidebar/helper';
import { toast } from 'react-toastify';
import { ToastTemplate } from 'helpers/generic.methods';

const Home = () => {
  let obj = {
    totalChats: 2,
    totalLeads: 3,
    activeChats: 11
  };

  const history = useHistory();
  const { t } = useTranslation();
  const [totalChats, setTotalChats] = useState({});
  const [totalLeads, setTotalLeads] = useState({});
  const [activeChats, setActiveChats] = useState({});
  const { roles } = useSelector(selectRoles);
  const { whatsNewIframeLink } = AppMetaProvider().GetStaticMeta;
  const setMethods = {
    totalChats: setTotalChats,
    totalLeads: setTotalLeads,
    activeChats: setActiveChats
  };

  const colorAndIcons = {
    TotalChats: {
      color: '#6201ed',
      icon: <UilCommentAlt />
    },
    LeadConversionRate: {
      color: '#29c0b1',
      icon: <UilUsdCircle />
    },
    TotalLeads: {
      color: '#2c50ed',
      icon: <UilArrowGrowth />
    },
    ActiveChats: {
      color: '#222437',
      icon: <UilCommentAltLines />
    }
  };

  useEffect(() => {
    getAllQuickStats();
  }, []);

  const getAllQuickStats = () => {
    Object.values(obj).map((val, index) => {
      let payload = {
        timeIntervals: parseInt(TimeIntervalsEnum.Day),
        BusinessCategory: 0,
        companyKeyArray: [],
        deviceTypeArray: [],
        initiateByArray: [],
        pageSize: 10,
        userTypeArray: [],
        widgetType: val
      };
      let key = getObjectKeyByValue(obj, val);
      setMethods[key]({});
      getQuickStats(payload).then(res => {
        if (res && res.data) {
          const { data } = res.data;
          let key = getObjectKeyByValue(obj, data[0].widgetId);
          setMethods[key](data[0] || {});
        }
      });
    });
  };

  return (
    <>
      <ScrollBar horizontalScroll={false} style={{ height: 'inherit' }}>
        <Container fluid className="home-container h-100">
          <Row>
            <Col className="m-3">
              <Card className="card-border">
                <Card.Body>
                  {/*  Image and Dummy text removed According to UIUX-925 */}

                  <Row>
                    <Col>
                      <h2 className="mb-5 d-flex align-items-center negative-margin">
                        {t(`company.general.analyticsInsights`)}
                        {roles &&
                        Object.keys(roles).length &&
                        roles.analytics.view ? (
                          <Button
                            className="btn-md detail-button ml-3"
                            type="button"
                            variant="primary"
                            onClick={() => {
                              history.push('/analytics');
                            }}
                          >
                            {t(`company.general.detailView`)}
                          </Button>
                        ) : null}
                      </h2>
                      <div className="cards-container">
                        {totalChats && Object.keys(totalChats).length ? (
                          <CardComponent
                            title={totalChats.widgetName}
                            icon={colorAndIcons.TotalChats.icon}
                            bgColor={colorAndIcons.TotalChats.color}
                            value={totalChats.current}
                            footer="lastHours"
                          />
                        ) : (
                          <CardComponent loading={true} />
                        )}
                        {totalLeads && Object.keys(totalLeads).length ? (
                          <CardComponent
                            title={totalLeads.widgetName}
                            icon={colorAndIcons.TotalLeads.icon}
                            bgColor={colorAndIcons.TotalLeads.color}
                            value={totalLeads.current}
                            footer="lastHours"
                          />
                        ) : (
                          <CardComponent loading={true} />
                        )}
                        {totalChats &&
                        Object.keys(totalChats).length &&
                        totalLeads &&
                        Object.keys(totalLeads).length ? (
                          <CardComponent
                            title={'LeadConversionRate'}
                            icon={colorAndIcons.LeadConversionRate.icon}
                            bgColor={colorAndIcons.LeadConversionRate.color}
                            value={`${
                              totalChats.current
                                ? Math.round(
                                    (totalLeads.current / totalChats.current) *
                                      100
                                  )
                                : 0
                            }`}
                            footer="lastHours"
                            showPercentSign={true}
                          />
                        ) : (
                          <CardComponent loading={true} />
                        )}

                        {activeChats && Object.keys(activeChats).length ? (
                          <CardComponent
                            title={activeChats.widgetName}
                            icon={colorAndIcons.ActiveChats.icon}
                            bgColor={colorAndIcons.ActiveChats.color}
                            value={activeChats.current}
                          />
                        ) : (
                          <CardComponent loading={true} />
                        )}
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Row>
                  <iframe
                    id="iframe-whats-new"
                    className="whats-new-frame"
                    src={whatsNewIframeLink}
                    title="Iframe Example"
                  ></iframe>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </ScrollBar>
    </>
  );
};

export default Home;
