import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Badge, Button } from 'react-bootstrap';
import moment from 'moment';
import SidebarRight from 'components/Sidebar/sidebar-right';
import {
  removeAllCompaniesFromState,
  removeCompaniesFromState,
  getAllCompanies,
  exportAllCompanies,
  selectCompany
} from 'features/companies/companiesSlice';
import storage from 'helpers/storage';
import { useDispatch, useSelector } from 'react-redux';
import Company from '../company/index-v2';
import {
  fetchMobileSuggestionsAsync,
  selectMobileSuggestions
} from 'features/fetchGeneralData/fetchGeneralDataOnAuth';
import {
  TabComponent,
  TabsContainer,
  TabNav
} from '../../../components/TabsComponent';
import '@inovua/reactdatagrid-community/index.css';
import { companiesTableConfig } from 'utils/tableConfig';
import { toast } from 'react-toastify';
import { GridWrapper } from '../../../components/GridWrapper';
import DynamicColumns from '../report/dynamicColumns';
import UilEye from '@iconscout/react-unicons/icons/uil-eye';
import useQuery from 'routes/searchQueryHook';
import { getFilterValue } from 'components/GridWrapper/helper';
import { ToastTemplate } from 'helpers/generic.methods';

const Companies = props => {
  let query = useQuery();
  const [openColapse, setOpenColapse] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [selectedTab, setSelectedTab] = useState(0);
  const dispatch = useDispatch();
  const mobileSuggestions = useSelector(selectMobileSuggestions);
  const [loading, setLoading] = useState(false);
  const [vissibleColumns, setVissibleColumns] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState(null);

  const tabKey = 'companies-selectedTab';
  const dataKey = 'companies-selectedCompanies';
  // Colapsable panel Open/Close Handler
  const handleOpen = status => {
    setOpenColapse(status);
  };

  // get company data from selectCompanies selector for tab title
  const { company: selectedCompanyData } = useSelector(e =>
    selectCompany(e, selectedTab)
  );

  // Sppend Close button to TabTitle
  const tabTitle = (index, company) => {
    return (
      <TabNav
        totalTabs={selectedCompanies.length}
        index={index}
        id={company.id}
        title={company.companyName || selectedCompanyData.companyName || ''}
        removeTab={removeCompany}
      />
    );
  };
  // initialize selected Tab Data after Redirect From other screen
  useEffect(() => {
    setSelectedTab(storage.getItem(tabKey));
    let data = JSON.parse(storage.getItem(dataKey)) || [];
    setSelectedCompanies(data);
    updateSelectedData(data);
  }, []);

  // get id params from url if exist and add company id in selectedCompanies array and remove previously selected companies
  // and open sidebar
  useEffect(() => {
    let id = query.get('id');
    if (id) {
      updateCompaniesAndSaveToLS([
        { id: Number(id), selectedMenu: 'general-basic-details' }
      ]);
      updateTabAndSaveToLS(Number(id));
      handleOpen(true);
    }
  }, []);

  // select Company
  const handleSelect = payload => {
    const { data } = payload;
    if (data.length || Object.keys(data).length) {
      let temp = data;
      if (data.length) temp = data[0];
      let selected = selectedCompanies.filter(sc => sc.id === Number(temp.id));
      if (selected.length === 0) {
        let companies = [
          { ...temp, selectedMenu: 'general-basic-details' },
          ...selectedCompanies
        ];
        updateCompaniesAndSaveToLS(companies);
      } else if (data instanceof Object && !Array.isArray(data)) {
        if (!Object.keys(selected).includes(data.id)) {
          let index = selectedCompanies.findIndex(com => com.id === data.id);
          removeCompany(index, data.id);
        }
      }

      updateTabAndSaveToLS(temp.id);
      if (Array.isArray(data)) handleOpen(true);
    } else {
      clearAll();
    }
  };

  // Select Tab Handler
  const handleTabSelect = (tabKey, ind) => {
    // selectedCompanies.findIndex(com => com.id == tabKey);
    if (selectedTab !== tabKey) {
      updateTabAndSaveToLS(Number(tabKey));
    }
  };

  // Remove  Tab From Selected Companies
  const removeCompany = (index, id, nextIndex) => {
    let companies = [...selectedCompanies];
    companies.splice(index, 1);

    if (selectedTab === selectedCompanies[index].id) {
      updateTabAndSaveToLS(selectedCompanies[nextIndex].id);
    }

    updateCompaniesAndSaveToLS(companies);
    dispatch(removeCompaniesFromState({ id: id }));
  };

  //  update Selected Companies and save to Local Storage
  const updateCompaniesAndSaveToLS = companies => {
    setSelectedCompanies(companies);
    updateSelectedData(companies);
    storage.setItem(dataKey, companies);
  };

  const updateSelectedData = companies => {
    let checked = {};
    companies.forEach(com => {
      checked[com.id] = com;
    });
    setSelectedData(checked);
  };

  // update Tabs and save To Local Storage
  const updateTabAndSaveToLS = key => {
    setSelectedTab(key);
    storage.setItem(tabKey, key);
  };

  // update Selected Tab Menu For get Back to same menu after Redirecting
  const selectTabMenu = menuKey => {
    let index = selectedCompanies.findIndex(
      sc => sc.id === Number(selectedTab)
    );
    if (index > -1) {
      let companies = selectedCompanies;
      companies[index] = { ...companies[index], selectedMenu: menuKey };
      updateCompaniesAndSaveToLS(companies);
    }
  };

  const getDateFilterValue = data => {
    let updatedValue;
    if (data.name === 'createdOn' && data.operator === 'before') {
      updatedValue = moment(data.value).local();
    } else if (data.name === 'createdOn' && data.operator === 'after') {
      updatedValue = moment().local();
    } else {
      updatedValue = data.value;
    }
    return updatedValue;
  };

  const loadData = ({ skip, limit, sortInfo, filterValue }) => {
    try {
      setLoadingMessage('Loading ...');
      const updatedValue = filterValue
        .filter(v => v.value !== '')
        .map(v => ({
          propertyName: v.name,
          propertyValue1: getFilterValue(v),
          propertyValue2:
            v.name === 'createdOn' &&
            (v.operator === 'before' || v.operator === 'after')
              ? getDateFilterValue(v)
              : '',
          propertyValue3: [],
          filterDataType: 2,
          comparison: 2
        }));
      let payload = {
        start: skip,
        limit: limit,
        filter: JSON.stringify(updatedValue),
        dir: !sortInfo ? 'DESC' : sortInfo.dir === 1 ? 'ASC' : 'DESC',
        sort: sortInfo ? sortInfo.name : 'createdOn'
      };
      setSelectedFilters(payload);
      return getAllCompanies(payload).then(response => {
        if (response.data.totalSize > 20) {
          setDataLimit(skip + response.data.data.length);
        } else {
          setDataLimit(response.data.data.length);
        }
        const totalCount = response.data.totalSize;
        setTotalRecords(totalCount);
        return { data: response.data.data, count: parseInt(totalCount) };
      });
    } catch (error) {
      toast.error(ToastTemplate.error(error || error.message));
    }
  };

  useEffect(() => {
    !mobileSuggestions && dispatch(fetchMobileSuggestionsAsync());
  }, [mobileSuggestions, dispatch]);

  const dataSource = useCallback(loadData, []);
  const [dataLimit, setDataLimit] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loadingMessage, setLoadingMessage] = useState('Loading ...');

  const renderColumnContextMenu = useCallback((menuProps, { cellProps }) => {
    menuProps.items = menuProps.items.splice(0, 8);
  }, []);

  //  TODO clear all selected tabs
  const clearAll = () => {
    updateCompaniesAndSaveToLS([]);
    updateTabAndSaveToLS('');
    dispatch(removeAllCompaniesFromState());

    // remove query params from url if exist
    if (query.has('id')) {
      query.delete('id');
      props.history.replace({
        search: ''
      });
    }
  };

  const handleColumnsChange = data => {
    setVissibleColumns({ ...data });
  };

  const handleView = val => {
    console.log('view', val);
  };

  const ActionButtons = ({ value }) => (
    <>
      <Button
        className="btn-sm btn-flex mr-1"
        variant="lightgray-3"
        onClick={e => handleView(value)}
      >
        <UilEye className="mr-1" />
        View
      </Button>
    </>
  );
  return (
    <>
      <Container fluid>
        <Row>
          <Col className="m-3">
            <h2>
              Companies{' '}
              <Badge variant="primary p-1 badge-align">
                {loading ? '...' : `${dataLimit} / ${totalRecords}`}
              </Badge>
            </h2>
          </Col>
          <div className="d-flex" style={{ margin: '20px', gap: '10px' }}>
            <DynamicColumns
              handleChange={handleColumnsChange}
              menuOptions={companiesTableConfig()}
              selectedFilters={selectedFilters}
              endPointAPI={exportAllCompanies}
              fileNamePrefix="Companies"
            />
          </div>
        </Row>
        <Row>
          <Col className={loading && 'blux-text'}>
            <div className="mobile_view">
              <GridWrapper
                columns={companiesTableConfig(ActionButtons)}
                // actionButtons={ActionButtons}
                totalRecords={totalRecords}
                dataSource={dataSource}
                setLoading={setLoading}
                loadingMessage={loadingMessage}
                renderColumnContextMenu={renderColumnContextMenu}
                selectedData={selectedData}
                handleSelect={handleSelect}
                vissibleColumns={vissibleColumns}
              />
            </div>
          </Col>
        </Row>
      </Container>

      {selectedCompanies.length ? (
        <SidebarRight handleOpen={handleOpen} open={openColapse}>
          <TabsContainer
            activeKey={selectedTab}
            clearAll={clearAll}
            openColapse={openColapse}
            onTabSelect={e => handleTabSelect(e)}
            selectedData={selectedCompanies}
          >
            {selectedCompanies.map((company, index) => (
              <TabComponent
                tabClassName={'tab-ui'}
                key={company.id}
                id={`tab-${company.id}`}
                eventKey={company.id}
                title={tabTitle(index, company)}
              >
                <Company
                  selectMenu={selectTabMenu}
                  selectedTab={selectedTab}
                  selectedCompany={company}
                  removeSelectedCompanies={clearAll}
                />
              </TabComponent>
            ))}
          </TabsContainer>
        </SidebarRight>
      ) : null}
    </>
  );
};

export default Companies;
