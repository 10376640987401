import React, { useState, useEffect } from 'react';
import { Button, Form, Modal, OverlayTrigger, Spinner } from 'react-bootstrap';
import { useFormik } from 'formik';
import { success } from 'helpers/variables';
import { toast } from 'react-toastify';

import CustomAutoCompleteFilter from 'pages/portal/dashboard/filters/custom-auto-complete-filter';
import renderToolTip from 'helpers/ui-controls/renderToolTip';
import UilInfoCircle from '@iconscout/react-unicons/icons/uil-info-circle';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useViewport from 'helpers/viewport-hook';
import './styles.scss';
import {
  forwardLeadSchemas,
  InitialValues
} from 'helpers/schemas/forwardLeadSchemas';
import { onForwardLead } from 'features/lead/leadSlice';
import { ToastTemplate } from 'helpers/generic.methods';

const useStyles = makeStyles(theme => ({
  inputClass: { width: '100% !important' },
  hint: { fontSize: '13px', marginLeft: '14px' },
  errorClass: ({ isSmall }) => ({
    position: isSmall ? 'static' : 'absolute',
    top: '100%'
  })
}));

const ForwardLeadModal = ({
  showModal,
  handleClose,
  size = 'lg',
  savedData = null,
  leadId
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const { t } = useTranslation();
  const screen = useViewport();
  const classes = useStyles({ isSmall: screen.sm });

  const handleModalClose = payload => {
    setLoading(false);
    handleClose(payload);
    setSelectedData(null);
    forwardLeadFormIk.resetForm();
  };

  const handleModalSave = formValues => {
    setLoading(true);
    let data = { ...formValues, emails: formValues.emails.join(','), leadId };
    onForwardLead(data)
      .then(response => {
        if (response.success && response.data) {
          setLoading(false);
          handleModalClose(InitialValues);
          toast.success(success.saveSuccess('The Lead', 'forwarded'));
        } else {
          setLoading(false);
        }
      })
      .catch(error => {
        setLoading(false);
        toast.error(ToastTemplate.error(error || error.message));
      });
  };

  const fetchData = () => {
    if (savedData && Object.keys(savedData).length) {
      setFormValues({ ...savedData });
    }
  };

  const handleOnChange = e => {
    let value = e.target.value;
    setFormValues({ additional_notes: value });
  };

  /** Init Formik */
  const forwardLeadFormIk = useFormik({
    enableReinitialize: true,
    initialValues: InitialValues,
    validationSchema: forwardLeadSchemas,
    onSubmit: handleModalSave
  });

  const setFormValues = updatedFormValueObject => {
    forwardLeadFormIk.setValues({
      ...forwardLeadFormIk.values,
      ...updatedFormValueObject
    });
  };

  const onChangeEmail = (data, e) => {
    if (data.length <= 3) {
      setFormValues({ emails: data || [] });
    } else {
      forwardLeadFormIk.setErrors({
        emails: 'Maximum 3 emails are allowed at a time.'
      });
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={() => handleModalClose(forwardLeadFormIk.values)}
      onShow={fetchData}
      size={size}
      // keyboard={false}
      id="viewModal"
      backdrop={true}
      dialogClassName={'forwardModalContainer'}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable={true}
      backdropClassName="modal-dark-backdrop"
    >
      <Modal.Body>
        <Modal.Title id="contained-modal-title-vcenter">
          {t('company.leadDetails.forwardLeadModal.title')}
        </Modal.Title>
        <div className="mobile_view mt-3">
          <Form className="form" autoComplete="off">
            <div className="row">
              <div className="col-12">
                <span className="d-block mb-1">
                  {t('company.leadDetails.forwardLeadModal.email_label')}
                </span>
                <div className="d-flex align-items-center">
                  <CustomAutoCompleteFilter
                    editable={true}
                    setFunction={onChangeEmail}
                    handleTextChange={text => console.log('textchange', text)}
                    options={[]}
                    loading={false}
                    name={'emails'}
                    selected={forwardLeadFormIk.values['emails']}
                    error={
                      (forwardLeadFormIk.values['emails'].length ||
                        forwardLeadFormIk.touched['emails']) &&
                      forwardLeadFormIk.errors['emails']
                    }
                    handleBlur={forwardLeadFormIk.handleBlur}
                    liStyles={{
                      alignSelf: 'end',
                      width: '100%',
                      listStyle: 'none'
                    }}
                    variant="outlined"
                    isFilterWrapper={false}
                    isMultiSelectEnable={true}
                    freeSolo={true}
                    placeholder={t(
                      'company.leadDetails.forwardLeadModal.email_placeholder'
                    )}
                    styles={{
                      marginBottom: '0px'
                    }}
                    inputClass={classes.inputClass}
                    errorClass={classes.errorClass}
                  />

                  <OverlayTrigger
                    placement="auto"
                    overlay={renderToolTip(
                      t('company.leadDetails.forwardLeadModal.email_info')
                    )}
                  >
                    <UilInfoCircle className="list-icon-after-text ml-1" />
                  </OverlayTrigger>
                </div>
              </div>
              <div className="col-12">
                <Form.Group
                  controlId="additional_notes"
                  className="normal-form-group mb-3 mt-3"
                >
                  <Form.Label>
                    {t('company.leadDetails.forwardLeadModal.note_label')}
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    label="Additional Notes"
                    name="additional_notes"
                    onChange={e => handleOnChange(e)}
                    onBlur={forwardLeadFormIk.handleBlur}
                    value={forwardLeadFormIk.values.additional_notes}
                    placeholder={t(
                      'company.leadDetails.forwardLeadModal.note_placeholder'
                    )}
                    rows={6}
                    minrows={1}
                    className="note-field"
                  />
                  {forwardLeadFormIk.errors['additional_notes'] &&
                    forwardLeadFormIk.touched['additional_notes'] && (
                      <p
                        className={`text-red-1 field-error mt-0 position-absolute`}
                      >
                        {forwardLeadFormIk.errors['additional_notes']}
                      </p>
                    )}
                </Form.Group>
              </div>
            </div>
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer className={`user-modal-footer`}>
        {/* <div className="d-flex"> */}
        <Button
          type="button"
          variant="outline-primary"
          className="passBtn btn-md btn-flex mr-2"
          onClick={() => handleModalClose(forwardLeadFormIk.values)}
        >
          <span>{t('company.leadDetails.forwardLeadModal.closeBtn')}</span>
        </Button>
        <Button
          type="button"
          variant="outline-primary"
          className="passBtn btn-md btn-flex"
          disabled={loading}
          onClick={forwardLeadFormIk.handleSubmit}
        >
          <span>{t('company.leadDetails.forwardLeadModal.sendBtn')}</span>
          {loading && (
            <Spinner className="ml-2" animation="border" size="sm" as="span" />
          )}
        </Button>
        {/* </div> */}
      </Modal.Footer>
    </Modal>
  );
};

export default ForwardLeadModal;
