import React from 'react';
import {
  UilPhoneTimes,
  UilPhone,
  UilPhoneSlash
} from '@iconscout/react-unicons';
const StatusText = ({ flag }) => {
  return (
    <div
      className={`flag-wrapper ${
        flag !== 3 ? 'text-success' : 'text-danger'
      } align-items-center d-flex px-1r`}
    >
      {getStatus(flag)}
    </div>
  );
};

const getStatus = flag => {
  switch (flag) {
    case 1:
      return (
        <>
          <UilPhone className="list-icon-before-text" />
          <span className="ml-1">Completed</span>
        </>
      );
    case 2:
      return (
        <>
          <UilPhoneSlash className="list-icon-before-text" />
          <span className="ml-1">Not Called</span>
        </>
      );
    case 3:
      return (
        <>
          <UilPhoneTimes className="list-icon-before-text" />
          <span className="ml-1">Failed</span>
        </>
      );
    default:
      return null;
  }
};
export default StatusText;
