import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Badge, OverlayTrigger } from 'react-bootstrap';
import renderToolTip from 'helpers/ui-controls/renderToolTip';
import { getSelectedChatLastVisitorOrUserMessage } from 'features/messagingChats/messagingChats';
import { calculateIdleTime, getIsChatEnded } from '../Helper';
import { UnSubscribeChat } from 'app/communication/services/chat/pub_sub_chat';

const IdleCounter = ({
  chat,
  lastMessage,
  handleShelvedChatsOnChatEnded,
  eventKey,
  handleChatInitialTime
}) => {
  const [idle, setIdleTime] = useState(0);

  //  Idle Timer function
  // const lastVisitorMsg = useSelector(getSelectedChatLastVisitorMessage);
  const lastVisitorOrUserMsg = useSelector(
    getSelectedChatLastVisitorOrUserMessage
  );
  // let _lastVisitorMsg = lastVisitorOrUserMsg(chat.getId());
  let _lastVisitorMsg = lastMessage(chat.getId());
  const timeSinceLastVisitorMessage = () => {
    let idle = calculateIdleTime(
      _lastVisitorMsg?.createdOn || chat?.data?.createdOn
    );
    setIdleTime(idle);
    // return idle;
  };

  useEffect(() => {
    let myInterval = setInterval(() => {
      timeSinceLastVisitorMessage();
      handleChatInitialTime(chat);
    }, 1000);

    if (getIsChatEnded(lastMessage(chat.getId()))) {
      clearInterval(myInterval);
      // if chatId exist in shelved list then remove it from shelved chat list
      handleShelvedChatsOnChatEnded(chat.getId());

      // Unsubscribed chat if it's ended
      UnSubscribeChat(chat.getId());
    }

    return () => {
      clearInterval(myInterval);
    };
  }, [chat, _lastVisitorMsg?.createdOn]);

  return (
    idle >= 0 &&
    (_lastVisitorMsg?.userId === 0 || eventKey == '0') && ( // eventKey = 0 means incoming chat section
      <div className="float-right lang-idle-pill-container d-flex align-items-center">
        <OverlayTrigger placement={'auto'} overlay={renderToolTip('Idle Time')}>
          <span className="text-right  idle-time d-block ml-2">
            <Badge
              bg="warning"
              className={`idle-pill badge ${
                // _lastVisitorMsg?.userId === 0 || eventKey == '0' // eventKey = 0 means incoming chat section
                //   ?
                idle <= 30
                  ? 'badge-success'
                  : idle > 30 && idle <= 60
                  ? 'badge-warning'
                  : idle > 60
                  ? 'badge-danger'
                  : null
                // : 'badge-primary'
              }`}
            >
              {idle}s
            </Badge>
          </span>
        </OverlayTrigger>
      </div>
    )
  );
};

export default IdleCounter;
