import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { toast } from 'react-toastify';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { addFolder } from 'features/messagingChats/suggestions';
import { errors, success } from 'helpers/variables';
import 'static/scss/change-password.scss';
import { ToastTemplate } from 'helpers/generic.methods';

const useStyle = makeStyles(() => ({
  viewModalContainer: {
    '& .modal-content': {
      boxShadow: '0 4px 8px 0 #222'
    }
  }
}));

const AddForlderModal = ({
  showModal,
  closeModal,
  title = 'Add Folder',
  size = 'md',
  selectedCompany,
  refetchFolders,
  agentScriptId
}) => {
  const [loading, setLoading] = useState(false);
  const [folderName, setForlderName] = useState('');
  const classes = useStyle();

  const handleOnModalClose = () => {
    closeModal();
    setForlderName('');
  };
  const handleOnModalSave = () => {
    if (folderName) {
      setLoading(true);
      let data = { agentScriptId, folderName };
      addFolder(data)
        .then(response => {
          if (response.data.success && response.data.data) {
            setLoading(false);
            toast.success(success.saveSuccess('Folder', 'created'));
            handleOnModalClose();
            refetchFolders();
          } else {
            setLoading(false);
            toast.error(response.data.error || errors.failure);
          }
        })
        .catch(error => {
          setLoading(false);
          toast.error(ToastTemplate.error(error || error.message));
        });
    }
  };

  const handleOnChange = e => {
    let value = e.target.value;
    setForlderName(value);
  };

  return (
    <Modal
      show={showModal}
      onHide={handleOnModalClose}
      onShow={null}
      size={size}
      keyboard={false}
      id="viewModal"
      backdrop={'static'}
      dialogClassName={`viewModal ${classes.viewModalContainer}`}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable={true}
    >
      <Modal.Body>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
        <div className="mobile_view mt-3">
          <Form.Group controlId="statement" className="normal-form-group mb-3">
            <Form.Label>Folder Name</Form.Label>
            <Form.Control
              as="input"
              label="forlderName"
              name="forlderName"
              onChange={e => handleOnChange(e)}
              value={folderName}
              placeholder="Enter folder name"
            />
          </Form.Group>
        </div>
      </Modal.Body>
      <Modal.Footer className="user-modal-footer">
        <Button
          type="button"
          variant="outline-primary"
          className="passBtn btn-md btn-flex"
          onClick={() => handleOnModalClose()}
        >
          <span>Close</span>
        </Button>
        <Button
          type="button"
          variant="outline-primary"
          className="passBtn btn-md btn-flex"
          disabled={!folderName?.trim() || loading}
          onClick={() => handleOnModalSave()}
        >
          <span>Save</span>
          {loading && (
            <Spinner className="ml-2" animation="border" size="sm" as="span" />
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddForlderModal;
