import SidebarRight from 'components/Sidebar/sidebar-right';
import moment from 'moment';
import {
  removeAllChatsFromState,
  removeChatsFromState,
  getAllChats,
  selectChat,
  exportAllChats
} from 'features/chat/chatSlice';
import storage from 'helpers/storage';
import React, { useEffect, useState, useCallback } from 'react';
import { Container, Row, Col, Badge, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import ChatContainer from './details/index';
import {
  TabComponent,
  TabsContainer,
  TabNav
} from '../../../components/TabsComponent';
import '@inovua/reactdatagrid-community/index.css';
import { chatTableConfig } from 'utils/tableConfig';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import UILInfoCircle from '@iconscout/react-unicons/icons/uil-info-circle';
import { toast } from 'react-toastify';
import { ToastTemplate, toolTipPlacement } from 'helpers/generic.methods';
import renderToolTip from 'helpers/ui-controls/renderToolTip';
import { GridWrapper } from '../../../components/GridWrapper';
import { useTranslation } from 'react-i18next';
import useQuery from 'routes/searchQueryHook';
import DynamicColumns from '../report/dynamicColumns';
import UilEye from '@iconscout/react-unicons/icons/uil-eye';
import { getFilterValue } from 'components/GridWrapper/helper';
import { selectRoles } from 'features/roles/rolesSlice';

const Chat = props => {
  let query = useQuery();
  const [openColapse, setOpenColapse] = useState(false);
  const [selectedChats, setSelectedChats] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [selectedTab, setSelectedTab] = useState(0);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [vissibleColumns, setVissibleColumns] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState(null);

  const {
    roles: {
      chat: { isAppointmentScheduler }
    }
  } = useSelector(selectRoles);
  const tabKey = 'chats-selectedTab';
  const dataKey = 'chats-selectedChats';

  // Colapsable panel Open/Close Handler
  const handleOpen = status => {
    setOpenColapse(status);
  };

  const dateTimeFormat = date => {
    return moment(date).format('hh:mm:ss a MM/DD/YYYY');
  };

  // get chat data from selectedChat selector for tab title
  let { chat: selectedChatData } = useSelector(e => selectChat(e, selectedTab));

  // Sppend Close button to TabTitle
  const tabTitle = (index, chat) => {
    return (
      <TabNav
        totalTabs={selectedChats.length}
        className="tab-heading"
        index={index}
        id={chat.id}
        title={`Conversation on ${dateTimeFormat(
          chat.createdOn || selectedChatData.createdOn || ''
        )} (${chat.id})`}
        textLimit={15}
        removeTab={removeChat}
      />
    );
  };

  // initialize selected Tab Data after Redirect From other screen
  useEffect(() => {
    setSelectedTab(JSON.parse(storage.getItem(tabKey)));
    let data = JSON.parse(storage.getItem(dataKey)) || [];
    setSelectedChats(data);
    updateSelectedData(data);
  }, []);

  // get id params from url if exist and add chat id in selectedchats array and remove previously selected chats
  // and open sidebar
  useEffect(() => {
    let id = query.get('id');
    if (id) {
      updateChatsAndSaveToLS([{ id: Number(id) }]);
      updateTabAndSaveToLS(Number(id));
      handleOpen(true);
    }
  }, []);

  const updateSelectedData = data => {
    let checked = {};
    data.forEach(com => {
      checked[com.id] = com;
    });
    setSelectedData(checked);
  };

  // Select Tab Handler
  const handleTabSelect = key => {
    if (selectedTab !== key) {
      updateTabAndSaveToLS(Number(key));
    }
  };

  // Remove  Tab From Selected Chats
  const removeChat = (index, id, nextIndex) => {
    let chats = [...selectedChats];
    chats.splice(index, 1);
    if (selectedTab === selectedChats[index].id) {
      updateTabAndSaveToLS(selectedChats[nextIndex].id);
    }
    updateChatsAndSaveToLS(chats);
    dispatch(removeChatsFromState({ id: id }));
  };

  //  update Selected Chats and save to Local Storage
  const updateChatsAndSaveToLS = chats => {
    setSelectedChats(chats);
    updateSelectedData(chats);
    storage.setItem(dataKey, chats);
  };

  // update Tabs and save To Local Storage
  const updateTabAndSaveToLS = key => {
    setSelectedTab(key);
    storage.setItem(tabKey, key);
  };

  // update Selected Tab Menu For get Back to same menu after Redirecting
  const selectTabMenu = menuKey => {
    let index = selectedChats.findIndex(sc => sc.id === selectedTab);
    if (index > -1) {
      let chats = selectedChats;
      chats[index] = { ...chats[index], selectedMenu: menuKey };
      updateChatsAndSaveToLS(chats);
    }
  };

  const getDateFilterValue = data => {
    let updatedValue;
    if (data.name === 'createdOn' && data.operator === 'before') {
      updatedValue = moment(data.value).local();
    } else if (data.name === 'createdOn' && data.operator === 'after') {
      updatedValue = moment().local();
    } else {
      updatedValue = data.value;
    }
    return updatedValue;
  };

  const loadData = ({ skip, limit, sortInfo, filterValue }) => {
    try {
      setLoadingMessage('Loading ...');
      const updatedValue = filterValue
        .filter(v => v.value !== '')
        .map(v => ({
          propertyName: v.name,
          propertyValue1: getFilterValue(v),
          propertyValue2:
            v.name === 'createdOn' &&
            (v.operator === 'before' || v.operator === 'after')
              ? getDateFilterValue(v)
              : '',
          propertyValue3: [],
          filterDataType: 2,
          comparison: 2
        }));
      let payload = {
        start: skip,
        limit: limit,
        filter: JSON.stringify(updatedValue),
        dir: !sortInfo ? '' : sortInfo.dir === 1 ? 'ASC' : 'DESC',
        sort: sortInfo ? sortInfo.name : ''
      };
      setSelectedFilters(payload);
      return getAllChats(payload).then(response => {
        if (response.data.totalSize > 20) {
          setDataLimit(skip + response.data.data.length);
        } else {
          setDataLimit(response.data.data.length);
        }
        const totalCount = response.data.totalSize;
        setTotalRecords(totalCount);
        return { data: response.data.data, count: parseInt(totalCount) };
      });
    } catch (error) {
      toast.error(ToastTemplate.error(error || error.message));
    }
  };

  const handleSelect = payload => {
    const { data } = payload;
    if (data.length || Object.keys(data).length) {
      let temp = data;
      if (data.length) temp = data[0];
      let selected = selectedChats.filter(sc => sc.id === Number(temp.id));
      if (selected.length === 0) {
        let chats = [temp, ...selectedChats];
        updateChatsAndSaveToLS(chats);
      } else if (data instanceof Object && !Array.isArray(data)) {
        if (!Object.keys(selected).includes(data.id)) {
          let index = selectedChats.findIndex(com => com.id === data.id);
          removeChat(index, data.id);
        }
      }

      updateTabAndSaveToLS(temp.id);
      if (Array.isArray(data)) handleOpen(true);
    } else {
      clearAll();
    }
  };

  const clearAll = () => {
    updateChatsAndSaveToLS([]);
    updateTabAndSaveToLS('');
    dispatch(removeAllChatsFromState());

    // remove query params from url if exist
    if (query.has('id')) {
      query.delete('id');
      props.history.replace({
        search: ''
      });
    }
  };
  const handleColumnsChange = data => {
    setVissibleColumns({ ...data });
  };

  const ActionButtons = ({ value }) => (
    <>
      <Button
        className="btn-sm btn-flex mr-1"
        variant="lightgray-3"
        onClick={e => console.log(value)}
      >
        <UilEye className="mr-1" />
        View
      </Button>
    </>
  );
  // useEffect(() => {
  //   !mobileSuggestions && dispatch(fetchMobileSuggestionsAsync());
  // }, [mobileSuggestions, dispatch]);

  const dataSource = useCallback(loadData, []);
  const [dataLimit, setDataLimit] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loadingMessage, setLoadingMessage] = useState('Loading ...');
  const renderColumnContextMenu = useCallback((menuProps, { cellProps }) => {
    menuProps.items = menuProps.items.splice(0, 8);
  }, []);

  // TODO - appointment scheduler recives from Company-API,
  // GridWrapper Rerenders after API response fetched

  return (
    <>
      <Container fluid>
        <Row>
          <Col className="m-3">
            <h2>
              Conversations{' '}
              <Badge variant="primary p-1 badge-align">
                {loading ? '...' : `${dataLimit} / ${totalRecords}`}
              </Badge>
              <span className="ml-2">
                <OverlayTrigger
                  placement={toolTipPlacement}
                  overlay={renderToolTip(t('chat.chatDetails.chatTooltip'))}
                >
                  <UILInfoCircle className="list-icon-after-text" />
                </OverlayTrigger>
              </span>
            </h2>
          </Col>
          <div className="d-flex" style={{ margin: '20px', gap: '10px' }}>
            <DynamicColumns
              handleChange={handleColumnsChange}
              menuOptions={chatTableConfig(isAppointmentScheduler)}
              selectedFilters={selectedFilters}
              endPointAPI={exportAllChats}
              fileNamePrefix="Chats"
            />
          </div>
        </Row>
        <Row>
          <Col className={loading && 'blux-text'}>
            <div className="mobile_view">
              <GridWrapper
                columns={chatTableConfig(
                  isAppointmentScheduler,
                  ActionButtons,
                  false
                )}
                totalRecords={totalRecords}
                dataSource={dataSource}
                setLoading={setLoading}
                loadingMessage={loadingMessage}
                renderColumnContextMenu={renderColumnContextMenu}
                selectedData={selectedData}
                handleSelect={handleSelect}
                vissibleColumns={vissibleColumns}
              />
            </div>
          </Col>
        </Row>
      </Container>
      {selectedChats.length ? (
        <SidebarRight handleOpen={handleOpen} open={openColapse}>
          {/* <button onClick={() => clearAll()}>Clear All</button> */}
          <TabsContainer
            activeKey={selectedTab}
            clearAll={clearAll}
            openColapse={openColapse}
            onTabSelect={e => handleTabSelect(e)}
            selectedData={selectedChats}
          >
            {selectedChats.map((chat, index) => (
              <TabComponent
                tabClassName={'tab-ui'}
                key={index}
                id={`tab-${chat.id}`}
                eventKey={chat.id}
                title={tabTitle(index, chat)}
              >
                <ChatContainer
                  dataKey={dataKey}
                  selectMenu={selectTabMenu}
                  selected={chat}
                  removeSelectedChats={clearAll}
                />
              </TabComponent>
            ))}
          </TabsContainer>
        </SidebarRight>
      ) : null}
    </>
  );
};

export default Chat;
