import {
  requiredText,
  email,
  urlText,
  phoneText,
  phoneTextRequired,
  alphaNumeric,
  alphaNumericLength,
  onlyText,
  matchPassword,
  requiredOnlyText,
  nameText,
  emailText,
  emailReqText,
  nameTextWithNumbers,
  requiredArray,
  requiredNumber,
  requiredTextWithZeroToHundred,
  multipleEmailReqText
} from '../genericSchemas';
import * as Yup from 'yup';
import { Enums } from 'helpers/dropdown-enums';

/**
 * Setup schema for contact information of the company
 * requires requiredText schema from genericSchemas
 **/

export const contactInformationSchemas = Yup.object().shape({
  primaryContactName: nameText,
  primaryContactEmail: null,
  primaryContactPhone: phoneText,
  primaryContactAltPhone: phoneText,
  address: null,
  city: onlyText,
  state: alphaNumeric,
  zip: alphaNumericLength,
  serviceArea: null,
  country: null
});
export const facebookPixelSectionSchema = Yup.object().shape({
  facebookPixelAccount: requiredText
});
export const customTrackingSectionSchema = Yup.object().shape({});
export const googleAnalyticsTrackingSectionSchema = Yup.object().shape({
  googleAnalyticsAccount: requiredOnlyText,
  googleAnalyticsDomain: urlText
});

export const facebookSchema = Yup.object().shape({
  // verificationToken: requiredText,
  // pageAccessToken: requiredText,
  // callbackURL: requiredText,
  // getStartedButtonGreetingText: requiredText,
  // facebookChatGreetingText: requiredText,
  // companyFacebookPageName: requiredText,
  // facebookPageID: requiredText
});
export const basicDetailsSchema = Yup.object().shape({
  domain: urlText
});

export const leadEmailSchema = Yup.object().shape({
  leadEmailAddresses: emailReqText,
  chatTranscriptEmailAddresses: emailText
});

export const callConnectSchema = Yup.object().shape({
  officeHoursTimezone: null,
  quickConnectPhoneNumber: phoneText,
  callConnectTranscriptOverride: null,
  callConnectTranscriptEmail: email
});

export const companyHeaderSchema = Yup.object().shape({
  companyName: requiredText
});

export const parameterSchemas = type => {
  let obj = { name: requiredText, email: emailReqText };

  switch (Number(type)) {
    case 1:
      break;
    case Enums.ReportType.Stats:
      obj = { ...obj, aggregate: requiredText };
      break;
    case Enums.ReportType.Often_Over_Budget_Companies:
      obj = { ...obj, limit: requiredNumber };
      break;
    case Enums.ReportType.Nearly_Over_Budget_Companies:
      obj = {
        ...obj,
        minimum: requiredTextWithZeroToHundred,
        maximum: requiredTextWithZeroToHundred
      };
      break;
    case Enums.ReportType.Summary_Response_Time:
    case Enums.ReportType.Leads_Delay:
    case Enums.ReportType.Performance:
    case Enums.ReportType.Profile_Response:
      obj = { ...obj, companyKeys: requiredArray };
      break;
    case Enums.ReportType.Invitation_Code_Installed_Report:
      obj = { ...obj, startDate: requiredText };
      break;
    default:
      // obj = obj;
      break;
  }

  return Yup.object(obj).shape(obj);
};
