import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import { useFormik } from 'formik';
import SimpleFilePicker from 'helpers/ui-controls/ui-components/SimpleFilePicker';
import SectionHeader from 'helpers/ui-controls/ui-components/AttachementSectionHeader';
import { attachements } from 'helpers/schemas/provisionSchemas/initialValues';
import { fileAttachementSchema } from 'helpers/schemas/provisionSchemas/schemas';
import {
  mapServerValuesToInitialValues,
  ToastTemplate
} from 'helpers/generic.methods';
import AttachementComponent from './attachementComponent';
import {
  SaveAttachements,
  getAttachements,
  DeleteAttachement
} from 'features/provisioning/ProvisioningSlice';
import { success, errors } from 'helpers/variables';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

const Attachements = ({ formData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState([]);
  const [newAttachements, setNewAttachements] = useState([]);

  const [editable, setEditable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [provisionId, setProvisionId] = useState('');

  const ProvisionFormHandler = async data => {
    try {
      if (newAttachements.length) {
        setLoading(true);
        let form = new FormData();
        newAttachements.forEach((val, index) => {
          form.append(`file-${index + 1}`, val);
        });
        SaveAttachements(form, provisionId).then(res => {
          if (res.data.success) {
            refetchAttachements();
            toast.success(success.saveSuccess('Onboarding'));
          } else {
            toast.error(res.data.error || errors.createError);
            setLoading(false);
          }
        });
      }
    } catch (error) {
      toast.error(ToastTemplate.error(error || error.message));
      setLoading(false);
    }
  };

  const AttachementsFormIk = useFormik({
    enableReinitialize: true,
    initialValues: formValues,
    validationSchema: fileAttachementSchema,
    onSubmit: ProvisionFormHandler
  });

  const refetchAttachements = () => {
    try {
      dispatch(getAttachements(provisionId)).then(response => {
        if (response.success) {
          setNewAttachements([]);
          setEditable(false);
          setLoading(false);
        } else {
          toast.error(response.error || errors.failure);
          setLoading(false);
        }
      });
    } catch (error) {
      toast.error(error || error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (formData && Object.keys(formData).length) {
      setProvisionId(formData?.id);
      mapServerValuesToInitialValues(formData, attachements)
        .then(data => {
          if (data && data?.fileAttachements) {
            setFormValues(data.fileAttachements);
          }
        })
        .catch(err => {
          throw new Error(err);
        });
    }
  }, [formData]);

  const handleOnChangeAttachements = attachementsData => {
    setNewAttachements(attachementsData);
  };

  const handleDeleteAttachement = index => {
    DeleteAttachement(provisionId, index).then(res => {
      if (res.data.success) {
        refetchAttachements();
      }
    });
  };

  return (
    <Form className="form" onSubmit={AttachementsFormIk.handleSubmit}>
      <SectionHeader
        sectionHeading={t('company.provisioningDetails.heading4')}
        loading={loading}
        editable={editable}
        handleEditableState={setEditable}
      />
      <section>
        <div className="section-body">
          <ul className="editable-fields-lists d-block">
            <AttachementComponent
              editable={editable}
              isListingEnable={true}
              selectedAttachements={formValues}
              handleChange={handleOnChangeAttachements}
              handleDelete={handleDeleteAttachement}
            />
          </ul>
        </div>
      </section>
    </Form>
  );
};

export default Attachements;
