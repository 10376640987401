import {
  animateTabTitleOnMessageReceived,
  incrementTotalUnreadMessagesCount,
  setTotalUnreadMessagesCount,
  sortChatsByActivity
} from 'features/messagingChats/messagingChats';
import { logIt } from 'helpers/logger';
import storage from 'helpers/storage';
import store from 'store';
import RTC from '../rtc';

const Subscribe_Unread_Messages_Count = callbacks => {
  const user = JSON.parse(storage.getItem('Profile'));
  RTC().Subscribe({
    channels: ['/unreadMessagesCount/' + user?.CompanyID],
    callbacks: callbacks
  });
};

export const Init_Unread_Messages_Count = () => {
  Subscribe_Unread_Messages_Count({
    onSuccess: function (args) {
      var data = args.getMeta();
      store.dispatch(setTotalUnreadMessagesCount(data));
      logIt('log', 'onSuccess::UnreadMessagesCount', args);
    },
    onFailure: function (args) {
      logIt('log', 'onFailure::UnreadMessagesCount', args);
    },
    onReceive: function (args) {
      var data = args.getData();
      if (data) {
        store.dispatch(
          incrementTotalUnreadMessagesCount({
            chatId: data?.ChatId
          })
        );
        store.dispatch(animateTabTitleOnMessageReceived());
        //sort the active chats list by latest chat message
        store.dispatch(sortChatsByActivity(data.ChatId));
        logIt('log', 'onReceive::', args.getData().text);
      }
    }
  });
};
