import store from 'store';
import { RemoveChannelsFromRTCCleint } from 'app/communication/services/chat/pub_sub_chat';
import {
  chatOnReceiveCallback,
  chatOnSuccessCallback
} from 'app/communication/services/chat/callbacks/chat_subscription_callbacks';
import {
  saveChatMessage,
  saveChatTypingIndicator,
  saveChatToShelved,
  setChatUpdatedInfoDataInChats,
  getUnsentLeadListAsync
} from 'features/messagingChats/messagingChats';
import { Enums } from 'helpers/dropdown-enums';
import { toast } from 'react-toastify';
import { success } from 'helpers/variables';
import { logIt } from 'helpers/logger';
import {
  getVisitorResponseTypeFromOperatorMsg,
  mapVisitorResponseAnswer
} from 'helpers/messaging/message.methods';

export const chatCallbacks = options => {
  return {
    onSuccess: function (args) {
      chatOnSuccessCallback({
        meta: args.getMeta(),
        onMessageArrived:
          options._onSuccessMessageArrived ||
          options._onMessageArrived ||
          _onMessageArrived
      });
    },
    onFailure: function (e) {
      logIt('Error', 'sub-failure:' + e.getException());
    },
    onReceive: function (args) {
      chatOnReceiveCallback({
        chatId: options.chatId,
        data: args.getData(),
        channel: args.getChannel(),
        onMessageArrived: options._onMessageArrived || _onMessageArrived,
        onTypingArrived: options._onTypingArrived || _onTypingArrived,
        onMetaArrived: options._onMetaArrived || _onMetaArrived,
        onParticipantsChanged:
          options._onParticipantsChanged || _onParticipantsChanged,
        onVisitorResponseArrivedForTemplatedQuestion:
          options._onVisitorResponseArrivedForTemplatedQuestion ||
          _onVisitorResponseArrivedForTemplatedQuestion
      });
    }
  };
};

export const _onMessageArrived = data => {
  store.dispatch(saveChatMessage(data));
};

export const _onTypingArrived = data => {
  const {
    auth: { profile }
  } = store.getState();
  // TODO:: here we will have 'data.indicator' property if that is true, then show typing indicator
  // dispatch to redux so all places where typing indicator needs to be appeared, they should display it.
  if (data.participantId !== profile?.UserID) {
    store.dispatch(
      saveChatTypingIndicator({
        id: data.chatId,
        typing: data.indicator
      })
    );
  }
};

export const _onMetaArrived = data => {
  // TODO:: here we will have 'data.isLead' and 'data.status' property.
  // TODO: if isLead is true, then we will add data to Unsent Lead list
  //if submitting lead form we will fetch unsent leads and stop submit button loading
  // after we get isLead = true and on updatedInfo data sendingLead = true
  if (data.isLead) {
    const {
      MessagingChats: { chats }
    } = store.getState();
    let selectedChatData = chats[data.chatId]?.updatedInfo;
    if (selectedChatData?.sendingLead) {
      toast.success(success.sendSuccess('The lead'));
      store.dispatch(getUnsentLeadListAsync());
      store.dispatch(
        setChatUpdatedInfoDataInChats({
          chatId: data.chatId,
          updatedInfoData: {
            sendingLead: false,
            leadSubmitMessage: 'Chat lead has been sent'
          }
        })
      );
    } else {
      store.dispatch(getUnsentLeadListAsync());
    }
  }

  // if status is 7(shelved), then we will add data to shelved chat list
  // dispatch to redux so all places where shelved chat needs to be appeared, they should display it.
  if (data.status === Enums.ChatStatus.Shelved) {
    store.dispatch(saveChatToShelved(data.chatId));
    // UnSubscribeChat(data.chatId);
    RemoveChannelsFromRTCCleint(data.chatId);
  }
};

export const _onParticipantsChanged = data => {
  logIt('log:', 'onParticipantsChanged', data);
  // here we will have update array of participants in a particular chat.
  if (data.participants) {
    const {
      MessagingChats: { chats }
    } = store.getState();
    if (chats[data.chatId] && chats[data.chatId]?.chatInfo?.data) {
      store.dispatch(
        setChatUpdatedInfoDataInChats({
          chatId: data.chatId,
          updatedInfoData: {
            participants: data.participants
          }
        })
      );
    }
  }
};

export const _onVisitorResponseArrivedForTemplatedQuestion = data => {
  logIt('log:', 'onVisitorResponseArrivedForTemplatedQuestion', data);
  // here we will update visitor info in a particular chat.
  if (
    data?.msgText &&
    (data?.questionType === Enums?.ChatQuestionType?.FullText ||
      data?.questionType === Enums?.ChatQuestionType?.Text)
  ) {
    const {
      MessagingChats: { chats }
    } = store.getState();

    if (chats[data.chatId] && chats[data.chatId]?.chatInfo?.data) {
      let messageslength = chats[data.chatId]?.messages?.length;
      let responseType = null;
      //****************************/
      // get visitorResponseType from operator question type message
      // last visitor answered message to previous message of operator
      //****************************/
      let messages = chats[data.chatId]?.messages || [];
      if (messages && messageslength) {
        let tempMsgs = [...messages];
        responseType = getVisitorResponseTypeFromOperatorMsg(
          tempMsgs,
          responseType
        );
      }
      //****************************/

      // checking contactInfo in case of mutliple visitorResponseType added
      let contactInfo = data?.contactInfo ? JSON.parse(data?.contactInfo) : {};
      let visitorInfo = {};
      if (responseType || (contactInfo && Object.keys(contactInfo)?.length)) {
        visitorInfo = mapVisitorResponseAnswer(
          visitorInfo,
          responseType,
          contactInfo,
          data?.msgText
        );
      }
      if (visitorInfo && Object.keys(visitorInfo)) {
        store.dispatch(
          setChatUpdatedInfoDataInChats({
            chatId: data?.chatId,
            updatedInfoData: {
              // visitorInfoName: data?.msgText
              ...visitorInfo
            }
          })
        );
      }
    }
  }
};
