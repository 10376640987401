import { FormLabel, OverlayTrigger } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import BadgeWithIcon from 'components/BadgeWithIcon';
import renderToolTip from '../../../../helpers/ui-controls/renderToolTip';
import UilInfoCircle from '@iconscout/react-unicons/icons/uil-info-circle';
import { toolTipPlacement } from '../../../../helpers/generic.methods';

const useStyles = makeStyles(theme => ({
  badgeCustomStyle: {
    borderRadius: '32px !important',
    marginLeft: '0px !important',
    padding: '4px 6px 4px 6px !important',
    color: 'white',
    marginTop: '8px',
    fontSize: '14px',
    '& span': { '& span': { padding: '0px !important' } }
  },
  success: {
    backgroundColor: 'var(--success-2)  !important'
  },
  info: {
    backgroundColor: 'var(--th-blue-6) !important'
  },
  danger: {
    backgroundColor: 'var(--danger-2) !important'
  },
  warning: {
    backgroundColor: 'var(--warning-7) !important'
  },
  purple: {
    backgroundColor: 'var(--purple) !important'
  }
}));

const BadgeWithTextFeild = ({ selected, label, options = [], tooltip }) => {
  const classes = useStyles();
  const getStatusValue = id => {
    let obj = options.find(op => op.value === id);
    return obj || { statusColor: '', name: '' };
  };

  const { statusColor, name } = getStatusValue(selected);
  return (
    <>
      <li className="toggle-tooltip-wrapper">
        <div className={`form-group ${'has-disabled'}`}>
          <FormLabel title={label}>{label}</FormLabel>
          <BadgeWithIcon
            showIcon={false}
            badgeClass={`${classes.badgeCustomStyle} ${classes[statusColor]}`}
            text={name}
          />
        </div>
        {tooltip && (
          <OverlayTrigger
            placement={toolTipPlacement}
            overlay={renderToolTip(tooltip)}
          >
            <UilInfoCircle className="list-icon-after-text ml-1" />
          </OverlayTrigger>
        )}
      </li>
    </>
  );
};

export default BadgeWithTextFeild;
