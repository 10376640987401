import { Box, TextareaAutosize } from '@material-ui/core';
import React, { Component } from 'react';
export const GetLeadType = ({ leadType }) => {
  const getLeadTypeName = leadType => {
    let name = '';
    if (leadType == 1) {
      name = 'Sales';
    } else if (leadType == 2) {
      name = 'Service';
    } else {
      name = 'Other';
    }

    return name;
  };

  return (
    <>
      <Box
        sx={{
          border: '1px solid #F97B35',
          borderRadius: '4px',
          color: '#F97B35',
          background: '#FCEBDB',
          fontSize: '12px',
          textAlign: 'center'
        }}
      >
        {getLeadTypeName(leadType)}
      </Box>
    </>
  );
};
