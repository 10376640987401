import React from 'react';
import { useTranslation } from 'react-i18next';
import { Enums } from 'helpers/dropdown-enums';
import ScheduleComp from 'pages/portal/modal/CreateReport/scheduleComp';
import { useState } from 'react';
import storage from 'helpers/storage';
import moment from 'moment';
import { createOrUpdateReport } from 'features/report/reportSlice';
import { toast } from 'react-toastify';
import { errors, success, toastMsg } from 'helpers/variables';
import { getAllCompanies } from 'features/companies/companiesSlice';
import { parameterSection } from 'helpers/schemas/reportSchemas/initialValues';
import { useFormik } from 'formik';
import { parameterSchemas } from 'helpers/schemas/reportSchemas/schemas';
import { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { updateReportToState } from 'features/report/reportsSlice';

const generateOptions = data => {
  return [...data].map(dt => ({
    key: `${dt.companyKey}`,
    value: dt.companyKey,
    id: dt.id
  }));
};
const ScheduleWrapper = ({
  report = {},
  showHeading = true,
  showNote = true,
  clickHandlerMenu,
  isCreate,
  hostList
}) => {
  const { t } = useTranslation();

  const [fieldErrorOnSubmit, setFieldErrorOnSubmit] = useState(false);
  const [selectedHosts, setSelectedHosts] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [hostOptions, setHostOptions] = useState(hostList);
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(storage.getItem('Profile'));
  const [editable, setEditable] = useState(false);
  const dispatch = useDispatch();

  /**
   * @name @changePasswordFormHandler
   * @description Used to connect with store and call change password api then toggle error
   * @requires  changeFormData from the form fields
   * */

  const generatePayload = () => {
    let payload = {
      ...reportFormIk.values
    };
    delete payload['createdOn'];
    delete payload['modifiedOn'];
    payload['scheduleType'] = Number(payload.scheduleType);
    payload['id'] = report.id;
    if (payload.scheduleType == 0) {
      payload.scheduleDayOfMonth = 0;
      payload.scheduleDaysOfWeek = [];
      payload.scheduleHour = 0;
    }
    return payload;
  };

  const reportFormHandler = (run = false) => {
    setLoading(true);
    let payload = generatePayload();
    try {
      createOrUpdateReport(payload, false).then(response => {
        if (response && response.data) {
          setLoading(false);
          setEditable(false);
          dispatch(updateReportToState(response.data));
          toast.success(success.saveSuccess('Report'));
        }
      });
    } catch {
      setFieldErrorOnSubmit(true);
      toast.error(toastMsg.errors.createError);
      setLoading(false);
      setEditable(false);
    }
  };

  /** Init Formik */
  const reportFormIk = useFormik({
    enableReinitialize: true,
    initialValues: parameterSection,
    validationSchema: parameterSchemas,
    onSubmit: reportFormHandler
  });

  useEffect(() => {
    setHostOptions(hostList);
  }, [hostList]);

  /**
   * @name @handleOnChange
   * @description do something on field onChange
   * @requires html input element
   * */
  const handleOnChange = (element, key, value) => {
    reportFormIk.setValues({
      ...reportFormIk.values,
      [key]: value
    });
    setFieldErrorOnSubmit(false);
  };

  useEffect(() => {
    if (report) {
      let data = {};

      reportFormIk.setValues({
        ...reportFormIk.values,
        ...report,
        ...data
      });
    }
  }, [report]);

  return (
    <>
      {/* {showNote && ( */}
      {/* <p className="fz-12 text-warning-2 fw-600 p-3 mb-0 warning-box">
        {t('company.reportDetails.note')}
      </p> */}
      <Form
        className="form"
        onSubmit={reportFormIk.handleSubmit}
        autoComplete="off"
      >
        <ScheduleComp
          headerSection={true}
          report={reportFormIk.values}
          handleChange={handleOnChange}
          editable={editable}
          setEditable={setEditable}
          loading={loading}
          showHeading={true}
        />
      </Form>
    </>
  );
};

export default ScheduleWrapper;
