import UilInfoCircle from '@iconscout/react-unicons/icons/uil-info-circle';
import React from 'react';
import { OverlayTrigger, FormLabel } from 'react-bootstrap';
import ToggleText from '../../../../helpers/ui-controls/toggleText';
import { toolTipPlacement } from 'helpers/generic.methods';
import renderToolTip from '../../../../helpers/ui-controls/renderToolTip';
import StatusText from './statusText';

const StatusComp = ({
  flag,
  //for disable enable value
  heading,
  //label
  titleText,
  //tooltip text
  toolTipFor,
  //htmlFor value
  editable,
  handleChange,
  field = '',
  className = 'toggle-tooltip-wrapper'
}) => {
  return (
    <>
      <li className={className}>
        <div className={`form-group ${!editable && 'has-disabled'}`}>
          <FormLabel
            //title={titleText}
            aria-label={heading + ' - ' + titleText}
            htmlFor={toolTipFor}
          >
            {heading}
          </FormLabel>
          <StatusText flag={flag} />
        </div>
        {titleText && (
          <OverlayTrigger
            placement={toolTipPlacement}
            overlay={renderToolTip(titleText)}
          >
            <UilInfoCircle className="list-icon-after-text" />
          </OverlayTrigger>
        )}
      </li>
    </>
  );
};

export default StatusComp;
