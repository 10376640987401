import { errors } from 'helpers/variables';
import * as Yup from 'yup';
import { requiredText, requiredTextWithNoZero } from '../genericSchemas';

/**
 * Setup schema for statement
 * requires requiredText schema from genericSchemas
 **/

export const statementFormSchema = Yup.object().shape({
  statement: Yup.string().trim().required(errors.empty),
  visibility: requiredTextWithNoZero('Please select visibility'),
  folderIdArray: Yup.array().min(1, 'Please select 1 folder.'),
  companyArray: Yup.mixed().test({
    message: 'Please select folder for all specified company(s)',
    test: val => {
      let keys = Object.keys(val);
      return keys.every(key => val[key]);
    }
  })
});
