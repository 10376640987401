import React, { useState } from 'react';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ScrollBar from 'components/Scrollbars';
import { Accordion, Card } from 'react-bootstrap';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { getQTInitials } from '../Helper';
import { getSuggestionIcon } from 'helpers/messaging/message.methods';
import BadgeWithIcon from 'components/BadgeWithIcon';
import { Enums } from 'helpers/dropdown-enums';
import { UilAngleUp, UilAngleDown } from '@iconscout/react-unicons';
import TruncateWithToolTip from 'components/TruncateWithToolTip';
import { truncateText } from 'helpers/generic.methods';
import useViewport from 'helpers/viewport-hook';
import { decodeHTML } from 'helpers/validate';

const useStyles = makeStyles(theme => ({
  fieldSet: {
    width: '100%'
  },
  labelPlacementStart: {
    marginBottom: '0',
    marginRight: '5px'
  },
  radioGroup: {
    paddingRight: '15px',
    marginTop: '5px'
  },
  accordionTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 6px',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    borderBottom: 'none'
  },
  selectedAccordion: {
    backgroundColor: 'var(--lightgray-2)'
  },
  badgeCustomStyle: {
    borderRadius: '4px !important',
    marginRight: '4px',
    padding: '4px !important'
  },
  statementListItem: {
    padding: '4px 15px'
  }
}));

const FolderList = ({
  list = [],
  handleChange,
  selectedFolder,
  haveScript
}) => {
  const screen = useViewport();
  const classes = useStyles();
  const [activeKey, setActiveKey] = useState('');

  const onChange = event => {
    event.stopPropagation();
    let value = Number(event.target.value);
    handleChange(value);
  };

  //  toggle accordion W.R.T suggestion ids
  const toggleActive = (index, id, hasAnswers) => {
    if (activeKey !== id.toString()) {
      setActiveKey(selectedFolder !== id || !hasAnswers ? '' : id.toString());
    } else {
      setActiveKey('');
    }
  };

  const TruncateTitle = (text, limit = 28) => {
    var isStyleTag = text.includes('</style>');
    let newText = isStyleTag
      ? text.replace(/(<style[\w\W]+style>)/g, '')
      : text;
    return (
      <TruncateWithToolTip
        text={decodeHTML(newText)}
        showInnerHtml={true}
        tooltip={true}
        limit={limit}
        className=""
        dangerouslySetInnerHTML={{
          __html: text
        }}
      />
    );
  };

  // styles are also include as text so
  // remove styles and then decide to trim text or not for folder text
  // without removing styles will cause trim actual text
  const TruncateFolderText = (text, limit = 28) => {
    var isStyleTag = text.includes('<style>');
    let newText = isStyleTag ? text.replace(/<style.*?<\/style>/g, '') : text;
    let decodedText = decodeHTML(newText);
    let canTrim = decodedText.length > limit;
    return (
      <TruncateWithToolTip
        text={text}
        showInnerHtml={true}
        limit={limit}
        className=""
        dangerouslySetInnerHTML={{
          __html: canTrim ? truncateText(text, limit) : text
        }}
      />
    );
  };

  return (
    <div>
      <FormControl component="fieldset" className={classes.fieldSet}>
        <ScrollBar
          horizontalScroll={false}
          style={{
            // height: 'calc(100vh - 380px)',
            height: `calc(100vh - ${screen.sm ? '200px' : '380px'})`,
            maxHeight: '305px'
          }}
        >
          {list.length ? (
            <RadioGroup
              aria-label="folder"
              name="folder"
              value={selectedFolder}
              onChange={onChange}
              className={classes.radioGroup}
            >
              {list.map((question, que) => {
                return (
                  question.id > 0 &&
                  !question?.verticalId && (
                    <Accordion
                      key={question.id}
                      defaultActiveKey={
                        question?.expanded ? question.id.toString() : '-1'
                      }
                      activeKey={activeKey}
                      flush="true"
                    >
                      <div>
                        <Accordion.Toggle
                          as={Card.Header}
                          className={`according-text ${classes.accordionTitle}
                        ${
                          selectedFolder === question.id &&
                          classes.selectedAccordion
                        }
                        `}
                          eventKey={question.id.toString()}
                          onClick={() =>
                            toggleActive(
                              que,
                              question.id,
                              !!question.answers.length
                            )
                          }
                        >
                          <span className="tab-icon">
                            <FormControlLabel
                              key={question.id}
                              className={classes.labelPlacementStart}
                              value={question.id}
                              labelPlacement="end"
                              control={<Radio color="primary" />}
                              // label={getLabel(question.text)}
                            />
                            <span className="tab-title">
                              {TruncateTitle(question.text, 50)} (
                              {question.answers.length})
                            </span>
                          </span>
                          {selectedFolder === question.id &&
                            !!question.answers.length && (
                              <span>
                                {activeKey === question.id.toString() ? (
                                  <UilAngleUp className="collapse-caret" />
                                ) : (
                                  <UilAngleDown className="collapse-caret" />
                                )}
                              </span>
                            )}
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={question.id.toString()}>
                          <List
                            component="nav"
                            aria-label="main mailbox folders"
                          >
                            {question.answers &&
                              question.answers.map((answer, ans) => {
                                return (
                                  <ListItem
                                    key={answer.id}
                                    // button
                                    classes={{
                                      root: classes.statementListItem
                                    }}
                                  >
                                    <ListItemIcon>
                                      <BadgeWithIcon
                                        showIcon={true}
                                        badgeClass={classes.badgeCustomStyle}
                                        icon={
                                          <img
                                            src={getSuggestionIcon(
                                              answer?.questionType ===
                                                Enums.ChatQuestionType.None
                                                ? Enums.ChatQuestionType.Text
                                                : answer?.questionType
                                            )}
                                            style={{
                                              height: '14px',
                                              width: '14px',
                                              margin: '0px'
                                            }}
                                          />
                                        }
                                        toolTipText={
                                          answer?.questionType !==
                                            Enums.ChatQuestionType.None &&
                                          getQTInitials(answer?.questionType).qt
                                        }
                                      />
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={
                                        <span className="chatname-title">
                                          {TruncateTitle(answer.text)}
                                        </span>
                                      }
                                    />
                                  </ListItem>
                                );
                              })}
                          </List>
                        </Accordion.Collapse>
                      </div>
                    </Accordion>
                  )
                );
              })}
            </RadioGroup>
          ) : (
            <span className="d-flex justify-content-center">
              {haveScript ? 'No folder available' : 'Please add script first'}
            </span>
          )}
        </ScrollBar>
      </FormControl>
    </div>
  );
};

export default FolderList;
