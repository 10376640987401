import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import { useFormik } from 'formik';
import { featureDetails } from 'helpers/schemas/provisionSchemas/initialValues';
import { featureDetailSchemas } from 'helpers/schemas/provisionSchemas/schemas';
import SectionHeader from 'helpers/ui-controls/ui-components/SectionHeader';
import ToggleDetailed from 'helpers/ui-controls/toggleDetailed';
import {
  mapServerValuesToInitialValues,
  ToastTemplate
} from 'helpers/generic.methods';
import { toast } from 'react-toastify';
import {
  UpdateProvision,
  updateProvisionToState,
  getOfferedFeaturesByProvisioningId
} from 'features/provisioning/ProvisioningSlice';
import { useDispatch } from 'react-redux';
import {
  UilCommentAltMessage,
  UilEnvelopes,
  UilFacebookF,
  UilEnglishToChinese,
  UilFileCheckAlt,
  UilCalendarAlt,
  UilBars
} from '@iconscout/react-unicons';
// import SMS from '@iconscout/react-unicons/icons/uil-comment-alt-m';
import { success } from 'helpers/variables';
import { Enums, getObjectKeyByValue } from 'helpers/dropdown-enums';
import { ReactComponent as Document } from 'static/images/features-section-icons/document.svg';
import { ReactComponent as SMSN } from 'static/images/features-section-icons/smsn.svg';
import { ReactComponent as Popup } from 'static/images/features-section-icons/popup.svg';
import { ReactComponent as Fbook } from 'static/images/features-section-icons/fbook.svg';
import { ReactComponent as Calender } from 'static/images/features-section-icons/calender.svg';
import { ReactComponent as Others } from 'static/images/features-section-icons/others.svg';
import { ReactComponent as Translate } from 'static/images/features-section-icons/translate.svg';
import { Trans } from 'react-i18next';
const FeatureDetails = ({ formData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [editable, setEditable] = useState(false);
  const [formValues, setFormValues] = useState(featureDetails);
  const [loading, setLoading] = useState(false);
  const [id, setProvisionId] = useState('');
  const [offeredFeatures, setOfferedFeatures] = useState([]);
  const offeredFeaturesEditableStatus = [
    { value: 1, name: 'Adding Information' },
    { value: 2, name: 'Waiting For Setup' },
    { value: 3, name: 'Need More Information' }
  ];
  const ProvisionFormHandler = async data => {
    try {
      setLoading(true);
      let payload = {
        ...{ features: offeredFeatures.filter(val => val.isSelected) },
        id: id
      };
      const response = await UpdateProvision(payload);
      dispatch(updateProvisionToState(response?.data?.data));
      toast.success(success.saveSuccess('Onboarding'));
      setEditable(false);
      setLoading(false);
    } catch (error) {
      toast.error(ToastTemplate.error(error || error.message));
      setLoading(false);
    }
  };

  const FeaturesFormIk = useFormik({
    enableReinitialize: true,
    initialValues: formValues,
    validationSchema: featureDetailSchemas,
    onSubmit: ProvisionFormHandler
  });

  useEffect(() => {
    setProvisionId(formData.id);
    mapServerValuesToInitialValues(formData, featureDetails)
      .then(data => {
        getFeatures();
        setFormValues({ ...featureDetails, ...data });
      })
      .catch(err => {
        throw new Error(err);
      });
  }, [formData]);

  const handleOnChangeFeature = (element, key, value) => {
    const temp = [...offeredFeatures];
    let index = key.split('-')[1];
    temp[index].isSelected = value;
    setOfferedFeatures(temp);
  };

  const getFeatures = () => {
    getOfferedFeaturesByProvisioningId({
      params: { id: formData.id }
    }).then(response => {
      if (response.data.success) {
        const { data } = response.data;
        let temp = data.map(item => ({
          ...item,
          isSelected: checkSelected(item)
        }));
        if (!checkIfStatus_Editable(formData.status)) {
          temp = temp.filter(val => val.isSelected);
        }
        setOfferedFeatures(temp);
      }
    });
  };

  const checkSelected = obj => {
    let selectedFeatures = formData.features;
    return selectedFeatures.some(
      val =>
        val.id == obj.id &&
        val.description == obj.description &&
        val.paymentTypeId == obj.paymentTypeId
    );
  };

  const getFeatureIcon = id => {
    let featureIcons = {
      1: <SMSN className="mr-2 text-primary custom-svg" />,
      2: <Popup className="mr-2 text-primary custom-svg" />,
      3: <Document className="mr-2 text-primary custom-svg" />,
      4: <Others className="mr-2 text-primary custom-svg" />,
      5: <Calender className="mr-2 text-primary custom-svg" />,
      6: <Translate className="mr-2 text-primary custom-svg" />,
      7: <Fbook className="mr-2 text-primary custom-svg" />
    };
    return featureIcons[id];
  };

  const checkIfStatus_Editable = _status => {
    let isStatusEditable = offeredFeaturesEditableStatus.find(
      status => status.value == _status
    );
    if (isStatusEditable) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Form
      className="form"
      onSubmit={FeaturesFormIk.handleSubmit}
      autoComplete="off"
    >
      <SectionHeader
        sectionHeading={t('company.provisioningDetails.heading6')}
        loading={loading}
        editable={editable}
        hardTurnOff={!checkIfStatus_Editable(formData.status)}
        handleEditableState={setEditable}
      />

      <section>
        <div className="section-body">
          <ul className="editable-fields-lists onboarding-form-features">
            {/* {checkIfStatus_Editable(formData?.status)
              ? offeredFeatures.length > 0
                ? offeredFeatures.map((feature, index) => {
                    const { description, paymentTypeId, amount } = feature;
                    return (
                      <li
                        className={`list-group-item border-0 bg-lightgray-2 rounded mb-1 p-2`}
                        key={index}
                      >
                        <ToggleDetailed
                          heading={description}
                          flag={feature.isSelected}
                          listIcon={getFeatureIcon(feature.id)}
                          badges={[
                            `$${amount}`,
                            getObjectKeyByValue(
                              Enums.PaymentTypes,
                              paymentTypeId
                            )
                          ]}
                          titleText={
                            t('company.provisioningDetails.features', {
                              returnObjects: true,
                              description: description,
                              amount: `$${amount}`,
                              paymentType: getObjectKeyByValue(
                                Enums.PaymentTypes,
                                paymentTypeId
                              )
                            }).info
                          }
                          editable={editable}
                          handleChange={handleOnChangeFeature}
                          field={`feature-${index}`}
                        />
                      </li>
                    );
                  })
                : 'No features available'
              : formData?.features && formData?.features.length > 0
              ? formData.features.map((feature, index) => {
                  const { description, paymentTypeId, amount } = feature;
                  return (
                    <li
                      className={`list-group-item border-0 bg-lightgray-2 rounded mb-1 p-2`}
                      key={index}
                    >
                      <ToggleDetailed
                        heading={description}
                        flag={feature.isSelected}
                        listIcon={getFeatureIcon(feature.id)}
                        badges={[
                          `$${amount}`,
                          getObjectKeyByValue(Enums.PaymentTypes, paymentTypeId)
                        ]}
                        titleText={
                          t('company.provisioningDetails.features', {
                            returnObjects: true,
                            description: description,
                            amount: `$${amount}`,
                            paymentType: getObjectKeyByValue(
                              Enums.PaymentTypes,
                              paymentTypeId
                            )
                          }).info
                        }
                        editable={false}
                        field={feature.featureId}
                      />
                    </li>
                  );
                })
              : 'No features available'} */}
            {offeredFeatures.length > 0
              ? offeredFeatures.map((feature, index) => {
                  const { description, paymentTypeId, amount } = feature;
                  return (
                    <li
                      className={`list-group-item border-0 bg-lightgray-2 rounded mb-1 p-2`}
                      key={index}
                    >
                      <ToggleDetailed
                        heading={description}
                        flag={feature.isSelected}
                        listIcon={getFeatureIcon(feature.id)}
                        badges={[
                          `$${amount}`,
                          getObjectKeyByValue(Enums.PaymentTypes, paymentTypeId)
                        ]}
                        titleText={
                          t('company.provisioningDetails.features', {
                            returnObjects: true,
                            description: description,
                            amount: `$${amount}`,
                            paymentType: getObjectKeyByValue(
                              Enums.PaymentTypes,
                              paymentTypeId
                            )
                          }).info
                        }
                        editable={editable}
                        handleChange={handleOnChangeFeature}
                        field={`feature-${index}`}
                      />
                    </li>
                  );
                })
              : 'No features available'}
          </ul>
        </div>
      </section>
    </Form>
  );
};

export default FeatureDetails;
