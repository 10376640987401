import React, { useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import UilPlus from '@iconscout/react-unicons/icons/uil-plus';
import {
  addStatement,
  getAgentScriptIdByCompany,
  getAllAssignedCompanies,
  getAssignedCompaniesAsync,
  getCompanyLoading,
  getFolderByCompany,
  getFolderLoading,
  getFoldersAsync,
  getStatementText,
  removeSuggestion,
  setOpenSuggestionModal,
  setStatementText
} from 'features/messagingChats/suggestions';
import { success } from 'helpers/variables';
import 'static/scss/change-password.scss';
import AddForlderModal from './add-folder-modal';
import CompanyList from './company-list';
import FolderList from './folder-list';
import { selectRoles } from 'features/roles/rolesSlice';
import { useEffect } from 'react';
import VisibilityComp from './visibility';
import { useFormik } from 'formik';
import { makeStyles } from '@material-ui/core';
import { initialStatementValues } from 'helpers/schemas/statementSchemas/initialValues';
import { statementFormSchema } from 'helpers/schemas/statementSchemas/schemas';
import { Enums } from 'helpers/dropdown-enums';
import { ToastTemplate } from 'helpers/generic.methods';

const useStyles = makeStyles(theme => ({
  errorMessage: {
    position: 'absolute',
    color: 'var(--danger) !important',
    fontSize: '0.75rem',
    marginBottom: '0',
    height: '12px',
    whiteSpace: 'nowrap',
    // bottom: '-1px',
    top: '29px'
  },
  modalFooter: {
    padding: '0 0.75rem'
  }
}));

const StatementModal = ({ showModal, title = 'Create', size = 'lg' }) => {
  const [loading, setLoading] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedCompanyIndex, setSelectedCompanyIndex] = useState(null);
  const [openFolderModal, setOpenFolderModal] = useState(false);
  const assignedCompanies = useSelector(getAllAssignedCompanies);
  const companiesFetching = useSelector(getCompanyLoading);
  const foldersFetching = useSelector(getFolderLoading);
  const getAllFolders = useSelector(getFolderByCompany);
  const getAgentScriptId = useSelector(getAgentScriptIdByCompany);
  const { roles } = useSelector(selectRoles);
  const statementText = useSelector(getStatementText);
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    if (statementText && !statementFormIk.values.statement) {
      setFormValues({ statement: statementText });
    }
  }, [statementText]);

  useEffect(() => {
    openFolderListByDefault();
  }, [assignedCompanies.length]);

  useEffect(() => {
    if (!roles?.script?.edit) {
      setFormValues({ visibility: Enums.QTVisibility.Self });
    }
  }, []);

  const openFolderListByDefault = () => {
    if (assignedCompanies.length === 1) {
      let companyId = assignedCompanies[0].id;
      setSelectedCompanyIndex(companyId);
      handleCompanyChange(companyId);
    }
  };

  const setFolderPayload = () => {
    return { companyId: selectedCompany, threeLetterISOLanguageName: 'eng' };
  };

  const getFolders = () => {
    return getAllFolders(setFolderPayload()) || [];
  };

  const haveScript = () => {
    let folders = getAllFolders(setFolderPayload());
    return folders ? true : false;
  };

  const handleOnModalClose = () => {
    dispatch(setOpenSuggestionModal(false));
    dispatch(setStatementText(''));
    setSelectedData(null);
    setSelectedCompany(null);
    setLoading(false);
    setSelectedCompanyIndex(null);
    statementFormIk.resetForm();
  };

  const getSelectedFolderIds = (data = undefined) =>
    selectedData ? Object.values(data || selectedData).filter(key => key) : [];

  const handleOnModalSave = formValues => {
    setLoading(true);
    let data = { ...formValues };
    delete data['companyArray'];
    addStatement(data)
      .then(response => {
        if (response.data.success && response.data.data) {
          dispatch(removeSuggestion(setFolderPayload()));
          setLoading(false);
          handleOnModalClose();
          toast.success(success.saveSuccess('Statement', 'added'));
        }
      })
      .catch(error => {
        setLoading(false);
        toast.error(ToastTemplate.error(error || error.message));
      });
  };

  const fetchData = () => {
    if (!assignedCompanies.length) {
      try {
        dispatch(getAssignedCompaniesAsync());
      } catch (error) {
        toast.error(ToastTemplate.error(error || error.message));
      }
    } else {
      openFolderListByDefault();
    }

    // set visibility to self if user is not script editor
    if (!roles?.script?.edit) {
      // Note: added setTimeout to set visibility after component mounted
      // resolved UIUX-1621 bug
      setTimeout(() => {
        statementFormIk.setFieldValue('visibility', Enums.QTVisibility.Self);
      }, 1000);
    }
  };

  const handleOnChange = e => {
    let value = e.target.value;
    setFormValues({ statement: value });
  };

  const handleCompanyChange = companyId => {
    let tempSelectedData = { ...selectedData };
    if (selectedData && companyId in selectedData) {
      delete tempSelectedData[companyId];
      let keys = Object.keys(tempSelectedData);
      let prev_selected_company = Number(keys[keys?.length - 1]) || null;
      setSelectedCompany(prev_selected_company);
      setSelectedCompanyIndex(prev_selected_company);
      setFolderData(tempSelectedData);
    } else {
      setSelectedCompany(companyId);
      setSelectedCompanyIndex(companyId);
      tempSelectedData = { ...tempSelectedData, [companyId]: null };
      setFolderData(tempSelectedData);
      let payload = { companyId, threeLetterISOLanguageName: 'eng' };
      fetchFolders(payload);
    }
  };

  const viewFolderList = companyId => {
    setSelectedCompany(companyId);
    setSelectedCompanyIndex(companyId);
    let payload = { companyId, threeLetterISOLanguageName: 'eng' };
    fetchFolders(payload);
  };

  const handleFolderChange = folderId => {
    setFolderData({ ...selectedData, [selectedCompany]: folderId });
  };

  const fetchFolders = (payload, requiredUpdate = false) => {
    let folders = getAllFolders(payload);
    if (!folders || requiredUpdate) {
      dispatch(getFoldersAsync(payload));
    }
  };

  const onSaveFolder = () => {
    let payload = {
      companyId: selectedCompany,
      threeLetterISOLanguageName: 'eng'
    };
    fetchFolders(payload, true);
  };

  const visibilityHandler = e => {
    let value = e.target.value;
    setFormValues({ visibility: Number(value) });
  };

  const clearSelection = () => {
    if (selectedData && selectedCompany in selectedData) {
      setFolderData({ ...selectedData, [selectedCompany]: null });
    }
  };

  const setFolderData = data => {
    setSelectedData(data);
    let folderIds = getSelectedFolderIds(data);
    setFormValues({ folderIdArray: folderIds, companyArray: data });
    // remove formik validation error message from UI
    let formFields = statementFormIk.touched;
    delete formFields['companyArray'];
    delete formFields['folderIdArray'];
    statementFormIk.setTouched(formFields);
  };

  /** Init Formik */
  const statementFormIk = useFormik({
    enableReinitialize: true,
    initialValues: initialStatementValues,
    validationSchema: statementFormSchema,
    onSubmit: handleOnModalSave
  });

  const setFormValues = updatedFormValueObject => {
    statementFormIk.setValues({
      ...statementFormIk.values,
      ...updatedFormValueObject
    });
  };

  const showFolderError = () => {
    let error =
      (statementFormIk.touched['folderIdArray'] &&
        statementFormIk.errors.folderIdArray) ||
      (statementFormIk.touched.companyArray &&
        statementFormIk.errors.companyArray);
    return <span className={classes.errorMessage}>{error}</span>;
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleOnModalClose}
        onShow={fetchData}
        size={size}
        // keyboard={false}
        id="viewModal"
        backdrop={true}
        dialogClassName={`viewModal`}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Body>
          <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
          <div className="mobile_view mt-3">
            <Form.Group
              controlId="statement"
              className="normal-form-group mb-3"
            >
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                label="statement"
                name="statement"
                onChange={e => handleOnChange(e)}
                onBlur={statementFormIk.handleBlur}
                value={statementFormIk.values.statement}
                placeholder="Write a message"
              />
              {statementFormIk.errors['statement'] &&
                statementFormIk.touched['statement'] && (
                  <p
                    className={`text-red-1 field-error mt-0 position-absolute`}
                  >
                    {statementFormIk.errors['statement']}
                  </p>
                )}
            </Form.Group>
            <div className="row">
              <div className="col-12 col-md-6">
                <span className="d-flex justify-content-between align-items-end mb-3 mt-2">
                  <Title
                    text={'Companies'}
                    loading={companiesFetching}
                    count={assignedCompanies.length}
                  />
                </span>
                <CompanyList
                  list={assignedCompanies}
                  handleChange={handleCompanyChange}
                  openFolderList={viewFolderList}
                  selectedData={selectedData}
                  loading={companiesFetching}
                  selectedIndex={selectedCompanyIndex}
                  hasCompanyError={
                    statementFormIk.touched.companyArray &&
                    statementFormIk.errors.companyArray
                  }
                />
              </div>
              <div className="col-12 col-md-6">
                <span className="d-flex justify-content-between align-items-end mb-3">
                  <Title
                    text={'Folders'}
                    loading={foldersFetching}
                    count={getFolders()?.length}
                  />
                  {selectedData && selectedData[selectedCompany] && (
                    <span
                      className="text-primary cursor-pointer"
                      onClick={clearSelection}
                    >
                      Clear Selection
                    </span>
                  )}
                  {selectedCompany && !foldersFetching && roles?.script?.edit && (
                    <span>
                      <Button
                        className="btn-md mb-1 d-flex align-items-center p-1"
                        variant="primary"
                        onClick={() => setOpenFolderModal(true)}
                        disabled={!haveScript()}
                      >
                        <UilPlus style={{ height: 20, width: 20 }} />
                        Add Folder
                      </Button>
                    </span>
                  )}
                </span>
                {showFolderError()}
                {selectedCompany && !foldersFetching && (
                  <FolderList
                    list={getFolders() || []}
                    handleChange={handleFolderChange}
                    selectedFolder={
                      (selectedData && selectedData[selectedCompany]) || ''
                    }
                    haveScript={haveScript()}
                  />
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer
          className={`user-modal-footer justify-content-between ${classes.modalFooter}`}
        >
          <div className="d-flex align-items-center">
            <VisibilityComp
              selectedValue={statementFormIk.values.visibility}
              form={statementFormIk}
              handleChange={visibilityHandler}
              roles={roles}
            />
          </div>
          <div className="d-flex">
            <Button
              type="button"
              variant="outline-primary"
              className="passBtn btn-md btn-flex mr-2"
              onClick={() => handleOnModalClose()}
            >
              <span>Close</span>
            </Button>
            <Button
              type="button"
              variant="outline-primary"
              className="passBtn btn-md btn-flex"
              disabled={loading}
              onClick={statementFormIk.handleSubmit}
            >
              <span>Save</span>
              {loading && (
                <Spinner
                  className="ml-2"
                  animation="border"
                  size="sm"
                  as="span"
                />
              )}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <AddForlderModal
        closeModal={() => setOpenFolderModal(false)}
        showModal={openFolderModal}
        selectedCompany={selectedCompany}
        agentScriptId={getAgentScriptId(setFolderPayload())}
        refetchFolders={onSaveFolder}
      />
    </>
  );
};

const Title = ({ text, loading, count, error = null }) => {
  const classes = useStyles();
  return (
    <span className="position-relative">
      <span className="d-flex align-items-center">
        <h4 className="text-primary mb-0">{text}</h4>
        {count > 0 && <span className="mb-0 ml-1">({count})</span>}
        {loading && (
          <Spinner
            variant="primary"
            className="ml-2"
            animation="border"
            size="sm"
            as="span"
          />
        )}
      </span>
      {/* <span className={classes.errorMessage}>{error}</span> */}
    </span>
  );
};

export default StatementModal;
