import {
  Badge,
  Box,
  Grid,
  ListItem,
  ListItemText,
  makeStyles
} from '@material-ui/core';
import { GetLeadType } from 'components/lead/leadTypeBadge';
import { dayAgo } from 'helpers/generic.methods';
import React, { Component } from 'react';
import { getSelectedChatId } from 'features/messagingChats/messagingChats';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as SMSIcon } from 'static/images/chat-icons/SMS.svg';
import TruncateWithToolTip from 'components/TruncateWithToolTip';
import useQuery from 'routes/searchQueryHook';
import { selectLogin } from 'features/auth/authSlice';
import { selectRoles } from 'features/roles/rolesSlice';
const muiStyling = makeStyles(theme => ({
  active: {
    backgroundColor: 'var(--primary-4) !important'
  }
}));
export const OptInLeadListItem = ({ ...props }) => {
  var { optInLead, clickHandler } = props;
  const useStyling = muiStyling();
  const selectedChatId = useSelector(getSelectedChatId);
  const user = useSelector(selectLogin);
  const { roles } = useSelector(selectRoles);
  let query = useQuery();
  let qChatId = query.get('chatId');
  const canMarkAsDisabled = chatId => {
    let disabled = false;
    if (roles?.greyOutNonAccessibleChats) {
      if (chatId !== Number(qChatId)) {
        disabled = true;
      }
    }
    return disabled;
  };
  return (
    <ListItem
      button
      classes={{
        selected: useStyling.active
      }}
      disableGutters={true}
      style={{
        border: '1px solid #DFE3E8',
        borderRadius: '4px',
        padding: '8px',
        height: '85px',
        color: 'var(--primary)'
      }}
      selected={optInLead.chatId == selectedChatId}
      onClick={() => clickHandler(optInLead.chatId)}
      disabled={canMarkAsDisabled(optInLead.chatId)}
    >
      <ListItemText
        primary={
          <>
            <Grid container>
              <Grid item xs={10}>
                <Box sx={{ display: 'inline-block' }} mr={1}>
                  <Badge
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    classes={{ badge: 'badge-container-color' }}
                    color="secondary"
                    variant="dot"
                    invisible={optInLead.isRead}
                  >
                    <SMSIcon className="chat-icon" width={30} height={30} />
                  </Badge>
                </Box>
                <Box
                  sx={{
                    display: 'inline-block',
                    fontSize: '16px',
                    fontWeight: 700
                  }}
                >
                  <TruncateWithToolTip text={optInLead.name} limit={20} />
                </Box>
              </Grid>
              <Grid item xs={2}>
                <GetLeadType leadType={optInLead.leadType} />
              </Grid>
            </Grid>
          </>
        }
        secondary={
          <Box mt={1}>
            <Grid container>
              <Grid item xs={8}>
                <Box
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '2',
                    WebkitBoxOrient: 'vertical',
                    color: 'var(--primary)'
                  }}
                >
                  <TruncateWithToolTip text={optInLead.notes} limit={28} />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box sx={{ textAlign: 'right' }}>
                  {dayAgo(optInLead.createdOn)}
                </Box>
              </Grid>
            </Grid>
          </Box>
        }
      />
    </ListItem>
  );
};
