import { makeStyles } from '@material-ui/core/styles';
import { UilCheckCircle } from '@iconscout/react-unicons';
import BadgeWithIcon from 'components/BadgeWithIcon';
import { Hidden } from '@material-ui/core';
import React from 'react';

const useStyle = makeStyles(theme => ({
  check: {
    width: '18px !important',
    height: '18px !important',
    marginRight: '2px',
    marginLeft: '1px'
  },
  Qt_Badge: {
    fontSize: '13px !important',
    padding: ' 0 !important',
    '& span': {
      padding: '5px 10px',
      '& span': {
        padding: '0 !important'
      }
    }
  },
  selectedBadge: {
    backgroundColor: 'var(--success-4) !important',
    borderColor: 'var(--success)'
  }
}));

const QTOptions = ({ questionType, options, selectedAnswer }) => {
  const classes = useStyle();
  const parsedOpts = JSON.parse(options);
  let answer = selectedAnswer?.trim()?.split(/\s*,\s*/);

  //check options are in string format, probably its consent options
  if (typeof options == 'string') {
    //disabling it for now
    return <React.Fragment></React.Fragment>;
  }

  return Array.isArray(parsedOpts)
    ? parsedOpts?.map((opt, index) => (
        <BadgeWithIcon
          key={index}
          text={opt.text}
          icon={
            <UilCheckCircle className={`text-success-2 ${classes.check}`} />
          }
          showIcon={answer?.includes(opt.text)}
          badgeClass={`${
            answer?.includes(opt.text) ? classes.selectedBadge : ''
          } ${classes.Qt_Badge} mt-1`}
        />
      ))
    : null;
};

export default QTOptions;

