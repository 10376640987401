import { Row, Col, Button } from 'react-bootstrap';
import { UilArrowRight } from '@iconscout/react-unicons';
import SSO_Redirection from 'app/redirection-SSO';
import useViewport from 'helpers/viewport-hook';

const SpecialMessageComponent = ({ t, showButton = true, message = '' }) => {
  const screen = useViewport();
  const redirectHandler = () => {
    // redirection here..
    SSO_Redirection({ returnUrl: '/dashboard.aspx', subDomain: 'dashboard.' });
  };

  return (
    <>
      <span
        className={`mt-3 ${
          screen.xs ? 'special-message-text-mobile' : 'special-message-text'
        }`}
      >
        {message}
      </span>
      {showButton && !screen.xs && (
        <div style={{ marginRight: '30px' }}>
          <div
            className="container-header-existing-portal"
            style={{
              borderRadius: '0px', // UIUX-1733 Changed from 10px to 0px
              borderBottom: '1px solid #e4e3e2'
            }}
          >
            {/* <span className="msg">{t('company.general.messagingTag')}</span> */}
            <span className="msg">
              In the meantime, you can access the old dashboard
            </span>
            <Button
              className="btn-sm btn-flex ml-2 btn-container"
              variant="outline-warning"
              onClick={redirectHandler}
            >
              <span className="existing-btn-text">
                {t('company.general.clickBtn')}
              </span>
              <span>
                <UilArrowRight className="existing-btn-arrow" />
              </span>
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default SpecialMessageComponent;
