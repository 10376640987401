import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as forgotPasswordSchema from 'helpers/schemas/authSchemas';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { forgotPasswordAsync } from './authSlice';
import { errors, success, toastMsg } from 'helpers/variables';
import { toast } from 'react-toastify';
import 'static/scss/forgot-password.scss';
import {
  queryStringToJSONObject,
  setErrorClass
} from 'helpers/generic.methods';
import { email } from 'helpers/schemas/genericSchemas';

const ForgotPasswordForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [forgotPasswordSuccess, setForgotPasswordSuccess] = useState('');
  const [remember, setRemember] = useState({ rememberMe: false });
  const [fieldErrorOnSubmit, setFieldErrorOnSubmit] = useState(false);

  const errorHandler = resetForm => {
    toast.error(toastMsg.errors.login);
    setForgotPasswordSuccess(false);
    setFieldErrorOnSubmit(true);
    resetForm({});
    setLoading(false);
  };
  /**
   * @name @loginFormHandler
   * @description Used to connect with store and call login api then toggle error
   * @requires data from the form fields
   * */
  const loginFormHandler = async (data, { resetForm }) => {
    setLoading(true);
    setRemember({ remember, rememberMe: data.rememberMe });

    await dispatch(forgotPasswordAsync(data))
      .then(response => {
        if (response && email.isValidSync(response)) {
          history.replace({
            pathname: '/forgot-password',
            search: '?email=' + response
          });
          setForgotPasswordSuccess(response);
          toast.success(success.forgotPassword);
        } else {
          errorHandler(resetForm);
        }
      })
      .catch(err => {
        errorHandler(resetForm);
      });
  };

  useEffect(() => {
    if (location.search) {
      setForgotPasswordSuccess(queryStringToJSONObject(location.search)?.email);
    }
  }, [location]);

  /** Init Formik */
  const ForgotPasswordFormIk = useFormik({
    initialValues: forgotPasswordSchema.forgotPasswordInitialValues,
    validationSchema: forgotPasswordSchema.forgotPasswordSchema,
    onSubmit: loginFormHandler
  });

  /**
   * @name @handleOnChange
   * @description do something on field onChange
   * @requires html input element
   * */
  const handleOnChange = e => {
    ForgotPasswordFormIk.handleChange(e);
    setFieldErrorOnSubmit(false);
  };

  return (
    <>
      {forgotPasswordSuccess ? (
        <>
          <p className="fz-20 mb-lg-50 mb-30">
            Please check the email address '{forgotPasswordSuccess}' for further
            instructions.
          </p>
          <Link to="/login" className="btn btn-primary w-100">
            <i className="uil uil-arrow-left"></i> Back to login
          </Link>
        </>
      ) : (
        <form
          className="form"
          onSubmit={ForgotPasswordFormIk.handleSubmit}
          autoComplete="off"
        >
          <Form.Group
            controlId="companyKey"
            className={setErrorClass(
              [ForgotPasswordFormIk, fieldErrorOnSubmit],
              'companyKey'
            )}
          >
            <Form.Control
              className="lined with-label"
              label="companyKey"
              name="companyKey"
              onChange={e => handleOnChange(e)}
              value={ForgotPasswordFormIk.values['companyKey']}
            />
            <Form.Label className="label-movable">Company</Form.Label>
            <p className="text-red-1 field-error">
              {ForgotPasswordFormIk.errors['companyKey'] &&
                ForgotPasswordFormIk.touched['companyKey'] &&
                errors.fieldError('company')}
              {fieldErrorOnSubmit && errors.fieldCorrection('company name')}
            </p>
          </Form.Group>
          <Form.Group
            controlId="userName"
            className={setErrorClass(
              [ForgotPasswordFormIk, fieldErrorOnSubmit],
              'userName'
            )}
          >
            <Form.Control
              className="lined with-label"
              label="userName"
              name="userName"
              onChange={e => handleOnChange(e)}
              value={ForgotPasswordFormIk.values['userName']}
            />
            <Form.Label className="label-movable">Username</Form.Label>
            <p className="text-red-1 field-error">
              {ForgotPasswordFormIk.errors['userName'] &&
                ForgotPasswordFormIk.touched['userName'] &&
                errors.fieldError('userName')}
              {fieldErrorOnSubmit && errors.fieldCorrection('userName')}
            </p>
          </Form.Group>
          <Button
            disabled={loading || !ForgotPasswordFormIk.dirty}
            type="submit"
            variant="primary"
            className="w-100 mt-lg-20 "
            onClick={() => setFieldErrorOnSubmit(false)}
          >
            Request new password
            {loading && <i className="uil uil-spinner spinner ml-2"></i>}
          </Button>
        </form>
      )}
    </>
  );
};

export default ForgotPasswordForm;
