import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import UILSave from '@iconscout/react-unicons/icons/uil-save';
import UILEdit from '@iconscout/react-unicons/icons/uil-edit-alt';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';

const EditSaveToggleButton = ({
  pageHeading,
  sectionHeading,
  editable,
  loading,
  handleEditableState,
  disabled,
  saveText,
  editText,
  handleSubmit = () => console.log('submit'),
  /* headerProperty: Sometime we have Checkbox/Switch on header to enable/disable all fields underneath the header. 
   If hasHeaderProperty enabled, then we will show the Switch and bind the OnChange as well.*/
  hasHeaderProperty,
  headerProperty,
  headerPropertyOnChange,
  titleText,
  field,
  id,
  readOnlySection,
  /* Bug-572 turned off the edit button in facebook and custom tracking
  added this control variable for turningOff */
  hardTurnOff = false
}) => {
  const toggleChecked = e => {
    headerPropertyOnChange(e.target.checked);
    setEditableBtn(true);
    setHeaderPropertyCheck(!headerPropertyCheck);
  };

  const { t } = useTranslation();
  const [editableBtn, setEditableBtn] = useState(false);
  const [headerPropertyCheck, setHeaderPropertyCheck] = useState(
    !!headerProperty
  );

  return (
    <header className="section-head d-flex align-items-center justify-content-between">
      {/* {pageHeading && <h4 className="sub-head">{pageHeading}</h4>}
      {sectionHeading && <h5 className="fw-600 mb-0">{sectionHeading}</h5>} */}
      {sectionHeading && (
        <h5 className="fw-600 mb-0" style={{ width: '100%' }}>
          {sectionHeading}
        </h5>
      )}
      <div
        className=" align-items-center"
        style={{ width: '100%', display: 'block' }}
      >
        {editable ? (
          <Button
            onClick={e => handleSubmit()}
            disabled={disabled || loading}
            className="btn-md btn-flex ml-6r btn-float-right"
            type="submit"
            variant="primary"
          >
            <UILSave />
            <span>
              {saveText ? saveText : t('company.general.saveTxt')}
              {loading && (
                <i className="uil uil-spinner spinner small ml-2"></i>
              )}
            </span>
          </Button>
        ) : !hardTurnOff ? ( // Bug-572 used additional ternary check to exclude the button
          <Button
            className="btn-md btn-flex ml-6r introJs-companies-tab-view-0 btn-float-right"
            type="button"
            disabled={readOnlySection || disabled}
            variant="lightgray-3"
            onClick={e => {
              e.preventDefault();
              handleEditableState(true);
            }}
          >
            <UILEdit />
            <span>{editText ? editText : t('company.general.editTxt')}</span>
          </Button>
        ) : (
          <></> // Bug-572 JSX complete expression in case of no button
        )}
        {hasHeaderProperty && (
          <Form.Check
            type="switch"
            id={id || field}
            checked={!!headerProperty}
            onChange={e => toggleChecked(e)}
            title={titleText}
            disabled={editable ? false : true}
            className="btn-float-right"
          />
        )}
      </div>
    </header>
  );
};

export default EditSaveToggleButton;
