import React from 'react';
import DisplayKey from 'helpers/ui-controls/DisplayKey';
import { useTranslation } from 'react-i18next';
import FlagOutlinedIcon from '@material-ui/icons/FlagOutlined';
import ForwardIcon from '@iconscout/react-unicons/icons/uil-corner-up-right-alt';
import { Link } from 'react-router-dom';
const LeadHeader = ({
  name,
  id,
  chatId,
  companyId,
  flagAsProcessed,
  handleFlagProcessing,
  openForwardLeadModal
}) => {
  const { t } = useTranslation();
  const _handleFlagProcessing = () => {
    let payload = { leadId: id, processedByCustomer: !flagAsProcessed };
    handleFlagProcessing(payload);
  };
  return (
    <>
      <div className="page-header">
        <div className="page-header-bottom d-flex align-items-end justify-content-between">
          <h1 className="main-head text-primary-2 mb-0">{name}</h1>
          <div className="align-self-start">
            <DisplayKey displayKey={id} />
            <div className="d-flex">
              <button
                type="button"
                className={
                  flagAsProcessed
                    ? 'btn-md btn-flex ml-4r  btn btn-primary'
                    : 'btn-md btn-flex ml-4r introJs-companies-tab-view-0  btn btn-lightgray-3'
                }
                onClick={_handleFlagProcessing}
              >
                <FlagOutlinedIcon fontSize="inherit" />
                <span className="ml-0">
                  {flagAsProcessed
                    ? t('company.leadDetails.flagAsUnProcessed.label')
                    : t('company.leadDetails.flagAsProcessed.label')}
                </span>
              </button>
              {/* Forward lead button start */}
              <button
                type="button"
                className={'btn-md btn-flex ml-4r btn btn-primary'}
                onClick={openForwardLeadModal}
              >
                <ForwardIcon fontSize="inherit" />
                <span className="ml-0">
                  {t('company.leadDetails.forwardLead.label')}
                </span>
              </button>
              {/* Forward lead button end */}

              {!!chatId && (
                <Link
                  to={`conversations?id=${chatId}`}
                  className="btn-md btn-flex ml-4r introJs-companies-tab-view-0  btn btn-primary"
                >
                  {t('company.leadDetails.viewChat.label')}
                </Link>
              )}
              {!!companyId && (
                <Link
                  to={`companies?id=${companyId}`}
                  className="btn-md btn-flex ml-4r introJs-companies-tab-view-0  btn btn-primary"
                >
                  {t('company.leadDetails.viewCompany.label')}
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeadHeader;
