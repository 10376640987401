import React, { useState, useEffect } from 'react';
import UserInfo from './UserInfo';
import LeadForm from './LeadSection/LeadForm';
import { Tabs } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import { toast } from 'react-toastify';
import { errors, success } from 'helpers/variables';
import { sendCallConnect, sendLead } from 'features/lead/leadSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectChatDetailsRightPane,
  toggleChatDetailsRightPane
} from 'features/chat/chatSlice';
import {
  updateUnsentLeadStatus,
  setCallConnectStatus,
  getSelectedChatCallStatus,
  setChatUpdatedInfoDataInChats,
  getChatUpdatedInfoById
} from 'features/messagingChats/messagingChats';
import { Init_CallConnectStatusById } from 'app/communication/listeners/listener_call_connect_status_by_id';
import { isCallConnectEnable } from 'helpers/messaging/message.methods';
import './UserDetails.scss';
import { Enums } from 'helpers/dropdown-enums';
import LeadService from './LeadSection/lead-service';
import { SlimLeadForm } from './SlimLeadForm';
import { ToastTemplate } from 'helpers/generic.methods';
import { isNormalChat } from './Helper';

const UserDetails = ({ selectedChat, endChat }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  let callConnectionStatus = useSelector(
    getSelectedChatCallStatus(selectedChat?.getId())
  );
  let togglePanel = useSelector(selectChatDetailsRightPane);
  const chatUpdatedInfo = useSelector(
    getChatUpdatedInfoById(selectedChat.getId())
  );
  const rulesInfo = LeadService(selectedChat);

  useEffect(() => {
    setTimeout(() => {
      if (chatUpdatedInfo?.sendingLead) {
        dispatch(
          setChatUpdatedInfoDataInChats({
            chatId: selectedChat.getId(),
            updatedInfoData: { sendingLead: false }
          })
        );
      }
    }, 30000);
  }, [chatUpdatedInfo?.sendingLead]);

  const leadFromHandler = data => {
    setLoading(true);
    dispatch(sendLead(data))
      .then(res => {
        dispatch(
          setChatUpdatedInfoDataInChats({
            chatId: res.data.chatId,
            updatedInfoData: {
              leadId: res.data.id,
              sendingLead: true,
              leadSubmitMessage: 'Chat lead has been submitted'
            }
          })
        );
        setLoading(false);
      })
     .catch(error => {
        toast.error(ToastTemplate.error(error || errors.failure));
        setLoading(false);
      });
  };
  const callConnectFormHandler = data => {
    setLoading(true);
    Init_CallConnectStatusById({
      chatId: selectedChat.getId(),
      onStatusArrived: args => {
        onCallConnectStatusChange(args);
      }
    });
    dispatch(sendCallConnect(data))
      .then(() => {
        // toast.success(success.callConnectSuccess);
        setLoading(false);
      })
      .catch(error => {
        toast.error(ToastTemplate.error(error || errors.failure));
        setLoading(false);
      });
  };

  const onCallConnectStatusChange = args => {
    let { chatID, status, prettyError } = args;
    dispatch(setCallConnectStatus({ chatId: chatID, status }));
    console.warn('callConnectStatus failed with prettyError:', prettyError);
    if (status === Enums.CallConnectStatus.Failed) {
      toast.error(prettyError || 'Call connect failed');
    }
  };

  const toggleSidePane = activeTab => {
    dispatch(
      toggleChatDetailsRightPane({
        activeDetailsTab: activeTab,
        activeParentTab: 'user-details'
      })
    );
  };

  const unsentleadHandler = data => {
    setSending(true);
    dispatch(updateUnsentLeadStatus(data))
      .then(res => {
        if (res === true) {
          toast.success(success.updateLead(data.chatId));
        } else {
          toast.error(
             ToastTemplate.error(
              res && !res.includes('Network Error') ? res : errors.networkError
            )
          );
        }
        setSending(false);
      })
       .catch(error => {
        toast.error(ToastTemplate.error(error || errors.failure));
        setLoading(false);
      });
  };

  return (
    <>
      {isNormalChat(selectedChat?.data) && (
        <>
          {Object.keys(selectedChat)?.length && (
            <UserInfo
              selectedChat={selectedChat}
              endChat={endChat}
              participants={chatUpdatedInfo?.participants || []}
            />
          )}
          <div className="userdetails-lead-tabs">
            <Tabs
              activeKey={togglePanel.activeDetailsTab}
              id="uncontrolled-user-details-tabs"
              className="tab-right-panel mt-3"
              onSelect={toggleSidePane}
            >
              <Tab
                eventKey="lead-form"
                title="Lead Form"
                className="flex-column align-items-center"
              >
                <LeadForm
                  title="Lead"
                  id="lead-form"
                  hideFields={{ phoneFrom: true }}
                  fromHandler={leadFromHandler}
                  loading={loading}
                  onClickUnsentLeadHandler={unsentleadHandler}
                  sending={sending}
                  rulesInfo={rulesInfo}
                />
              </Tab>
              <Tab
                eventKey="call-connect"
                title="Call Connect"
                className={`flex-column align-items-center`}
                disabled={!isCallConnectEnable(selectedChat)}
                tabClassName={
                  !isCallConnectEnable(selectedChat) && 'disabled-tab'
                }
              >
                <LeadForm
                  title="Call Connect"
                  id="call-connect"
                  hideFields={{
                    email: true,
                    reason: true,
                    type: !rulesInfo?.rulesEnabled
                  }}
                  fromHandler={e => callConnectFormHandler(e)}
                  loading={loading}
                  callStatus={callConnectionStatus}
                  rulesInfo={rulesInfo}
                />
              </Tab>
            </Tabs>
          </div>
        </>
      )}
      {(selectedChat?.data?.status == Enums.ChatStatus.ClientHandling ||
        selectedChat?.data?.status == Enums.ChatStatus.Inactive) &&
        selectedChat?.data?.isOptInConsentGiven && (
          <LeadForm
            title="Lead"
            id="lead-form"
            hideFields={{ phoneFrom: true }}
            fromHandler={leadFromHandler}
            loading={loading}
            onClickUnsentLeadHandler={unsentleadHandler}
            sending={sending}
            rulesEnabled={rulesInfo?.rulesEnabled}
            leadCategories={rulesInfo?.leadCategories}
            regions={rulesInfo?.regions}
            rulesInfo={rulesInfo}
          />
        )}
    </>
  );
};

export default UserDetails;
