import React, { useState, useEffect, useRef } from 'react';
import SimpleLabelTextBox from 'helpers/ui-controls/ui-components/SimpleLabelTextbox';
import DropDownEditable from 'helpers/ui-controls/DropDownEditable';
import { useTranslation } from 'react-i18next';
import dropdownEnums, {
  Enums,
  getObjectKeyByValue
} from 'helpers/dropdown-enums';
import DaysAndHours from 'helpers/ui-controls/ui-components/DaysAndHours';
import ToggleToolTip from 'helpers/ui-controls/toggleToolTip';
import ToggleDetailed from 'helpers/ui-controls/toggleDetailed';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import { errors, success } from 'helpers/variables';
import { provisionFormInitialValues } from 'helpers/schemas/provisionSchemas/initialValues';
import { provisionFormSchemas } from 'helpers/schemas/provisionSchemas/schemas';
import { useFormik } from 'formik';
import { UilSave } from '@iconscout/react-unicons';
import {
  createNewProvision,
  SaveAttachements,
  getAttachements,
  setRefetchRequired,
  removeProvisionFromState,
  getOfferedFeaturesByHostId
} from 'features/provisioning/ProvisioningSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBusinessTypes,
  getBusinessSubCategories
} from 'features/analytics/analyticSlice';
import { getCookie, setCookie, ToastTemplate } from 'helpers/generic.methods';
import TimeZoneField from 'helpers/ui-controls/TimeZoneField';
import { CrmData } from 'app/crmData';
import CustomAutoCompleteFilter from 'pages/portal/dashboard/filters/custom-auto-complete-filter';
import AttachementComponent from './subSections/attachementComponent';
import storage from 'helpers/storage';
import { getAllCompanies } from 'features/companies/companiesSlice';
import '../style.scss';
import DeleteModal from 'pages/portal/modal/deleteModal';
import { selectRoles } from 'features/roles/rolesSlice';

import { ReactComponent as Document } from 'static/images/features-section-icons/document.svg';
import { ReactComponent as SMSN } from 'static/images/features-section-icons/smsn.svg';
import { ReactComponent as Popup } from 'static/images/features-section-icons/popup.svg';
import { ReactComponent as Fbook } from 'static/images/features-section-icons/fbook.svg';
import { ReactComponent as Calender } from 'static/images/features-section-icons/calender.svg';
import { ReactComponent as Others } from 'static/images/features-section-icons/others.svg';
import { ReactComponent as Translate } from 'static/images/features-section-icons/translate.svg';
import { UilExclamationCircle } from '@iconscout/react-unicons';
const NewOnboarding = ({ handleOpenSidebar, setFunction }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [editable, setEditable] = useState(true);
  const [businessTypesOptions, setBusinessTypesOptions] = useState([]);
  const [selectedBusinessTypeOption, setSelectedBusinessTypeOption] =
    useState(null);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [attachements, setAttachements] = useState([]);
  const [selectedCompanyKey, setCompanyKey] = useState([]);
  const [companiesOption, setCompaniesOption] = useState([]);
  const [offeredFeatures, setOfferedFeatures] = useState([]);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isSaveAllowed, setSaveAllowed] = useState(false);
  const user = JSON.parse(storage.getItem('Profile'));
  const { roles } = useSelector(selectRoles);
  const featureSection = useRef(null);
  const isCustomer = user?.CompanyRelationship == Enums.Relationship.Customer;
  const dispatch = useDispatch();

  const generateBusinessOptions = data => {
    return [...data].map(dt => ({
      name: `${dt.name}`,
      value: dt.id,
      id: dt.rlid
    }));
  };

  const ProvisionFormHandler = async formData => {
    if (
      !offeredFeatures.filter(val => val.isSelected).length &&
      !isSaveAllowed
    ) {
      setShowModal(true);
      return;
    }
    setLoading(true);
    formData.secondaryCategories = selectedSubCategories.map(val => val.value);
    formData.features = offeredFeatures.filter(val => val.isSelected);
    formData['officeHours'] = formData['officeHours'].map(item => {
      let tempOfficeHours = item;
      if (item.endTime.includes('undefined')) {
        tempOfficeHours.endTime = '';
      }
      if (item.startTime.includes('undefined')) {
        tempOfficeHours.startTime = '';
      }
      return tempOfficeHours;
    });
    formData['quickConnectHours'] = formData['quickConnectHours'].map(item => {
      let tempOfficeHours = item;
      if (item.endTime.includes('undefined')) {
        tempOfficeHours.endTime = '';
      }
      if (item.startTime.includes('undefined')) {
        tempOfficeHours.startTime = '';
      }
      return tempOfficeHours;
    });
    createNewProvision(formData).then(response => {
      if (response.data.success) {
        let provisionId = response.data.data.id;
        let dataProvisioning =
          JSON.parse(storage.getItem('provisions-selectedProvisions')) || [];
        let index = dataProvisioning.findIndex(value => value.id == 0);
        if (index != -1) {
          dataProvisioning[index] = response.data.data;
          setFunction(provisionId, dataProvisioning);
        }
        dispatch(setRefetchRequired(true));
        dispatch(removeProvisionFromState({ id: 0 }));
        if (attachements.length) {
          let form = new FormData();
          attachements.forEach((val, index) => {
            form.append(`file-${index + 1}`, val);
          });
          SaveAttachements(form, provisionId).then(res => {
            if (res.data.success) {
              dispatch(getAttachements(provisionId));
              toast.success(success.saveSuccess('New Onboarding'));
              setLoading(false);
            } else {
              toast.error(response.data.error || errors.createError);
              setLoading(false);
            }
          });
        } else {
          toast.success(success.saveSuccess('New Onboarding'));
          setLoading(false);
        }
      } else {
        toast.error(response.data.error || errors.createError);
        setLoading(false);
      }
    });
  };

  const handleOnChangeAttachements = attachementsData => {
    setAttachements(attachementsData);
  };

  const ProvisionFormIk = useFormik({
    enableReinitialize: true,
    initialValues: provisionFormInitialValues,
    validationSchema: provisionFormSchemas,
    onSubmit: ProvisionFormHandler
  });

  useEffect(() => {
    let temp = offeredFeatures.map(item => ({ ...item, isSelected: false }));
    setOfferedFeatures(temp);
    getFeatures();
    if (isCustomer) {
      let selectedCompanyKey = {
        key: user?.CompanyKey,
        value: user?.CompanyKey,
        id: user?.CompanyID
      };
      setCompanyKey(selectedCompanyKey);
      ProvisionFormIk.setValues({
        ...ProvisionFormIk.values,
        provisionedCompanyId: parseInt(selectedCompanyKey?.id)
      });
    }
    if (getCookie('analyticsBusinessOptions')) {
      let data = generateBusinessOptions(
        JSON.parse(getCookie('analyticsBusinessOptions'))
      );
      let temp = [{ name: '- None -', id: 0, value: 0 }];
      let combinedWithDefaultOption = [...temp, ...data];
      setBusinessTypesOptions(combinedWithDefaultOption);
    } else {
      getBussinesTypes();
    }
  }, []);

  const getFeatures = () => {
    let { HostID } = user;
    getOfferedFeaturesByHostId({
      params: { id: HostID }
    }).then(response => {
      if (response.data.success) {
        const { data } = response.data;
        let temp = data.map(item => ({ ...item, isSelected: false }));
        setOfferedFeatures(temp);
      }
    });
  };

  const getBussinesTypes = () => {
    try {
      getBusinessTypes().then(response => {
        if (response && response.data) {
          let data = generateBusinessOptions(response.data.data);
          let temp = [{ name: '- None -', id: 0, value: 0 }];
          let combinedWithDefaultOption = [...temp, ...data];
          setBusinessTypesOptions(combinedWithDefaultOption);
          setCookie(
            'analyticsBusinessOptions',
            JSON.stringify(response.data.data),
            1
          );
        }
      });
    } catch (error) {
      toast.error(ToastTemplate.error(error || error.message));
    }
  };

  const updateHours = (key, value) => {
    ProvisionFormIk.setValues({
      ...ProvisionFormIk.values,
      [key]: value
    });
  };

  const handleOnChange = (element, key, value) => {
    ProvisionFormIk.setValues({
      ...ProvisionFormIk.values,
      [key]: value
    });
    ProvisionFormIk.handleChange(element);
  };

  const handleOnChangeFeature = (element, key, value) => {
    const temp = [...offeredFeatures];
    let index = key.split('-')[1];
    temp[index].isSelected = value;
    setOfferedFeatures(temp);
  };

  const handleOnChangeCRM = (element, key, value) => {
    let selectedCRM = CrmData.find(crm => crm.value == value);
    let crmObj = {
      crmId: parseInt(selectedCRM.value),
      crmName: selectedCRM.name
    };
    ProvisionFormIk.setValues({
      ...ProvisionFormIk.values,
      ...crmObj
    });
    ProvisionFormIk.handleChange(element);
  };

  const handleChangeBusinessCategory = (element, key, value) => {
    handleOnChange(element, key, parseInt(value));
    findBusinessOptionInArray(value);
  };

  const findBusinessOptionInArray = id => {
    let selectedBusinessTypeOption = businessTypesOptions.find(
      val => val.value == id
    );
    setSelectedBusinessTypeOption(selectedBusinessTypeOption);
    if (selectedBusinessTypeOption && selectedBusinessTypeOption.value !== 0) {
      getSecondaryCategories(selectedBusinessTypeOption.id);
    }
  };

  const getSecondaryCategories = id => {
    id = parseInt(id);
    if (id > 0) {
      let payload = { rlid: id };
      getBusinessSubCategories(payload).then(response => {
        if (response.data.success) {
          let subCategories = [];
          let results = response.data.data;
          results.forEach(element => {
            subCategories.push({
              value: element.id,
              text: element.name,
              name: element.name,
              key: element.name
            });
          });
          setSubCategories(subCategories);
        }
      });
    }
  };

  const handleCompanyKeyTextChange = text => {
    setLoadingOptions(true);
    try {
      let payload = {
        start: 0,
        limit: 10,
        filter: JSON.stringify([
          {
            propertyName: 'companyKey',
            propertyValue1: `${text}%`,
            propertyValue2: '',
            propertyValue3: [],
            filterDataType: 2,
            comparison: 2
          }
        ]),
        dir: 'ASC',
        sort: 'companyKey'
      };
      getAllCompanies(payload).then(response => {
        if (response && response.data) {
          let options = generateCompanyKeyOptions(response.data.data);
          setCompaniesOption(options);
          setLoadingOptions(false);
        }
      });
    } catch (error) {
      setLoadingOptions(false);
      toast.error(ToastTemplate.error(error || error.message));
    }
  };

  const generateCompanyKeyOptions = data => {
    return [...data].map(dt => ({
      key: `${dt.companyKey}`,
      value: dt.companyKey,
      id: dt.id
    }));
  };

  const handleOnSelectCompanyKey = selectedCompanyKey => {
    setCompanyKey(selectedCompanyKey || []);
    ProvisionFormIk.setValues({
      ...ProvisionFormIk.values,
      provisionedCompanyId: selectedCompanyKey
        ? parseInt(selectedCompanyKey.id)
        : 0
    });
  };

  const getPaymentType = paymentTypeId => {
    const paymentTypes = [
      { id: '1', name: 'One Time' },
      { id: '2', name: 'Monthly' },
      { id: '3', name: 'Yearly' }
    ];
    const paymentType = paymentTypes.find(type => type.id == paymentTypeId);
    return paymentType.name;
  };

  const getFeatureIcon = id => {
    let featureIcons = {
      1: <SMSN className="mr-2 text-primary custom-svg" />,
      2: <Popup className="mr-2 text-primary custom-svg" />,
      3: <Document className="mr-2 text-primary custom-svg" />,
      4: <Others className="mr-2 text-primary custom-svg" />,
      5: <Calender className="mr-2 text-primary custom-svg" />,
      6: <Translate className="mr-2 text-primary custom-svg" />,
      7: <Fbook className="mr-2 text-primary custom-svg" />
    };
    return featureIcons[id];
  };

  const confirmSubmit = () => {
    setSaveAllowed(true);
    ProvisionFormIk.handleSubmit();
  };

  const cancelSubmit = () => {
    setTimeout(() => {
      featureSection.current?.scrollIntoView({
        behavior: 'smooth'
      });
    }, 300);
    setShowModal(false);
  };

  // ----for error on submit form
  useEffect(() => {
    if (!Object.keys(ProvisionFormIk.errors).length) {
      setShowErrors(false);
    } else if (
      ProvisionFormIk.isSubmitting &&
      Object.keys(ProvisionFormIk.errors).length
    ) {
      setShowErrors(true);
    }
  }, [ProvisionFormIk.errors, ProvisionFormIk.isSubmitting]);

  // ---end

  return (
    <>
      <DeleteModal
        title={t(
          'company.provisioningDetails.features.noFeatureSelectedWarningHeading'
        )}
        showModal={showModal}
        onSubmit={confirmSubmit}
        infoText={t(
          'company.provisioningDetails.features.noFeatureSelectedWarningMessage'
        )}
        loading={loading}
        eventHideModal={cancelSubmit}
        submitButtonTitle={t('company.provisioningDetails.features.yes')}
        cancelButtonTitle={t('company.provisioningDetails.features.no')}
        backdrop="static"
      />
      <Form
        className="form"
        onSubmit={ProvisionFormIk.handleSubmit}
        autoComplete="off"
      >
        <div className="page-header">
          <div className="page-header-bottom d-flex align-items-center justify-content-between">
            <h1 className="main-head text-primary-2">New Onboarding</h1>
            <button
              type="submit"
              className="btn btn-primary py-2 px-3 d-flex align-items-center"
              disabled={loading}
            >
              <UilSave />
              <span className="ml-2">
                {t('company.general.saveTxt')}
                {loading && <i className="uil uil-spinner spinner ml-2"></i>}
              </span>
            </button>
          </div>
        </div>
        <div className="page-body">
          <div className="page-section">
            <h4 className="sub-head">
              {t('company.provisioningDetails.heading1')}
            </h4>
            {showErrors ? (
              <div
                className="p-2"
                style={{
                  background: 'var(--danger-4)',
                  borderRadius: '5px',
                  width: 'fit-content'
                }}
              >
                <p className="fz-14 text-danger fw-600 d-flex align-items-center">
                  <UilExclamationCircle />
                  &nbsp;
                  {t('company.general.provisioningErrorMsg')}
                </p>
              </div>
            ) : null}
            <section>
              <div className="section-body">
                <ul className="editable-fields-lists">
                  <SimpleLabelTextBox
                    heading={t('company.provisioningDetails.companyName.label')}
                    field="companyName"
                    dataProperty={ProvisionFormIk.values['companyName']}
                    titleText={t(
                      'company.provisioningDetails.companyName.info'
                    )}
                    error={
                      ProvisionFormIk.touched.companyName &&
                      ProvisionFormIk.errors['companyName']
                    }
                    editable={editable}
                    handleChange={handleOnChange}
                    handleBlur={ProvisionFormIk.handleBlur}
                  />

                  <SimpleLabelTextBox
                    heading={t(
                      'company.provisioningDetails.companyKeyRequest.label'
                    )}
                    field="companyKeyRequest"
                    dataProperty={ProvisionFormIk.values['companyKeyRequest']}
                    titleText={t(
                      'company.provisioningDetails.companyKeyRequest.info'
                    )}
                    editable={editable}
                    handleChange={handleOnChange}
                  />

                  <DropDownEditable
                    heading={t('company.provisioningDetails.status.label')}
                    field="status"
                    dataProperty={ProvisionFormIk.values['status']}
                    editable={editable}
                    handleChange={handleOnChange}
                    titleText={t('company.provisioningDetails.status.info')}
                    materialFields
                    options={dropdownEnums.enum_Provision}
                  />

                  <SimpleLabelTextBox
                    heading={t(
                      'company.provisioningDetails.partnerRepresentativeName.label'
                    )}
                    field="accountManager"
                    dataProperty={ProvisionFormIk.values['accountManager']}
                    titleText={t(
                      'company.provisioningDetails.partnerRepresentativeName.info'
                    )}
                    error={
                      ProvisionFormIk.touched.accountManager &&
                      ProvisionFormIk.errors['accountManager']
                    }
                    editable={editable}
                    handleChange={handleOnChange}
                    handleBlur={ProvisionFormIk.handleBlur}
                  />

                  <SimpleLabelTextBox
                    heading={t(
                      'company.provisioningDetails.representativeEmail.label'
                    )}
                    field="accountManagerEmail"
                    dataProperty={ProvisionFormIk.values['accountManagerEmail']}
                    titleText={t(
                      'company.provisioningDetails.representativeEmail.info'
                    )}
                    error={
                      ProvisionFormIk.touched.accountManagerEmail &&
                      ProvisionFormIk.errors['accountManagerEmail']
                    }
                    editable={editable}
                    handleChange={handleOnChange}
                    handleBlur={ProvisionFormIk.handleBlur}
                  />

                  <SimpleLabelTextBox
                    heading={t('company.provisioningDetails.leadEmails.label')}
                    field="leadEmails"
                    dataProperty={ProvisionFormIk.values['leadEmails']}
                    titleText={t('company.provisioningDetails.leadEmails.info')}
                    error={
                      ProvisionFormIk.touched.leadEmails &&
                      ProvisionFormIk.errors['leadEmails']
                    }
                    editable={editable}
                    handleChange={handleOnChange}
                    handleBlur={ProvisionFormIk.handleBlur}
                  />
                  {roles.onBoarding.specialFields.billingEmailAddress.view && (
                    <SimpleLabelTextBox
                      heading={t(
                        'company.provisioningDetails.billingEmailAddress.label'
                      )}
                      field="billingEmailAddress"
                      dataProperty={
                        ProvisionFormIk.values['billingEmailAddress']
                      }
                      titleText={t(
                        'company.provisioningDetails.billingEmailAddress.info'
                      )}
                      error={
                        ProvisionFormIk.touched.billingEmailAddress &&
                        ProvisionFormIk.errors['billingEmailAddress']
                      }
                      editable={
                        editable &&
                        roles.onBoarding.specialFields.billingEmailAddress.edit
                      }
                      handleChange={handleOnChange}
                      handleBlur={ProvisionFormIk.handleBlur}
                    />
                  )}
                  <SimpleLabelTextBox
                    heading={t('company.provisioningDetails.website.label')}
                    field="url"
                    dataProperty={ProvisionFormIk.values['url']}
                    titleText={t('company.provisioningDetails.website.info')}
                    error={
                      ProvisionFormIk.touched.url &&
                      ProvisionFormIk.errors['url']
                    }
                    editable={editable}
                    handleChange={handleOnChange}
                    handleBlur={ProvisionFormIk.handleBlur}
                  />
                  <SimpleLabelTextBox
                    heading={t(
                      'company.provisioningDetails.googleAnalyticsCode.label'
                    )}
                    field="googleAnalyticsCode"
                    dataProperty={ProvisionFormIk.values['googleAnalyticsCode']}
                    titleText={t(
                      'company.provisioningDetails.googleAnalyticsCode.info'
                    )}
                    editable={editable}
                    handleChange={handleOnChange}
                  />
                  <DropDownEditable
                    heading={t('company.provisioningDetails.crm.label')}
                    field="crmId"
                    dataProperty={ProvisionFormIk.values['crmId']}
                    handleChange={handleOnChangeCRM}
                    materialFields
                    editable={editable}
                    options={CrmData}
                  />
                  <SimpleLabelTextBox
                    heading={t('company.provisioningDetails.crmEmail.label')}
                    field="crmEmailAddress"
                    dataProperty={ProvisionFormIk.values['crmEmailAddress']}
                    titleText={t('company.provisioningDetails.crmEmail.info')}
                    error={
                      ProvisionFormIk.touched.crmEmailAddress &&
                      ProvisionFormIk.errors['crmEmailAddress']
                    }
                    editable={editable}
                    handleChange={handleOnChange}
                    handleBlur={ProvisionFormIk.handleBlur}
                  />
                  <SimpleLabelTextBox
                    heading={t(
                      'company.provisioningDetails.setupInstructions.label'
                    )}
                    field="notes"
                    dataProperty={ProvisionFormIk.values['notes']}
                    titleText={t(
                      'company.provisioningDetails.setupInstructions.info'
                    )}
                    editable={editable}
                    handleChange={handleOnChange}
                  />

                  <CustomAutoCompleteFilter
                    editable={
                      editable &&
                      roles?.onBoarding?.specialFields?.provisionedCompanyId
                        ?.edit
                    }
                    liStyles={{ alignSelf: 'end' }}
                    title={t('company.analytics.companyKey')}
                    setFunction={handleOnSelectCompanyKey}
                    handleTextChange={handleCompanyKeyTextChange}
                    options={companiesOption}
                    loading={loadingOptions}
                    selected={selectedCompanyKey}
                    isFilterWrapper={false}
                    variant="standard"
                    isMultiSelectEnable={false}
                    name="provisionedCompanyId"
                    handleBlur={ProvisionFormIk.handleBlur}
                    error={
                      ProvisionFormIk.touched.provisionedCompanyId &&
                      ProvisionFormIk.errors['provisionedCompanyId']
                    }
                  />

                  <DropDownEditable
                    heading={t(
                      'company.provisioningDetails.businessCategory.label'
                    )}
                    field="primaryCategoryId"
                    dataProperty={ProvisionFormIk.values['primaryCategoryId']}
                    editable={editable}
                    handleChange={handleChangeBusinessCategory}
                    titleText={t(
                      'company.provisioningDetails.businessCategory.info'
                    )}
                    materialFields
                    options={businessTypesOptions}
                    error={
                      ProvisionFormIk.touched.primaryCategoryId &&
                      ProvisionFormIk.errors['primaryCategoryId']
                    }
                    handleBlur={ProvisionFormIk.handleBlur}
                  />

                  <CustomAutoCompleteFilter
                    editable={editable}
                    liStyles={{ alignSelf: 'end' }}
                    title={t('company.provisioningDetails.subCategories.label')}
                    setFunction={setSelectedSubCategories}
                    options={subCategories}
                    selected={selectedSubCategories}
                    isFilterWrapper={false}
                    variant="standard"
                  />
                </ul>
              </div>
            </section>
          </div>
          <div className="page-section">
            <h4 className="sub-head">
              {t('company.provisioningDetails.heading4')}
            </h4>{' '}
            <section>
              <div className="section-body">
                <ul className="editable-fields-lists d-block">
                  <AttachementComponent
                    handleChange={handleOnChangeAttachements}
                    editable={editable}
                  />
                </ul>
              </div>
            </section>
          </div>
          <div className="page-section">
            <h4 className="sub-head">
              {t('company.provisioningDetails.heading2')}
            </h4>{' '}
            <section>
              <div className="section-body">
                <ul className="editable-fields-lists">
                  <SimpleLabelTextBox
                    heading={t('company.provisioningDetails.pricing.label')}
                    field="pricing"
                    dataProperty={ProvisionFormIk.values['pricing']}
                    titleText={t('company.provisioningDetails.pricing.info')}
                    editable={editable}
                    handleChange={handleOnChange}
                  />
                  <ToggleToolTip
                    heading={t('company.provisioningDetails.setupFee.label')}
                    toolTipFor="setupFee"
                    flag={ProvisionFormIk.values?.hasSetupFee}
                    titleText={t('company.provisioningDetails.setupFee.info')}
                    editable={editable}
                    handleChange={handleOnChange}
                    field="hasSetupFee"
                  />
                  <ToggleToolTip
                    heading={t(
                      'company.provisioningDetails.sendDealerCRMEmail.label'
                    )}
                    toolTipFor="sendDealerCRMEmail"
                    flag={ProvisionFormIk.values?.useCrmEmail}
                    titleText={t(
                      'company.provisioningDetails.sendDealerCRMEmail.info'
                    )}
                    editable={editable}
                    handleChange={handleOnChange}
                    field="useCrmEmail"
                  />
                  {ProvisionFormIk.values?.useCrmEmail && (
                    <SimpleLabelTextBox
                      heading={t(
                        'company.provisioningDetails.autoDealerCRMEmail.label'
                      )}
                      field="autoDealerCRMEmail"
                      dataProperty={
                        ProvisionFormIk.values['autoDealerCRMEmail']
                      }
                      titleText={t(
                        'company.provisioningDetails.autoDealerCRMEmail.info'
                      )}
                      error={
                        ProvisionFormIk.touched.autoDealerCRMEmail &&
                        ProvisionFormIk.errors['autoDealerCRMEmail']
                      }
                      editable={editable}
                      handleChange={handleOnChange}
                      handleBlur={ProvisionFormIk.handleBlur}
                    />
                  )}

                  <ToggleToolTip
                    heading={t(
                      'company.provisioningDetails.freeConsultation.label'
                    )}
                    toolTipFor="freeConsultation"
                    flag={ProvisionFormIk.values?.freeConsultation}
                    titleText={t(
                      'company.provisioningDetails.freeConsultation.info'
                    )}
                    editable={editable}
                    handleChange={handleOnChange}
                    field="freeConsultation"
                  />
                  <SimpleLabelTextBox
                    heading={t(
                      'company.provisioningDetails.consultationFee.label'
                    )}
                    field="consultationFee"
                    dataProperty={ProvisionFormIk.values['consultationFee']}
                    titleText={t(
                      'company.provisioningDetails.consultationFee.info'
                    )}
                    editable={editable}
                    handleChange={handleOnChange}
                  />
                </ul>
              </div>
            </section>
          </div>
          {/* features section */}
          <div className="page-section" ref={featureSection}>
            <h4 className="sub-head">
              {t('company.provisioningDetails.heading6')}
            </h4>{' '}
            <section>
              <div className="section-body">
                <ul className="editable-fields-lists onboarding-form-features">
                  {offeredFeatures.length > 0
                    ? offeredFeatures.map((feature, index) => {
                        const { description, paymentTypeId, amount } = feature;
                        return (
                          <li
                            className={`list-group-item border-0 bg-lightgray-2 rounded mb-1 p-2`}
                            key={index}
                          >
                            <ToggleDetailed
                              heading={description}
                              flag={feature.isSelected}
                              listIcon={getFeatureIcon(feature.id)}
                              badges={[
                                `$${amount}`,
                                getObjectKeyByValue(
                                  Enums.PaymentTypes,
                                  paymentTypeId
                                )
                              ]}
                              titleText={
                                t('company.provisioningDetails.features', {
                                  returnObjects: true,
                                  description: description,
                                  amount: `$${amount}`,
                                  paymentType: getObjectKeyByValue(
                                    Enums.PaymentTypes,
                                    paymentTypeId
                                  )
                                }).info
                              }
                              editable={editable}
                              handleChange={handleOnChangeFeature}
                              field={`feature-${index}`}
                            />
                          </li>
                        );
                      })
                    : 'No features available'}
                </ul>
              </div>
            </section>
          </div>
        </div>
        {/* bussiness operations */}
        <div className="page-body">
          <div className="page-section">
            <h4 className="sub-head">
              {t('company.provisioningDetails.heading3')}
            </h4>{' '}
            <section>
              <div className="section-body">
                <ul className="editable-fields-lists">
                  <SimpleLabelTextBox
                    heading={t(
                      'company.provisioningDetails.areaOfCoverage.label'
                    )}
                    field="areaOfCoverage"
                    dataProperty={ProvisionFormIk.values['areaOfCoverage']}
                    titleText={t(
                      'company.provisioningDetails.areaOfCoverage.info'
                    )}
                    editable={editable}
                    handleChange={handleOnChange}
                  />
                  <SimpleLabelTextBox
                    heading={t(
                      'company.provisioningDetails.linkToPrivacyPolicy.label'
                    )}
                    field="linkToPrivacyPolicy"
                    dataProperty={ProvisionFormIk.values['linkToPrivacyPolicy']}
                    titleText={t(
                      'company.provisioningDetails.linkToPrivacyPolicy.info'
                    )}
                    error={
                      ProvisionFormIk.touched.linkToPrivacyPolicy &&
                      ProvisionFormIk.errors['linkToPrivacyPolicy']
                    }
                    editable={editable}
                    handleChange={handleOnChange}
                    handleBlur={ProvisionFormIk.handleBlur}
                  />
                  <SimpleLabelTextBox
                    heading={t(
                      'company.provisioningDetails.scriptTitlePosition.label'
                    )}
                    field="scriptTitlePosition"
                    dataProperty={ProvisionFormIk.values['scriptTitlePosition']}
                    titleText={t(
                      'company.provisioningDetails.scriptTitlePosition.info'
                    )}
                    editable={editable}
                    handleChange={handleOnChange}
                  />
                  <ToggleToolTip
                    heading={t('company.provisioningDetails.hipaa.label')}
                    toolTipFor="hipaa"
                    flag={ProvisionFormIk.values?.hipaa}
                    titleText={t('company.provisioningDetails.hipaa.info')}
                    editable={editable}
                    handleChange={handleOnChange}
                    field="hipaa"
                  />
                  <SimpleLabelTextBox
                    heading={t('company.provisioningDetails.languages.label')}
                    field="languages"
                    dataProperty={ProvisionFormIk.values['languages']}
                    titleText={t('company.provisioningDetails.languages.info')}
                    editable={editable}
                    handleChange={handleOnChange}
                  />
                  <ToggleToolTip
                    heading={t('company.provisioningDetails.callConnect.label')}
                    toolTipFor="callConnect"
                    flag={ProvisionFormIk.values?.callConnect}
                    titleText={t(
                      'company.provisioningDetails.callConnect.info'
                    )}
                    editable={editable}
                    handleChange={handleOnChange}
                    field="callConnect"
                  />
                  {ProvisionFormIk.values?.callConnect ? (
                    <>
                      <ToggleToolTip
                        heading={t(
                          'company.provisioningDetails.callConnectTranscript.label'
                        )}
                        toolTipFor="callConnect"
                        flag={ProvisionFormIk.values?.quickConnectTranscript}
                        titleText={t(
                          'company.provisioningDetails.callConnectTranscript.info'
                        )}
                        editable={editable}
                        handleChange={handleOnChange}
                        field="quickConnectTranscript"
                      />

                      <SimpleLabelTextBox
                        heading={t(
                          'company.provisioningDetails.callConnectPhone.label'
                        )}
                        field="callConnectPhone"
                        dataProperty={
                          ProvisionFormIk.values['callConnectPhone']
                        }
                        titleText={t(
                          'company.provisioningDetails.callConnectPhone.info'
                        )}
                        error={
                          ProvisionFormIk.touched.callConnectPhone &&
                          ProvisionFormIk.errors['callConnectPhone']
                        }
                        editable={editable}
                        handleChange={handleOnChange}
                        handleBlur={ProvisionFormIk.handleBlur}
                      />
                      <SimpleLabelTextBox
                        heading={t(
                          'company.provisioningDetails.callConnectHours.label'
                        )}
                        field="callConnectHours"
                        dataProperty={
                          ProvisionFormIk.values['callConnectHours']
                        }
                        titleText={t(
                          'company.provisioningDetails.callConnectHours.info'
                        )}
                        editable={editable}
                        handleChange={handleOnChange}
                      />
                    </>
                  ) : null}
                  <SimpleLabelTextBox
                    heading={t(
                      'company.provisioningDetails.businessHours.label'
                    )}
                    field="businessHours"
                    dataProperty={ProvisionFormIk.values['businessHours']}
                    titleText={t(
                      'company.provisioningDetails.businessHours.info'
                    )}
                    editable={editable}
                    handleChange={handleOnChange}
                  />

                  <SimpleLabelTextBox
                    heading={t(
                      'company.provisioningDetails.insuranceCoverageResponse.label'
                    )}
                    field="insuranceCoverageResponse"
                    dataProperty={
                      ProvisionFormIk.values['insuranceCoverageResponse']
                    }
                    titleText={t(
                      'company.provisioningDetails.insuranceCoverageResponse.info'
                    )}
                    editable={editable}
                    handleChange={handleOnChange}
                  />
                  <SimpleLabelTextBox
                    heading={t(
                      'company.provisioningDetails.specialInstructions.label'
                    )}
                    field="specialInstructions"
                    dataProperty={ProvisionFormIk.values['specialInstructions']}
                    titleText={t(
                      'company.provisioningDetails.specialInstructions.info'
                    )}
                    editable={editable}
                    handleChange={handleOnChange}
                  />
                  <TimeZoneField
                    heading={t(
                      'company.provisioningDetails.officeHoursTimezone.label'
                    )}
                    field="officeHoursTimezone"
                    dataProperty={ProvisionFormIk.values['officeHoursTimezone']}
                    editable={editable}
                    handleChange={handleOnChange}
                  />
                </ul>
                <section>
                  <header className="section-head d-flex align-items-center justify-content-between">
                    <h5 className="fw-600 mb-0">
                      {t('company.provisioningDetails.officeHours.label')}
                    </h5>
                  </header>
                  <div className="section-body">
                    <DaysAndHours
                      dataArray={ProvisionFormIk.values['officeHours']}
                      field="officeHours"
                      titleText={t(
                        'company.provisioningDetails.officeHours.info'
                      )}
                      handleHoursChange={updateHours}
                      disabled={!editable}
                    ></DaysAndHours>
                  </div>
                </section>

                <section className="mt-5">
                  <header className="section-head d-flex align-items-center justify-content-between">
                    <h5 className="fw-600 mb-0">
                      {t('company.provisioningDetails.quickConnectHours.label')}
                    </h5>
                  </header>
                  <div className="section-body">
                    <DaysAndHours
                      dataArray={ProvisionFormIk.values['quickConnectHours']}
                      field="quickConnectHours"
                      titleText={t(
                        'company.provisioningDetails.quickConnectHours.info'
                      )}
                      handleHoursChange={updateHours}
                      disabled={!editable}
                    ></DaysAndHours>
                  </div>
                </section>
              </div>
            </section>
          </div>
        </div>
      </Form>
    </>
  );
};

export default NewOnboarding;
