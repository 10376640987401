import React, { useState, useLayoutEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import SignUpForm from './form';
import { MDSignUpForm, MDSignUpSchemas } from 'helpers/schemas/MDSignUpSchema';
import './style.scss';
import { errors, success } from 'helpers/variables';
import { toast } from 'react-toastify';
import { PostMDSignUp } from 'features/messagingChats/signup';
import storage from 'helpers/storage';
import {
  getUserFromViewedUser,
  ToastTemplate,
  updateViewedUser
} from 'helpers/generic.methods';
import Header from './header';
import ImageSection from './image-section';
import FormSection from './form-section';

const signUpMessage =
  'Thank you for signing up. Our representative will contact you shortly.';

const MDSignUpPage = ({ showForm = true }) => {
  const { t } = useTranslation();
  const user = JSON.parse(storage.getItem('Profile'));
  let {
    DisplayName,
    Email,
    Phone,
    CompanyName,
    HostID,
    UserID,
    CompanyID,
    CompanyKey
  } = user;
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [isGrid, setGrid] = useState(showForm);
  const [alreadySignedUp, setSignUp] = useState(false);

  useLayoutEffect(() => {
    if (!showForm) {
      setMessage('Contact your admin to get the access now.');
      setGrid(false);
    }

    setAlreadySignedUpUser();
  }, []);

  const setAlreadySignedUpUser = () => {
    const viewedUser = getUserFromViewedUser();
    if (viewedUser?.hasSignUpRequested) {
      setMessage(signUpMessage);
      setSignUp(true);
      setGrid(false);
    }
  };

  const signUpFormHandler = async formData => {
    try {
      setLoading(true);
      let payload = {
        ...formData,
        hostId: HostID,
        userId: UserID,
        companyId: CompanyID,
        companyKey: CompanyKey
      };

      const response = await PostMDSignUp(payload);
      // toast.success(success.saveSuccess('MD Signup information'));
      if (response) {
        updateViewedUser({ hasSignUpRequested: true });
        setSignUp(true);
        setMessage(signUpMessage);
      }
      setLoading(false);
    } catch (error) {
      toast.error(ToastTemplate.error(error || errors.failure));
      setLoading(false);
    }
  };

  /** Init Formik */
  const MDSignUpFormIk = useFormik({
    enableReinitialize: true,
    initialValues: Object.assign(MDSignUpForm, {
      name: DisplayName,
      companyName: CompanyName,
      email: Email,
      phone: Phone
    }),
    validationSchema: MDSignUpSchemas,
    onSubmit: signUpFormHandler
  });

  /**
   * @name @handleOnChange
   * @description do something on field onChange
   * @requires html input element
   * */
  const handleOnChange = (element, key, value) => {
    MDSignUpFormIk.setValues({
      ...MDSignUpFormIk.values,
      [key]: value
    });
  };

  return (
    <Container
      fluid
      className="main-coming-soon-container"
      style={{ paddingTop: '0' }}
    >
      <div
        className={`inner-container`}
        style={{ padding: '0', display: isGrid ? 'flex' : 'block' }}
      >
        <Header
          hideHeader={isGrid}
          alreadySignedUp={alreadySignedUp}
          message={message}
          showForm={showForm}
          t={t}
        />
        <Row className="mx-0" style={{ width: '100%' }}> {/* UIUX-1523 Removed my-3 class for scroll issue*/}
          <ImageSection isGrid={isGrid} />
          <FormSection
            isGrid={isGrid}
            message={message}
            loading={loading}
            alreadySignedUp={alreadySignedUp}
          >
            <SignUpForm
              disabled={message}
              loading={loading}
              form={MDSignUpFormIk}
              handleOnChange={handleOnChange}
            />
          </FormSection>
        </Row>
      </div>
    </Container>
  );
};

export default MDSignUpPage;
