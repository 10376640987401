import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import SidebarRight from 'components/Sidebar/sidebar-right';
import {
  getAllProvisionList,
  removeAllProvisionFromState,
  removeProvisionFromState,
  isRefetchRequired,
  setRefetchRequired,
  exportAllProvisionsList,
  selectProvision,
  getAllProvisions
} from 'features/provisioning/ProvisioningSlice';
import { Container, Row, Col, Badge, Button } from 'react-bootstrap';
import storage from 'helpers/storage';
import {
  TabComponent,
  TabsContainer,
  TabNav
} from '../../../components/TabsComponent';
import ProvisionContainer from './details/index';
import '@inovua/reactdatagrid-community/index.css';
import { provisionTableConfig } from 'utils/tableConfig';
import HeaderToExistingPortal from 'components/headerToExistingPortal';
import { selectRoles } from 'features/roles/rolesSlice';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { GridWrapper } from '../../../components/GridWrapper';
import { provisionFormInitialValues } from 'helpers/schemas/provisionSchemas/initialValues';
import DynamicColumns from '../report/dynamicColumns';
import { UilPlus, UilEye } from '@iconscout/react-unicons';
import useQuery from 'routes/searchQueryHook';
import { getFilterValue } from 'components/GridWrapper/helper';
import { ToastTemplate } from 'helpers/generic.methods';

const Provision = () => {
  const { t } = useTranslation();
  const [openColapse, setOpenColapse] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [selectedTab, setSelectedTab] = useState(0);
  const dispatch = useDispatch();
  const query = useQuery();
  const _isRefetchRequired = useSelector(isRefetchRequired);
  const [loading, setLoading] = useState(false);
  const { roles } = useSelector(selectRoles);
  const permissions = (roles && roles.switch) || {};
  const [vissibleColumns, setVissibleColumns] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState(null);
  let provisions = useSelector(getAllProvisions);

  // Colapsable panel Open/Close Handler
  const handleOpen = status => {
    setOpenColapse(status);
  };
  const ActionButtons = ({ value }) => (
    <>
      <Button
        className="btn-sm btn-flex mr-1"
        variant="lightgray-3"
        onClick={e => console.log(value)}
      >
        <UilEye className="mr-1" />
        View
      </Button>
    </>
  );

  useEffect(() => {
    if (_isRefetchRequired) {
      dispatch(setRefetchRequired(false));
    }
  }, [_isRefetchRequired]);

  // Sppend Close button to TabTitle
  const tabTitle = (index, company) => {
    let currentTabProvision = provisions.filter(p => p.id == company.id)[0];

    return (
      <TabNav
        totalTabs={selectedCompanies.length}
        index={index}
        activeKey={selectedTab}
        id={company.id}
        title={
          !company.isNewonboarding
            ? currentTabProvision?.companyName
            : company.newOnboardingTabName
        }
        removeTab={removeCompany}
      />
    );
  };

  // initialize selected Tab Data after Redirect From other screen
  useEffect(() => {
    setSelectedTab(JSON.parse(storage.getItem('provisions-selectedTab')));
    let dataProvisioning =
      JSON.parse(storage.getItem('provisions-selectedProvisions')) || [];
    setSelectedCompanies(dataProvisioning);
    updateSelectedData(dataProvisioning);
  }, []);
  // get id params from url if exist and add chat id in selectedchats array and remove previously selected chats
  // and open sidebar
  useEffect(() => {
    let id = query.get('id');
    if (id) {
      updateCompaniesAndSaveToLS([{ id: Number(id) }]);
      updateTabAndSaveToLS(Number(id));
      handleOpen(true);
    }
  }, []);

  // select Company
  const handleSelect = payload => {
    const { data } = payload;
    if (data.length || Object.keys(data).length) {
      let temp = data;
      if (data.length) temp = data[0];
      let selected = selectedCompanies.filter(sc => sc.id === Number(temp.id));
      if (selected.length === 0) {
        let chats = [temp, ...selectedCompanies];
        updateCompaniesAndSaveToLS(chats);
      } else if (data instanceof Object && !Array.isArray(data)) {
        if (!Object.keys(selected).includes(data.id)) {
          let index = selectedCompanies.findIndex(com => com.id === data.id);
          removeCompany(index, data.id);
        }
      }

      updateTabAndSaveToLS(temp.id);
      if (Array.isArray(data)) handleOpen(true);
    } else {
      clearAll();
    }
  };

  const handleNewOnboarding = () => {
    if (selectedCompanies.find(provision => provision.id == 0)) {
      updateTabAndSaveToLS(0);
      handleOpen(true);
      return;
    }
    let initialValues = { ...provisionFormInitialValues };
    let temp = {
      id: 0,
      isNewonboarding: true,
      newOnboardingTabName: 'New Onboarding',
      provision: initialValues
    };

    let provisions = [temp, ...selectedCompanies];
    handleNewOnboardingTabSelection(temp.id, provisions);
    handleOpen(true);
  };

  const handleNewOnboardingTabSelection = (id, provisions) => {
    updateCompaniesAndSaveToLS(provisions);
    updateTabAndSaveToLS(id);
  };

  // Select Tab Handler
  const handleTabSelect = tabKey => {
    if (selectedTab !== tabKey) {
      updateTabAndSaveToLS(Number(tabKey));
    }
  };

  // Remove  Tab From Selected Companies
  const removeCompany = (index, id, nextIndex) => {
    let chats = [...selectedCompanies];
    chats.splice(index, 1);

    if (selectedTab === selectedCompanies[index].id) {
      updateTabAndSaveToLS(selectedCompanies[nextIndex].id);
    }
    updateCompaniesAndSaveToLS(chats);
    dispatch(removeProvisionFromState({ id: id }));
  };

  //  update Selected Companies and save to Local Storage
  const updateCompaniesAndSaveToLS = companies => {
    setSelectedCompanies(companies);
    storage.setItem('provisions-selectedProvisions', companies);
    updateSelectedData(companies);
  };

  const updateSelectedData = companies => {
    let checked = {};
    companies.forEach(com => {
      checked[com.id] = com;
    });
    setSelectedData(checked);
  };

  // update Tabs and save To Local Storage
  const updateTabAndSaveToLS = tabKey => {
    setSelectedTab(tabKey);
    storage.setItem('provisions-selectedTab', tabKey);
  };

  // update Selected Tab Menu For get Back to same menu after Redirecting
  const selectTabMenu = menuKey => {
    let index = selectedCompanies.findIndex(sc => sc.id === selectedTab);
    if (index > -1) {
      let companies = selectedCompanies;
      companies[index] = { ...companies[index], selectedMenu: menuKey };
      updateCompaniesAndSaveToLS(companies);
    }
  };

  const getDateFilterValue = data => {
    let updatedValue;
    if (data.name === 'createdOn' && data.operator === 'before') {
      updatedValue = moment(data.value).local();
    } else if (data.name === 'createdOn' && data.operator === 'after') {
      updatedValue = moment().local();
    } else {
      updatedValue = data.value;
    }
    return updatedValue;
  };

  const loadData = ({ skip, limit, sortInfo, filterValue }) => {
    try {
      setLoadingMessage('Loading ...');
      setDataLimit(skip);
      const updatedValue = filterValue
        .filter(v => v.value !== '')
        .map(v => ({
          propertyName: v.name,
          propertyValue1: getFilterValue(v),
          propertyValue2:
            v.name === 'createdOn' &&
            (v.operator === 'before' || v.operator === 'after')
              ? getDateFilterValue(v)
              : '',
          propertyValue3: [],
          filterDataType: 2,
          comparison: 2
        }));
      let payload = {
        start: skip,
        limit: limit,
        filter: JSON.stringify(updatedValue),
        dir: !sortInfo ? 'DESC' : sortInfo.dir === 1 ? 'ASC' : 'DESC',
        sort: sortInfo ? sortInfo.name : 'createdOn'
      };
      setSelectedFilters(payload);
      return getAllProvisionList(payload).then(response => {
        if (response.data.totalSize > 20) {
          setDataLimit(skip + response.data.data.length);
        } else {
          setDataLimit(response.data.data.length);
        }
        const totalCount = response.data.totalSize;
        setTotalRecords(totalCount);
        return { data: response.data.data, count: parseInt(totalCount) };
      });
    } catch (error) {
      toast.error(ToastTemplate.error(error || error.message));
    }
  };

  const handleColumnsChange = data => {
    setVissibleColumns({ ...data });
  };

  const clearAll = () => {
    updateCompaniesAndSaveToLS([]);
    updateTabAndSaveToLS('');
    dispatch(removeAllProvisionFromState());
  };

  const dataSource = useCallback(loadData, []);
  const [dataLimit, setDataLimit] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loadingMessage, setLoadingMessage] = useState('Loading ...');
  const renderColumnContextMenu = useCallback((menuProps, { cellProps }) => {
    menuProps.items = menuProps.items.splice(0, 8);
  }, []);

  return (
    <>
      <Container fluid>
        <Row>
          <Col className="m-3">
            <h2>
              Onboarding{' '}
              <Badge variant="primary p-1 badge-align">
                {loading ? '...' : `${dataLimit} / ${totalRecords}`}
              </Badge>
            </h2>
          </Col>
          <div className="d-flex" style={{ margin: '20px', gap: '10px' }}>
            <button
              type="button"
              className="btn-md d-flex align-items-center btn btn-primary"
              onClick={handleNewOnboarding}
            >
              <UilPlus className="mr-1" style={{ height: 20, width: 20 }} />
              New Onboarding
            </button>
            <DynamicColumns
              handleChange={handleColumnsChange}
              menuOptions={provisionTableConfig()}
              selectedFilters={selectedFilters}
              isExport={true}
              endPointAPI={exportAllProvisionsList}
              fileNamePrefix={'Onboarding'}
            />
          </div>
        </Row>
        <Row>
          <Col className={loading && 'blux-text'}>
            {permissions.allowPortal ? (
              <HeaderToExistingPortal msg={t('company.general.oldPortalTag')} />
            ) : null}
            <div className="mobile_view">
              <GridWrapper
                columns={provisionTableConfig(ActionButtons)}
                gridClass={
                  permissions.allowPortal ? 'provisioning-table-container' : ''
                }
                totalRecords={totalRecords}
                dataSource={dataSource}
                setLoading={setLoading}
                loadingMessage={loadingMessage}
                renderColumnContextMenu={renderColumnContextMenu}
                selectedData={selectedData}
                refetch={_isRefetchRequired}
                handleSelect={handleSelect}
                vissibleColumns={vissibleColumns}
              />
            </div>
          </Col>
        </Row>
      </Container>

      {selectedCompanies.length ? (
        <SidebarRight handleOpen={handleOpen} open={openColapse}>
          <TabsContainer
            activeKey={selectedTab}
            clearAll={clearAll}
            openColapse={openColapse}
            selectedData={selectedCompanies}
            onTabSelect={e => handleTabSelect(e)}
          >
            {selectedCompanies.map((company, index) => (
              <TabComponent
                tabClassName={'tab-ui'}
                key={company.id}
                eventKey={company.id}
                title={tabTitle(index, company)}
                id={`tab-${company.id}`}
              >
                <ProvisionContainer
                  selectMenu={selectTabMenu}
                  setFunction={handleNewOnboardingTabSelection}
                  selectedProvision={company}
                  handleOpen={handleOpen}
                />
              </TabComponent>
            ))}
          </TabsContainer>
        </SidebarRight>
      ) : null}
    </>
  );
};

export default Provision;
