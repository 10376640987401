import { Button, Modal } from 'react-bootstrap';
import React from 'react';
// import 'static/scss/change-password.scss';
import './styles.scss';
const DeleteModal = ({
  title = '',
  showModal,
  eventHideModal,
  onSubmit,
  cancelButtonTitle = 'Cancel',
  submitButtonTitle = 'Submit',
  infoText = 'Your Text Will be displayed here.',
  submitBtn = true,
  closeBtn = true,
  loading = false,
  backdrop = true
}) => {
  /**
   * to reset the form fields when close the modal with close icon
   */
  const handleOnModalClose = fromIkObject => {
    eventHideModal();
  };

  return (
    <Modal
      show={showModal}
      onHide={eventHideModal}
      size="md"
      keyboard={false}
      id="deleteModal"
      dialogClassName="deleteModal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop={backdrop}
    >
      <Modal.Body>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>

        <div className="modal-info-text">{infoText}</div>
      </Modal.Body>
      <Modal.Footer className="user-modal-footer">
        {closeBtn && (
          <Button
            type="button"
            variant="outline-primary"
            className="passBtn btn-md btn-flex float-right"
            onClick={() => handleOnModalClose()}
          >
            <span>{cancelButtonTitle}</span>
          </Button>
        )}
        {submitBtn && (
          <Button
            type="button"
            variant="primary"
            className="passBtn btn-md btn-flex float-right"
            onClick={onSubmit}
            disabled={loading}
          >
            {loading && <i className="uil uil-spinner spinner mr-2"></i>}
            <span className="submit-btn-text">{submitButtonTitle}</span>
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
