import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import './styles.scss';
import { makeStyles } from '@material-ui/core';
import DropDownEditable from 'helpers/ui-controls/DropDownEditable';
import { generateDropDownOptions } from '../CreateReport/helper';
import { ToastTemplate } from 'helpers/generic.methods';
import { success } from 'helpers/variables';
import { toast } from 'react-toastify';
import { changeRequestLeadType } from 'features/lead/leadSlice';
import { Enums } from 'helpers/dropdown-enums';

const useStyle = makeStyles(() => ({
  viewModalContainer: {
    maxWidth: '40%'
  }
}));

const LeadTypeChange = ({
  title = '',
  showModal,
  eventHideModal,
  size = 'md',
  cancelButtonTitle = 'Cancel',
  leadId,
  prevLeadType
}) => {
  const options = [];
  const leadTypes = Enums.LeadType;
  Object.entries(leadTypes).forEach(([key, value]) => {
    if (key.toLowerCase() !== 'all') options.push({ id: value, name: key });
  });

  const [loading, setLoading] = useState(false);
  const [leadType, setLeadType] = useState(leadTypes[prevLeadType]);

  const classes = useStyle();

  const onEnterCallback = () => {
    setLeadType(leadTypes[prevLeadType]);
  };

  const handleOnChange = e => {
    setLeadType(e.target.value);
  };

  const onSubmit = () => {
    setLoading(true);
    let data = { leadId, leadType };
    //throw error if user selects prev leadtype
    if (leadType == leadTypes[prevLeadType]) {
      setLoading(false);
      toast.error(
        ToastTemplate.error('Cannot select previously selected Lead Type')
      );
    } else {
      changeRequestLeadType(data)
        .then(response => {
          if (response.success && response.data) {
            setLoading(false);
            eventHideModal();
            toast.success(
              success.saveSuccess('Lead change request', 'submitted')
            );
          } else {
            setLoading(false);
          }
        })
        .catch(error => {
          setLoading(false);
          toast.error(ToastTemplate.error(error || error.message));
        });
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={eventHideModal}
      size={size}
      keyboard={false}
      id="viewModal"
      dialogClassName={`viewModal ${classes.viewModalContainer}`}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onEnter={onEnterCallback}
    >
      <div className="change-leadtype-modal-header-container">
        <Modal.Header className="change-leadtype-modal-header">
          {title}
        </Modal.Header>
      </div>
      <Modal.Body>
        <Form className="form form-class" autoComplete="off">
          <div className="row">
            <div className="col-12">
              <ul style={{ listStyleType: 'none', padding: '0' }}>
                <DropDownEditable
                  heading="Lead Type"
                  field="leadTypeId"
                  dataProperty={leadType}
                  handleChange={handleOnChange}
                  materialFields
                  editable={true}
                  options={generateDropDownOptions(options)}
                />
              </ul>
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          variant="outline-primary"
          className="passBtn btn-md btn-flex float-right"
          onClick={() => eventHideModal()}
        >
          <span>{cancelButtonTitle}</span>
        </Button>
        <Button
          type="button"
          variant="primary"
          className="passBtn btn-md btn-flex float-right"
          onClick={onSubmit}
          disabled={loading}
        >
          {loading && <i className="uil uil-spinner spinner mr-2"></i>}
          <span className="submit-btn-text">OK</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LeadTypeChange;
