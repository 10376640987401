import { UilInfoCircle, UilBars } from '@iconscout/react-unicons';
import React from 'react';
import { OverlayTrigger, FormLabel, Form } from 'react-bootstrap';
import ToggleText from './toggleText';
import { toolTipPlacement } from 'helpers/generic.methods';
import renderToolTip from './renderToolTip';
import TruncateWithToolTip from 'components/TruncateWithToolTip';

const ToggleDetailed = ({
  flag,
  //for disable enable value
  heading,
  //label
  titleText,
  //tooltip text
  toolTipFor,
  //htmlFor value
  editable,
  handleChange,
  field = '',
  listIcon = <UilBars className="mr-2 text-primary" />,
  badges = []
}) => {
  const handleSwitch = element => {
    handleChange(element, field, element.target.checked);
  };

  return (
    <div
      className={`form-group d-lg-flex d-md-block justify-content-between align-items-center ${
        !editable && 'has-disabled'
      }`}
    >
      <span>
        <FormLabel
          aria-label={heading + ' - ' + titleText}
          htmlFor={toolTipFor}
          className="fw-600"
          style={{ whiteSpace: 'nowrap' }}
        >
          {listIcon}
          <TruncateWithToolTip
            text={heading}
            tooltip={heading.length > 25}
            limit={25}
          />
        </FormLabel>
      </span>
      <span
        className="d-flex align-items-center justify-content-between"
        style={{ whiteSpace: 'nowrap' }}
      >
        {badges.length > 0 ? (
          <span className="mr-2">
            {badges.map((badge, index) => {
              return (
                <span
                  // className="badge bg-transparent border-success text-success"
                  className={`badge py-2 px-2 mr-2 bg-transparent ${
                    editable
                      ? 'border-success text-success bg-success-4'
                      : 'border-muted text-muted'
                  }`}
                  key={index}
                >
                  {badge}
                </span>
              );
            })}
          </span>
        ) : null}

        <span className="d-flex align-items-center">
          <Form.Check
            type="switch"
            checked={flag}
            onChange={e => handleSwitch(e)}
            id={field}
            disabled={!editable}
            className={`mr-2 ${!editable && ''}`}
          />
          {titleText && (
            <OverlayTrigger
              placement={toolTipPlacement}
              overlay={renderToolTip(titleText)}
            >
              <UilInfoCircle size={18} className="text-muted" />
            </OverlayTrigger>
          )}
        </span>
      </span>
    </div>
  );
};
export default ToggleDetailed;
