import UilCheck from '@iconscout/react-unicons/icons/uil-check';
import UilTimes from '@iconscout/react-unicons/icons/uil-times';
import { Box, IconButton } from '@material-ui/core';
import React from 'react';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';

const ActionButtons = ({
  chat,
  onAccept,
  onIgnore = null,
  onReject = null,
  rejectLoading = false
}) => {
  const onAcceptHandler = e => {
    e.stopPropagation();
    onAccept(chat);
  };

  const onIgnoreHandler = e => {
    e.stopPropagation();
    onIgnore(chat);
  };

  const onRejectHandler = e => {
    e.stopPropagation();
    onReject(chat);
  };

  return (
    // <div className="action-btn-container text-right">
    //   {/* TODO */}
    //   {!!onIgnore && (
    //     <button
    //       className="btn btn-danger btn-font-size mr-1 p-0"
    //       // style={{ width: '45%' }}
    //       // disabled={true}
    //       onClick={onIgnoreHandler}
    //     >
    //       <UilTimes size={'18'} />
    //     </button>
    //   )}
    //   {!!onReject && (
    //     <button
    //       className="btn btn-danger btn-font-size mr-1 p-0"
    //       // style={{ width: '45%' }}
    //       disabled={rejectLoading}
    //       onClick={onRejectHandler}
    //     >
    //       {rejectLoading ? (
    //         <i className="uil uil-spinner spinner"></i>
    //       ) : (
    //         <UilTimes size={'18'} />
    //       )}
    //     </button>
    //   )}
    //   <button
    //     className="btn btn-success btn-font-size p-0"
    //     // style={!!onIgnore ? { width: '45%' } : {}}
    //     onClick={onAcceptHandler}
    //   >
    //     <UilCheck size={'18'} />
    //   </button>
    // </div>
    <>
      {!!onIgnore && (
        <Box pr={1} display="inline">
          <IconButton
            style={{
              borderRadius: 2,
              background: '#dc3545',
              color: '#fff'
            }}
            size="small"
            onClick={onIgnoreHandler}
          >
            <ClearIcon style={{ fontSize: '10px' }} />
          </IconButton>
        </Box>
      )}
      {!!onReject && (
        <Box pr={1} display="inline">
          <IconButton
            disabled={rejectLoading}
            onClick={onRejectHandler}
            style={{
              borderRadius: 2,
              background: '#dc3545',
              color: '#fff'
            }}
            size="small"
          >
            {rejectLoading ? (
              <i className="uil uil-spinner spinner"></i>
            ) : (
              <ClearIcon style={{ fontSize: '15px' }} />
            )}
          </IconButton>
        </Box>
      )}
      <Box display="inline">
        <IconButton
          style={{ borderRadius: 2, background: '#198754', color: '#fff' }}
          size="small"
          onClick={onAcceptHandler}
        >
          <CheckIcon style={{ fontSize: '15px' }} />
        </IconButton>
      </Box>
    </>
  );
};

export default ActionButtons;
