import { Enums, getObjectKeyByValue } from 'helpers/dropdown-enums';
import {
  camelCaseStringToNormalText,
  getStringInitials
} from 'helpers/generic.methods';
import { hideTypingIndicatorChatTypes } from 'helpers/specific-conditions';
import storage from 'helpers/storage';
import moment from 'moment';

export const calculatePickupTime = (
  firstMsgCreatedOn,
  selectedChatCreatedOn
) => {
  let _firstMsg = moment(firstMsgCreatedOn);
  let _selectedChatCreatedOn = moment(selectedChatCreatedOn);
  let duration = moment.duration(_firstMsg.diff(_selectedChatCreatedOn));
  let inSeconds = parseInt(duration.asSeconds());
  return inSeconds;
};

export const calculateIdleTime = lastMsgCreatedOn => {
  let _lastMsg = moment(lastMsgCreatedOn);
  let serverOffset = window.RTC.serverOffset;
  let current = moment().add(serverOffset, 'seconds');
  let duration = moment.duration(current.diff(_lastMsg));
  let idle = parseInt(duration.asSeconds());
  return idle;
};

export const calculateTotalDuration = firstMsgCreatedOn => {
  let _firstMsg = moment(firstMsgCreatedOn);
  let serverOffset = window.RTC.serverOffset;
  let current = moment().add(serverOffset, 'seconds');
  let duration = moment.duration(current.diff(_firstMsg));
  let minutes = duration.minutes();
  let seconds = duration.seconds();
  let timer = `${minutes <= 9 ? '0' + minutes : minutes}:${
    seconds <= 9 ? '0' + seconds : seconds
  }`;
  return timer;
};

export const getCountryCode = chatLocation => {
  fetch('/countryCode.json')
    .then(response => response.json())
    .then(codes => {
      let location = chatLocation;
      let countryISO = location.slice(
        location.indexOf('(') + 1,
        location.indexOf(')')
      );
      let countryObj = codes.filter(country => country.code === countryISO);
      if (countryObj.length) {
        storage.setItem('MD_visitorCountry', countryObj[0]);
      }
    });
};

export const getIsChatEnded = chat => {
  let isChatEnded = false;
  if (
    chat.userId === Enums.ChatMessageType.System &&
    (chat.isChatEnded || Enums.ChatEndedMessages.includes(chat.text))
  ) {
    isChatEnded = true;
  }

  return isChatEnded;
};

export const showTypingIndicatorInChat = selectedChat => {
  let visible = !hideTypingIndicatorChatTypes.includes(
    selectedChat?.initiatedBy
  );
  if (selectedChat.status == Enums.ChatStatus.ClientHandling) {
    visible = false;
  }
  return visible && !selectedChat?.isSms ? true : false;
};

export const mapVisitorInfoInLeadForm = (info, leadFormValues) => {
  let values = {};
  // Update visitor name in lead form
  if (
    info?.visitorInfoName &&
    (!leadFormValues.name || info?.visitorInfoName !== leadFormValues.name)
  ) {
    values['name'] = info?.visitorInfoName;
  }

  // Update visitor email in lead form
  if (info?.visitorInfoEmail && !leadFormValues.email) {
    values['email'] = info?.visitorInfoEmail;
  }

  // Update visitor phoneNumber in lead form
  if (info?.visitorInfoPhone && !leadFormValues.phoneNumber) {
    values['phoneNumber'] = info?.visitorInfoPhone;
  }

  return values;
};

export const getQTInitials = questionType => {
  let initials = '';
  let qt = getObjectKeyByValue(Enums.ChatQuestionType, questionType);
  if (questionType === Enums.ChatQuestionType['TrueOrFalse']) {
    qt = qt.replace('Or', '');
  }
  let qtHeading = camelCaseStringToNormalText(qt, 'heading');
  initials = getStringInitials(qtHeading);
  return { qt: qtHeading, initials };
};

export const searchSuggestion = (items, term, onlyAnswers = false) => {
  return items?.reduce((acc, item) => {
    if (
      !onlyAnswers &&
      (contains(item.text, term) || (item.answers && item.answers.length > 0))
    ) {
      let newItems =
        item.answers && item.answers.length > 0
          ? searchSuggestion(item.answers, term)
          : [];
      if (newItems && newItems.length > 0) {
        acc.push({
          ...item,
          answers: newItems,
          expanded: true
        });
      } else if (contains(item.text, term)) {
        acc.push(item);
      }
    } else if (onlyAnswers && item.answers && item.answers.length > 0) {
      let newItems = searchSuggestion(item.answers, term);
      if (newItems && newItems.length > 0) {
        acc.push(...newItems);
      }
    }
    return acc;
  }, []);
};

export const contains = (text, term) => {
  return text.toLowerCase().indexOf(term.toLowerCase()) >= 0;
};

export const responseTypeUserIds = [
  Enums.ChatMessageType.Prechat,
  Enums.ChatMessageType.Visitor,
  Enums.ChatMessageType.Simulated,
  Enums.ChatMessageType.PokeMessage
];

// UIUX-1836 updated conditon to solve the issue
// previous condition was this
// (selectedChat?.data?.status !== Enums.ChatStatus.ClientHandling && !selectedChat?.data?.isOptInConsentGiven)
export const isNormalChat = room => {
  return (
    (room?.status !== Enums.ChatStatus.ClientHandling &&
      !room?.isOptInConsentGiven) ||
    (room?.status !== Enums.ChatStatus.ClientHandling &&
      room?.status !== Enums.ChatStatus.Inactive &&
      room?.isOptInConsentGiven)
  );
};
