import React, { useState } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import routes from 'routes';
import generalRoutes from 'routes/generalRoutes';
import HandlePageRoutes from 'routes/HandlePageRoutes';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoading } from 'features/loading/loadingSlice';
import Sidebar from 'components/Sidebar';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Home from 'pages/portal/home';
import { isMobile } from 'react-device-detect';
import NotificationComp from 'components/notification/notification';
import HomeRedirector from 'pages/portal/home/home-redirector';
import { selectRoles, setRolesToState } from 'features/roles/rolesSlice';
import { getCompanyById } from 'features/company/companySlice';
import TopBar from 'components/Topbar';
import { getNavbarStatus } from 'features/sidebar-nav/sidebarNavSlice';
import { useEffect } from 'react';
import storage from 'helpers/storage';
import {
  selectLoggedinCompany,
  setLoggedInCompanyToState
} from 'features/auth/authSlice';
// import { Enums } from 'helpers/dropdown-enums';
const PortalLayout = () => {
  const loading = useSelector(selectLoading);
  const { roles } = useSelector(selectRoles);
  const { navbar } = useSelector(getNavbarStatus);
  const company = useSelector(selectLoggedinCompany);
  const [showSidebar, setShowSidebar] = useState(navbar);
  const [showLimitedAccessModal, setShowLimitedAccessModal] = useState(false);
  window.originalTitle = window.document.title;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!company || !Object.keys(company).lenth) {
      const user = JSON.parse(storage.getItem('Profile'));
      const companyId = user?.CompanyID;
      if (companyId && !roles?.showLimitedAccessWarning) {
        getCompany(companyId);
      }
    }
  }, []);

  const getCompany = async companyId => {
    try {
      let { data } = await getCompanyById(companyId);

      if (data?.data && Object.keys(data.data).length) {
        const company = data.data;
        let tempRoles = JSON.parse(JSON.stringify(roles));

        // TODO - UIUX-1150 3.8 item
        // if (!company.isTranscriptVisible) {
        //   tempRoles.report.reportTypes = tempRoles.report.reportTypes.filter(
        //     rt => rt.id !== Enums.ReportType.Transcripts
        //   );
        // }

        tempRoles['chat'].isAppointmentScheduler =
          !!company.isAppointmentScheduler;
        tempRoles['report'].requestLeadChange = company.canRequestLeadChange;

        dispatch(setRolesToState(tempRoles));
        dispatch(setLoggedInCompanyToState(company));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const limitedAccessModalHandler = () => {
    setShowLimitedAccessModal(!showLimitedAccessModal);
  };

  return (
    <>
      {loading.show ? (
        <ProgressBar className="global-loader" now={loading.loading} />
      ) : null}

      <TopBar
        showSidebar={showSidebar}
        setShowSidebar={setShowSidebar}
        limitedAccessModalHandler={limitedAccessModalHandler}
      />
      <div
        className={`main d-flex align-items-stretch ${
          isMobile ? 'mobile' : 'desktop'
        }-wrapper`}
      >
        <NotificationComp />
        <Sidebar
          showSidebar={showSidebar}
          setShowSidebar={setShowSidebar}
          showLimitedAccessModal={showLimitedAccessModal}
          limitedAccessModalHandler={limitedAccessModalHandler}
        />
        <div id="content">
          <div
            className="h-100 full-height-min rounded-inner print-version-wrapper main-content-container"
            //style={{ pageBreakAfter: 'always' }}
            style={{ paddingTop: '70px' }}
          >
            <Switch>
              <Route
                path={'/'}
                component={HomeRedirector}
                exact={true}
                key={`home-redirector`}
              />
              <Route
                path={'/home'}
                component={Home}
                exact={true}
                key={`home-child`}
              />
              {HandlePageRoutes([...routes, ...generalRoutes], 'portal')}
              <Redirect to="/not-found" />
            </Switch>
          </div>
        </div>
      </div>
    </>
  );
};

export default PortalLayout;
