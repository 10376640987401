export const WebNotification = ({ meta }) => {
  const _getPermission = async () => {
    Notification.requestPermission();
  };

  const _init = () => {
    if (typeof Notification === 'undefined') {
      return;
    } else if (
      Notification.permission !== 'denied' &&
      Notification.permission !== 'granted'
    ) {
      _getPermission();
    }
  };

  const _sendAlert = message => {
    if (Notification.permission === 'granted') {
      _sendMessage(message);
    } else if (Notification.permission !== 'denied') {
      _getPermission().then(permission => {
        if (permission === 'granted') {
          _sendMessage(message);
        }
      });
    }
  };

  const _sendMessage = message => {
    new Notification(meta?.hostName, {
      body: message,
      icon: meta?.hostLogo
    });
  };

  //initiate the request
  _init();
  return {
    sendMessage: _sendAlert
  };
};
