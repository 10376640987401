import React, { useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import 'static/scss/change-password.scss';
import StaticFeild from 'helpers/ui-controls/staticFeild';
import { dateTimerFormat } from 'helpers/generic.methods';
import { general } from 'api/endpoints';
import ApiServices from 'api/apexchat.api.services';

const AppInfoModal = ({ showModal, eventHideModal }) => {
  const [loading, setLoading] = useState(false);
  const [serverAppInfo, setServerAppInfo] = useState(null);
  /**
   * to reset the form fields when close the modal with close icon
   */
  const handleOnModalClose = fromIkObject => {
    eventHideModal();
  };

  const onEnterCallback = () => {
    setLoading(true);
    fetchServerInfo();
  };

  const fetchServerInfo = () => {
    return ApiServices.get(general.getServerInfo)
      .then(async ({ data }) => {
        setLoading(false);
        if (data?.success) {
          await setServerAppInfo(data.data);
        }
      })
      .catch(error => {
        setLoading(false);
      });
  };

  return (
    <Modal
      show={showModal}
      onHide={eventHideModal}
      size="md"
      keyboard={false}
      id="passwordModal"
      dialogClassName="passwordModal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onEnter={onEnterCallback}
    >
      <Modal.Body>
        <Modal.Title id="contained-modal-title-vcenter">App Info</Modal.Title>
        {/* <div>{global.AppInfo}</div>*/}
        {loading ? (
          <Spinner animation="border" variant="primary" />
        ) : (
          <>
            <ul className="editable-fields-list">
              <StaticFeild
                heading={'Environment'}
                field="environment"
                dataProperty={global.AppInfo.environment}
              />

              <StaticFeild
                heading={'Current Version'}
                field="environment"
                dataProperty={global.AppInfo.version}
              />
              <StaticFeild
                heading={'Meta Version'}
                field="environment"
                dataProperty={global.AppInfo.metaVersion}
              />
              <StaticFeild
                heading={'Modified On'}
                field="environment"
                dataProperty={dateTimerFormat(global.AppInfo.modifiedOn)}
              />
            </ul>
            <hr />
            <ul className="editable-fields-list">
              <StaticFeild
                heading={'API Current Version'}
                field="environment"
                dataProperty={serverAppInfo?.AppVersion}
              />
              <StaticFeild
                heading={'API Build Time'}
                field="environment"
                dataProperty={dateTimerFormat(serverAppInfo?.BuildTime)}
              />
            </ul>
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="user-modal-footer">
        <Button
          type="button"
          variant="outline-primary"
          className="passBtn btn-md btn-flex float-right"
          onClick={() => handleOnModalClose()}
        >
          <span>Close</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AppInfoModal;
