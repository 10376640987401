import * as Yup from 'yup';
import { emailRegx } from '../genericSchemas';

export const InitialValues = {
  emails: [],
  additional_notes: ''
};

/**
 * Setup schema for forward lead
 **/

export const forwardLeadSchemas = Yup.object().shape({
  emails: Yup.array('Invalid Emails')
    .of(
      Yup.string().matches(emailRegx, value => {
        //   // added custom error message for array field
        let matches = value.path.match(/\[(.*?)\]/);
        let index = Number(matches[1]);
        return `Email no.${index + 1} is invalid.`;
      })
    )
    .min(1, 'At least 1 email is required.')
    .max(3, 'Maximum 3 emails are allowed at a time.')
    .required('Not be empty.')
});
