import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  selectCompany,
  updateCompany
} from 'features/companies/companiesSlice';
import Button from 'react-bootstrap/Button';
import EditSaveButton from 'helpers/ui-controls/ui-components/EditSaveButton';
import { toast } from 'react-toastify';
import { success } from 'helpers/variables';
import EditableCompanyHeader from 'helpers/ui-controls/EditableCompanyHeader';
import { companyHeaderSchema } from 'helpers/schemas/companySchemas/schemas';
import { companyHeader } from 'helpers/schemas/companySchemas/initialValues';
import {
  mapServerValuesToInitialValues,
  ToastTemplate
} from 'helpers/generic.methods';
import { useFormik } from 'formik';
import Form from 'react-bootstrap/Form';
import DisplayKey from 'helpers/ui-controls/DisplayKey';
import { useTranslation } from 'react-i18next';
import UilTimesCircle from '@iconscout/react-unicons/icons/uil-times-circle';
import UilCheckCircle from '@iconscout/react-unicons/icons/uil-check-circle';
import { selectRoles } from 'features/roles/rolesSlice';
import { Link } from 'react-router-dom';

const CompanyHeader = ({ companyId, permissionEdit }) => {
  const { company } = useSelector(e => selectCompany(e, companyId));
  // const { company } = useSelector(selectCompany);
  const [editable, setEditable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [companyHeaderObj, setCompanyHeaderObj] = useState(companyHeader);
  const [disabled, setDisabled] = useState(false);
  const { t } = useTranslation();
  const { roles } = useSelector(selectRoles);

  const companyHeaderFormHandler = async formData => {
    try {
      setLoading(true);
      let payload = {
        ...formData,
        id: company.id,
        companyKey: company.companyKey
      };
      const response = await updateCompany(payload);
      setCompanyHeaderObj(response?.data?.data);
      toast.success(success.saveSuccess('Company name'));
      setEditable(false);
      setLoading(false);
    } catch (error) {
      toast.error(ToastTemplate.error(error || error.message));
      setLoading(false);
    }
  };
  const CompanyHeaderFormIk = useFormik({
    enableReinitialize: true,
    initialValues: companyHeaderObj,
    validationSchema: companyHeaderSchema,
    onSubmit: companyHeaderFormHandler
  });
  const handleOnChange = (element, key, value) => {
    CompanyHeaderFormIk.values[key] = value;
    CompanyHeaderFormIk.handleChange(element);
    setDisabled(value ? false : true);
  };

  useEffect(() => {
    mapServerValuesToInitialValues(company, companyHeader)
      .then(data => {
        setCompanyHeaderObj({
          ...companyHeader,
          ...data
        });
      })
      .catch(err => {
        throw new Error(err);
      });
  }, [company]);
  return (
    <>
      <div className="page-header">
        <div className="page-header-top d-flex align-items-end justify-content-between mb-2r">
          <div className="d-flex align-items-center">
            <h6 className="company-id mb-0 mr-8">{company.companyKey}</h6>
            {company.active === true ? (
              <Button
                className="btn-sm btn-flex btn-status"
                variant="success-3"
              >
                <UilCheckCircle className="mr-1" />
                {t('company.general.activeText')}
              </Button>
            ) : company.active === false ? (
              <Button className="btn-sm btn-flex btn-status" variant="danger-4">
                <UilTimesCircle className="mr-1" />
                {t('company.general.inActiveText')}
              </Button>
            ) : null}
          </div>
          <div className="align-self-start">
            <DisplayKey displayKey={company['id']} />
          </div>
        </div>
        <Form
          className="form"
          onSubmit={CompanyHeaderFormIk.handleSubmit}
          autoComplete="off"
        >
          <div className="page-header-bottom d-flex align-items-center justify-content-between">
            {/* <h1 className="main-head text-primary-2">{company.companyName}</h1> */}
            <EditableCompanyHeader
              headerDataProperty={company['companyName']}
              handleChange={handleOnChange}
              error={CompanyHeaderFormIk.errors['companyName']}
              editable={editable}
              field="companyName"
              titleText={company['companyName']}
            />
            <div className="btn-set">
              {roles.onBoarding.view && company?.provisioningId ? (
                <Link
                  to={`onboarding?id=${company?.provisioningId}`}
                  className="btn-md btn-flex ml-6r introJs-companies-tab-view-0 btn-float-right btn btn-primary"
                >
                  {t('company.general.goToOnboarding')}
                </Link>
              ) : null}
              <EditSaveButton
                editable={editable}
                handleEditableState={setEditable}
                loading={loading}
                headerEditbutton={true}
                disabled={disabled || !permissionEdit}
                // handleOnClick={() => companyHeaderFormHandler()}
              />
              {/* <Button className="btn-sm btn-flex" variant="lightgray-3">
                <UILFileExport />
              </Button> */}
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default CompanyHeader;
