import React, { useEffect, useState } from 'react';
import './dashboard.scss';
import { ListGroup } from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import { getAccountsStats } from 'features/analytics/analyticSlice';
import { APIStatus } from 'helpers/dropdown-enums';
import { toast } from 'react-toastify';
import ScrollBar from 'components/Scrollbars';
import { LoadingComponent } from './loadingListComp';

// UIUX-525, Accounts component
const Accounts = ({ data, status, loading }) => {
  const [accounts, setAccounts] = useState([]);
  // const [noData, setNoData] = useState(false);
  // const [loading, setLoading] = useState(false);
  useEffect(() => {
    getAccountsData();
  }, [data]);

  const getAccountsData = async () => {
    // setLoading(true);
    // try {
    //   let payload = {
    //     pageSize: 10
    //   };
    //   const response = await getAccountsStats(payload);
    //   if (response.data && response.status === APIStatus.OK) {
    //     const { data } = response.data;
    setAccounts(data || []);
  };

  return (
    <>
      {/* {loading && (
        <span className="ml-2 mr-2 mb-5">
          <i className="uil uil-spinner spinner mr-2 fz-24"></i>
          <span className="fz-14 text-darkgray-2">
            Please wait, Data is being fetched...
          </span>
        </span>
      )} */}
      <ListGroup
        className="analytic-list-container"
        variant="flush"
        style={{ borderRadius: '10px' }}
        // loading
      >
        <ScrollBar
          horizontalScroll={false}
          className="print-preview-scroll"
          style={{ height: '100vh', marginBottom: 10 }}
        >
          {accounts.map((dt, index) => (
            <ListGroup.Item key={index} className="analytics-list">
              <div className="heading">{dt.CompanyName}</div>
              {/* TODO: when API is giving data replace the active keyword with actual data */}
              <div className="cursor-pointer count-percentage">
                <span
                  className={
                    status == 'Added'
                      ? 'active-added-color'
                      : 'active-cancelled-color'
                  }
                >
                  {status}
                </span>
              </div>
            </ListGroup.Item>
          ))}
          {/* {!accounts.length ? (
            <ListGroup.Item>
              <div className="heading text-center">No data Available</div>
            </ListGroup.Item>
          ) : null} */}
          <LoadingComponent loading={loading} dataLength={accounts.length} />
        </ScrollBar>
      </ListGroup>
    </>
  );
};

export default Accounts;
