import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import RightArrowIcon from '@material-ui/icons/ArrowForwardIos';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ScrollBar from 'components/Scrollbars';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useViewport from 'helpers/viewport-hook';
import AnimateListItem from './list-item-animation';

const useStyles = makeStyles(theme => ({
  listItemRoot: {
    fontFamily: 'Lato',
    padding: '0 !important'
  },
  listItemText: {
    '& span': {
      fontFamily: 'Lato'
    }
  },
  selectedCompany: {
    backgroundColor: 'var(--lightgray-2) !important'
  },
  errorListItem: {
    color: 'var(--danger)'
  },
  iconButton: {
    '&:hover': {
      backgroundColor: 'transparent !important'
    },
    '& svg': {
      width: '20px'
    }
  }
}));

const CompanyList = ({
  list = [],
  handleChange,
  openFolderList,
  selectedData,
  loading,
  selectedIndex,
  hasCompanyError = false
}) => {
  const classes = useStyles();
  const screen = useViewport();

  const markChecked = id => {
    // console.log('selectedData', selectedData);
    return (selectedData && id in selectedData && selectedData[id]) ||
      (selectedData && id in selectedData)
      ? true
      : false;
  };

  const markErrorItem = id => {
    return hasCompanyError &&
      selectedData &&
      id in selectedData &&
      !selectedData[id]
      ? true
      : false;
  };

  const checkBoxClickHandler = (e, itemId) => {
    e.stopPropagation();
    // let value = e.target.checked;
    handleChange(itemId);
  };

  const listItemClickHandler = (e, itemId) => {
    if (markChecked(itemId)) {
      openFolderList(itemId);
    }
  };

  let tempList = [...list];

  return (
    <div>
      <ScrollBar
        horizontalScroll={false}
        style={{
          borderRadius: '0 0 0 24px',
          height: `calc(100vh - ${screen.sm ? '200px' : '380px'})`,
          // height: `calc(100vh - 380px)`,
          maxHeight: '305px'
        }}
      >
        {!loading &&
          (tempList.length ? (
            <List component="nav" aria-label="main mailbox folders">
              {tempList
                .sort((a, b) => (markChecked(a.id) ? -1 : 1))
                .map((item, index) => (
                  <AnimateListItem key={item.id} id={`${index}-${item.id}`}>
                    <ListItem
                      button
                      selected={
                        selectedIndex === item.id || markChecked(item.id)
                      }
                      onClick={e => listItemClickHandler(e, item.id)}
                      classes={{
                        root: classes.listItemRoot,
                        selected: classes.selectedCompany
                      }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          checked={markChecked(item.id)}
                          color="primary"
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                          onChange={e => checkBoxClickHandler(e, item.id)}
                        />
                      </ListItemIcon>
                      <ListItemText
                        className={`${classes.listItemText} 
                        ${markErrorItem(item.id) ? classes.errorListItem : ''}`}
                        primary={item.name}
                      />
                      {selectedIndex === item.id && (
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            disableRipple
                            disableFocusRipple
                            classes={{ root: classes.iconButton }}
                          >
                            <RightArrowIcon size={20} />
                          </IconButton>
                        </ListItemSecondaryAction>
                      )}
                    </ListItem>
                  </AnimateListItem>
                ))}
            </List>
          ) : (
            <span className="d-flex justify-content-center">
              No company available
            </span>
          ))}
      </ScrollBar>
    </div>
  );
};

export default CompanyList;
