import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import SimpleLabelTextBox from 'helpers/ui-controls/ui-components/SimpleLabelTextbox';
import DaysAndHours from 'helpers/ui-controls/ui-components/DaysAndHours';
import EditSaveButton from 'helpers/ui-controls/ui-components/EditSaveButton';
import { toast } from 'react-toastify';
import SectionHeader from 'helpers/ui-controls/ui-components/SectionHeader';
import { useFormik } from 'formik';
import { success, errors, toastMsg } from 'helpers/variables';
import { callConnectSchema } from 'helpers/schemas/companySchemas/schemas';
import { callConnectSection } from 'helpers/schemas/companySchemas/initialValues';
import {
  mapServerValuesToInitialValues,
  ToastTemplate,
  toolTipPlacement
} from 'helpers/generic.methods';
import TimeZoneField from 'helpers/ui-controls/TimeZoneField';
import {
  updateCompany,
  updateCompanyToState
} from 'features/companies/companiesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ToggleToolTip from 'helpers/ui-controls/toggleToolTip';
import { OverlayTrigger } from 'react-bootstrap';
import UilInfoCircle from '@iconscout/react-unicons/icons/uil-info-circle';
import renderToolTip from 'helpers/ui-controls/renderToolTip';
import ToggleMenuButton from 'helpers/ui-controls/ui-components/ToggleMenuButton';
import { selectRoles } from 'features/roles/rolesSlice';

const HoursCallConnect = ({ company, permissionEdit, clickHandlerMenu }) => {
  const [companyObj, setCompanyObj] = useState({});
  const [loading, setLoading] = useState(false);
  const [callConnect_chk, setCallConnect_chk] = useState(
    company.quickConnectEnabled
  );
  const [editable, setEditable] = useState(false);
  const [callConnectObj, setcallConnectObj] = useState(callConnectSection);
  const [editable_CCH, setEditable_CCH] = useState(false); // CCH: Call connect hours
  const [editable_OH, setEditable_OH] = useState(false); // OH: office hours
  const [checked_TO, setChecked_TO] = useState(company.callConnectOverride); //TO: transcript override
  const companyId = company.id;
  const [updatedCompanyData, setUpdatedDataCompany] = useState({});

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { roles } = useSelector(selectRoles);

  const updateData = (key, value) => {
    let _tempCompanyObj = {};
    _tempCompanyObj[key] = value;
    setCompanyObj({ ...companyObj, ..._tempCompanyObj });
  };

  const CallConnectFormHandler = async formData => {
    try {
      setLoading(true);
      let payload = {
        ...formData,
        quickConnectEnabled: callConnect_chk,
        id: company.id,
        companyKey: company.companyKey
      };
      const response = await updateCompany(payload);
      setUpdatedDataCompany(response?.data?.data);
      toast.success(success.saveSuccess('Call connect'));
      setEditable(false);
      setLoading(false);
    } catch (error) {
      toast.error(ToastTemplate.error(error || error.message));
      setLoading(false);
    }
  };

  const CallConnectHoursFormHandler = async formData => {
    try {
      let callConnect = [];
      if (companyObj.quickConnectHours) {
        callConnect = companyObj.quickConnectHours.map(item => {
          let tempCallConnect = item;
          if (item.endTime.includes('undefined')) {
            tempCallConnect.endTime = '';
          }
          if (item.startTime.includes('undefined')) {
            tempCallConnect.startTime = '';
          }
          return tempCallConnect;
        });
      } else {
        callConnect = company.quickConnectHours;
      }
      setLoading(true);
      let payload = {
        // ...company,
        id: company.id,
        quickConnectHours: callConnect
      };

      const response = await updateCompany(payload);
      setUpdatedDataCompany(
        getSectionUpdatedData(response?.data?.data, CallConnectFormIk.values)
      );
      toast.success(success.saveSuccess('Call connect hours'));
      setEditable_CCH(false);
      setLoading(false);
    } catch (error) {
      toast.error(ToastTemplate.error(error || error.message));
      setLoading(false);
    }
  };

  const getSectionUpdatedData = (data = {}, oldData = {}) => {
    return { ...data, ...oldData };
  };

  const OfficeHoursFormHandler = async formData => {
    try {
      let officeHours = [];
      if (companyObj.officeHours) {
        officeHours = companyObj.officeHours.map(item => {
          let tempOfficeHours = item;
          if (item.endTime.includes('undefined')) {
            tempOfficeHours.endTime = '';
          }
          if (item.startTime.includes('undefined')) {
            tempOfficeHours.startTime = '';
          }
          return tempOfficeHours;
        });
      } else {
        officeHours = company.officeHours;
      }
      setLoading(true);
      let payload = {
        // ...company,
        id: company.id,
        officeHours
      };
      const response = await updateCompany(payload);
      setUpdatedDataCompany(
        getSectionUpdatedData(response?.data?.data, {
          ...CallConnectFormIk.values
        })
      );
      toast.success(success.saveSuccess('Office hours'));
      setEditable_OH(false);
      setLoading(false);
    } catch (error) {
      toast.error(ToastTemplate.error(error || error.message));
      setLoading(false);
    }
  };

  const CallConnectFormIk = useFormik({
    enableReinitialize: true,
    initialValues: callConnectObj,
    validationSchema: callConnectSchema,
    onSubmit: CallConnectFormHandler
  });

  const handleOnChange = (element, key, value) => {
    CallConnectFormIk.values[key] = value;
    CallConnectFormIk.handleChange(element);
  };

  const handleCallConnectSwitch = checked => {
    setCallConnect_chk(checked);
  };

  const handleTranscriptOverride = (element, value) => {
    handleOnChange(element, 'callConnectTranscriptOverride', value);
    setChecked_TO(value);
  };

  useEffect(() => {
    handleCallConnectSwitch(!!company.quickConnectEnabled);
    setChecked_TO(!!company.callConnectTranscriptOverride);
    mapServerValuesToInitialValues(company, callConnectSection)
      .then(data => {
        setcallConnectObj({
          ...callConnectSection,
          ...data
        });
      })
      .catch(err => {
        throw new Error(err);
      });
    dispatch(updateCompanyToState({ id: companyId, data: updatedCompanyData }));
  }, [company, updatedCompanyData]);

  const submitForm = e => {
    e.preventDefault();
    if (Object.keys(CallConnectFormIk.errors).length) {
      toast.error(toastMsg.errors.saveError('Call connect settings'));
    } else {
      CallConnectFormIk.handleSubmit();
    }
  };

  return (
    <>
      <Form
        className="form"
        onSubmit={CallConnectFormIk.handleSubmit}
        autoComplete="off"
      >
        <div className="page-section" style={{ borderBottom: 'none' }}>
          <ToggleMenuButton
            heading={t('company.hoursCallConnect.heading1')}
            clickHandlerMenu={clickHandlerMenu}
          />
          <SectionHeader
            sectionHeading={t('company.hoursCallConnect.heading2')}
            field="call connect"
            id={'call-connect' + companyId}
            loading={loading}
            showSection
            // disabled={loading || CallConnectFormIk.dirty}
            disabled={!permissionEdit}
            editable={editable}
            error={CallConnectFormIk.errors['Call Connect']}
            handleEditableState={setEditable}
            hasHeaderProperty={true}
            headerProperty={callConnect_chk}
            headerPropertyOnChange={handleCallConnectSwitch}
            handleChange={handleOnChange}
          />

          <section>
            <div className="section-body">
              {!!callConnect_chk && (
                <ul className="editable-fields-lists">
                  <TimeZoneField
                    heading={t('company.hoursCallConnect.timeZone.label')}
                    field="Time Zone"
                    dataProperty={company['officeHoursTimezone']}
                    titleText="Time Zone"
                    helperText={t('company.hoursCallConnect.timeZone.info')} //"You can select Time Zone"
                    handleChange={handleOnChange}
                    editable={editable}
                  />
                  <SimpleLabelTextBox
                    heading={t(
                      'company.hoursCallConnect.quickConnectPhoneNumber.label'
                    )}
                    field="quickConnectPhoneNumber"
                    dataProperty={company['quickConnectPhoneNumber']}
                    titleText={t(
                      'company.hoursCallConnect.quickConnectPhoneNumber.info'
                    )}
                    handleChange={handleOnChange}
                    error={CallConnectFormIk.errors['quickConnectPhoneNumber']}
                    editable={editable}
                    multiline={false}
                  />
                  <li className="">
                    <div className="form-group">
                      <Form.Label
                        style={{ fontSize: '0.75rem', marginBottom: '0.2rem' }}
                      >
                        {t('company.hoursCallConnect.transcriptOverride.label')}
                      </Form.Label>
                      {/* UI-Control: toggleText takes flag as an argument and renders the state */}
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        style={{
                          width: 'calc(100% - 29px)',
                          display: 'inline-block'
                          // top: 10
                        }}
                        checked={checked_TO}
                        disabled={!editable}
                        onChange={e =>
                          handleTranscriptOverride(e, e.target.checked)
                        }
                      />
                    </div>
                    <OverlayTrigger
                      placement={toolTipPlacement}
                      overlay={renderToolTip(
                        t('company.hoursCallConnect.transcriptOverride.info')
                      )}
                    >
                      <UilInfoCircle
                        className="list-icon-after-text ml-1"
                        // style={{ marginLeft: 160 }}
                      />
                    </OverlayTrigger>
                  </li>
                  <SimpleLabelTextBox
                    heading={t(
                      'company.hoursCallConnect.transcriptEmail.label'
                    )}
                    field="callConnectTranscriptEmail"
                    dataProperty={
                      CallConnectFormIk.values['callConnectTranscriptEmail']
                    }
                    titleText={t(
                      'company.hoursCallConnect.transcriptEmail.info'
                    )}
                    handleChange={handleOnChange}
                    error={
                      CallConnectFormIk.errors['callConnectTranscriptEmail']
                    }
                    editable={editable}
                    multiline={false}
                  />

                  {roles.isAdminCompany ? (
                    <>
                      <ToggleToolTip
                        heading={t(
                          'company.hoursCallConnect.useCallConnectNumberforCallerID.label'
                        )}
                        toolTipFor="useQuickConnectForCallerId"
                        flag={company.useQuickConnectForCallerId}
                        titleText={t(
                          'company.hoursCallConnect.useCallConnectNumberforCallerID.info'
                        )}
                      />
                      <ToggleToolTip
                        heading={t(
                          'company.hoursCallConnect.companyNumberFirst.label'
                        )}
                        toolTipFor="companyCallConnectFirst"
                        flag={company.companyCallConnectFirst}
                        titleText={t(
                          'company.hoursCallConnect.companyNumberFirst.info'
                        )}
                      />
                      <ToggleToolTip
                        heading={t('company.hoursCallConnect.override.label')}
                        toolTipFor="callConnectOverride"
                        flag={company.callConnectOverride}
                        titleText={t('company.hoursCallConnect.override.info')}
                      />
                      <ToggleToolTip
                        heading={t(
                          'company.hoursCallConnect.officeHourMonitoring.label'
                        )}
                        toolTipFor="officeHoursMonitoring"
                        flag={company.officeHoursMonitoring}
                        titleText={t(
                          'company.hoursCallConnect.officeHourMonitoring.info'
                        )}
                      />
                      <SimpleLabelTextBox
                        heading={t(
                          'company.hoursCallConnect.smsChatNumber.label'
                        )}
                        field="smsNumber"
                        dataProperty={company['smsNumber']}
                        titleText={t(
                          'company.hoursCallConnect.smsChatNumber.info'
                        )}
                        handleChange={handleOnChange}
                        editable={false}
                        // readOnly={true}
                        disabled={true}
                        multiline={false}
                      />
                      <SimpleLabelTextBox
                        heading={t(
                          'company.hoursCallConnect.visitorgreeting.label'
                        )}
                        field="quickConnectGreeting"
                        dataProperty={company['quickConnectGreeting']}
                        titleText={t(
                          'company.hoursCallConnect.visitorgreeting.info'
                        )}
                        handleChange={handleOnChange}
                        editable={false}
                        // readOnly={true}
                        disabled={true}
                        multiline={false}
                      />
                      <SimpleLabelTextBox
                        heading={t(
                          'company.hoursCallConnect.visitorgreetingdelay.label'
                        )}
                        field="visitorGreetingDelay"
                        dataProperty={company['visitorGreetingDelay']}
                        titleText={t(
                          'company.hoursCallConnect.visitorgreetingdelay.info'
                        )}
                        //handleChange={handleOnChange}
                        editable={false}
                        //  readOnly={true}
                        // disabled={true}
                        multiline={false}
                      />
                      <SimpleLabelTextBox
                        heading={t(
                          'company.hoursCallConnect.businessgreeting.label'
                        )}
                        field="businessGreeting"
                        dataProperty={company['businessGreeting']}
                        titleText={t(
                          'company.hoursCallConnect.businessgreeting.info'
                        )}
                        handleChange={handleOnChange}
                        editable={false}
                        //  readOnly={true}
                        disabled={true}
                        multiline={true}
                      />
                      <SimpleLabelTextBox
                        heading={t(
                          'company.hoursCallConnect.businessgreetingdelay.label'
                        )}
                        field="businessGreetingDelay"
                        dataProperty={company['businessGreetingDelay']}
                        titleText={t(
                          'company.hoursCallConnect.businessgreetingdelay.info'
                        )}
                        handleChange={handleOnChange}
                        editable={false}
                        // readOnly={true}
                        disabled={true}
                        multiline={false}
                      />
                    </>
                  ) : null}
                </ul>
              )}
            </div>
          </section>
        </div>
      </Form>
      <div className="page-section">
        <Form className="form" autoComplete="off">
          <section>
            <header className="section-head d-flex align-items-center justify-content-between">
              <h5 className="fw-600 mb-0">
                {t('company.hoursCallConnect.heading3')}
              </h5>

              <div className="d-flex align-items-center">
                <EditSaveButton
                  editable={editable_CCH}
                  loading={loading}
                  handleEditableState={setEditable_CCH}
                  disabled={loading || !permissionEdit}
                  handleOnClick={CallConnectHoursFormHandler}
                ></EditSaveButton>
              </div>
            </header>
            <div className="section-body">
              <DaysAndHours
                dataArray={company.quickConnectHours}
                field="quickConnectHours"
                titleText="Quick Connect Hours..."
                handleHoursChange={updateData}
                disabled={!editable_CCH}
              ></DaysAndHours>
            </div>
          </section>
        </Form>
      </div>

      <div className="page-section">
        <section>
          <header className="section-head d-flex align-items-center justify-content-between">
            <h5 className="fw-600 mb-0">
              {t('company.hoursCallConnect.heading4')}
            </h5>

            <div className="d-flex align-items-center">
              <EditSaveButton
                editable={editable_OH}
                loading={loading}
                handleEditableState={setEditable_OH}
                disabled={loading || !permissionEdit}
                handleOnClick={OfficeHoursFormHandler}
              ></EditSaveButton>
            </div>
          </header>

          <div className="section-body">
            <DaysAndHours
              dataArray={company.officeHours}
              field="officeHours"
              titleText="office Hours..."
              handleHoursChange={updateData}
              disabled={!editable_OH}
            ></DaysAndHours>
          </div>
        </section>
      </div>
    </>
  );
};

export default HoursCallConnect;
