import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { getLeadFormUserCredentials } from 'features/lead/leadSlice';
import {
  selectChatDetailsRightPane,
  toggleChatDetailsRightPane
} from 'features/chat/chatSlice';
import UilEnvelopeCheck from '@iconscout/react-unicons/icons/uil-envelope-check';
import UilPhone from '@iconscout/react-unicons/icons/uil-phone';
import UilArrowUp from '@iconscout/react-unicons/icons/uil-arrow-up';
import UilAangleDoubleUp from '@iconscout/react-unicons/icons/uil-angle-double-up';
import UilCommentAdd from '@iconscout/react-unicons/icons/uil-comment-add';
import { isCallConnectEnable } from 'helpers/messaging/message.methods';
import DropDownMenu from 'helpers/ui-controls/ui-components/dropdownMenu';
import {
  setOpenSuggestionModal,
  setStatementText
} from 'features/messagingChats/suggestions';
import { selectRoles } from 'features/roles/rolesSlice';

const useStyles = makeStyles(theme => ({
  settingMenu: {
    padding: '0',
    backgroundColor: 'transparent !important',
    boxShadow: 'unset',
    '&:hover': {
      boxShadow: 'unset !important',
      backgroundColor: 'transparent !important'
    },
    '&:focus': {
      backgroundColor: 'transparent !important'
    }
  },
  menuIcon: {
    width: '32px !important',
    height: '32px !important'
  },
  menuClass: {
    '& .MuiPaper-root': {
      borderRadius: '10px',
      '& .MuiMenuItem-root': {
        padding: '6px 10px !important'
      }
    }
  },
  iconStyle: {
    color: 'var(--primary)'
  }
}));

const IconsContainer = ({ selectedChat, isMobile, inputMessage }) => {
  const classes = useStyles();
  const [activeDetailsTab, setActiveDetailsTab] = useState();
  let togglePanel = useSelector(selectChatDetailsRightPane);
  const leadFormUserCredentials = useSelector(getLeadFormUserCredentials);
  const { roles } = useSelector(selectRoles);
  const dispatch = useDispatch();

  const toggleSidePane = activeTab => e => {
    e.stopPropagation();
    dispatch(
      toggleChatDetailsRightPane({
        show: activeDetailsTab === activeTab ? !togglePanel.show : true,
        activeDetailsTab: activeTab,
        activeParentTab: 'user-details'
      })
    );
    setActiveDetailsTab(activeTab);
  };

  const openAddStatementModal = e => {
    e.stopPropagation();
    dispatch(setOpenSuggestionModal(true));
    dispatch(setStatementText(inputMessage));
  };

  const showCallConnect =
    leadFormUserCredentials?.phoneNumber &&
    leadFormUserCredentials?.phoneNumber.length;
  const showLeadForm =
    leadFormUserCredentials?.email && leadFormUserCredentials?.email.length;

  const iconList = [
    {
      key: 'email',
      isVisible: showCallConnect || showLeadForm,
      icon: UilEnvelopeCheck,
      clickHandler: toggleSidePane('lead-form'),
      mobileClasses: classes.iconStyle,
      desktopClasses: `lead-form-trigger ${
        togglePanel.activeDetailsTab === 'lead-form' && togglePanel.show
          ? 'active'
          : ''
      }`
    },
    {
      key: 'call-connect',
      isVisible: showCallConnect && isCallConnectEnable(selectedChat),
      icon: UilPhone,
      clickHandler: toggleSidePane('call-connect'),
      mobileClasses: classes.iconStyle,
      desktopClasses: `call-connect-form-trigger ${
        togglePanel.activeDetailsTab === 'call-connect' && togglePanel.show
          ? 'active'
          : ''
      }`
    },
    {
      key: 'add-to-script',
      isVisible: !!inputMessage && !roles?.isAgent,
      icon: UilCommentAdd,
      clickHandler: openAddStatementModal,
      mobileClasses: classes.iconStyle,
      desktopClasses: ''
    }
  ];

  const createMenuOptions = () => {
    let options = [];
    iconList.map(item => {
      if (item.isVisible) {
        options.push({
          key: item.key,
          value: (
            <item.icon
              className={item.mobileClasses}
              onClick={item.clickHandler}
            />
          )
        });
      }
    });

    return options;
  };

  return isMobile && createMenuOptions().length ? (
    <DropDownMenu
      title={<UilAangleDoubleUp className={classes.menuIcon} />}
      classes={`btn btn-sm btn-flex mr-1 btn-lightgray-3 ${classes.settingMenu}`}
      onOptionSelect={e => {}}
      options={createMenuOptions()}
      disabled={false}
      isOnlyIcon={true}
      disableClick={false}
      vertical="top"
      menuClass={classes.menuClass}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    />
  ) : !isMobile ? (
    <div className="input-action-icon chat_button_section">
      {/* togglePanel.activeDetailsTab */}
      {iconList.map(
        (item, index) =>
          item.isVisible && (
            <span
              key={index}
              className={`action-icon ${item.desktopClasses}`}
              onClick={item.clickHandler}
            >
              <item.icon />
            </span>
          )
      )}
    </div>
  ) : null;
};

export default IconsContainer;
