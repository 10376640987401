import { Box, Divider, Grid, TextField, Typography } from '@material-ui/core';
import { GetLeadType } from 'components/lead/leadTypeBadge';
import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
export const SlimLeadForm = ({ LeadFormIk, leadNotes }) => {
  return (
    <Box p={2}>
      <Grid container>
        <Grid item xs={10} alignContent="flex-end">
          <Typography variant="h6">
            <strong>{LeadFormIk?.values['name']}</strong>
          </Typography>
          <Box>
            <Typography variant="subtitle2">
              {LeadFormIk?.values['email']}
            </Typography>
          </Box>
          <Box>
            <Typography variant="p">
              {LeadFormIk?.values['phoneNumber']}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2} alignContent="flex-end" alignItems="flex-end">
          <Box textAlign="right" width={50}>
            <GetLeadType
              leadType={
                LeadFormIk?.values['type'] == 'Sales'
                  ? 1
                  : LeadFormIk?.values['type'] == 'Service'
                  ? 2
                  : 3
              }
            />
          </Box>
        </Grid>
      </Grid>

      <Box pt={6}>
        <Form.Group controlId="reason" className="normal-form-group mb-3">
          <Form.Control
            as="textarea"
            label="reason"
            name="reason"
            // value={LeadFormIk.values['reason']}
            value={leadNotes}
            placeholder="Comments"
            style={{ height: '350px' }}
          />
        </Form.Group>
      </Box>
    </Box>
  );
};
