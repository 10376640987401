import UilInfoCircle from '@iconscout/react-unicons/icons/uil-info-circle';
import React from 'react';
import { OverlayTrigger, FormLabel, Form } from 'react-bootstrap';
import ToggleText from './toggleText';
import { toolTipPlacement } from 'helpers/generic.methods';
import renderToolTip from './renderToolTip';

const ToggleToolTip = ({
  flag,
  //for disable enable value
  heading,
  //label
  titleText,
  //tooltip text
  toolTipFor,
  //htmlFor value
  editable,
  handleChange,
  field = '',
  falgOptions,
  className = 'toggle-tooltip-wrapper'
}) => {
  const handleSwitch = element => {
    handleChange(element, field, element.target.checked);
  };

  return (
    <>
      <li className={className}>
        <div className={`form-group ${!editable && 'has-disabled'}`}>
          <FormLabel
            //title={titleText}
            aria-label={heading + ' - ' + titleText}
            htmlFor={toolTipFor}
          >
            {heading}
          </FormLabel>
          {editable ? (
            <Form.Check
              type="switch"
              checked={flag}
              onChange={e => handleSwitch(e)}
              id={field}
              // className="btn-float-right"
            />
          ) : (
            <ToggleText flag={flag} options={falgOptions} />
          )}
        </div>
        {titleText && (
          <OverlayTrigger
            placement={toolTipPlacement}
            overlay={renderToolTip(titleText)}
          >
            <UilInfoCircle className="list-icon-after-text" />
          </OverlayTrigger>
        )}
      </li>
    </>
  );
};
export default ToggleToolTip;
