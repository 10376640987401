import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DisplayKey from 'helpers/ui-controls/DisplayKey';

// sub sections
import BasicDetails from './subSections/basicDetails';
import Attachements from './subSections/attachements';
import PricingDetails from './subSections/pricingDetails';
import FeatureDetails from './subSections/featureDetails';
import BusinessOperations from './subSections/businessOperations';

const ProvisionDetails = ({ provisioning }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="page-header">
        {/* <div className="page-header-top d-flex align-items-start mb-2r justify-content-between">
          <h6 className="company-id mb-0">{provisioning.companyKey}</h6>
        </div> */}
        <div className="page-header-bottom d-flex align-items-end justify-content-between">
          <h1
            className="main-head text-primary-2"
            title={provisioning.companyName}
          >
            {provisioning.companyName}
          </h1>
          <div className="align-self-start">
            <DisplayKey displayKey={provisioning['id']} />
          </div>
        </div>
      </div>
      <div className="page-body">
        <div className="page-section">
          <h4 className="sub-head">
            {t('company.provisioningDetails.heading1')}
          </h4>
          <BasicDetails formData={provisioning} />
        </div>
        <div className="page-section">
          <Attachements formData={provisioning} />
        </div>
        <div className="page-section">
          <PricingDetails formData={provisioning} />
        </div>
        <div className="page-section">
          <FeatureDetails formData={provisioning} />
        </div>
        <div className="page-section">
          <BusinessOperations formData={provisioning} />
        </div>
      </div>
    </>
  );
};

export default ProvisionDetails;
