import ReactGA from 'react-ga4';
import config from 'config';
import { Enums, getObjectKeyByValue } from 'helpers/dropdown-enums';
import { EventProvider } from './tracking-events';
import { CreateTracker } from './tracking-client';
import storage from 'helpers/storage';
import { AppInsightsClient } from 'TelemetryService';

const customDataCallBack = () => {
  const user = JSON.parse(storage.getItem('Profile'));
  let CompanyRelationship = getObjectKeyByValue(
    Enums.Relationship,
    user?.CompanyRelationship
  );
  let UserType = getObjectKeyByValue(Enums.UserType, user?.UserType);
  if (user) {
    const { Username, CompanyKey, UserID, Email, HostID } = user;
    return {
      ...{
        Username,
        UserType,
        UserID,
        Email,
        CompanyKey,
        CompanyRelationship,
        HostID,
        DateTime: new Date().toString()
      }
    };
  }
  return { DateTime: new Date().toString() };
};
const gaTracker = CreateTracker(config, {
  type: Enums.TrackerType.GoogleAnalytics,
  client: ReactGA,
  customDataCallBack: customDataCallBack, // Any data object you want to publish on every event
  applicationEvents: EventProvider
});
const appInsightTracker = CreateTracker(config, {
  type: Enums.TrackerType.AzureAppInsight,
  client: AppInsightsClient, // Azure App Insight,
  customDataCallBack: customDataCallBack,
  applicationEvents: EventProvider
});
let trackerObjects = {
  GoogleAnalytics: null,
  AzureAppInsight: null
};
const Tracker = {
  Initialize: () => {
    // initializing GA-4
    trackerObjects.GoogleAnalytics = new gaTracker();
    // TODO: Azure App Insights
    trackerObjects.AzureAppInsight = new appInsightTracker();
  },
  GetTrackerByType: trackerType => {
    return getObjectKeyByValue(Enums.TrackerType, trackerType);
  },
  PageRoute: (
    pageRouteData,
    trackerType = Enums.TrackerType.GoogleAnalytics
  ) => {
    // Setting GoogleAnalytics Tracking by default
    trackerObjects[Tracker.GetTrackerByType(trackerType)].PageRoute(
      pageRouteData
    );
  },
  Event: (trackerType, eventParameters) => {
    trackerObjects[
      Tracker.GetTrackerByType(trackerType)
    ].ApplicationEvents.CustomEvent.Publish(eventParameters);
  },
  EventsProvider: trackerType => {
    var trackerObj = trackerObjects[Tracker.GetTrackerByType(trackerType)];
    return trackerObj.ApplicationEvents;
  }
};

export default Tracker;
