import RTC from '../../rtc';
import { getRealTimeCommunicationChannels } from '../chatroom-service';
import { db } from '../indexedDbService';
const indexedDb = db();
const Initiate_Chat = options => {
  RTC().Subscribe({
    meta: {
      chatId: options.chatId,
      companyId: options.companyId,
      lastChatLogId: 0
    },
    channels: getRealTimeCommunicationChannels(options.chatId),
    callbacks: options.callbacks,
    forceSubscribe: options.forceSubscribe
  });
};

export default Initiate_Chat;
