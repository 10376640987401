import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import {
  downloadQueuedReport,
  getAllQueuedReportList
} from 'features/report/reportsSlice';
import { Container, Row, Col, Button } from 'react-bootstrap';
import '@inovua/reactdatagrid-community/index.css';
import { queuedReportTableConfig } from 'utils/tableConfig';
import HeaderToExistingPortal from 'components/headerToExistingPortal';
import { selectRoles } from 'features/roles/rolesSlice';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { GridWrapper } from '../../../../components/GridWrapper';
import { UilEye, UilTrashAlt, UilDownloadAlt } from '@iconscout/react-unicons';
import { ReportStatus } from 'helpers/dropdown-enums';
import ViewReportModal from 'pages/portal/modal/ViewReportModal';
import {
  dateTimerFormat,
  downloadFile,
  ToastTemplate
} from 'helpers/generic.methods';
import DropDownMenu from 'helpers/ui-controls/ui-components/dropdownMenu';
import {
  getSpecificGeneralInfo,
  setGeneralState
} from 'features/general/generalSlice';
import storage from 'helpers/storage';
import { getHostById } from 'features/hosts/hostSlice';
import { getFilterValue } from 'components/GridWrapper/helper';

const useStyles = makeStyles(theme => ({
  downloadMenu: {
    minHeight: '32px',
    width: '32px',
    minWidth: '20px',
    padding: '0',
    border: '1px solid #c4cdd5',
    borderRadius: '0.5rem',
    boxShadow: 'unset',
    '&:hover': {
      boxShadow: 'unset !important'
    }
  }
}));

const queuedDownloadOptions = [
  { key: 'xlsx', value: 'Excel' },
  { key: 'csv', value: 'CSV' }
];

const QueuedReports = ({
  setDataLimit,
  setTotalRecords,
  setLoading,
  loading,
  totalRecords,
  handleDelete,
  vissibleColumns,
  refetch,
  setRefetch,
  setFilters
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [selectedReport, setSelectedReport] = useState(null);
  const selectedData = {};
  const { roles } = useSelector(selectRoles);
  const permissions = (roles && roles.switch) || {};
  const [limit, setLimit] = useState(20);
  const [show, setShow] = useState(false);
  const hostInfo = useSelector(getSpecificGeneralInfo('host'));
  const dispatch = useDispatch();

  const ActionButtons = ({ value }) => {
    return (
      <>
        <Button
          className="btn-sm btn-flex mr-1 action-buttons"
          variant="lightgray-3"
          style={{ minHeight: '32px' }}
          onClick={e => handleView(value)}
          disabled={value.reportStatusId !== ReportStatus.Completed}
        >
          <UilEye className="mr-1" />
          View
        </Button>
        <DropDownMenu
          title={<UilDownloadAlt />}
          classes={`btn btn-sm btn-flex mr-1 btn-lightgray-3 ${classes.downloadMenu}`}
          onOptionSelect={handleDownload(value)}
          options={queuedDownloadOptions}
          disabled={value.reportStatusId !== ReportStatus.Completed}
        />
        <Button
          className="btn-sm btn-flex"
          style={{ minHeight: '32px' }}
          onClick={e => handleDelete(e, value)}
          variant="lightgray-3"
        >
          <UilTrashAlt />
        </Button>
      </>
    );
  };

  useEffect(() => {
    getHost();
  }, []);

  const getHost = () => {
    //fetch redactReferrer from get host api and
    //save in redux general state
    if (!hostInfo || !('redactReferrer' in hostInfo)) {
      let profile = JSON.parse(storage.getItem('Profile'));
      getHostById(profile?.HostID).then(res => {
        if (res.data && res.data.data) {
          let hostData = res.data.data;
          let hostInfo = { host: { redactReferrer: hostData.redactReferrer } };
          dispatch(setGeneralState(hostInfo));
          // storage.setItem('Profile', profile);
        }
      });
    }
  };

  const handleView = value => {
    setSelectedReport(value);
    openCloseViewReportModal();
  };
  const handleDownload = value => format => {
    try {
      let payload = {
        id: value.id,
        format: format
      };

      downloadQueuedReport(payload).then(res => {
        if (res?.data) {
          let currentDate = dateTimerFormat(new Date());
          let fileName = `${value.name}-${value.id}-${currentDate}`;
          downloadFile(res?.data, fileName, payload.format);
        }
      });
    } catch (error) {
      toast.error(ToastTemplate.error(error || error.message));
    }
  };

  const openCloseViewReportModal = () => {
    if (show) {
      setSelectedReport(null);
    }

    setShow(!show);
  };

  const getDateFilterValue = data => {
    let updatedValue;
    if (data.name === 'createdOn' && data.operator === 'before') {
      updatedValue = moment(data.value).local();
    } else if (data.name === 'createdOn' && data.operator === 'after') {
      updatedValue = moment().local();
    } else {
      updatedValue = data.value;
    }
    return updatedValue;
  };

  const loadData = ({ skip, limit, sortInfo, filterValue }) => {
    try {
      setLoadingMessage('Loading ...');
      const updatedValue = filterValue
        .filter(v => v.value !== '')
        .map(v => ({
          propertyName: v.name,
          propertyValue1: getFilterValue(v),
          propertyValue2:
            v.name === 'createdOn' &&
            (v.operator === 'before' || v.operator === 'after')
              ? getDateFilterValue(v)
              : '',
          propertyValue3: [],
          filterDataType: 2,
          comparison: 2
        }));
      let payload = {
        start: skip,
        limit: limit,
        filter: JSON.stringify(updatedValue),
        dir: !sortInfo ? 'DESC' : sortInfo.dir === 1 ? 'ASC' : 'DESC',
        sort: sortInfo ? sortInfo.name : 'createdOn'
      };
      setFilters(payload);
      return getAllQueuedReportList(payload).then(response => {
        if (response.data.totalSize > 20) {
          setDataLimit(skip + response.data.data.length);
        } else {
          setDataLimit(response.data.data.length);
        }
        const totalCount = response.data.totalSize;
        setTotalRecords(totalCount);
        setRefetch(false);
        return { data: response.data.data, count: parseInt(totalCount) };
      });
    } catch (error) {
      toast.error(ToastTemplate.error(error || error.message));
    }
  };

  const dataSource = useCallback(loadData, []);
  const [loadingMessage, setLoadingMessage] = useState('Loading ...');

  const renderColumnContextMenu = useCallback((menuProps, { cellProps }) => {
    menuProps.items = menuProps.items.splice(0, 8);
  }, []);

  return (
    <>
      <Container fluid>
        <Row>
          <Col className={`report-section-wrapper ${loading && 'blux-text'}`}>
            <div className="mobile_view">
              <GridWrapper
                enableSelection={false}
                activateRowOnFocus={false}
                showActiveRowIndicator={false}
                refetch={refetch}
                vissibleColumns={vissibleColumns}
                columns={queuedReportTableConfig(ActionButtons)}
                gridClass={
                  permissions.allowPortal ? 'myreports-table-container' : ''
                }
                limit={limit}
                setLimit={setLimit}
                totalRecords={totalRecords}
                dataSource={dataSource}
                setLoading={setLoading}
                loadingMessage={loadingMessage}
                renderColumnContextMenu={renderColumnContextMenu}
                selectedData={selectedData}
              />
            </div>
          </Col>
        </Row>
      </Container>

      <ViewReportModal
        size={'xl'}
        title={'View Report'}
        eventHideModal={openCloseViewReportModal}
        showModal={show}
        selectedReport={selectedReport}
      />
    </>
  );
};

export default QueuedReports;
