import React, { useState, useCallback, useRef } from 'react';
import * as _ from 'lodash.debounce';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import EmojiPopUp from 'components/EmojiPopup';
import ActionButtons from './ActionButtons';
import { searchSuggestion } from '../Helper';
import {
  getAutoSuggestEnable,
  getSuggestionsByCompany
} from 'features/messagingChats/suggestions';
import AutoCompleteSuggestion from './AutoCompleteSuggestions';
import {
  saveInputMessage,
  setMessagesAsRead,
  decrementTotalUnreadMessagesCount,
  markChatMessagesAsRead,
  getUnreadMsgsCountByChatId
} from 'features/messagingChats/messagingChats';
import IconsContainer from './IconsContainer';
import useViewport from 'helpers/viewport-hook';
import { parseScriptText } from 'helpers/messaging/message.methods';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Enums } from 'helpers/dropdown-enums';

const BottomSection = ({
  isChatEnded,
  onEmojiClick,
  inputMessage,
  handleInputChange,
  handleKeyPress,
  sendText,
  selectedChat,
  chatStatus
}) => {
  const screen = useViewport();
  const dispatch = useDispatch();
  const [suggestions, setSuggestions] = useState([]);
  const [inputMode, setInputMode] = useState('text');
  const emojiRef = useRef(null);
  const getAutoSuggestStatus = useSelector(getAutoSuggestEnable);
  const delayedQuery = useCallback(
    _(q => getAutoSuggestText(q), 1000),
    []
  );
  const unreadMsgCount = useSelector(getUnreadMsgsCountByChatId);

  //  get suggestions for input field autocomplete scenario
  let payload = {
    companyId: selectedChat?.getCompanyId(),
    threeLetterISOLanguageName: selectedChat?.getLanguage()
  };
  const allSuggestions = useSelector(getSuggestionsByCompany(payload));

  const getAutoSuggestText = ({ questions, text }) => {
    let searchText = text.trim();
    if (questions && questions.length && searchText) {
      let newData = searchSuggestion(questions, searchText, true);
      if (newData && newData.length) {
        setSuggestions(newData);
      } else {
        setSuggestions([]);
      }
    }
  };

  const onChange = e => {
    let text = e.target.value;
    handleInputChange(e);
    if (text.trim()) {
      delayedQuery({
        questions: allSuggestions?.questions,
        text: text
      });
    } else {
      setSuggestions([]);
      delayedQuery.cancel();
    }
  };

  const onSuggestionClickHandler = suggestion => {
    let inputElem = document.getElementById('msg-input');
    let text = parseScriptText(suggestion.text);
    let QTDetails = suggestion;
    dispatch(saveInputMessage({ id: selectedChat?.getId(), text, QTDetails }));
    inputElem.focus();
    setSuggestions([]);
  };

  const onSendText = event => {
    let inputElem = document.getElementById('msg-input');
    delayedQuery.cancel();
    sendText(event);
    setSuggestions([]);
    inputElem.focus();
  };

  const onKeyPress = event => {
    handleKeyPress(event);
    if (event.key === 'Enter' && !event.shiftKey) {
      console.log('enter press here! ');
      setSuggestions([]);
      delayedQuery.cancel();
      return false;
    }
  };

  const onSuggestionClose = () => {
    setSuggestions([]);
  };

  const onFocusHandler = () => {
    // issue:MD-252 point no.3. when emoji popup opens in mobile,it also open keyboard,which needs to be hide
    // set inputMode to none if emoji popup open in mobile to hide mobile keyboard
    if (screen.xs && emojiRef?.current) {
      setInputMode(emojiRef.current.showEmoji ? 'none' : 'text');
    } else if (inputMode !== 'text') {
      setInputMode('text');
    }
    //clear all unread messages
    if (unreadMsgCount(selectedChat?.getId())) {
      dispatch(markChatMessagesAsRead(selectedChat?.getId()));
    }
  };

  const IconsContainerSection = () => {
    return (
      <IconsContainer
        selectedChat={selectedChat}
        isMobile={screen.xs}
        inputMessage={inputMessage}
      />
    );
  };

  // UIUX-1500 set input focus on click anywhere inside text box area
  const setInputFocus = () => {
    let inputElem = document.getElementById('msg-input');
    inputElem.focus();
  };

  return (
    <div
      className={`row chat-message-input ${isChatEnded && 'chat-disabled'}`}
      onClick={setInputFocus}
    >
      <div className="w-100">
        <TextField
          InputProps={{ disableUnderline: true }}
          inputProps={{
            style: { paddingLeft: '5px', width: '100%' },
            inputMode: inputMode
          }}
          multiline
          maxRows={4}
          minRows={1}
          id="msg-input"
          type="text"
          name="message-input"
          placeholder={
            selectedChat?.data?.isOptInConsentGiven
              ? `Send message to ${selectedChat?.data?.visitorInfoName}`
              : 'Type your message here'
          }
          className={`col msg-textarea-field ${
            screen.xs ? 'mobile-msg-textarea-field' : ''
          }`}
          value={inputMessage || ''}
          onChange={getAutoSuggestStatus ? onChange : handleInputChange}
          disabled={isChatEnded}
          // onKeyPress={onKeyPress}
          onFocus={onFocusHandler}
          onKeyDown={onKeyPress}
          onClick={onFocusHandler} //on mobile screen open keyboard when emoji popup open and closed by clicking on textarea
        />
        <AutoCompleteSuggestion
          suggestions={isChatEnded ? [] : suggestions}
          onClickSuggestion={onSuggestionClickHandler}
          onClose={onSuggestionClose}
        />

        {/* changed icons place on mobile screen */}
        {screen.xs && (
          <span className="on-mobile-screen-icons">
            <IconsContainerSection />
          </span>
        )}

        <span
          className={`d-flex align-items-center justify-content-between ${
            screen.xs ? 'mobile-icons-container' : ''
          }`}
        >
          <span className="d-flex">
            <div className="input-action-icon emojis-wrap mr-1">
              <EmojiPopUp
                ref={emojiRef}
                onEmojiClick={onEmojiClick}
                pickerStyle={{
                  position: 'absolute',
                  bottom: '100%',
                  left: '0'
                }}
              />
            </div>
            {/* show icons here on desktop screen */}
            {!screen.xs && <IconsContainerSection />}
          </span>
          <ActionButtons sendText={onSendText} isMobile={screen.xs} />
        </span>
      </div>
    </div>
  );
};

export default BottomSection;