import React, { useEffect, useState } from 'react';
import AnalyticsCard from './analyticsCard';
import { CardWrapper } from './cardWrapper';
import {
  getAllowedFilters,
  getChartsAndGraphs,
  getQuickStats
} from 'features/analytics/analyticSlice';
import storage from 'helpers/storage';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import { UilFilter, UilEllipsisH, UilPrint } from '@iconscout/react-unicons';
import { useDispatch, useSelector } from 'react-redux';
import { selectRoles } from 'features/roles/rolesSlice';
import AnalyticsGraph from './analyticsGraph';
import { toast } from 'react-toastify';
import TopRevenue from './topRevenue';
import { Enums, getObjectKeyByValue } from 'helpers/dropdown-enums';
import SidebarAnalytics from '../../../components/Sidebar/sidebar-analytics';
import Filters from './filters';
import Skeleton from 'react-loading-skeleton';
import {
  getCookie,
  jsonParser,
  removeFromCookies,
  setCookieForSpecificMinutes,
  camelize,
  makeTitleFromKey,
  ToastTemplate
} from 'helpers/generic.methods';
import SubHeading from 'components/sub-heading';
import BudgetPacing from './budgetPacing';
import AgentImagesCTR from './agentImagesCTR';
import Accounts from './accounts';
import QuickPopOver from './quickstats-popover';
import FeaturesUsageGraph from './featuresUsage';
import StatsOperatorAgents from './statsOperatorAgent';
import AppliedFilters from './filters/applied-filters';
import ScrollBar from 'components/Scrollbars';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {
  GetDurationByEnum,
  initialGraphsCharts,
  initialGraphsLoading,
  initializeDuration,
  initialSelected,
  quickStatsOptions,
  withOutFiltersCards,
  initialChatStatsButtons
} from './filters/constants';
import QuickStatsLegends from './sub-components/quickStatsLegends';
import DurationDropdown from './sub-components/durationComponent';
import {
  calculateCTR,
  calculateLTR,
  FeatureUsageConstants,
  getPermitCheckBox,
  getUniqueListBy,
  removeZeroValues
} from './helper';
import renderToolTip from 'helpers/ui-controls/renderToolTip';
import { OverlayTrigger } from 'react-bootstrap';
import UilInfoCircle from '@iconscout/react-unicons/icons/uil-info-circle';
import {
  getCompanyAsync,
  selectCompany
} from 'features/companies/companiesSlice';
const storageKey = 'analyticsChartsHideShow';

const Dashboard = () => {
  const { roles } = useSelector(selectRoles);
  let userProfile = JSON.parse(storage.getItem('Profile'));
  const { company } = useSelector(e => selectCompany(e, userProfile.CompanyID));
  const permissions = (roles && roles.analytics) || {};
  const isAdminCompany = (roles && roles.isAdminCompany) || false;
  const { filters: filtersPermissions, graphs: graphPermissions } =
    permissions || {};
  const initialVissibleCards =
    JSON.parse(storage.getItem(storageKey)) ||
    getPermitCheckBox(graphPermissions);
  const [quickStats, setQuickStats] = useState([]);

  const [quickStatsWidgets, setQuickStatsWidgets] = useState(
    jsonParser(storage.getItem('analytics-quickstats-widget')) ||
      quickStatsOptions.map(qs => qs.id)
  );
  const [openColapse, setOpenColapse] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedLeadAccount, setSelectedLeadAccount] =
    useState('top_lead_acc');
  const [selectedChatLead, setSelectedChatLead] = useState([
    'ChatGraph',
    'LeadGraph'
  ]);
  const [selectedChatstat, setSelectedChatstat] = useState([
    'Agent',
    'Operator'
  ]);
  const [chatStatsButtons, setChatStatsButtons] = useState(
    initialChatStatsButtons
  );
  const [selectedAccountsChart, setSelectedAccountsChart] = useState('added');
  const [selectedFilters, setSelectedFilters] = useState(
    jsonParser(storage.getItem('analytics-selected-filters')) || initialSelected
  );
  const [loginDetails, setLoginDetails] = useState([]);
  const [user, setUserDetails] = useState([]);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [vissibleCards, setVissibleCards] = useState(initialVissibleCards);
  const [graphsData, setGraphsData] = useState(initialGraphsCharts);
  const [graphsLoading, setGraphsLoading] = useState(initialGraphsLoading());
  const [duration, setDurationValue] = useState(
    initializeDuration(roles?.analytics?.durationOptions, 'duration')
  );
  const allowedFilters = useSelector(getAllowedFilters);

  const handleClick = key => {
    let data = { ...vissibleCards };
    data[key] = false;
    toast.error(
      ToastTemplate.error('Deleted Chart can be restored from filter panel')
    );
    handleVissibleCards(data);
  };

  const handleVissibleCards = data => {
    setVissibleCards(data);
    storage.setItem(storageKey, data);
  };

  useEffect(() => {
    setLoginDetails(JSON.parse(storage.getItem('ViewedUser')));
    setUserDetails(userProfile);
    if (userProfile.CompanyRelationship === Enums.Relationship.Customer)
      dispatch(
        getCompanyAsync({
          params: { id: parseInt(userProfile.CompanyID), isCrmRequired: true }
        })
      );
  }, []);

  useEffect(() => {
    getQuick();
    getChartAndGraphs();
  }, [duration]);

  const generatePayload = (filters = {}) => {
    const { items, features, userTypes, devices, businessTypes } = filters;
    let payload = {
      timeIntervals: parseInt(duration),
      companyKeyArray: items.map(ib => ib.id) || [],
      initiateByArray: features.map(ib => ib.id) || [],
      userTypeArray: userTypes.map(ib => ib.id) || [],
      deviceTypeArray: devices.map(ib => ib.id) || [],
      BusinessCategory: businessTypes.length
        ? parseInt(businessTypes[0].id)
        : 0,
      pageSize: 10
    };
    return payload;
  };

  const onQuickStatsFilterChange = filters => {
    let filteredWidgets = filters.map(f => f.id);
    setQuickStatsWidgets(filteredWidgets);
    getQuick(undefined, filteredWidgets);
    storage.setItem('analytics-quickstats-widget', filteredWidgets);
  };

  const setDataInState = (response, selectedWidgets) => {
    let { data } = response;

    if (data && data.length) {
      let isCurrentTC = data[0].widgetId === 2 ? true : false;
      let isCurrentTL = data[0].widgetId === 3 ? true : false;
      let isCurrentTV = data[0].widgetId === 1 ? true : false;
      setQuickStats(prev => {
        let totalChats = getObjectFromArray(
          'TotalChats',
          isCurrentTC ? data : prev
        );

        let totalLeads = getObjectFromArray(
          'TotalLeads',
          isCurrentTL ? data : prev
        );

        let LTR = calculateLTR(selectedWidgets, totalChats, totalLeads);
        data = LTR ? [...data, LTR] : data;

        let newData = removeDuplicateData(prev, data, selectedWidgets);
        // setCookieForSpecificMinutes('QuickStats', JSON.stringify(newData), 5);
        return newData;
        // return [...prev,...data];
      });
    }
  };

  const removeDuplicateData = (prev, data, selectedWidgets) => {
    let mergedData = [...prev, ...data];
    // remove duplicate entries from mergedData.
    let newData = getUniqueListBy(mergedData, 'widgetId', selectedWidgets);
    return newData;
  };

  const getQuick = (
    filtersData = selectedFilters,
    selectedCards = quickStatsWidgets
  ) => {
    try {
      let payload = generatePayload(filtersData);

      if (selectedCards.length) {
        selectedCards.map(item => {
          setQuickStats([]);
          setLoading(true);

          // if selected widget is not LTR and CTR, then hit api
          if (item !== 6 && item !== 5) {
            // check if selected card is active chat or active call connect
            // set timeintervals to 24 hours....
            // timeIntervals: parseInt(duration),
            payload['timeIntervals'] =
              item == 8 || item == 11 ? 1 : parseInt(duration);
            payload['widgetType'] = item;

            getQuickStats(payload).then(res => {
              const { data } = res;
              if (data && data.success && data.data.length) {
                if (data.data[0].widgetId === 1) {
                  fetchCTRData(data.data[0], payload, selectedCards);
                }
                setDataInState(data, selectedCards);
              } else {
                let temp = {
                  data: [
                    {
                      ...getAPIErrorProps(item),
                      errorMsg: 'Something Went Wrong...'
                    }
                  ]
                };
                setDataInState(temp, selectedCards);
              }
              setLoading(false);
            });
          }
        });
      } else {
        setQuickStats([]);
      }
      // }
    } catch (error) {
      toast.error(ToastTemplate.error(error || error.message));
    }
  };

  const fetchCTRData = (totalVisits, payload, selectedCards) => {
    payload['widgetType'] = 5;
    setLoading(true);
    getQuickStats(payload).then(res => {
      if (res && res.data && res.data.data.length) {
        let CTR = calculateCTR(
          res.data.data[0],
          totalVisits,
          parseInt(duration)
        );

        setQuickStats(prev => {
          let newData = removeDuplicateData(prev, [CTR], selectedCards);
          // setCookieForSpecificMinutes('QuickStats', JSON.stringify(newData), 5);
          return newData;
        });
        // setDataInState(res.data, selectedCards);
      }
      setLoading(false);
    });
  };

  const getObjectFromArray = (key, arr) => {
    return arr.find(val => val.widgetName == key);
  };
  // get Charts and Graphs
  const getChartAndGraphs = (
    argselectedFilters = selectedFilters,
    cardsVissible = vissibleCards
  ) => {
    try {
      let payload = generatePayload(argselectedFilters);
      let selectedGraphs = getVissibleChart(cardsVissible);
      if (selectedGraphs.length) {
        setGraphsData(initialGraphsCharts);
        selectedGraphs.map(item => {
          let key = camelize(getObjectKeyByValue(Enums.graphType, item));
          let loadingKey = getObjectKeyByValue(Enums.graphType, item);
          payload['chartsTypeArray'] = [item];
          if (withOutFiltersCards.includes(item) && getCookie(key)) {
            setGraphsData(prev => {
              return { ...prev, ...JSON.parse(getCookie(key)) };
            });
          } else {
            setGraphsLoading(prev => {
              return { ...prev, [loadingKey]: true };
            });
            getChartsAndGraphs(payload).then(res => {
              if (res && res.data) {
                const { data } = res.data;
                let tempFeatureUsage = data?.FeatureUsage;
                let graphData = {
                  // ...initialGraphsCharts,
                  ...data
                };
                if (data && tempFeatureUsage) {
                  tempFeatureUsage =
                    generateCustomDataForFeatureUsage(tempFeatureUsage);
                  graphData['FeatureUsage'] = tempFeatureUsage;
                }

                setGraphsData(prev => {
                  if (withOutFiltersCards.includes(item)) {
                    setCookieForSpecificMinutes(
                      key,
                      JSON.stringify(graphData),
                      15
                    );
                  }

                  return { ...prev, ...graphData };
                });
              }
              setGraphsLoading(prev => {
                return { ...prev, [loadingKey]: false };
              });
            });
          }
        });
      }
    } catch (error) {
      toast.error(ToastTemplate.error(error || error.message));
    }
  };
  const getVissibleChart = vissibleCards => {
    let chartsIds = [];
    Object.keys(vissibleCards)
      .filter(k => vissibleCards[k])
      .forEach(key => {
        chartsIds.push(Enums.graphType[key]);
      });
    return chartsIds;
  };

  const handleOpen = status => {
    setOpenColapse(status);
  };

  const onFilterChange = (data, vcards) => {
    setSelectedFilters(data);
    storage.setItem('analytics-selected-filters', data);
    handleVissibleCards(vcards);
    getQuick(data);
    getChartAndGraphs(data, vcards);
    setOpenColapse(false);
  };

  useEffect(() => {
    setTimeout(function () {
      if (loginDetails) {
        for (let i = 0; i < loginDetails.length; i++) {
          if (
            loginDetails[i].userId === user?.UserID &&
            loginDetails[i].isLogginBefore === false
          ) {
            setModalShow(true);
            break;
          }
        }
      } else {
        setModalShow(false);
      }
    }, 3000);
  }, [loginDetails]);

  const handleClose = () => {
    console.log('Clear All');
  };

  const printHandler = () => {
    window.print();
  };
  const updateSelectedFilters = data => {
    setSelectedFilters(data);
    getQuick(data);
    getChartAndGraphs(data);
  };

  const checkFilteter = () => {
    const allEmpty = Object.keys(selectedFilters).every(key => {
      return selectedFilters[key].length === 0;
    });
    if (!allEmpty || !Object.keys(selectedFilters).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const navDropdownTitle = <UilEllipsisH />;

  const handleSelect = e => {
    let removeKeys = [];
    withOutFiltersCards.forEach((item, index) => {
      let key = camelize(getObjectKeyByValue(Enums.graphType, item));
      if (getCookie(key)) {
        removeKeys.push(key);
      }
    });
    if (duration != e) {
      removeFromCookies(removeKeys);
    }
    setDurationValue(e);
    storage.setItem('duration', e);
  };

  const selectedGraphData = (data, selected) => {
    let obj = {};
    [...selected].forEach(scl => {
      obj[scl] = data[scl];
    });
    return obj;
  };
  // generateCustomDataForFeatureUsage
  const generateCustomDataForFeatureUsage = data => {
    let obj = {};
    if (data && Object.keys(data).length)
      Object.keys(data).forEach(key => {
        if (FeatureUsageConstants.Sms.includes(key)) {
          obj['Sms'] = (obj.Sms || 0) + data[key];
        } else if (FeatureUsageConstants.Unknown.includes(key)) {
          obj['Unknown'] = (obj.Unknown || 0) + data[key];
        } else {
          if (key == 'FacebookMessenger') {
            obj['Facebook'] = data[key];
          } else {
            obj[key] = data[key];
          }
        }
      });
    // As per Ali's Requirement
    delete obj['WebApi'];
    return obj;
  };
  const getAPIErrorProps = widgetId => {
    return quickStatsOptions
      .filter(o => o.id == widgetId)
      .map(v => ({
        widgetId: v.id,
        widgetName: v.key,
        previous: 0,
        current: 0
      }))[0];
  };

  const checkNonFiltersCardsAvailable = () => {
    let selectedGraphs = getVissibleChart(vissibleCards);
    let result = selectedGraphs.some(s => withOutFiltersCards.includes(s));
    return result;
  };

  useEffect(() => {
    let tempButtons = initialChatStatsButtons.filter(btn =>
      !isAdminCompany ? allowedFilters[btn.id] : btn
    );
    setSelectedChatstat(tempButtons.map(tb => tb.key));
    setChatStatsButtons(tempButtons);
  }, [allowedFilters]);

  return (
    <>
      <ScrollBar horizontalScroll={false} style={{ height: 'inherit' }}>
        <div className="container-fluid">
          <div className="row mr-lg-0 mr-md-0 mr-sm-3">
            <div className="col-md-12">
              <div className="analytics-header d-lg-flex justify-content-between pt-3">
                {/* style={{ position: 'absolute' }} */}
                <h2 className="analytics-title ml-2">
                  {t('company.analytics.analytics')}
                </h2>
                <div className="d-flex">
                  <DurationDropdown
                    handleSelect={handleSelect}
                    duration={duration}
                    roles={roles}
                  />

                  <div className="filter-menu-button">
                    <Button
                      className="start-tour-btn"
                      variant={'primary'}
                      title={t('company.analytics.filters')}
                      onClick={() => handleOpen(true)}
                    >
                      <UilFilter style={{ marginRight: '0px' }} />
                    </Button>
                  </div>
                  <div className="share-menu-button">
                    <Button
                      className="start-tour-btn"
                      variant={'primary'}
                      title={t('company.analytics.print')}
                      onClick={printHandler}
                    >
                      <UilPrint style={{ marginRight: '0px' }} />
                    </Button>
                    {/* <Nav variant="pills" activeKey="1">
                      <NavDropdown title={navDropdownTitle} id="nav-dropdown">
                        <NavDropdown.Item eventKey="4.2" onClick={printHandler}>
                          <UilPrint />
                          <span className="menu-title">Print</span>
                        </NavDropdown.Item>
                      </NavDropdown>
                    </Nav> */}
                  </div>
                </div>
              </div>
              <div className="dashboard " onClick={() => handleOpen(false)}>
                <div
                  className={`introJS-analytics-quickstats ${
                    loading ? ' quick-stats-container-min-height' : ''
                  }`}
                  //style={{ pageBreakAfter: 'always' }}
                >
                  <img
                    src="/undraw_Hello_re_3evm.png"
                    style={{ display: 'none' }}
                    alt=""
                  />
                  {Object.keys(selectedFilters).some(
                    key => selectedFilters[key].length
                  ) ? (
                    <AppliedFilters
                      data={selectedFilters}
                      title={t('company.analytics.filterApplied')}
                      note={
                        'Note: Above filters are applied to highlighted data.'
                      }
                      handleClose={handleClose}
                      setSelectedFilters={updateSelectedFilters}
                    />
                  ) : null}
                  {permissions.quickStats ? (
                    <div className="cards-container ">
                      <div className="d-md-flex justify-content-between">
                        <div className="heading">
                          <span>{t('company.analytics.quickStats')}</span>
                          <SubHeading
                            text={
                              t('company.analytics.label4') +
                              ' ' +
                              t(
                                `company.analytics.${camelize(
                                  makeTitleFromKey(GetDurationByEnum(duration))
                                )}`
                              )
                            }
                          />
                          {loading && (
                            <span className="ml-2 mr-2 mb-5">
                              <i className="uil uil-spinner spinner mr-2 fz-24"></i>
                              <span className="fz-14 text-darkgray-2">
                                {t('company.analytics.pleaseWait')}
                              </span>
                            </span>
                          )}
                        </div>

                        <div className="add-more-btn mb-lg-1">
                          <QuickPopOver
                            onChangefilters={onQuickStatsFilterChange}
                            selected={quickStatsWidgets}
                          />
                        </div>
                      </div>
                      <div className="sub-items">
                        {quickStatsWidgets.length > 0 &&
                          quickStatsWidgets.sort().map((val, index) =>
                            quickStats[index] && !loading ? (
                              <div
                                key={index}
                                className={
                                  'introJS-analytics-quickstats-widgets-' +
                                  index
                                }
                              >
                                <AnalyticsCard
                                  {...quickStats[index]}
                                  key={index}
                                  duration={duration}
                                  hasInfo={!!quickStats[index].hasInfo}

                                  // clickMoreOptions={()=>handleClick("")}
                                />
                              </div>
                            ) : (
                              <div
                                key={index}
                                className="introJS-analytics-quickstats-widgets-0"
                              >
                                <div className="analytics-card">
                                  <div style={{ margin: '10px 0px' }}>
                                    <Skeleton />
                                  </div>
                                  <div>
                                    <Skeleton />
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                      </div>
                      {quickStats.length ? (
                        <QuickStatsLegends duration={duration} />
                      ) : null}
                    </div>
                  ) : null}
                </div>
                <div className="graph-cards-container introJS-analytics-graph print-graph-cards-container">
                  {vissibleCards['Chats vs Leads'] &&
                    graphPermissions['Chats vs Leads'] && (
                      <CardWrapper
                        title={t('company.analytics.chatsLeads')}
                        clickMoreOptions={() => handleClick('Chats vs Leads')}
                        buttons={[
                          { label: 'Chats', key: 'ChatGraph' },
                          { label: 'Leads', key: 'LeadGraph' }
                        ]}
                        handleChangeButton={setSelectedChatLead}
                        selected={selectedChatLead}
                        multiple={true}
                      >
                        <AnalyticsGraph
                          graphData={selectedGraphData(
                            graphsData.ChatVsLeadGraph,
                            selectedChatLead
                          )}
                          loading={graphsLoading['Chats vs Leads']}
                          conversion={true}
                          removeTextFromLabel="Graph"
                          appendTextToLabel="s"
                        />
                      </CardWrapper>
                    )}
                  {vissibleCards['Features Usage'] &&
                    graphPermissions['Features Usage'] && (
                      <CardWrapper
                        title={t('company.analytics.featuresUsage')}
                        clickMoreOptions={() => handleClick('Features Usage')}
                        isCard={true}
                        showSubHeading={false}
                      >
                        <FeaturesUsageGraph
                          loading={graphsLoading['Features Usage']}
                          graphData={removeZeroValues(graphsData.FeatureUsage)}
                        />
                      </CardWrapper>
                    )}
                  {vissibleCards['Chat Stats By'] &&
                    graphPermissions['Chat Stats By'] && (
                      <CardWrapper
                        title={t('company.analytics.chatStatsBy')}
                        clickMoreOptions={() => handleClick('Chat Stats By')}
                        showTabs={true}
                        StatsOperatorAgents={StatsOperatorAgents}
                        buttons={chatStatsButtons}
                        handleChangeButton={setSelectedChatstat}
                        selected={selectedChatstat}
                        multiple={true}
                      >
                        <AnalyticsGraph
                          loading={graphsLoading['Chat Stats By']}
                          graphData={selectedGraphData(
                            graphsData.ChatStats,
                            selectedChatstat
                          )}
                          // graphData={graphsData.ChatStats}
                        />
                      </CardWrapper>
                    )}
                  {graphPermissions['Top/Bottom Lead Accounts'] &&
                    vissibleCards['Top/Bottom Lead Accounts'] && (
                      <CardWrapper
                        title={t('company.analytics.topBottomLeadAccounts')}
                        clickMoreOptions={() =>
                          handleClick('Top/Bottom Lead Accounts')
                        }
                        isCard={false}
                        // isFilter
                        // below these two props are used to handle the button functioning in header
                        variant="list"
                        buttons={[
                          { label: 'Top', key: 'top_lead_acc' },
                          { label: 'Bottom', key: 'bottom_lead_acc' }
                        ]}
                        handleChangeButton={setSelectedLeadAccount}
                        selected={selectedLeadAccount}
                      >
                        {/* <TopBottomBillers /> */}
                        {selectedLeadAccount === 'top_lead_acc' && (
                          <TopRevenue
                            loading={graphsLoading['Top/Bottom Lead Accounts']}
                            graphData={graphsData.TopBottomBillers.Top}
                            duration={duration}
                          />
                        )}
                        {selectedLeadAccount === 'bottom_lead_acc' && (
                          <TopRevenue
                            loading={graphsLoading['Top/Bottom Lead Accounts']}
                            graphData={graphsData.TopBottomBillers.Bottom}
                            duration={duration}
                          />
                        )}
                      </CardWrapper>
                    )}
                  {/* non filter cards */}
                </div>
                {checkNonFiltersCardsAvailable() ? (
                  <h3 className="analytics-title ml-2 mt-3">
                    {t('company.analytics.nonFilteredGraphs')}
                    <span className="ml-2">
                      <OverlayTrigger
                        placement={'right'}
                        overlay={renderToolTip(t('company.analytics.tooltip1'))}
                      >
                        <UilInfoCircle className="" />
                      </OverlayTrigger>
                    </span>
                  </h3>
                ) : null}
                {selectedFilters.items.length ? (
                  <div className="none-filtered-graph-note">
                    Note: Non-filtered graphs are hidden since company key
                    filter is applied.
                  </div>
                ) : (
                  <div className="graph-cards-container introJS-analytics-graph print-graph-cards-container">
                    {vissibleCards['Chat Referrals'] &&
                      graphPermissions['Chat Referrals'] && (
                        <CardWrapper
                          title={t('company.analytics.chatReferrals')}
                          clickMoreOptions={() => handleClick('Chat Referrals')}
                          isCard={true}
                          showSubHeading={false}
                        >
                          <FeaturesUsageGraph
                            loading={graphsLoading['Chat Referrals']}
                            graphData={removeZeroValues(
                              graphsData.ChatReferral
                            )}
                          />
                        </CardWrapper>
                      )}
                    {graphPermissions['Accounts Activation'] &&
                      vissibleCards['Accounts Activation'] && (
                        <CardWrapper
                          title={t('company.analytics.accountsActivation')}
                          clickMoreOptions={() =>
                            handleClick('Accounts Activation')
                          }
                        >
                          <AnalyticsGraph
                            loading={graphsLoading['Accounts Activation']}
                            graphData={{
                              Activated: graphsData.AccountsActivations
                            }}
                          />
                        </CardWrapper>
                      )}

                    {graphPermissions['Top Inactive Billers'] &&
                      vissibleCards['Top Inactive Billers'] && (
                        <CardWrapper
                          title={t('company.analytics.topInactiveBillers')}
                          clickMoreOptions={() =>
                            handleClick('Top Inactive Billers')
                          }
                          isCard={false}
                          showSubHeading={true}
                          subHeading={t('company.analytics.forThePastYear')}
                          variant="list"
                        >
                          <BudgetPacing
                            customClass="line-height-ext"
                            loading={graphsLoading['Top Inactive Billers']}
                            data={graphsData.TopInActiveBillersGraph}
                            satus={true}
                            countWithName={true}
                            showYear={true}
                            statusText="Inactive"
                          />
                        </CardWrapper>
                      )}
                    {/* UIUX-521, Agent Images CTR chart */}
                    {graphPermissions['Agent Images CTR'] &&
                      vissibleCards['Agent Images CTR'] && (
                        <CardWrapper
                          title={t('company.analytics.agentImages')}
                          clickMoreOptions={() =>
                            handleClick('Agent Images CTR')
                          }
                          isCard={false}
                          variant="list"
                        >
                          <AgentImagesCTR
                            loading={graphsLoading['Agent Images CTR']}
                            data={graphsData.AgentsImagesCTR}
                            duration={duration}
                          />
                        </CardWrapper>
                      )}
                    {/* UIUX-517, Budget pacing Chart */}
                    {(userProfile.CompanyRelationship !==
                      Enums.Relationship.Customer ||
                      company?.pacingEnabled) &&
                      graphPermissions['Budget/Pacing'] &&
                      vissibleCards['Budget/Pacing'] && (
                        <CardWrapper
                          title={t('company.analytics.budgetPacing')}
                          clickMoreOptions={() => handleClick('Budget/Pacing')}
                          isCard={false}
                          variant="list"
                        >
                          <BudgetPacing
                            loading={graphsLoading['Budget/Pacing']}
                            data={graphsData.BudgetPacings}
                            status={true}
                            statusText="Active"
                            componentKey={'budget'}
                          />
                        </CardWrapper>
                      )}
                    {/* UIUX-525, Accounts chart */}
                    {graphPermissions['Accounts'] && vissibleCards['Accounts'] && (
                      <CardWrapper
                        title={t('company.analytics.accounts')}
                        clickMoreOptions={() => handleClick('Accounts')}
                        isCard={false}
                        // below these two props are used to handle the button functioning in header
                        variant="list"
                        buttons={[
                          { label: 'Added', key: 'added' },
                          { label: 'Cancelled', key: 'cancelled' }
                        ]}
                        handleChangeButton={setSelectedAccountsChart}
                        selected={selectedAccountsChart}
                      >
                        {selectedAccountsChart === 'added' && (
                          <Accounts
                            loading={graphsLoading['Accounts']}
                            data={graphsData.AccountsAddedCancelled.Added}
                            status={'Added'}
                          />
                        )}
                        {selectedAccountsChart === 'cancelled' && (
                          <Accounts
                            loading={graphsLoading['Accounts']}
                            data={graphsData.AccountsAddedCancelled.Cancelled}
                            status={'Cancelled'}
                          />
                        )}
                      </CardWrapper>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </ScrollBar>
      <SidebarAnalytics open={openColapse}>
        <ScrollBar
          horizontalScroll={false}
          style={{ height: 'calc(100% - 30px)' }}
        >
          <Filters
            openColapse={openColapse}
            cardsSection={graphPermissions}
            isAdmin={isAdminCompany}
            roles={filtersPermissions}
            selectedData={selectedFilters}
            onFilterChange={onFilterChange}
            closePanel={() => handleOpen(false)}
            hiddenCards={vissibleCards}
          />
        </ScrollBar>
      </SidebarAnalytics>
    </>
  );
};

export default Dashboard;
