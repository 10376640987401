import React, { useEffect, useState } from 'react';
import { UilMessage } from '@iconscout/react-unicons';
import UilPhone from '@iconscout/react-unicons/icons/uil-phone';
import CustomRadio from 'components/CustomRadio/CustomRadio';
import * as _ from 'lodash.debounce';
import { useDispatch, useSelector } from 'react-redux';
import {
  getLeadFormUserCredentials,
  selectIsPhoneNumberFromFieldChange,
  setIsPhoneNumberFromFieldChange,
  setLeadFormUserCredentials
} from 'features/lead/leadSlice';
import { Form, Button, Badge } from 'react-bootstrap';
import { errors } from 'helpers/variables';
import { useFormik } from 'formik';
import {
  callConnectFormSchema,
  leadFormSchema
} from 'helpers/schemas/leadsSchemas/leadFormSchemas';
import {
  getChatInfoById,
  getChatUpdatedInfoById,
  getSelectedChat,
  getSelectedChatAllVisitorMessages,
  getUnsentLeads,
  setChatUpdatedInfoDataInChats
} from 'features/messagingChats/messagingChats';
import { selectRoles } from 'features/roles/rolesSlice';

import dropdownEnums, { Enums } from 'helpers/dropdown-enums';
import {
  UilCalling,
  UilPhoneTimes,
  UilCheck,
  UilMissedCall,
  UilStopCircle,
  UilOutgoingCall
} from '@iconscout/react-unicons';
import InputWithStartIcon from 'components/InputWithStartIcon';
import { getEnumTextByValue,ToastTemplate  } from 'helpers/generic.methods';

import './LeadForm.scss';
import ButtonWithConfirmLoader from 'components/ButtonWithConfimLoader';
import { toast } from 'react-toastify';
import SimpleSelectBox from 'helpers/ui-controls/ui-components/SimpleSelectBox';
import LocationFields from './locationFields';
import { isNormalChat, mapVisitorInfoInLeadForm } from '../Helper';
import { SlimLeadForm } from '../SlimLeadForm';
import { createLeadNotes } from 'helpers/messaging/message.methods';

const LeadForm = ({
  id,
  title,
  hideFields,
  fromHandler,
  loading,
  onClickUnsentLeadHandler,
  sending,
  callStatus,

  // rulesEngine work
  rulesInfo: { rulesEnabled, leadCategories, regions }
}) => {
  const leadFormUserCredentials = useSelector(getLeadFormUserCredentials);
  const selectedChat = useSelector(getSelectedChat);
  const unsentLeads = useSelector(getUnsentLeads);
  const isPhoneNumberFromFieldChange = useSelector(
    selectIsPhoneNumberFromFieldChange
  );
  const {
    roles: { isOperator }
  } = useSelector(selectRoles);
  const { data: chatInfo } = useSelector(getChatInfoById(selectedChat.getId()));
  const chatUpdatedInfo = useSelector(
    getChatUpdatedInfoById(selectedChat.getId())
  );

  const getVisitorMessages = useSelector(getSelectedChatAllVisitorMessages);
  const dispatch = useDispatch();

  // lead form initial state
  const [leadFormInitialValues, setleadFormInitialValues] = useState({
    ...leadFormUserCredentials
  });

  useEffect(() => {
    if (
      leadFormUserCredentials.name &&
      leadFormInitialValues.name !== leadFormUserCredentials.name
    ) {
      // we will update chats{} chatInfo visitorInfoName
      dispatch(
        setChatUpdatedInfoDataInChats({
          chatId: selectedChat.getId(),
          updatedInfoData: { visitorInfoName: leadFormUserCredentials.name }
        })
      );
    }
    // update initial values for email or phone sent by the visitor
    setleadFormInitialValues({
      ...leadFormUserCredentials,
      phoneNumberFrom: selectedChat?.data?.quickConnectPhoneNumber
    });
  }, [leadFormUserCredentials]);

  // Note: update leadFormUserCredentials from here if chat's updatedInfo object data updated
  useEffect(() => {
    // save visitorInfo into LeadForm if exist in chatInfo updated data
    if (chatUpdatedInfo) {
      let { visitorInfoName, visitorInfoEmail, visitorInfoPhone } =
        chatUpdatedInfo;
      let info = { visitorInfoName, visitorInfoEmail, visitorInfoPhone };
      let visitorData = mapVisitorInfoInLeadForm(info, leadFormUserCredentials);
      if (visitorData && Object.keys(visitorData).length) {
        dispatch(setLeadFormUserCredentials(visitorData));
      }
    }
  }, [
    chatUpdatedInfo?.visitorInfoName,
    chatUpdatedInfo?.visitorInfoEmail,
    chatUpdatedInfo?.visitorInfoPhone
  ]);

  /** Lead form submission handler */
  const leadFormHandler = async (leadFormData, { resetForm }) => {
    // Add companyId and chatId to form data
    leadFormData['companyId'] = selectedChat.getCompanyId();
    leadFormData['chatId'] = selectedChat.getId();
    if (hideFields.type) {
      delete leadFormData['type'];
    }

    // delete location fields if showLocationInLeadEmail is false
    if (!chatInfo?.showLocationInLeadEmail) {
      delete leadFormData['city'];
      delete leadFormData['state'];
      delete leadFormData['zip'];
    }

    fromHandler(leadFormData);
  };

  /** Init Formik */
  const LeadFormIk = useFormik({
    enableReinitialize: true,
    initialValues: leadFormInitialValues,
    validationSchema:
      id === 'call-connect'
        ? callConnectFormSchema(false)
        : leadFormSchema(chatInfo?.locationFieldsRequired),
    onSubmit: leadFormHandler
  });

  /**
   * @name @handleOnChange
   * @description do something on field onChange
   * @requires html input element
   * */
  const handleOnChange = e => {
    let key = e.target.name;
    let value = e.target.value;
    if (key === 'phoneNumberFrom') {
      // show warning on edit company number
      if (!isPhoneNumberFromFieldChange) {
        onEditFromNumber();
        dispatch(setIsPhoneNumberFromFieldChange(true));
      }
    }

    if (key in leadFormInitialValues) {
      dispatch(setLeadFormUserCredentials({ [key]: value }));
    }

    if (
      key in leadFormInitialValues &&
      (key === 'name' || key === 'email' || key === 'phoneNumber')
    ) {
      // we will update chats{} updatedInfo visitorInfoName, visitorInfoEmail,visitorInfoPhone etc
      let infoKey = key[0]?.toUpperCase() + key.slice(1);
      if (key === 'phoneNumber') {
        // remove 'Number' from key, so that it matches updatedInfo object key 'visitorInfoPhone'
        infoKey = infoKey.replace('Number', '');
      }
      dispatch(
        setChatUpdatedInfoDataInChats({
          chatId: selectedChat.getId(),
          updatedInfoData: { [`visitorInfo${infoKey}`]: value }
        })
      );
    }
    LeadFormIk.handleChange(e);
  };

  //  Save/Remove from unsent leads list
  const unsentLeadHandler = bool => {
    let data = { chatId: selectedChat.getId(), isLead: bool };
    onClickUnsentLeadHandler(data);
  };

  // on edit from number
  const onEditFromNumber = _(() => {
    // toast.warning('You are about to change call connect number');
    // changed as per Owais said
     toast.warning(
      ToastTemplate.warning('The company call connect number has been changed.')
    );
  }, 1000);

  const createOptions = (options = [], selectOptText = '') => {
    let placeholderOpt = { value: null, text: `Select ${selectOptText}` };
    let newOpts = options.map(opt => ({ value: opt.id, text: opt.name }));
    return [placeholderOpt, ...newOpts];
  };

  // add visitor response messages in reason field if reason field is empty and side pane is open
  const addVisitorMessagesInLeadNotes = () => {
    let allMessages = getVisitorMessages(selectedChat.getId());
    return createLeadNotes(allMessages);
  };
  return (
    <>
      {isNormalChat(selectedChat?.data) && (
        <div className="lead-form-section">
          <h5>{title} Form</h5>
          <form className="form" autoComplete="off">
            <Form.Group controlId="name" className="normal-form-group mb-3">
              <Form.Control
                label="name"
                name="name"
                onChange={e => handleOnChange(e)}
                value={LeadFormIk.values['name']}
                placeholder="Enter name"
              />
              {LeadFormIk.errors['name'] && LeadFormIk.touched['name'] && (
                <p className="text-red-1 field-error">
                  {errors.fieldCorrection('name')}
                </p>
              )}
            </Form.Group>
            <div className="grid-column">
              {!hideFields?.email && (
                <Form.Group
                  controlId="email"
                  className="normal-form-group mb-0"
                >
                  <Form.Control
                    label="email"
                    name="email"
                    onChange={e => handleOnChange(e)}
                    value={LeadFormIk.values['email']}
                    placeholder="Email"
                  />
                  {LeadFormIk.errors['email'] &&
                    LeadFormIk.touched['email'] && (
                      <p className="text-red-1 field-error">
                        {LeadFormIk.errors['email']}
                      </p>
                    )}
                </Form.Group>
              )}
              <Form.Group
                controlId="phoneNumber"
                className="normal-form-group mb-0"
              >
                <Form.Control
                  label="phoneNumber"
                  name="phoneNumber"
                  onChange={e => handleOnChange(e)}
                  value={LeadFormIk.values['phoneNumber']}
                  placeholder="Phone number"
                />
                {LeadFormIk.errors['phoneNumber'] &&
                  LeadFormIk.touched['phoneNumber'] && (
                    <p className="text-red-1 field-error">
                      {LeadFormIk.errors['phoneNumber']}
                    </p>
                  )}
              </Form.Group>
            </div>
            {!hideFields?.phoneFrom && (
              <InputWithStartIcon
                startTextOrIcon="From:"
                endTextOrIcon={null}
                name="phoneNumberFrom"
                label="phoneNumberFrom"
                placeholder="From Phone number"
                handleOnChange={handleOnChange}
                value={LeadFormIk.values['phoneNumberFrom']}
                disabled={!isOperator}
              >
                {LeadFormIk.errors['phoneNumberFrom'] &&
                  LeadFormIk.touched['phoneNumberFrom'] && (
                    <p className="text-red-1 field-error">
                      {LeadFormIk.errors['phoneNumberFrom']}
                    </p>
                  )}
              </InputWithStartIcon>
            )}

            {/* Location Fields start*/}
            {id === 'lead-form' && chatInfo?.showLocationInLeadEmail && (
              <LocationFields
                form={LeadFormIk}
                handleOnChange={handleOnChange}
              />
            )}
            {/* Location Fields end */}

            {/* rules engine fields starts */}
            {rulesEnabled && (
              <div className="grid-column rules-section">
                {!!(regions && regions.length) && (
                  <SimpleSelectBox
                    dataProperty={LeadFormIk.values['regionId'] || ''}
                    name="regionId"
                    field="regionId"
                    options={createOptions(regions, 'Region')}
                    handleChange={handleOnChange}
                    showTitle={false}
                    error={
                      LeadFormIk.touched.regionId && LeadFormIk.errors.regionId
                    }
                    customStyle={{
                      color: !LeadFormIk.values['regionId']
                        ? '#727272'
                        : 'inherit',
                      height: `40px`,
                      marginBottom: '16px'
                    }}
                  />
                )}
                {!!(leadCategories && leadCategories.length) && (
                  <SimpleSelectBox
                    dataProperty={LeadFormIk.values['categoryId'] || ''}
                    name="categoryId"
                    field="categoryId"
                    options={createOptions(leadCategories, 'Lead Category')}
                    handleChange={handleOnChange}
                    showTitle={false}
                    error={
                      LeadFormIk.touched.categoryId &&
                      LeadFormIk.errors.categoryId
                    }
                    customStyle={{
                      color: !LeadFormIk.values['categoryId']
                        ? '#727272'
                        : 'inherit',
                      height: `40px`,
                      marginBottom: '16px'
                    }}
                  />
                )}
              </div>
            )}

            {/* rules engine fields ends */}

            {!hideFields?.reason && (
              <Form.Group controlId="reason" className="normal-form-group mb-3">
                <Form.Control
                  as="textarea"
                  label="reason"
                  name="reason"
                  onChange={e => handleOnChange(e)}
                  value={LeadFormIk.values['reason']}
                  placeholder="Comments"
                />
                {LeadFormIk.errors['reason'] &&
                  LeadFormIk.touched['reason'] && (
                    <p className="text-red-1 field-error">
                      {LeadFormIk.errors['reason']}
                    </p>
                  )}
              </Form.Group>
            )}

            {!hideFields?.type && (
              <Form.Group controlId="type" className="normal-form-group mb-0">
                <div className="radio-as-button-wrapper row no-gutters mt-1">
                  {dropdownEnums.enum_LeadType
                    .filter(item => item?.name?.toLowerCase() !== 'all')
                    .map((type, i) => (
                      <div
                        key={i}
                        className={`col px-0 ${i === 3 ? ' mr-0' : ' mr-2'}`}
                      >
                        <CustomRadio
                          selectedValue={LeadFormIk.values['type']}
                          value={type.name}
                          id={id ? `${id}-${type.name}` : type.name}
                          label={type.name}
                          name="type"
                          handleOnChange={e => handleOnChange(e)}
                        />
                      </div>
                    ))}
                </div>
              </Form.Group>
            )}

            {/* call connect status  */}
            {id === 'call-connect' && (
              <span>
                <span className="d-flex align-items-center call-connect-status mt-2">
                  Status:&nbsp;
                  <Badge
                    bg="primary"
                    pill
                    className={`call-status-badge d-flex align-items-center ${CallConnectionClass(
                      callStatus
                    )}`}
                  >
                    {CallConnectionIcon(callStatus)}
                    <b className="ml-1">
                      {getEnumTextByValue(Enums.CallConnectStatus, callStatus)}
                    </b>
                  </Badge>
                </span>
                <ButtonWithConfirmLoader
                  title="Connect"
                  loading={loading}
                  isDisabled={loading}
                  onConfirm={() => LeadFormIk.handleSubmit()}
                  buttonIcon={<UilPhone className="mr-1" />}
                  loaderDuration={3000}
                  classes="mt-16 mb-24 float-right"
                  variant={'primary'}
                  confirmVariant={'success'}
                  confirmButtonType={'button'}
                />
              </span>
            )}

            {/* save/remove from unsent leads buttons */}
            {id === 'lead-form' && (
              <span className="lead-form-footer">
                {(chatInfo?.leadId || chatUpdatedInfo?.leadId) &&
                chatUpdatedInfo?.leadSubmitMessage &&
                !chatUpdatedInfo?.sendingLead ? (
                  <label className="submit-lead mt-2">
                    {/* Chat lead has been sent. */}
                    {chatUpdatedInfo?.leadSubmitMessage}
                  </label>
                ) : null}
                <span
                  className="lead-form-buttons "
                  style={{ marginTop: '30px' }}
                >
                  {selectedChat?.data?.isLead ||
                  (unsentLeads.length &&
                    unsentLeads.some(
                      chat => chat.chatId === selectedChat?.getId()
                    )) ? (
                    <Button
                      disabled={sending}
                      type="button"
                      variant="primary"
                      className=" mr-1"
                      size="md"
                      onClick={() => unsentLeadHandler(false)}
                    >
                      {sending && (
                        <i className="uil uil-spinner spinner mr-1 small"></i>
                      )}
                      Unmark Lead
                    </Button>
                  ) : (
                    <Button
                      disabled={sending}
                      type="button"
                      variant="primary"
                      className=" mr-1"
                      size="md"
                      onClick={() => unsentLeadHandler(true)}
                    >
                      {sending && (
                        <i className="uil uil-spinner spinner mr-1 small"></i>
                      )}
                      Mark as Lead
                    </Button>
                  )}

                  <Button
                    disabled={loading || chatUpdatedInfo?.sendingLead}
                    type="button"
                    variant="primary"
                    className="  d-flex align-items-center justify-content-center"
                    size="md"
                    onClick={() => LeadFormIk.handleSubmit()}
                  >
                    {loading || chatUpdatedInfo?.sendingLead ? (
                      <i className="uil uil-spinner spinner mr-1 small"></i>
                    ) : (
                      <UilMessage className="mr-1" />
                    )}
                    {(chatInfo?.leadId || chatUpdatedInfo.leadId) &&
                    !chatUpdatedInfo?.sendingLead
                      ? 'Re-Submit'
                      : 'Submit'}
                  </Button>
                </span>
              </span>
            )}
          </form>
        </div>
      )}
      {(selectedChat?.data?.status == Enums.ChatStatus.ClientHandling ||
        selectedChat?.data?.status == Enums.ChatStatus.Inactive) &&
        selectedChat?.data?.isOptInConsentGiven && (
          <SlimLeadForm
            LeadFormIk={LeadFormIk}
            leadNotes={addVisitorMessagesInLeadNotes()}
          />
        )}
    </>
  );
};

const CallConnectionIcon = status => {
  switch (status) {
    case Enums.CallConnectStatus.Ringing:
    case Enums.CallConnectStatus['In Progress']:
      return <UilCalling size={14} />;
    case Enums.CallConnectStatus.Failed:
    case Enums.CallConnectStatus.Busy:
      return <UilPhoneTimes size={14} />;
    case Enums.CallConnectStatus.Canceled:
    case Enums.CallConnectStatus['No Answer']:
      return <UilMissedCall size={14} />;
    case Enums.CallConnectStatus.Completed:
      return <UilCheck size={14} />;
    case Enums.CallConnectStatus.Idle:
      return <UilStopCircle size={14} />;
    case Enums.CallConnectStatus.Queued:
      return <UilOutgoingCall size={14} />;
    default:
      return '';
  }
};

const CallConnectionClass = status => {
  switch (status) {
    case Enums.CallConnectStatus.Ringing:
    case Enums.CallConnectStatus['In Progress']:
      return 'badge-warning';
    case Enums.CallConnectStatus.Failed:
    case Enums.CallConnectStatus.Busy:
      return 'badge-danger';
    case Enums.CallConnectStatus.Canceled:
    case Enums.CallConnectStatus['No Answer']:
      return 'badge-secondary';
    case Enums.CallConnectStatus.Completed:
      return 'badge-success';
    case Enums.CallConnectStatus.Queued:
      return 'badge-primary';
    default:
      return 'badge-primary';
  }
};

// { value: 1, name: 'Queued' },
// { value: 9, name: 'Scheduled' },
// { value: 10, name: 'Initializing' }

export default LeadForm;
