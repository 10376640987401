import React, { useState, useCallback } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'static/scss/change-password.scss';
import { GridWrapper } from 'components/GridWrapper';
import { getQueuedReportById } from 'features/report/reportsSlice';
import { getReportTypeColumns } from 'pages/portal/report/reports-columns';
import { makeStyles } from '@material-ui/core';
import { ToastTemplate } from 'helpers/generic.methods';
import { Enums } from 'helpers/dropdown-enums';
import LeadTypeChange from '../LeadTypeChange';
import { useSelector } from 'react-redux';
import { selectRoles } from 'features/roles/rolesSlice';

const useStyle = makeStyles(() => ({
  viewModalContainer: {
    maxWidth: '90%'
  }
}));

const ViewReportModal = ({
  title = '',
  showModal,
  eventHideModal,
  size = 'md',
  cancelButtonTitle = 'Cancel',
  selectedReport = null
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectedLeadType, setLeadType] = useState('');
  const [selectedLeadId, setLeadId] = useState(0);
  const [showChangeLeadModal, setShowChangeLeadModal] = useState(false);
  const { roles } = useSelector(selectRoles);
  const renderColumnContextMenu = useCallback((menuProps, { cellProps }) => {
    menuProps.items = menuProps.items.splice(0, 8);
  }, []);
  const selectedData = {};
  const classes = useStyle();

  const handleOnModalClose = () => {
    eventHideModal();
    setData([]);
    setColumns([]);
  };
  const closeChangeLeadModal = () => {
    setLeadId(0);
    setLeadType('');
    setShowChangeLeadModal(false);
  };
  const onClickCallbacks = {
    LeadsRequestChange: e => {
      setLeadId(e.data.leadId);
      setLeadType(e.data.leadTypeName);
      setShowChangeLeadModal(true);
    }
  };

  const fetchData = async () => {
    if (selectedReport && selectedReport.id) {
      try {
        setLoading(true);
        let payload = {
          id: selectedReport.id,
          format: 'json'
        };

        // loadData(payload);
        getQueuedReportById(payload).then(async response => {
          let reportData = response?.data?.table1 || [];
          if (reportData.length) {
            let firstRowKeys = Object.keys(reportData[0]);
            // let columns = DynamicColGenerator(firstRowKeys);
            let reportColumns = getReportTypeColumns(
              selectedReport.reportId,
              onClickCallbacks
            );
            reportColumns = reportColumns.filter(
              col =>
                firstRowKeys.includes(col.columnName) ||
                (col.columnName == 'change' &&
                  selectedReport.reportId == Enums?.ReportType?.Leads &&
                  roles['report']?.requestLeadChange)
            );
            let result = new Promise(async resolve => {
              await setColumns(reportColumns);
              return resolve('Resolved');
            });
            result.then(res => {
              setData(reportData);
              setLoading(false);
            });
          }
        });
      } catch (error) {
        toast.error(ToastTemplate.error(error || error.message));
      }
    }
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleOnModalClose}
        onShow={fetchData}
        size={size}
        keyboard={false}
        id="viewModal"
        dialogClassName={`viewModal ${classes.viewModalContainer}`}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
          {loading ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            <div className="mobile_view">
              <GridWrapper
                columns={columns}
                gridClass={`myreports-table-container`}
                dataSource={data}
                // setLoading={onLoadingChange}
                loadingMessage={'loadingMessage'}
                renderColumnContextMenu={renderColumnContextMenu}
                selectedData={selectedData}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="user-modal-footer">
          <Button
            type="button"
            variant="outline-primary"
            className="passBtn btn-md btn-flex float-right"
            onClick={() => handleOnModalClose()}
          >
            <span>{cancelButtonTitle}</span>
          </Button>
        </Modal.Footer>
      </Modal>
      <LeadTypeChange
        title={'Request Lead Type Change'}
        eventHideModal={closeChangeLeadModal}
        showModal={showChangeLeadModal}
        leadId={selectedLeadId}
        prevLeadType={selectedLeadType}
      />
    </>
  );
};

export default ViewReportModal;
