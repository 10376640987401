import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { theme } from 'helpers/variables';
import { stopPropogation } from 'features/general/generalSlice';

import RenderSidebarTooltip from 'helpers/ui-controls/renderSidebarTooltip';
import { UilQuestionCircle } from '@iconscout/react-unicons';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ActiveLink } from 'helpers/hoc/Link';

const useStyles = makeStyles(theme => ({
  linkContainer: {
    width: '100%',
    '& a.sidebar-link:hover': {
      textDecoration: 'none !important'
    },
    '& a.sidebar-link:not(.active-link)': {
      color: 'var(--primary-3)'
    }
  },
  linkTextBelowIcon: {
    fontSize: '0.5rem'
  },
  popper: {
    left: ({ left }) => (left ? left : '0'),
    top: ({ top }) => (top ? top : '0'),
    right: 0
  }
}));

const PopMenu = ({
  id = '',
  showSideBar,
  icon,
  title = '',
  toolTipText = '',
  clickHandler,
  path = '',
  menuHeading = '',
  MenuIcon = 'UilQuestionCircle',
  children,
  position = { left: '52px', top: '0px' }, // change popup menu position
  showTooltip = true
}) => {
  const [menuShow, setMenuShow] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles(position);

  const useCloseOnClickedOutside = async ref => {
    await dispatch(stopPropogation(ref)).then(res => {
      if (res) setMenuShow(false);
    });
  };

  // use ref on wrapper element
  const wrapperRef = useRef(null);
  // calling the method to check if the clicked event
  // happened inside / outside the wrapper
  // and pass the wrapper ref
  useCloseOnClickedOutside(wrapperRef);

  const toggleDropdownHandler = e => {
    if (!path) {
      e.preventDefault();
    }

    if (children) {
      setMenuShow(!menuShow);
    } else {
      clickHandler();
    }
  };

  return (
    <div
      id={id}
      className={`profile-nav ${classes.linkContainer}`}
      ref={wrapperRef}
    >
      <ActiveLink
        // id={id}
        activeClassName="active-link"
        href={path}
        onClick={toggleDropdownHandler}
      >
        <div className={`sidebar-link sidebar-link-with-below-text`}>
          <RenderSidebarTooltip
            showSidebar={showSideBar}
            title={toolTipText || title}
            icon={icon}
            showTooltip={showTooltip}
          />
          <span className={'link-text-below-icon'}>{title}</span>
        </div>
      </ActiveLink>
      {/* toggle the menu on condition check */}
      {menuShow && children && (
        <div
          className={`sidebar-bottom-menu${showSideBar ? '-open' : ''} ${
            classes.popper
          }`}
        >
          <ul className="dropdow-list">
            {menuHeading && (
              <li className="notification-list-item heading cursor-pointer p-0">
                <div
                  className="d-flex justify-content-between px-3 py-2 align-items-center"
                  style={{
                    background: 'var(--lightgray-2)',
                    boxShadow: '0px 4px var(--lightgray-5)',
                    borderRadius: '0.25rem 0.25rem 0.25rem 0.25rem'
                  }}
                >
                  <p className="m-0" style={{ fontWeight: 700 }}>
                    {menuHeading}
                  </p>
                  <UilQuestionCircle size="18" className="sidebar-link" />
                </div>
              </li>
            )}
            {children({ menuSet: setMenuShow })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default PopMenu;

export const PopMenuItem = ({
  icon,
  text = '',
  handler,
  style = { color: 'var(--primary)' },
  loading,
  ...rest
}) => {
  return (
    <li className="notification-list-item cursor-pointer">
      <a style={style} onClick={handler}>
        {icon}
        {text}
        {loading || ''}
      </a>
    </li>
  );
};
