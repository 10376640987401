import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { Row, Col, Card, ListGroup } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';

import { toast } from 'react-toastify';
import UnsentLeads from '../UnsentLeadChats';
import ChatDetail from '../ChatDetail';
import History from '../History';
import {
  getActiveChatList,
  getAllChatList,
  getIncomingChatList,
  getSelectedChatId,
  setSelectedChat,
  setUnreadMessage,
  saveChatMessage,
  changeMessagingDashboardStatusAsync,
  getUnsentLeads,
  getChatRoomData,
  getShelvedChatList,
  setMessagesRecived,
  onTransferChatUpdate,
  removeFromIncomingSection,
  isActiveChatUnsubscribed,
  onChatReject,
  getAssignedGroups,
  addRejectLoadingInIncomingChat,
  addRejectedChatId,
  getOptInLeads,
  getTotalOptInLeadsCount,
  incrementTotalUnreadMessagesCount,
  decrementTotalUnreadMessagesCount,
  setChatStack,
  setMessagesAsRead,
  sortChatsByActivity,
  markChatMessagesAsRead,
  animateTabTitleOnMessageReceived,
  getSelectedChat,
  markMessageLeadAsRead,
  setMessageLeadAsRead,
  setAccessibleChat,
  getEnableNotificationSound
} from 'features/messagingChats/messagingChats';
import storage from 'helpers/storage';
import ChatList from '../ChatList';
import './Chats.scss';
import {
  resetLeadFormReason,
  resetLeadFormUserCredentials,
  setLeadFormReason,
  setLeadFormUserCredentials
} from 'features/lead/leadSlice';
import {
  selectChat,
  toggleChatDetailsRightPane
} from 'features/chat/chatSlice';
import { errors, success } from 'helpers/variables';
import {
  selectDynamicMeta,
  selectLogin,
  setProfileToState
} from 'features/auth/authSlice';
import Header from './Header';
import { ChatsType, UnsentChatList } from './Constants';
import { selectRoles } from 'features/roles/rolesSlice';
import ScrollBar from 'components/Scrollbars';
import { Enums } from 'helpers/dropdown-enums';
import {
  RemoveChannelsFromRTCCleint,
  UnSubscribeChat
} from 'app/communication/services/chat/pub_sub_chat';
import { chatCallbacks } from 'app/communication/services/chat/callbacks/chat_default_callbacks';
import Initiate_Chat from 'app/communication/services/chat/initiate_chat';
import BottomNavigationComponent from '../BottomNavigationComponent';
import { logIt } from 'helpers/logger';
import { ToastTemplate } from 'helpers/generic.methods';
import useQuery from 'routes/searchQueryHook';
import { restrictChatByRoles } from 'helpers/messaging/message.methods';
import PreventChatOpeningModal from '../PreventChatOpeningModal';
import {
  ToggleButtonGroup,
  ToggleButton as MuiToggleButton,
  TabPanel,
  TabContext
} from '@material-ui/lab';
import { Box, List, makeStyles, Badge } from '@material-ui/core';
import { OptInLeadListItem } from '../ChatList/OptInLeadListItem';
import { getCookie } from 'helpers/generic.methods';
import { WebNotification } from 'app/communication/services/webNotification';
import { db } from 'app/communication/services/indexedDbService';

const muiStyling = makeStyles(theme => ({
  buttonGroup: {
    width: '100%'
  },
  tab: {
    width: '100%',
    color: 'rgba(0, 0, 0, 0.6)',
    borderRadius: '0.5em',
    textTransform: 'unset',
    background: '#F4F6F8',
    lineHeight: 1,
    border: 'none',
    '&.Mui-selected': {
      background: 'var(--primary)',
      color: '#fff'
    },
    '&:hover': {
      backgroundColor: '#d9d9d9 !important',
      color: '#000'
    }
  },
  firstTab: {
    width: '50%',
    '&.Mui-selected': {
      zIndex: 2,
      'border-top-right-radius': '0.5em !important',
      'border-bottom-right-radius': '0.5em !important'
    }
  },
  secondTab: {
    width: 'calc(50% + 12px)',
    marginLeft: '-12px !important',
    '&.Mui-selected': {
      'border-top-left-radius': '0.5em !important',
      'border-bottom-left-radius': '0.5em !important'
    }
  },
  tabPanel: {
    padding: 0
  }
}));
const Chats = ({
  setToggleChatDetail,
  toggleChatDetail,
  toggleActiveChats,
  setToggleActiveChats,
  setToggleChatSuggestion,
  setDefaultSuggestionTab,
  defaultMessageText
}) => {
  // const { toggle, incomingChatSound, clearSound } = useNotification();
  let enableNotificationSound = useSelector(getEnableNotificationSound);
  const useStyling = muiStyling();
  const [statusLoading, setStatusLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [preventModalData, setPreventModalData] = useState({});

  let query = useQuery();
  const dispatch = useDispatch();
  const dynamicMeta = useSelector(selectDynamicMeta);
  const webPushNotification = WebNotification({ meta: dynamicMeta });
  let { profile } = useSelector(selectLogin);
  // get chatId params from url if exist and open chatDetails section
  useEffect(() => {
    let chatId = query.get('chatId');
    if (!chatId && profile?.UserRoles.includes('MessagingLeadsAccessOnly')) {
      chatId = getCookie('X-Auth-Accessor-Id');
    }
    if (chatId) {
      let ischatAvailable = -1;
      if (chats?.optInLeadsList) {
        ischatAvailable = chats.optInLeadsList.findIndex(
          chat => chat.chatId === Number(chatId)
        );
      }

      if (ischatAvailable == -1) {
        ischatAvailable = chats.activeChatList.findIndex(
          chat => chat.getId() === Number(chatId)
        );
      }
      if (ischatAvailable !== -1) {
        onOptInLeadClick(Number(chatId));
        dispatch(setAccessibleChat(Number(chatId)));
        return;
      }
    }
  }, []);

  let { roles } = useSelector(selectRoles);
  let chats = {
    incomingChatList: useSelector(getIncomingChatList),
    activeChatList: useSelector(getActiveChatList),
    allChatList: useSelector(getAllChatList),
    shelvedChatList: useSelector(getShelvedChatList),
    unsentLeadList: useSelector(getUnsentLeads),
    optInLeadsList: useSelector(getOptInLeads),
    totalOptInLeadsCount: useSelector(getTotalOptInLeadsCount)
  };
  const [selectedBottomMenu, setSelectedBottomMenu] = useState(0);
  const [pickChatLeadTabsIndex, setPickChatLeadTabsIndex] = useState(
    roles?.messaging?.defaultChatRoomTab ?? 0
  );
  const [activeAllTabsIndex, setActiveAllTabsIndex] = useState(0);
  const onChangeNavMenu = value => {
    setSelectedBottomMenu(value);
  };
  let getActiveChatUnsubscribedStatus = useSelector(isActiveChatUnsubscribed);
  let selectedChatId = useSelector(getSelectedChatId);
  let selectedChat = useSelector(getSelectedChat);
  const user = useSelector(selectLogin);
  const indexedDb = db();
  // auto subscribe active chats if they are not subscribed after refresh
  const _onSuccessMessageArrived = data => {
    data['preventNotification'] = true;
    data['preventSorting'] = true;
    _onMessageArrived(data);
  };
  const _onMessageArrived = data => {
    if (data.userId !== user?.profile?.UserID) {
      reviceMsg();
      //do for visitor messages only
      if (data.isUnread && data.userId == 0) {
        dispatch(setUnreadMessage(data.chatId));
        dispatch(animateTabTitleOnMessageReceived());
        // dispatch(incrementTotalUnreadMessagesCount(data.chatId));
      }
      //insert into indexedDb
      if (!data.preventNotification) {
        if (selectedChat?.data?.visitorInfoName) {
          webPushNotification.sendMessage(
            `You have received a new message from ${selectedChat?.data?.visitorInfoName}`
          );
        } else {
          webPushNotification.sendMessage(`You have received a new message`);
        }
      }
    }
    // indexedDb.chatLogsDbService.insertChatLog(data.chatId, data);

    // only run below statement if chat-detail section is opened
    if (
      data.chatId === selectedChatId &&
      // toggleChatDetail &&
      data.userId === Enums.ChatMessageType.Visitor
    ) {
      dispatch(setLeadFormUserCredentials(data.text));
      dispatch(setLeadFormReason(data.text));
    }

    if (!data.preventSorting) {
      dispatch(sortChatsByActivity(data.chatId));
    }
    dispatch(saveChatMessage(data));
  };
  const getChatsSectionHeight = (tabsCount, isTopSection) => {
    var getBottomSectionActiveTabIndex = activeAllTabsIndex; //0 index for active tab, 1 for All chats tab
    var getTopSectionActiveTabIndex = pickChatLeadTabsIndex; //0 index for incoming tab, 1 for message leads tab

    var topSectionContentInConsideration;
    var bottomSectionContentInConsideration;
    //select top content
    if (getTopSectionActiveTabIndex == 0) {
      topSectionContentInConsideration = chats?.incomingChatList;
    } else {
      topSectionContentInConsideration = chats?.optInLeadsList;
    }
    //select bottom content
    if (getBottomSectionActiveTabIndex == 0) {
      bottomSectionContentInConsideration = chats?.activeChatList;
    } else {
      bottomSectionContentInConsideration = chats?.allChatList;
    }

    //get the chat section height
    var headerHeight = 48;
    var topBar = 70;
    var topTabsHeight = 68;
    var bottomTabsHeight = 68;
    if (!roles?.messaging.showBottomNavigationSection) {
      //remove height, just add for padding
      bottomTabsHeight = 16;
    }
    var bottomHeight = 48;
    var gutter = 10 + 10;
    var height =
      window.innerHeight -
      topBar -
      gutter -
      headerHeight -
      topTabsHeight -
      bottomTabsHeight -
      bottomHeight;
    let maxSectionHeight = height / 2;
    //each tab height is 85px + 8px padding per item
    //calculate for top section

    let totalTopTabHeight = topSectionContentInConsideration.length * (85 + 8);
    let totalBottomTabHeight =
      bottomSectionContentInConsideration.length * (85 + 8);
    if (totalBottomTabHeight == 0) {
      totalBottomTabHeight = 70;
    }
    if (totalTopTabHeight == 0) {
      totalTopTabHeight = 70;
    }
    if (totalTopTabHeight == 0 && isTopSection) {
      maxSectionHeight = 70;
    } else if (
      totalTopTabHeight > maxSectionHeight &&
      totalBottomTabHeight < maxSectionHeight
    ) {
      maxSectionHeight = isTopSection
        ? maxSectionHeight + (maxSectionHeight - totalBottomTabHeight)
        : totalBottomTabHeight;
    } else if (
      totalTopTabHeight < maxSectionHeight &&
      totalBottomTabHeight > maxSectionHeight
    ) {
      maxSectionHeight = isTopSection
        ? totalTopTabHeight
        : maxSectionHeight + (maxSectionHeight - totalTopTabHeight);
    } else {
      maxSectionHeight = maxSectionHeight;
    }
    return maxSectionHeight;
  };
  useEffect(() => {
    if (chats.activeChatList.length) {
      // re-subscribe active chats after reload
      chats.activeChatList.map(chat => {
        if (!getActiveChatUnsubscribedStatus(chat.getId())) {
          let callbacks = chatCallbacks({
            _onSuccessMessageArrived,
            _onMessageArrived,
            chatId: chat.getId()
          });
          Initiate_Chat({
            chatId: chat.getId(),
            companyId: chat.getCompanyId(),
            callbacks
          });
        }
      });
    }
  }, [chats.activeChatList.length]);
  const onOptInLeadClick = (chatId, leadId) => {
    let isFromIncomingSection = true;
    dispatch(getChatRoomData(chatId)).then(res => {
      if (res && typeof res !== 'string') {
        var enableToggle = true;
        setChatRoomForSelectedChat(
          { ...res, isFromIncomingSection },
          enableToggle
        );
      }
    });
    dispatch(markChatMessagesAsRead(chatId));
    dispatch(setMessageLeadAsRead(leadId));
    // dispatch(setMessagesAsRead(chatId));
    // dispatch(decrementTotalUnreadMessagesCount(chatId));
  };
  const onChatClickHandler = roomType => async chat => {
    let isFromIncomingSection =
      roomType === Enums.RoomType.IncomingChat ? true : false;
    chat = { ...chat, isFromIncomingSection };
    //set all messages as read
    dispatch(setMessagesAsRead(chat?.chatId || chat.getId()));
    dispatch(decrementTotalUnreadMessagesCount(chat?.chatId || chat.getId()));
    // condition either its historyChat or not etc...
    chat = await handleHistoryChatHandler(chat).then(res => res);
    // Prevent Agent/Operator to view eachother's on going chats..
    var result = restrictChatByRoles(chat, roles);
    result = handleAdminitrativeRights(result);
    if (result?.shouldSubcribeChat) {
      SubcribeChatHandler(chat);
    } else {
      // showModalPopup with "result" data.
      openChatPreventModal(result);
    }
  };

  var handleAdminitrativeRights = result => {
    //Show only if the agent is also admin
    if (roles?.messaging?.canPickOthersChat) {
      //Don't restrict operate chats for admin
      return { shouldSubcribeChat: true };
    }
    return result;
  };

  var handleHistoryChatHandler = async chat => {
    // history scenario..
    if (!chat.getId && chat.chatId !== selectedChatId) {
      let data = await dispatch(getChatRoomData(chat.chatId))
        .then(res => {
          if (res && typeof res !== 'string') {
            if ('response' in chat) {
              dispatch(removeFromIncomingSection(chat.chatId));
            }

            return res;
          }
        })
        .catch(error => {
          toast.error(ToastTemplate.error(error || errors.failure));
        });
      return { ...data, isFromIncomingSection: chat?.isFromIncomingSection };
    }
    return chat;
  };
  var SubcribeChatHandler = chat => {
    /// Sara Kachra...
    if (chat?.isFromIncomingSection) {
      let isRouted = window.RTC.subscribedChannels.some(item =>
        item.includes(chat.getId())
      );
      if (isRouted) {
        //unsubscribe chat;
        UnSubscribeChat(chat.getId());
        // RemoveChannelsFromRTCCleint(chat.getId());
      }
    }
    dispatch(decrementTotalUnreadMessagesCount(chat.getId()));
    setChatRoomForSelectedChat(chat);
  };

  const toggleChatLeadTabs = e => {};

  const setChatRoomForSelectedChat = (chat, enableToggle) => {
    dispatch(resetLeadFormReason(''));
    if (chat?.getId() !== selectedChatId) {
      dispatch(
        toggleChatDetailsRightPane({
          show: !!enableToggle
        })
      );
      dispatch(resetLeadFormUserCredentials());
      dispatch(setChatStack(chat.getId()));
    }
    dispatch(setSelectedChat(chat));
    setToggleChatDetail(true);
    if (!isMobile) {
      setToggleChatSuggestion(true);
    }
    if (isMobile) {
      setToggleActiveChats(true);
    }
  };

  const reviceMsg = () => {
    dispatch(setMessagesRecived(true));
    setTimeout(() => {
      dispatch(setMessagesRecived(false));
    }, 1000);
  };

  const toggleStatus = async e => {
    let value = e.target.checked;
    setStatusLoading(true);
    let statusChanged = await dispatch(
      changeMessagingDashboardStatusAsync(+value)
    );

    if (statusChanged) {
      toast.success(success.statusChange);
      setStatusLoading(false);
      let profile = JSON.parse(storage.getItem('Profile'));
      profile['Active'] = value;
      storage.setItem('Profile', profile);
      dispatch(setProfileToState());
    } else {
      toast.error(ToastTemplate.error(errors.failure));
      setStatusLoading(false);
    }
  };

  // on Transfer chat click
  // response: 1/0 as = accept/ignore
  const selectTranferedChat = response => chat => {
    // let response = ApexChat.ChatTransferResponse.rejected;
    let data = {
      chatId: chat?.getId(),
      userId: profile?.UserID,
      message: 'test',
      response
    };
    dispatch(onTransferChatUpdate(data)).then(res => {
      if (typeof res !== 'string' && res) {
        if (response) {
          let chatHandler = onChatClickHandler(Enums.RoomType.IncomingChat);
          chatHandler(data);
          // dispatch(removeFromIncomingSection(data.chatId));
        } else {
          dispatch(removeFromIncomingSection(data.chatId));
        }
        // TODO: remove from incoming section and if accepted then add to active section
      }
    });
  };

  // on Reject chat click
  const selectRejectChat = chat => {
    let payload = {
      chatId: chat?.getId()
    };
    // add loading on reject click
    dispatch(
      addRejectLoadingInIncomingChat({ id: payload.chatId, loading: true })
    );

    // get assignedGroup in order to get activeAgentsCount
    // if activeAgentsCount is greater than 1, then user can reject chat else not
    getAssignedGroups()
      .then(res => {
        if (res && res.data.success && res.data.data) {
          let groups = res.data.data;
          let canReject = groups.every(item => item.activeAgentsCount > 1);
          logIt('log', 'canReject--', canReject);
          // if activeAgentsCount is greater than 1 call reject api
          // else show warning that your the only active user
          if (canReject) {
            dispatch(onChatReject(payload.chatId))
              .then(res => {
                if (res) {
                  toast.success(success.chatRejected);
                  dispatch(addRejectedChatId(payload.chatId));
                  dispatch(removeFromIncomingSection(payload.chatId));
                }
              })
              .catch(err => {
                console.log('onError', err);
                toast.error(ToastTemplate.error(errors.failure));
                dispatch(
                  addRejectLoadingInIncomingChat({
                    id: payload.chatId,
                    loading: false
                  })
                );
              });
          } else {
            toast.warn(
              ToastTemplate.warning(
                'You are the last active user for one of your groups. You cannot reject the chat.'
              )
            );
            dispatch(
              addRejectLoadingInIncomingChat({
                id: payload.chatId,
                loading: false
              })
            );
          }
        }
      })
      .catch(err => {
        console.log('onError', err);
        toast.error(ToastTemplate.error(errors.failure));
        dispatch(
          addRejectLoadingInIncomingChat({
            id: payload.chatId,
            loading: false
          })
        );
      });
  };

  //History chat click handler
  // remove chatId from RTC list to subscribe again in order
  // to get messages again after closed previously
  const historyChatClickHandler = chat => {
    RemoveChannelsFromRTCCleint(chat.chatId);
    onChatClickHandler(6)(chat);
  };
  const openChatPreventModal = result => {
    // showModalPopup with "result" data.
    setPreventModalData(result);
    setOpenModal(true);
  };

  const onCloseModal = () => {
    setOpenModal(false);
    setPreventModalData({});
  };

  return (
    <>
      <Col
        className="mb-3 col-chat-wrap text-primary"
        style={{ paddingRight: '4px' }}
      >
        <Card border="white" className="card-rounded all-chat-section">
          <Row className="all-chat-row no-gutters">
            {!toggleActiveChats && (
              <div className="chat-list-wrapper border-right px-8">
                <Header
                  totalCount={
                    chats.activeChatList.length +
                    chats.allChatList.length +
                    chats.incomingChatList.length +
                    chats.shelvedChatList.length
                  }
                  toggleStatus={toggleStatus}
                  status={profile.Active}
                  statusLoading={statusLoading}
                  isAgent={roles.isAgent}
                />
                <Box pt={2} pb={2}>
                  {roles?.messaging?.showIncomingMessagesTab &&
                    roles?.messaging?.showMessageLeadsTab && (
                      <ToggleButtonGroup
                        color="primary"
                        exclusive
                        value={pickChatLeadTabsIndex}
                        className={useStyling.buttonGroup}
                        onChange={(o, k) => {
                          setPickChatLeadTabsIndex(k);
                        }}
                      >
                        <MuiToggleButton
                          value={0}
                          className={classNames(
                            useStyling.tab,
                            useStyling.firstTab
                          )}
                        >
                          Pick Chats
                        </MuiToggleButton>
                        <MuiToggleButton
                          value={1}
                          className={classNames(
                            useStyling.tab,
                            useStyling.secondTab
                          )}
                        >
                          Incoming Leads
                          <Box pl={2}>
                            <Badge
                              color="secondary"
                              classes={{ badge: 'badge-container' }}
                              badgeContent={chats?.totalOptInLeadsCount}
                            ></Badge>
                          </Box>
                        </MuiToggleButton>
                      </ToggleButtonGroup>
                    )}
                  {!roles?.messaging?.showMessageLeadsTab &&
                    roles?.messaging?.showIncomingMessagesTab && (
                      <span className="tab-title">Incoming Chats</span>
                    )}
                  {!roles?.messaging?.showIncomingMessagesTab &&
                    roles?.messaging?.showMessageLeadsTab && (
                      <span className="tab-title">
                        Incoming Leads{' '}
                        <span style={{ paddingLeft: '15px' }}>
                          <Badge
                            classes={{
                              badge: `${useStyling.badge} badge-container`
                            }}
                            color="secondary"
                            badgeContent={chats?.totalOptInLeadsCount}
                          ></Badge>
                        </span>
                      </span>
                    )}
                </Box>
                <TabContext value={pickChatLeadTabsIndex}>
                  {roles?.messaging?.showIncomingMessagesTab && (
                    <TabPanel value={0} className={useStyling.tabPanel}>
                      <ScrollBar
                        horizontalScroll={false}
                        style={{
                          height: getChatsSectionHeight(
                            chats.incomingChatList.length,
                            true
                          )
                          // height: 'calc(100% - 102px)'
                          // maxHeight: '300px'
                        }}
                      >
                        {ChatsType.map(
                          type =>
                            type.key == 'INC' && (
                              <ChatList
                                title={type.title}
                                chats={chats[type.chats]}
                                showActionBtns={type.showActionBtns}
                                onChatClickHandler={onChatClickHandler(
                                  type.roomType
                                )}
                                onAccept={selectTranferedChat(1)}
                                onIgnore={selectTranferedChat(0)}
                                onReject={selectRejectChat}
                                eventKey={type.eventKey}
                                defaultActiveKey={type.defaultActiveKey}
                                roomType={type.roomType}
                              />
                            )
                        )}
                      </ScrollBar>
                    </TabPanel>
                  )}
                  {roles?.messaging?.showMessageLeadsTab && (
                    <TabPanel value={1} className={useStyling.tabPanel}>
                      <ScrollBar
                        horizontalScroll={false}
                        style={{
                          height: getChatsSectionHeight(
                            chats.optInLeadsList.length,
                            true
                          )
                          // height: 'calc(100% - 102px)'
                          // maxHeight: '300px'
                        }}
                      >
                        {chats.optInLeadsList &&
                          chats.optInLeadsList.map(t => (
                            <List style={{ padding: '8px 0 0 0' }}>
                              <OptInLeadListItem
                                clickHandler={() =>
                                  onOptInLeadClick(t.chatId, t.leadId)
                                }
                                optInLead={t}
                              />
                            </List>
                          ))}
                        {chats.optInLeadsList.length == 0 && (
                          <ListGroup.Item className="non-active-list">
                            <div>
                              <span className="text-center message">
                                No lead available
                              </span>
                            </div>
                          </ListGroup.Item>
                        )}
                      </ScrollBar>
                    </TabPanel>
                  )}
                </TabContext>
                <Box pt={2} pb={1}>
                  <ToggleButtonGroup
                    color="primary"
                    exclusive
                    value={activeAllTabsIndex}
                    className={useStyling.buttonGroup}
                    onChange={(o, k) => {
                      if (k != null) {
                        setActiveAllTabsIndex(k);
                      }
                    }}
                  >
                    <MuiToggleButton
                      value={0}
                      className={classNames(
                        useStyling.tab,
                        useStyling.firstTab
                      )}
                    >
                      Active Chats
                    </MuiToggleButton>
                    <MuiToggleButton
                      value={1}
                      className={classNames(
                        useStyling.tab,
                        useStyling.secondTab
                      )}
                    >
                      All
                    </MuiToggleButton>
                  </ToggleButtonGroup>
                </Box>
                <TabContext value={activeAllTabsIndex}>
                  <TabPanel value={0} className={useStyling.tabPanel}>
                    <ScrollBar
                      horizontalScroll={false}
                      style={{
                        height: getChatsSectionHeight(
                          chats.activeChatList.length,
                          false
                        )
                      }}
                    >
                      {ChatsType.map(
                        type =>
                          type.key == 'ACT' && (
                            <ChatList
                              title={type.title}
                              chats={chats[type.chats]}
                              showActionBtns={type.showActionBtns}
                              onChatClickHandler={onChatClickHandler(
                                type.roomType
                              )}
                              onAccept={selectTranferedChat(1)}
                              onIgnore={selectTranferedChat(0)}
                              onReject={selectRejectChat}
                              eventKey={type.eventKey}
                              defaultActiveKey={type.defaultActiveKey}
                              roomType={type.roomType}
                            />
                          )
                      )}
                    </ScrollBar>
                  </TabPanel>
                  <TabPanel value={1} className={useStyling.tabPanel}>
                    <ScrollBar
                      horizontalScroll={false}
                      style={{
                        height: getChatsSectionHeight(
                          chats.allChatList.length,
                          false
                        )
                        // height: 'calc(100% - 102px)'
                        // maxHeight: '300px'
                      }}
                    >
                      {ChatsType.map(
                        type =>
                          type.key == 'ALL' && (
                            <ChatList
                              title={type.title}
                              chats={chats[type.chats]}
                              showActionBtns={type.showActionBtns}
                              onChatClickHandler={onChatClickHandler(
                                type.roomType
                              )}
                              onAccept={selectTranferedChat(1)}
                              onIgnore={selectTranferedChat(0)}
                              onReject={selectRejectChat}
                              eventKey={type.eventKey}
                              defaultActiveKey={type.defaultActiveKey}
                              roomType={type.roomType}
                            />
                          )
                      )}
                    </ScrollBar>
                  </TabPanel>
                </TabContext>
                {roles?.messaging?.showBottomNavigationSection && (
                  <div
                    className="messaging-chats bottom-sheet-container"
                    style={{
                      marginLeft: '-9px',
                      marginRight: '-8px'
                    }}
                  >
                    <BottomNavigationComponent
                      onChangeNavMenu={value => onChangeNavMenu(value)}
                      unSentLeadCount={chats[UnsentChatList.chats].length}
                    >
                      {selectedBottomMenu == 0 ? (
                        <UnsentLeads
                          title={UnsentChatList.title}
                          chats={chats[UnsentChatList.chats]}
                          showActionBtns={UnsentChatList.showActionBtns}
                          onChatClickHandler={onChatClickHandler(
                            UnsentChatList.roomType
                          )}
                          eventKey={UnsentChatList.eventKey}
                        />
                      ) : (
                        <History onChatClickHandler={historyChatClickHandler} />
                      )}
                    </BottomNavigationComponent>
                  </div>
                )}
              </div>
            )}
            {toggleChatDetail && selectedChatId && (
              <Col className="card-rounded chat-details">
                <ChatDetail
                  setToggleChatDetail={setToggleChatDetail}
                  setToggleActiveChats={setToggleActiveChats}
                  setToggleChatSuggestion={setToggleChatSuggestion}
                  setDefaultSuggestionTab={setDefaultSuggestionTab}
                  defaultMessageText={defaultMessageText}
                  notifiySound={reviceMsg}
                  _onSuccessMessageArrived={_onSuccessMessageArrived}
                  _onMessageArrived={_onMessageArrived}
                />
              </Col>
            )}
          </Row>
        </Card>
      </Col>
      <PreventChatOpeningModal
        showModal={openModal}
        onHide={onCloseModal}
        data={preventModalData}
      />
    </>
  );
};

export default Chats;
