import React from 'react';
import { Card } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
import Accordion from 'react-bootstrap/Accordion';
import Header from './Header';
import ChatListItem from './ChatListItem';
import './index.scss';
import ScrollBar from 'components/Scrollbars';
import { useSelector } from 'react-redux';
import { selectRoles } from 'features/roles/rolesSlice';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import InboxIcon from '@material-ui/icons/Inbox';
import { Enums } from 'helpers/dropdown-enums';

const ChatList = ({
  title,
  chats,
  showActionBtns = false,
  onChatClickHandler,
  onAccept,
  onIgnore,
  onReject,
  eventKey,
  defaultActiveKey,
  roomType
}) => {
  const {
    roles: { isOperator, isAgent }
  } = useSelector(selectRoles);

  return (
    <>
      <ListGroup className="chat-list h-100">
        {chats?.length > 0 &&
          chats.map(chat => (
            <ChatListItem
              isOperator={isOperator}
              isAgent={isAgent}
              key={chat.getId()}
              showActionBtns={showActionBtns}
              chat={chat}
              onChatClickHandler={onChatClickHandler}
              onAccept={onAccept}
              onIgnore={onIgnore}
              onReject={onReject}
              roomType={roomType}
              eventKey={eventKey} //to see which section chat will be showns incoming/active/all/shelved/unsentleads
            />
          ))}
        {chats?.length === 0 && (
          <ListGroup.Item className="non-active-list">
            <div>
              <span className="text-center message">No chat available</span>
            </div>
          </ListGroup.Item>
        )}
      </ListGroup>
    </>
  );
};

export default ChatList;
