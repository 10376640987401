import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import { useFormik } from 'formik';
import { businessOperation } from 'helpers/schemas/provisionSchemas/initialValues';
import { BusinessOperationSchemas } from 'helpers/schemas/provisionSchemas/schemas';
import SimpleLabelTextBox from 'helpers/ui-controls/ui-components/SimpleLabelTextbox';
import SectionHeader from 'helpers/ui-controls/ui-components/SectionHeader';
import ToggleToolTip from 'helpers/ui-controls/toggleToolTip';
import DaysAndHours from 'helpers/ui-controls/ui-components/DaysAndHours';
import {
  dateTimerFormat,
  mapServerValuesToInitialValues,
  ToastTemplate
} from 'helpers/generic.methods';
import { toast } from 'react-toastify';
import {
  UpdateProvision,
  updateProvisionToState
} from 'features/provisioning/ProvisioningSlice';
import { success, errors } from 'helpers/variables';
import TimeZoneField from 'helpers/ui-controls/TimeZoneField';
import { useDispatch } from 'react-redux';
import { UilExclamationCircle } from '@iconscout/react-unicons';

const BusinessOperations = ({ formData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [editable, setEditable] = useState(false);
  const [formValues, setFormValues] = useState(businessOperation);
  const [loading, setLoading] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [id, setProvisionId] = useState('');

  const ProvisionFormHandler = async data => {
    try {
      setLoading(true);
      data['officeHours'] = data['officeHours'].map(item => {
        let tempOfficeHours = item;
        if (item.endTime.includes('undefined')) {
          tempOfficeHours.endTime = '';
        }
        if (item.startTime.includes('undefined')) {
          tempOfficeHours.startTime = '';
        }
        return tempOfficeHours;
      });

      data['quickConnectHours'] = data['quickConnectHours'].map(item => {
        let tempOfficeHours = item;
        if (item.endTime.includes('undefined')) {
          tempOfficeHours.endTime = '';
        }
        if (item.startTime.includes('undefined')) {
          tempOfficeHours.startTime = '';
        }
        return tempOfficeHours;
      });

      let payload = {
        ...data,
        id: id
      };
      const response = await UpdateProvision(payload);
      if (response.data.success) {
        dispatch(updateProvisionToState(response?.data?.data));
        toast.success(success.saveSuccess('Onboarding'));
        setEditable(false);
        setLoading(false);
      } else {
        toast.error(response.data.error || errors.createError);
        setEditable(false);
        setLoading(false);
      }
    } catch (error) {
      toast.error(ToastTemplate.error(error || error.message));
      setLoading(false);
    }
  };

  const BusinessOperationFormIk = useFormik({
    enableReinitialize: true,
    initialValues: formValues,
    validationSchema: BusinessOperationSchemas,
    onSubmit: ProvisionFormHandler
  });

  useEffect(() => {
    setProvisionId(formData.id);
    mapServerValuesToInitialValues(formData, businessOperation)
      .then(data => {
        setFormValues({ ...businessOperation, ...data });
      })
      .catch(err => {
        throw new Error(err);
      });
  }, [formData]);

  const handleOnChange = (element, key, value) => {
    BusinessOperationFormIk.values[key] = value;
    BusinessOperationFormIk.handleChange(element);
  };

  const updateHours = (key, value) => {
    BusinessOperationFormIk.values[key] = value;
  };

  useEffect(() => {
    if (!Object.keys(BusinessOperationFormIk.errors).length) {
      setShowErrors(false);
    }
  }, [BusinessOperationFormIk.errors]);

  const handleClickSave = () => {
    if (Object.keys(BusinessOperationFormIk.errors).length) setShowErrors(true);
  };

  return (
    <Form
      className="form"
      onSubmit={BusinessOperationFormIk.handleSubmit}
      autoComplete="off"
    >
      <SectionHeader
        handleSubmit={handleClickSave}
        sectionHeading={t('company.provisioningDetails.heading3')}
        loading={loading}
        editable={editable}
        handleEditableState={setEditable}
      />
      <section>
        {showErrors ? (
          <div
            className="p-2"
            style={{
              background: 'var(--danger-4)',
              borderRadius: '5px',
              width: 'fit-content'
            }}
          >
            <p className="fz-14 text-danger fw-600 d-flex align-items-center">
              <UilExclamationCircle />
              &nbsp;
              {t('company.general.provisioningErrorMsg')}
            </p>
          </div>
        ) : null}
        <div className="section-body">
          <ul className="editable-fields-lists">
            <SimpleLabelTextBox
              heading={t('company.provisioningDetails.areaOfCoverage.label')}
              field="areaOfCoverage"
              dataProperty={BusinessOperationFormIk.values['areaOfCoverage']}
              titleText={t('company.provisioningDetails.areaOfCoverage.info')}
              editable={editable}
              handleChange={handleOnChange}
            />
            <SimpleLabelTextBox
              heading={t(
                'company.provisioningDetails.linkToPrivacyPolicy.label'
              )}
              field="linkToPrivacyPolicy"
              dataProperty={
                BusinessOperationFormIk.values['linkToPrivacyPolicy']
              }
              titleText={t(
                'company.provisioningDetails.linkToPrivacyPolicy.info'
              )}
              error={BusinessOperationFormIk.errors['linkToPrivacyPolicy']}
              editable={editable}
              handleChange={handleOnChange}
            />

            <SimpleLabelTextBox
              heading={t(
                'company.provisioningDetails.scriptTitlePosition.label'
              )}
              field="scriptTitlePosition"
              dataProperty={
                BusinessOperationFormIk.values['scriptTitlePosition']
              }
              titleText={t(
                'company.provisioningDetails.scriptTitlePosition.info'
              )}
              editable={editable}
              handleChange={handleOnChange}
            />
            <ToggleToolTip
              heading={t('company.provisioningDetails.hipaa.label')}
              toolTipFor="hipaa"
              flag={BusinessOperationFormIk.values?.hipaa}
              titleText={t('company.provisioningDetails.hipaa.info')}
              editable={editable}
              handleChange={handleOnChange}
              field="hipaa"
            />
            <SimpleLabelTextBox
              heading={t('company.provisioningDetails.languages.label')}
              field="languages"
              dataProperty={BusinessOperationFormIk.values['languages']}
              titleText={t('company.provisioningDetails.languages.info')}
              editable={editable}
              handleChange={handleOnChange}
            />
            <ToggleToolTip
              heading={t('company.provisioningDetails.callConnect.label')}
              toolTipFor="callConnect"
              flag={BusinessOperationFormIk.values?.callConnect}
              titleText={t('company.provisioningDetails.callConnect.info')}
              editable={editable}
              handleChange={handleOnChange}
              field="callConnect"
            />
            {BusinessOperationFormIk.values?.callConnect ? (
              <>
                <ToggleToolTip
                  heading={t(
                    'company.provisioningDetails.callConnectTranscript.label'
                  )}
                  toolTipFor="callConnectTranscript"
                  flag={BusinessOperationFormIk.values?.quickConnectTranscript}
                  titleText={t(
                    'company.provisioningDetails.callConnectTranscript.info'
                  )}
                  editable={editable}
                  handleChange={handleOnChange}
                  field="quickConnectTranscript"
                />
                <SimpleLabelTextBox
                  heading={t(
                    'company.provisioningDetails.callConnectPhone.label'
                  )}
                  field="callConnectPhone"
                  dataProperty={
                    BusinessOperationFormIk.values['callConnectPhone']
                  }
                  titleText={t(
                    'company.provisioningDetails.callConnectPhone.info'
                  )}
                  error={BusinessOperationFormIk.errors['callConnectPhone']}
                  editable={editable}
                  handleChange={handleOnChange}
                />
                <SimpleLabelTextBox
                  heading={t(
                    'company.provisioningDetails.callConnectHours.label'
                  )}
                  field="callConnectHours"
                  dataProperty={
                    BusinessOperationFormIk.values['callConnectHours']
                  }
                  titleText={t(
                    'company.provisioningDetails.callConnectHours.info'
                  )}
                  editable={editable}
                  handleChange={handleOnChange}
                />
              </>
            ) : null}
            <SimpleLabelTextBox
              heading={t('company.provisioningDetails.businessHours.label')}
              field="businessHours"
              dataProperty={BusinessOperationFormIk.values['businessHours']}
              titleText={t('company.provisioningDetails.businessHours.info')}
              editable={editable}
              handleChange={handleOnChange}
            />

            <SimpleLabelTextBox
              heading={t(
                'company.provisioningDetails.insuranceCoverageResponse.label'
              )}
              field="insuranceCoverageResponse"
              dataProperty={
                BusinessOperationFormIk.values['insuranceCoverageResponse']
              }
              titleText={t(
                'company.provisioningDetails.insuranceCoverageResponse.info'
              )}
              editable={editable}
              handleChange={handleOnChange}
            />
            <SimpleLabelTextBox
              heading={t('company.provisioningDetails.createdOn.label')}
              field="createdOn"
              dataProperty={dateTimerFormat(
                BusinessOperationFormIk.values['createdOn'] || ''
              )}
              titleText={t('company.provisioningDetails.createdOn.info')}
              handleChange={handleOnChange}
            />
            <SimpleLabelTextBox
              heading={t('company.provisioningDetails.modifiedOn.label')}
              field="modifiedOn"
              dataProperty={dateTimerFormat(
                BusinessOperationFormIk.values['modifiedOn'] || ''
              )}
              titleText={t('company.provisioningDetails.modifiedOn.info')}
              handleChange={handleOnChange}
            />
            <SimpleLabelTextBox
              heading={t(
                'company.provisioningDetails.specialInstructions.label'
              )}
              field="specialInstructions"
              dataProperty={
                BusinessOperationFormIk.values['specialInstructions']
              }
              titleText={t(
                'company.provisioningDetails.specialInstructions.info'
              )}
              editable={editable}
              handleChange={handleOnChange}
            />
            <TimeZoneField
              heading={t(
                'company.provisioningDetails.officeHoursTimezone.label'
              )}
              field="officeHoursTimezone"
              dataProperty={
                BusinessOperationFormIk.values['officeHoursTimezone']
              }
              editable={editable}
              handleChange={handleOnChange}
            />
          </ul>

          <section>
            <header className="section-head d-flex align-items-center justify-content-between">
              <h5 className="fw-600 mb-0">
                {t('company.provisioningDetails.officeHours.label')}
              </h5>
            </header>
            <div className="section-body">
              <DaysAndHours
                dataArray={BusinessOperationFormIk.values['officeHours']}
                field="officeHours"
                titleText={t('company.provisioningDetails.officeHours.info')}
                handleHoursChange={updateHours}
                disabled={!editable}
              ></DaysAndHours>
            </div>
          </section>

          <section className="mt-5">
            <header className="section-head d-flex align-items-center justify-content-between">
              <h5 className="fw-600 mb-0">
                {t('company.provisioningDetails.quickConnectHours.label')}
              </h5>
            </header>
            <div className="section-body">
              <DaysAndHours
                dataArray={BusinessOperationFormIk.values['quickConnectHours']}
                field="quickConnectHours"
                titleText={t(
                  'company.provisioningDetails.quickConnectHours.info'
                )}
                handleHoursChange={updateHours}
                disabled={!editable}
              ></DaysAndHours>
            </div>
          </section>
        </div>
      </section>
    </Form>
  );
};

export default BusinessOperations;
