import React, { useState, useEffect } from 'react';
import DisplayKey from 'helpers/ui-controls/DisplayKey';
import { Link } from 'react-router-dom';
const ChatHeader = ({ chat, companyId }) => {
  const [chatData, setChatData] = useState({});

  useEffect(() => {
    // mapServerValuesToInitialValues(company, companyHeader)
    //   .then(data => {
    //     setCompanyHeaderObj({
    //       ...companyHeaderObj,
    //       ...data
    //     });
    //   })
    //   .catch(err => {
    //     throw new Error(err);
    //   });
    setChatData(chat);
  }, [chat]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="page-header">
        <div className="page-header-bottom d-flex align-items-end justify-content-between">
          <h1 className="main-head text-primary-2 mb-0">
            {chatData.companyName}
          </h1>
          <div className="align-self-start">
            <DisplayKey displayKey={companyId} />

            {!!chatData.leadId && (
              <Link
                to={`leads?id=${chatData.leadId}`}
                className="btn-md btn-flex ml-6r introJs-companies-tab-view-0 btn-float-right btn btn-primary"
              >
                View lead
              </Link>
            )}
            {chatData.companyId && (
              <Link
                to={`companies?id=${chatData.companyId}`}
                className="btn-md btn-flex ml-6r introJs-companies-tab-view-0 btn-float-right btn btn-primary"
              >
                View Company
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatHeader;
