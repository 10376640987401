import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import { useFormik } from 'formik';
import SimpleLabelTextBox from 'helpers/ui-controls/ui-components/SimpleLabelTextbox';
import SectionHeader from 'helpers/ui-controls/ui-components/SectionHeader';
import DropDownEditable from 'helpers/ui-controls/DropDownEditable';
import dropdownEnums, { Enums } from 'helpers/dropdown-enums';
import { basicDetails } from 'helpers/schemas/provisionSchemas/initialValues';
import { basicDetailSchemas } from 'helpers/schemas/provisionSchemas/schemas';
import {
  mapServerValuesToInitialValues,
  ToastTemplate
} from 'helpers/generic.methods';
import {
  getBusinessTypes,
  getBusinessSubCategories
} from 'features/analytics/analyticSlice';
import { getCookie, setCookie } from 'helpers/generic.methods';
import { toast } from 'react-toastify';
import {
  UpdateProvision,
  updateProvisionToState
} from 'features/provisioning/ProvisioningSlice';
import { getAllCompanies } from 'features/companies/companiesSlice';
import { errors, success } from 'helpers/variables';
import CustomAutoCompleteFilter from 'pages/portal/dashboard/filters/custom-auto-complete-filter';
import { CrmData } from 'app/crmData';
import { useDispatch, useSelector } from 'react-redux';
import { selectRoles } from 'features/roles/rolesSlice';
import { UilExclamationCircle } from '@iconscout/react-unicons';

const BasicDetails = ({ formData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState({});
  const [editable, setEditable] = useState(false);
  const [businessTypesOptions, setBusinessTypesOptions] = useState([]);
  const [selectedBusinessTypeOption, setSelectedBusinessTypeOption] =
    useState(null);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [provisionId, setProvisionId] = useState('');
  const [companiesOption, setCompaniesOption] = useState([]);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [selectedCompanyKey, setCompanyKey] = useState([]);
  const { roles } = useSelector(selectRoles);
  const [isCompanykeyEditbale, setCompanyKeyEditable] = useState(true);
  const [showErrors, setShowErrors] = useState(false);

  const ProvisionFormHandler = async data => {
    data.secondaryCategories = selectedSubCategories.map(val => val.value);
    try {
      setLoading(true);
      let payload = {
        ...data,
        id: provisionId
      };
      const response = await UpdateProvision(payload);
      if (response.data.success) {
        dispatch(updateProvisionToState(response?.data?.data));
        toast.success(success.saveSuccess('Onboarding'));
        setEditable(false);
        setLoading(false);
      } else {
        toast.error(response.data.error || errors.createError);
        setEditable(false);
        setLoading(false);
      }
    } catch (error) {
      toast.error(ToastTemplate.error(error || error.message));
      setLoading(false);
    }
  };

  const BasicDetailsFormIk = useFormik({
    enableReinitialize: true,
    initialValues: formValues,
    validationSchema: basicDetailSchemas,
    onSubmit: ProvisionFormHandler
  });

  useEffect(() => {
    setProvisionId(formData.id);
    mapServerValuesToInitialValues(formData, basicDetails)
      .then(data => {
        if (formData.provisionedCompanyId) {
          let selectedCompanyKey = {
            key: formData.provisionedCompanyKey,
            value: formData.provisionedCompanyKey,
            id: formData.provisionedCompanyId
          };
          setCompanyKey(selectedCompanyKey);
          setCompanyKeyEditable(false);
        }
        setFormValues({ ...basicDetails, ...data });
      })
      .catch(err => {
        throw new Error(err);
      });
  }, [formData]);

  useEffect(() => {
    if (businessTypesOptions.length && formData.primaryCategoryId) {
      findBusinessOptionInArray(formData.primaryCategoryId);
    }
  }, [businessTypesOptions, formData.primaryCategoryId]);

  useEffect(() => {
    if (getCookie('analyticsBusinessOptions')) {
      let data = generateBusinessOptions(
        JSON.parse(getCookie('analyticsBusinessOptions'))
      );
      let temp = [{ name: '- None -', id: 0, value: 0 }];
      let combinedWithDefaultOption = [...temp, ...data];
      setBusinessTypesOptions(combinedWithDefaultOption);
    } else {
      getBussinesTypes();
    }
  }, []);

  const findBusinessOptionInArray = id => {
    let selectedBusinessTypeOption = businessTypesOptions.find(
      val => val.value == id
    );
    setSelectedBusinessTypeOption(selectedBusinessTypeOption);
    if (selectedBusinessTypeOption && selectedBusinessTypeOption.value !== 0) {
      getSecondaryCategories(selectedBusinessTypeOption.id);
    }
  };

  useEffect(() => {
    if (selectedBusinessTypeOption && selectedBusinessTypeOption.value !== 0) {
      getSecondaryCategories(selectedBusinessTypeOption.rlid);
    }
  }, [selectedBusinessTypeOption]);

  const generateBusinessOptions = data => {
    return [...data].map(dt => ({
      name: `${dt.name}`,
      value: dt.id,
      id: dt.rlid
    }));
  };

  const generateCompanyKeyOptions = data => {
    return [...data].map(dt => ({
      key: `${dt.companyKey}`,
      value: dt.companyKey,
      id: dt.id
    }));
  };

  const getBussinesTypes = () => {
    try {
      getBusinessTypes().then(response => {
        if (response && response.data) {
          let data = generateBusinessOptions(response.data.data);
          let temp = [{ name: '- None -', id: 0, value: 0 }];
          let combinedWithDefaultOption = [...temp, ...data];
          setBusinessTypesOptions(combinedWithDefaultOption);
          setCookie(
            'analyticsBusinessOptions',
            JSON.stringify(response.data.data),
            1
          );
        }
      });
    } catch (error) {
      toast.error(ToastTemplate.error(error || error.message));
    }
  };

  const handleCompanyKeyTextChange = text => {
    setLoadingOptions(true);
    try {
      let payload = {
        start: 0,
        limit: 10,
        filter: JSON.stringify([
          {
            propertyName: 'companyKey',
            propertyValue1: `${text}%`,
            propertyValue2: '',
            propertyValue3: [],
            filterDataType: 2,
            comparison: 2
          }
        ]),
        dir: 'ASC',
        sort: 'companyKey'
      };
      getAllCompanies(payload).then(response => {
        if (response && response.data) {
          let options = generateCompanyKeyOptions(response.data.data);
          setCompaniesOption(options);
          setLoadingOptions(false);
        }
      });
    } catch (error) {
      setLoadingOptions(false);
      toast.error(ToastTemplate.error(error || error.message));
    }
  };

  const handleChangeBusinessCategory = (element, key, value) => {
    handleOnChange(element, key, parseInt(value));
    findBusinessOptionInArray(value);
  };

  const getSecondaryCategories = id => {
    id = parseInt(id);
    if (id >= 0) {
      let payload = { rlid: id };
      getBusinessSubCategories(payload).then(response => {
        if (response.data.success) {
          let subCategories = [];
          let results = response.data.data;
          results.forEach(element => {
            subCategories.push({
              value: element.id,
              text: element.name,
              name: element.name,
              key: element.name
            });
          });
          setSubCategories(subCategories);
          setSelectedSubCategoriesInFormat(subCategories);
        }
      });
    }
  };

  const setSelectedSubCategoriesInFormat = allSubCategories => {
    let secondaryCategories = [...formData.secondaryCategories];
    let temp = allSubCategories.filter(val => {
      return secondaryCategories.includes(val.value);
    });
    setSelectedSubCategories(temp);
  };

  const handleOnChangeCRM = (element, key, value) => {
    let selectedCRM = CrmData.find(crm => crm.value == value);
    BasicDetailsFormIk.values['crmId'] = parseInt(selectedCRM.value);
    BasicDetailsFormIk.values['crmName'] = selectedCRM.name;
  };

  const handleOnChange = (element, key, value) => {
    BasicDetailsFormIk.setValues({
      ...BasicDetailsFormIk.values,
      [key]: value
    });
    BasicDetailsFormIk.handleChange(element);
  };

  const handleOnSelectCompanyKey = selectedCompanyKey => {
    setCompanyKey(selectedCompanyKey || []);
    BasicDetailsFormIk.setValues({
      ...BasicDetailsFormIk.values,
      provisionedCompanyId: selectedCompanyKey
        ? parseInt(selectedCompanyKey.id)
        : 0
    });
  };

  useEffect(() => {
    if (!Object.keys(BasicDetailsFormIk.errors).length) {
      setShowErrors(false);
    }
  }, [BasicDetailsFormIk.errors]);

  const handleClickSave = () => {
    if (Object.keys(BasicDetailsFormIk.errors).length) setShowErrors(true);
  };

  return (
    <Form
      className="form"
      onSubmit={BasicDetailsFormIk.handleSubmit}
      autoComplete="off"
    >
      <SectionHeader
        handleSubmit={handleClickSave}
        sectionHeading={t('company.provisioningDetails.heading5')}
        loading={loading}
        editable={editable}
        handleEditableState={setEditable}
      />
      <section>
        <div className="section-body">
          {showErrors ? (
            <div
              className="p-2"
              style={{
                background: 'var(--danger-4)',
                borderRadius: '5px',
                width: 'fit-content'
              }}
            >
              <p className="fz-14 text-danger fw-600 d-flex align-items-center">
                <UilExclamationCircle />
                &nbsp;
                {t('company.general.provisioningErrorMsg')}
              </p>
            </div>
          ) : null}
          <ul className="editable-fields-lists">
            <SimpleLabelTextBox
              heading={t('company.provisioningDetails.companyName.label')}
              field="companyName"
              dataProperty={BasicDetailsFormIk.values['companyName']}
              titleText={t('company.provisioningDetails.companyName.info')}
              error={BasicDetailsFormIk.errors['companyName']}
              editable={editable}
              handleChange={handleOnChange}
            />
            <SimpleLabelTextBox
              heading={t('company.provisioningDetails.companyKeyRequest.label')}
              field="companyKeyRequest"
              dataProperty={BasicDetailsFormIk.values['companyKeyRequest']}
              titleText={t(
                'company.provisioningDetails.companyKeyRequest.info'
              )}
              editable={editable}
              handleChange={handleOnChange}
            />
            <DropDownEditable
              heading={t('company.provisioningDetails.status.label')}
              field="status"
              dataProperty={BasicDetailsFormIk.values['status']}
              editable={editable}
              handleChange={handleOnChange}
              titleText={t('company.provisioningDetails.status.info')}
              materialFields
              options={dropdownEnums.enum_Provision}
            />
            <SimpleLabelTextBox
              heading={t(
                'company.provisioningDetails.partnerRepresentativeName.label'
              )}
              field="accountManager"
              dataProperty={BasicDetailsFormIk.values['accountManager']}
              titleText={t(
                'company.provisioningDetails.partnerRepresentativeName.info'
              )}
              error={BasicDetailsFormIk.errors['accountManager']}
              editable={editable}
              handleChange={handleOnChange}
            />
            <SimpleLabelTextBox
              heading={t(
                'company.provisioningDetails.representativeEmail.label'
              )}
              field="accountManagerEmail"
              dataProperty={BasicDetailsFormIk.values['accountManagerEmail']}
              titleText={t(
                'company.provisioningDetails.representativeEmail.info'
              )}
              error={BasicDetailsFormIk.errors['accountManagerEmail']}
              editable={editable}
              handleChange={handleOnChange}
            />
            <SimpleLabelTextBox
              heading={t('company.provisioningDetails.leadEmails.label')}
              field="leadEmails"
              dataProperty={BasicDetailsFormIk.values['leadEmails']}
              titleText={t('company.provisioningDetails.leadEmails.info')}
              error={BasicDetailsFormIk.errors['leadEmails']}
              editable={editable}
              handleChange={handleOnChange}
            />
            {roles.onBoarding.specialFields.billingEmailAddress.view && (
              <SimpleLabelTextBox
                heading={t(
                  'company.provisioningDetails.billingEmailAddress.label'
                )}
                field="billingEmailAddress"
                dataProperty={BasicDetailsFormIk.values['billingEmailAddress']}
                titleText={t(
                  'company.provisioningDetails.billingEmailAddress.info'
                )}
                error={BasicDetailsFormIk.errors['billingEmailAddress']}
                editable={
                  editable &&
                  roles.onBoarding.specialFields.billingEmailAddress.edit
                }
                handleChange={handleOnChange}
              />
            )}
            <SimpleLabelTextBox
              heading={t('company.provisioningDetails.website.label')}
              field="url"
              dataProperty={BasicDetailsFormIk.values['url']}
              titleText={t('company.provisioningDetails.website.info')}
              editable={editable}
              handleChange={handleOnChange}
              error={BasicDetailsFormIk.errors['url']}
            />
            <SimpleLabelTextBox
              heading={t(
                'company.provisioningDetails.googleAnalyticsCode.label'
              )}
              field="googleAnalyticsCode"
              dataProperty={BasicDetailsFormIk.values['googleAnalyticsCode']}
              titleText={t(
                'company.provisioningDetails.googleAnalyticsCode.info'
              )}
              editable={editable}
              handleChange={handleOnChange}
            />
            <DropDownEditable
              heading={t('company.provisioningDetails.crm.label')}
              field="crmId"
              dataProperty={BasicDetailsFormIk.values['crmId']}
              handleChange={handleOnChangeCRM}
              materialFields
              editable={editable}
              options={CrmData}
            />
            <SimpleLabelTextBox
              heading={t('company.provisioningDetails.crmEmail.label')}
              field="crmEmailAddress"
              dataProperty={BasicDetailsFormIk.values['crmEmailAddress']}
              titleText={t('company.provisioningDetails.crmEmail.info')}
              editable={editable}
              handleChange={handleOnChange}
              error={BasicDetailsFormIk.errors['crmEmailAddress']}
            />
            <SimpleLabelTextBox
              heading={t('company.provisioningDetails.setupInstructions.label')}
              field="notes"
              dataProperty={BasicDetailsFormIk.values['notes']}
              titleText={t(
                'company.provisioningDetails.setupInstructions.info'
              )}
              editable={editable}
              handleChange={handleOnChange}
            />

            <CustomAutoCompleteFilter
              title={t('company.analytics.companyKey')}
              editable={editable && isCompanykeyEditbale}
              setFunction={handleOnSelectCompanyKey}
              handleTextChange={handleCompanyKeyTextChange}
              options={companiesOption}
              loading={loadingOptions}
              selected={selectedCompanyKey}
              liStyles={{ alignSelf: 'end' }}
              variant="standard"
              isFilterWrapper={false}
              isMultiSelectEnable={false}
              name="provisionedCompanyId"
              error={BasicDetailsFormIk.errors['provisionedCompanyId']}
            />

            <DropDownEditable
              heading={t('company.provisioningDetails.businessCategory.label')}
              field="primaryCategoryId"
              dataProperty={BasicDetailsFormIk.values['primaryCategoryId']}
              editable={editable}
              handleChange={handleChangeBusinessCategory}
              titleText={t('company.provisioningDetails.businessCategory.info')}
              materialFields
              options={businessTypesOptions}
              error={BasicDetailsFormIk.errors['primaryCategoryId']}
            />

            <CustomAutoCompleteFilter
              editable={editable}
              liStyles={{ alignSelf: 'baseline' }}
              title={t('company.provisioningDetails.subCategories.label')}
              setFunction={setSelectedSubCategories}
              options={subCategories}
              selected={selectedSubCategories}
              isFilterWrapper={false}
              variant="standard"
            />
          </ul>
        </div>
      </section>
    </Form>
  );
};
export default BasicDetails;
