import React, { useState, useEffect, useRef } from 'react';
import * as _ from 'lodash.debounce';
import { Card } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import {
  getSelectedChat,
  getSelectedChatById,
  saveInputMessage,
  getUnsentLeads,
  getUnsentLeadListAsync,
  setVisitorStatus,
  getSelectedVisitorStatus,
  setChatUpdatedInfoDataInChats,
  setChatStatusToClientHandlingForOptInLeads,
  sortChatsByActivity
} from 'features/messagingChats/messagingChats';
import {
  setLeadFormUserCredentials,
  getLeadFormUserCredentials
} from 'features/lead/leadSlice';
import Initiate_Chat from 'app/communication/services/chat/initiate_chat';
import { chatCallbacks } from 'app/communication/services/chat/callbacks/chat_default_callbacks';

import PubhlishToChat, {
  PublishTypingIndicatorToChat
} from 'app/communication/services/chat/pub_sub_chat';
import { Enums } from 'helpers/dropdown-enums';
import TypingIndicator from '../TypingIndicator';
import { selectLogin } from 'features/auth/authSlice';
import './ChatDetail.scss';

import { getCountryCode, showTypingIndicatorInChat } from '../Helper';
import BottomSection from './BottomSection';
import AllMessages from './AllMessages';
import ChatDetailHeader from './ChatDetaiHeader';
import { logIt } from 'helpers/logger';
import useViewport from 'helpers/viewport-hook';

const ChatDetail = React.memo(
  ({
    setToggleActiveChats,
    setToggleChatDetail,
    setToggleChatSuggestion,
    setDefaultSuggestionTab,
    defaultMessageText,
    notifiySound,
    _onSuccessMessageArrived,
    _onMessageArrived
  }) => {
    const screen = useViewport();
    const [sendingText, setSendingText] = useState(false);
    const dispatch = useDispatch();
    const selectedChat = useSelector(getSelectedChat);
    const chatId = selectedChat.getId();
    const chatById = useSelector(getSelectedChatById(chatId));
    const unsentLeadList = useSelector(getUnsentLeads);
    const [chatStatus, setChatStatus] = useState(selectedChat?.data?.status);
    const leadFormUserCredentials = useSelector(getLeadFormUserCredentials);

    logIt('log', 'chatById::', chatById);
    const user = useSelector(selectLogin);

    let getVisitorStatus = useSelector(getSelectedVisitorStatus);
    let visitorStatus = getVisitorStatus(selectedChat?.getId());
    const [isChatEnded, setIsChatEnded] = useState(false);
    useEffect(() => {
      // only runs first time when component mount
      if (chatById?.messages?.length) {
        chatById?.messages.forEach(msg => {
          if (msg.userId === Enums.ChatMessageType.Visitor) {
            dispatch(setLeadFormUserCredentials(msg.text));
            // dispatch(setLeadFormReason(msg.text));
            // commented out this for testing from _onMessageArrived method
            // if (!selectedChat?.data?.isLead && !selectedChat?.data?.fetchLeads) {
            //   checkIfEmailOrNumExistInMsg(msg.text);
            // }
          }
        });
      }

      // fetch unsent leads if started chat is initiated by sms static and does not exist unsentLead list
      // OR
      // fetch unsent leads if started message is email or number from visitor and isLead is true
      fetchUnsentLead();

      // get country code from visitor location
      getCountryCode(selectedChat?.getLocation());
    }, [chatId]);

    useEffect(() => {
      if (chatById?.messages.length) {
        let lastMsg = chatById.messages[chatById.messages.length - 1];
        if (selectedChat.data.isOptInConsentGiven) {
          setIsChatEnded(false);
        } else if (
          lastMsg &&
          lastMsg.userId === Enums.ChatMessageType.System &&
          (lastMsg.isChatEnded ||
            Enums.ChatEndedMessages.includes(lastMsg.text))
        ) {
          setIsChatEnded(true);
        } else {
          setIsChatEnded(false);
        }

        handleVisitorStatus(lastMsg);
      }
    }, [selectedChat, chatById]);

    useEffect(() => {
      setChatStatus(selectedChat?.data?.status);
      // subscribe chat only if selected chat id changed
      let callbacks = chatCallbacks({
        _onMessageArrived: _onMessageArrived,
        _onSuccessMessageArrived: _onSuccessMessageArrived,
        chatId
      });
      Initiate_Chat({
        chatId: chatId,
        companyId: selectedChat.getCompanyId(),
        callbacks,
        forceSubscribe: true
      });
    }, [chatId]);

    useEffect(() => {
      // set visitorIfnoName if question type is Text and auto-send-script is disabled
      handleVisitorInfoNameWithTextName(chatById.messages);
    }, [chatById.messages.length]);

    useEffect(() => {
      // save visitorInfo into updatedInfo if exist in room data
      let obj = {};
      let newInfo = {};
      let savedInfo = {};

      if (chatById?.chatInfo?.data) {
        let { visitorInfoName, visitorInfoEmail, visitorInfoPhone } =
          chatById?.chatInfo?.data;
        newInfo = { visitorInfoName, visitorInfoEmail, visitorInfoPhone };
      }

      if (chatById?.updatedInfo) {
        let { visitorInfoName, visitorInfoEmail, visitorInfoPhone } =
          chatById?.updatedInfo;
        savedInfo = { visitorInfoName, visitorInfoEmail, visitorInfoPhone };
      }

      for (let key in newInfo) {
        if (newInfo[key] && !savedInfo[key]) {
          obj[key] = newInfo[key];
        } else {
          obj[key] = savedInfo[key];
        }
      }

      if (obj && Object.keys(obj).length) {
        dispatch(
          setChatUpdatedInfoDataInChats({
            chatId: chatId,
            updatedInfoData: obj
          })
        );
      }
    }, [
      chatById?.chatInfo?.data?.visitorInfoName,
      chatById?.chatInfo?.data?.visitorInfoEmail,
      chatById?.chatInfo?.data?.visitorInfoPhone
    ]);

    // fetch unsent leads if selected chat is initiated by sms static and have visitorInfoPhone
    // and added phone number in lead form
    // OR
    // fetch unsent leads if started message is email or number from visitor and isLead is true
    const fetchUnsentLead = () => {
      if (
        (selectedChat?.data?.initiatedBy == Enums.InitiatedBy['Sms Static'] &&
          selectedChat?.data?.visitorInfoPhone) ||
        selectedChat?.data?.isLead
      ) {
        let index = unsentLeadList.findIndex(
          item => item.chatId === selectedChat.getId()
        );

        // add phone number in lead form field
        if (selectedChat?.data?.visitorInfoPhone) {
          dispatch(
            setLeadFormUserCredentials({
              phoneNumber: selectedChat?.data?.visitorInfoPhone
            })
          );
        }

        // fetch unsentlead if selected chat not in unsentlead list
        if (index === -1) {
          setTimeout(() => {
            dispatch(getUnsentLeadListAsync());
          }, 2000);
        }
      }
    };

    // Set visitor status to active/inactive globally
    const handleVisitorStatus = lastMsg => {
      if (lastMsg.userId <= 0) {
        if (
          (lastMsg &&
            lastMsg.userId === Enums.ChatMessageType.System &&
            (lastMsg.isChatEnded ||
              Enums.ChatEndedMessages.includes(lastMsg.text))) ||
          (lastMsg.userId === Enums.ChatMessageType.Bot &&
            Enums.ChatLeftMsgs.includes(lastMsg.text))
        ) {
          dispatch(setVisitorStatus({ status: false, chatId }));
        } else {
          // if visitor send msg after 'visitor left message' came from Bot, then
          // set status to true again
          if (
            !visitorStatus &&
            lastMsg &&
            lastMsg.userId === Enums.ChatMessageType.Visitor
          ) {
            dispatch(setVisitorStatus({ status: true, chatId }));
          }
        }
      }
    };

    // set second message text to visitorInfoName in chat object when questionType is Text and auto-send-script is disabled
    const handleVisitorInfoNameWithTextName = msgs => {
      if (!chatById?.chatInfo?.data?.visitorInfoName) {
        msgs.map((msg, index) => {
          if (
            msg.questionType === Enums.ChatQuestionType.Text &&
            msg.text.includes('name')
          ) {
            let nextMsg = msgs[index + 1];
            if (nextMsg && nextMsg.userId === Enums.ChatMessageType.Visitor) {
              dispatch(
                setChatUpdatedInfoDataInChats({
                  chatId: chatId,
                  updatedInfoData: { visitorInfoName: nextMsg.text }
                })
              );
            }
          }
        });
      }
    };

    const onBackButtonHandler = () => {
      setToggleChatDetail(false);
      setToggleActiveChats(false);
    };
    // const onInfoButtonSuggestionHandler = () => {
    //   setToggleChatDetail(false);
    //   setToggleChatSuggestion(true);
    //   setDefaultSuggestionTab(true);
    // };

    // const onSuggestionButtonHandler = () => {
    //   setToggleChatDetail(false);
    //   setToggleChatSuggestion(true);
    //   setDefaultSuggestionTab(false);
    // };

    useEffect(() => {
      if (isChatEnded) {
        logIt(
          'Warning',
          'stop sending typing inidicator value when chat is ended'
        );
        setDebounced.current.cancel();
      }
    }, [isChatEnded]);

    const _publishTypingIndicator = chat => {
      // publish typing true first
      PublishTypingIndicatorToChat({
        selectedChat: chat,
        typing: true
      });

      // publish typing false after 3secs
      setTimeout(() => {
        PublishTypingIndicatorToChat({
          selectedChat: chat,
          typing: false
        });
      }, 3000);
    };
    const setDebounced = useRef(_(_publishTypingIndicator, 7000)); // set delay of 7secs on input change

    const handleInputChange = e => {
      let text = e.target.value;
      let id = selectedChat.getId();
      setDebounced.current(selectedChat);
      dispatch(saveInputMessage({ id, text }));
    };

    // add emoji in input field
    const onEmojiClick = (event, emojiObject) => {
      let inputElem = document.getElementById('msg-input');
      let cursorPosition = inputElem.selectionStart;
      let inputValue = inputElem.value;
      let newValue =
        inputValue.substring(0, cursorPosition) +
        emojiObject.emoji +
        inputValue.substring(cursorPosition, inputValue.length);
      // let text = chatById.inputMessage + emojiObject.emoji;
      let text = newValue;
      inputElem.focus();
      setTimeout(() => {
        inputElem.setSelectionRange(cursorPosition + 2, cursorPosition + 2);
      }, 0);

      let id = chatId;
      dispatch(saveInputMessage({ id, text }));
    };

    const handleKeyPress = event => {
      // select all text and replace with new text
      // need first to empty textarea to remove QTDetails if exist
      let selectedTextLength = window.getSelection()?.toString()?.length;
      let inputMsgLength = chatById?.inputMessage?.length;
      if (
        selectedTextLength &&
        selectedTextLength === inputMsgLength &&
        event.key.length === 1
      ) {
        let id = selectedChat.getId();
        dispatch(saveInputMessage({ id, text: '' }));
      }
      if (event.key === 'Enter' && !event.shiftKey) {
        console.log('enter press here! ');
        event.preventDefault();
        if (!sendingText) {
          sendText(event);
        }
        return false;
      }
    };

    const sendText = options => {
      if (chatById?.inputMessage?.trim()) {
        let text = '';
        let id = chatId;
        dispatch(saveInputMessage({ id, text }));
        setSendingText(true);
        let scriptDetails = chatById?.QTDetails;
        let QTDetails = {};
        if (scriptDetails) {
          QTDetails = {
            //Set these properties here, to pass these details in ChatLog table
            scriptAnswerId: scriptDetails?.id,
            questionType: scriptDetails?.questionType,
            options: scriptDetails?.options,
            visitorResponseType: scriptDetails?.visitorResponseType,
            threeLetterISOLanguageName: 'eng', //TODO: suggestion language here
            //CHAT-9320 set the visitor resposne types
            visitorResponseTypes: scriptDetails?.visitorResponseTypes,
            questionTypeSettings: scriptDetails?.questionTypeSettings
          };
        }
        PubhlishToChat({
          text: chatById.inputMessage,
          selectedChat: selectedChat,
          QTDetails,
          onMessageArrived: args => {
            // TODO: other ui stuffs
            setSendingText(false);
            if (
              selectedChat.data.isOptInConsentGiven &&
              selectedChat.data.status == Enums.ChatStatus.Inactive
            ) {
              dispatch(
                setChatStatusToClientHandlingForOptInLeads(selectedChat.getId())
              );
              setChatStatus(Enums.ChatStatus.ClientHandling);
            }
          }
        });
      }
    };

    return (
      <>
        <Card border="0" className={`chat-details-card`}>
          <ChatDetailHeader
            onBackButtonHandler={onBackButtonHandler}
            selectedChat={selectedChat}
            isChatEnded={isChatEnded}
            setToggleChatSuggestion={setToggleChatSuggestion}
            setToggleChatDetail={setToggleChatDetail}
            setToggleActiveChats={setToggleActiveChats}
            isMobile={screen?.xs}
          />
          {/* <div className="transferred-chat">
            Chat is transferred by an agent
          </div> */}
          <div
            id="chat"
            className="chat_box_wrapper chat_box_small chat_box_active"
            style={{
              opacity: 1,
              display: 'block',
              transform: 'translateX(0px)'
            }}
          >
            <AllMessages
              selectedChat={selectedChat}
              chatById={chatById}
              isChatEnded={isChatEnded}
              userImage={user?.profile?.ImageUrl}
              inputMessage={chatById?.inputMessage}
              chatStatus={chatStatus}
              isConsentGiven={selectedChat?.data?.isOptInConsentGiven}
            />
            <div
              className="col-12 ml-3 text-right position-absolute"
              style={{ bottom: screen.xs ? '60px' : '130px', right: '25px' }}
            >
              {showTypingIndicatorInChat(chatById?.chatInfo?.data) ? (
                <TypingIndicator typing={chatById?.isTyping} size={10} />
              ) : null}
            </div>
            <BottomSection
              isChatEnded={isChatEnded}
              onEmojiClick={onEmojiClick}
              inputMessage={chatById?.inputMessage}
              handleInputChange={handleInputChange}
              handleKeyPress={handleKeyPress}
              sendText={sendText}
              selectedChat={selectedChat}
              chatStatus={chatStatus}
            />
          </div>
        </Card>
      </>
    );
  }
);

export default ChatDetail;
