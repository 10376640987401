import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getLeadAsync,
  selectLead,
  updateLead,
  updateLeadToState
} from 'features/lead/leadSlice';
import LeadHeader from 'components/lead/LeadHeader';
import { Scrollbars } from 'react-custom-scrollbars';
import LeadDetails from './LeadDetails';
import { toast } from 'react-toastify';
import ForwardLeadModal from 'pages/portal/modal/ForwardLeadModal';
import { success } from 'helpers/variables';
import { ToastTemplate } from 'helpers/generic.methods';
import { Toast } from 'react-bootstrap';
import { getUserById } from 'features/users/userSlice';

const LeadContainer = ({ selectedlead, removeSelectedLeads }) => {
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();
  const leadId = selectedlead?.id || 0;
  let { lead } = useSelector(e => selectLead(e, leadId));

  useEffect(() => {
    if (!lead || !Object.keys(lead).length) {
      fetchLead();
    }
  }, [leadId, dispatch]);

  useEffect(() => {
    if (lead && Object.keys(lead).length && lead.sentBy && !lead.userType) {
      getUser();
    }
  }, [lead]);
  // eslint-disable-line react-hooks/exhaustive-deps

  const getUser = async () => {
    let res = await getUserById({
      params: { id: lead.sentBy }
    });
    const { data } = res.data;
    dispatch(updateLeadToState({ data: { ...lead, userType: data.userType } }));
  };

  const fetchLead = () => {
    let result = dispatch(
      getLeadAsync({
        params: { id: parseInt(leadId) }
      })
    );

    result.catch(err => {
      if (
        err &&
        typeof err === 'string' &&
        err === 'Invalid lead ID specified.'
      ) {
        removeSelectedLeads();
        toast.error(
          ToastTemplate.error(`You don't have access to specified lead`)
        );
      }
    });
  };

  const handleFlagProcessing = payload => {
    try {
      setLoading(true);
      updateLead(payload).then(response => {
        if (response.data.success) {
          dispatch(
            updateLeadToState({ id: leadId, data: response?.data?.data })
          );
          toast.success(success.saveSuccess('Lead', 'updated'));
          setLoading(false);
        }
      });
    } catch (error) {
      toast.error(ToastTemplate.error(error || error.message));
      setLoading(false);
    }
  };

  const openForwardLeadModal = () => {
    setOpenModal(true);
  };

  const onModalClose = (payload = null) => {
    setOpenModal(false);
    if (payload && Object.keys(payload).length) {
      let data = { ...lead, forwardLeadData: payload };
      dispatch(updateLeadToState({ id: leadId, data }));
    }
  };
  return (
    <>
      <div>
        <Scrollbars
          style={{ borderRadius: '0 0 0 24px', height: 'calc(100vh - 130px)' }}
        >
          <div className="page ">
            <LeadHeader
              name={lead?.name}
              id={leadId}
              flagAsProcessed={lead?.processedByCustomer}
              handleFlagProcessing={handleFlagProcessing}
              chatId={lead?.chatId}
              companyId={lead?.companyId}
              openForwardLeadModal={openForwardLeadModal}
            />
          </div>
          <div className="page ">
            {/* <div className="page-content col px-0"> */}
            <div className="page-body">
              <LeadDetails selectedLead={lead} />
            </div>
            {/* </div> */}
          </div>
        </Scrollbars>
      </div>

      <ForwardLeadModal
        showModal={openModal}
        handleClose={onModalClose}
        savedData={lead?.forwardLeadData}
        leadId={leadId}
      />
    </>
  );
};

export default LeadContainer;
