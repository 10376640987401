import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import storage from 'helpers/storage';
import { useFormik } from 'formik';
import * as loginSchema from 'helpers/schemas/authSchemas';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { loginAsync } from './authSlice';
import { errors } from 'helpers/variables';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  convertLegacyPortalLinkToCustomerPortal,
  getCookie,
  setErrorClass
} from 'helpers/generic.methods';
import { selectRoles, setRolesToState } from 'features/roles/rolesSlice';
import RoleEngine from 'app/role-engine';
import portalRoutes from 'routes/portalRoutes';
import { UilEye, UilEyeSlash } from '@iconscout/react-unicons';
const LoginForm = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [remember, setRemember] = useState({ rememberMe: false });
  const [fieldErrorOnSubmit, setFieldErrorOnSubmit] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [loginValuesInit, setLoginValuesInit] = useState(false);
  const { roles } = useSelector(selectRoles);
  const [isShowPasswordEnable, setShowPassword] = useState(false);
  const loginAssist = JSON.parse(storage.getItem('loginAssist'));
  useEffect(() => {
    //getting loggedin user data from local storage and assinging it
    //to loginInitialValues in authSchema.js
    let lastLoggedInUser = storage.getItem('lastLoggedInUser');
    lastLoggedInUser = JSON.parse(lastLoggedInUser);
    //if login user saved in storage then map it to schema else set it to empty string
    loginSchema.loginInitialValues['apexchat-username'] = lastLoggedInUser
      ? lastLoggedInUser['apexchat-username']
      : loginAssist
      ? loginAssist.username
      : '';
    loginSchema.loginInitialValues['apexchat-company'] = lastLoggedInUser
      ? lastLoggedInUser['apexchat-company']
      : loginAssist
      ? loginAssist.company
      : '';
    setLoginValuesInit(true);
  }, []);

  const serverErrors = ['The account has been locked out.'];

  /**
   * @name @loginFormHandler
   * @description Used to connect with store and call login api then toggle error
   * @requires  loginFormData from the form fields
   * */
  const loginFormHandler = async (loginFormData, { resetForm }) => {
    setLoading(true);
    setErrorMsg('');
    setRemember({ remember, rememberMe: loginFormData.rememberMe });
    const isRememberMe = loginFormData.rememberMe;
    await dispatch(loginAsync(loginFormData))
      .then(() => {
        rememberMeHandler(isRememberMe, loginFormData);
        const user = JSON.parse(storage.getItem('Profile'));
        if (!user) {
          // If there is no User then dont redirect..
          return false;
        }
        const { CompanyRelationship, UserType, UserRoles } = user;
        const roles = RoleEngine(
          CompanyRelationship,
          UserType,
          UserRoles,
          user
        );

        dispatch(setRolesToState(roles));
        // default analytics route
        let redirectRoute = '/';
        // to redirect first enabled screen when login
        let roleKey = Object.keys(roles).filter(key => roles[key].view);
        if (roleKey.length) {
          let routes = portalRoutes.filter(pr => pr.name === roleKey[0]);
          if (routes.length) redirectRoute = routes[0].path;
        }
        // only Allow dashboard available
        else if (roles && roles.messaging.allowMessaging) {
          redirectRoute = '/portal/messaging';
        }

        // let returnUrl = storage.getItem('return-url');
        let ssoReturnUrl = JSON.parse(storage.getItem('SSO-return-location'));

        let { from } = location.state || { from: { pathname: redirectRoute } };
        if (ssoReturnUrl) {
          let url = convertLegacyPortalLinkToCustomerPortal(ssoReturnUrl);
          // remove return url from localstorage
          storage.deleteItem('SSO-return-location');
          history.push(url);
        } else {
          let queryString = from?.search || '';
          window.location.replace(
            window.location.protocol +
              '//' +
              window.location.host +
              (from?.pathname || '/home') +
              queryString
          );
        }

        setErrorMsg('');
        // returnUrl = JSON.parse(returnUrl);
        // if (returnUrl) {
        // history.push(returnUrl);
        // storage.deleteItem('return-url');
        // } else history.push(redirectRoute);
        // Removing history state of the previous page
        // this will resist user to go back to login
        // window.addEventListener('popstate', () => {
        //   history.go(1);
        // });
      })
      .catch((err, err2) => {
        let showServerError =
          typeof err === 'string' && serverErrors.includes(err);
        setErrorMsg(showServerError ? err : errors.login);
        // setFieldErrorOnSubmit(true);
        // resetForm({});
        setLoading(false);
      });
  };

  /**
   * @name @rememberMeHandler
   * @description save user data in local storage
   * @requires isRememberMe boolean
   * */
  const rememberMeHandler = (isRememberMe, loggedInUser) => {
    //Checking if user checked the remember me checkbox
    //Delete the password from object and save the rest in local storage
    if (isRememberMe) {
      delete loggedInUser['apexchat-password'];
      storage.setItem('lastLoggedInUser', loggedInUser);
    } else {
      //checking if remember me is unchecked
      //remove the lastLoggedInUser
      if (storage.getItem('lastLoggedInUser')) {
        storage.deleteItem('lastLoggedInUser');
      }
    }
  };

  /** Init Formik */
  const LogInFormIk = useFormik({
    initialValues: loginSchema.loginInitialValues,
    validationSchema: loginSchema.loginSchema,
    onSubmit: loginFormHandler
  });

  /**
   * @name @handleOnChange
   * @description do something on field onChange
   * @requires html input element
   * */
  const handleOnChange = e => {
    let name = e.target.name;
    if (name.includes('company') || name.includes('username')) {
      e.target.value = e.target.value.toLowerCase();
    }

    LogInFormIk.handleChange(e);
    setFieldErrorOnSubmit(false);
  };

  const handleShowPassword = val => {
    setShowPassword(val);
  };

  useEffect(() => {
    const isAuthenticated = !!getCookie('Auth');
    let from = location?.state?.from;
    if (from && from?.search) {
      let modifiedFrom = { ...from, search: `${from.search}&isCP=true` };
      storage.setItem('SSO-return-location', modifiedFrom);
    }
    if (isAuthenticated) {
      if (from) {
        window.location.replace(
          window.location.protocol +
            '//' +
            window.location.host +
            (from?.pathname || '/home')
        );
      } else {
        history.push('/home');
      }
    }
  }, []);

  return (
    <>
      <span
        className="text-danger d-flex justify-content-center"
        style={{
          marginTop: '-30px',
          position: 'absolute',
          right: '0',
          left: '0'
          // marginBottom: '10px'
        }}
      >
        {errorMsg}
      </span>
      <form
        className="form login-form"
        onSubmit={LogInFormIk.handleSubmit}
        autoComplete="on"
      >
        <Form.Group
          controlId="apexchat-company"
          className={setErrorClass(
            [LogInFormIk, fieldErrorOnSubmit],
            'apexchat-company'
          )}
        >
          <Form.Control
            className="lined with-label"
            label="apexchat-company"
            name="apexchat-company"
            onChange={e => handleOnChange(e)}
            value={LogInFormIk.values['apexchat-company']}
            autoComplete="on"
          />
          <Form.Label className="label-movable">Company</Form.Label>
          <p className="text-red-1 field-error">
            {LogInFormIk.errors['apexchat-company'] &&
              LogInFormIk.touched['apexchat-company'] &&
              errors.fieldError('company')}
            {fieldErrorOnSubmit && errors.fieldCorrection('company name')}
          </p>
        </Form.Group>
        <Form.Group
          controlId="apexchat-username"
          className={setErrorClass(
            [LogInFormIk, fieldErrorOnSubmit],
            'apexchat-username'
          )}
        >
          <Form.Control
            className="lined with-label"
            label="apexchat-username"
            name="apexchat-username"
            onChange={e => handleOnChange(e)}
            value={LogInFormIk.values['apexchat-username']}
          />
          <Form.Label className="label-movable">Username</Form.Label>
          <p className="text-red-1 field-error">
            {LogInFormIk.errors['apexchat-username'] &&
              LogInFormIk.touched['apexchat-username'] &&
              errors.fieldError('username')}
            {fieldErrorOnSubmit && errors.fieldCorrection('username')}
          </p>
        </Form.Group>
        <Form.Group
          controlId="apexchat-password"
          className={setErrorClass(
            [LogInFormIk, fieldErrorOnSubmit],
            'apexchat-password'
          )}
        >
          <Form.Control
            className="lined with-label"
            type={isShowPasswordEnable ? 'text' : 'password'}
            label="apexchat-password"
            name="apexchat-password"
            onChange={e => handleOnChange(e)}
            value={LogInFormIk.values['apexchat-password']}
          />
          <Form.Label className="label-movable">Password</Form.Label>
          <p
            className="position-absolute"
            style={{ right: 2, top: 2, cursor: 'pointer' }}
            onClick={e => handleShowPassword(!isShowPasswordEnable)}
          >
            {!isShowPasswordEnable ? <UilEye /> : <UilEyeSlash />}
          </p>
          <p className="text-red-1 field-error">
            {LogInFormIk.errors['apexchat-password'] &&
              LogInFormIk.touched['apexchat-password'] &&
              errors.fieldError('password')}
            {fieldErrorOnSubmit && errors.fieldCorrection('password')}
          </p>
        </Form.Group>
        <Form.Group
          className=" form-group float-left w-100 rememberMe"
          controlId="remember"
        >
          <Form.Check
            custom
            type="checkbox"
            id="rememberMe"
            label="Remember my login"
            onChange={e => handleOnChange(e)}
            value={LogInFormIk.values.remember}
          />
        </Form.Group>
        <Button
          // || emptyField
          disabled={loading || !LogInFormIk.dirty}
          type="submit"
          variant="primary"
          className="w-100 mb-lg-2 mb-2"
          onClick={() => setFieldErrorOnSubmit(false)}
        >
          Login
          {loading && <i className="uil uil-spinner spinner ml-2"></i>}
        </Button>
      </form>
    </>
  );
};

export default LoginForm;
