import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ChatHeader from 'components/chat/ChatHeader';
import { Scrollbars } from 'react-custom-scrollbars';
import ChatDetails from './ChatDetails';
import { getChatAsync, selectChat } from 'features/chat/chatSlice';
import storage from 'helpers/storage';
import { toast } from 'react-toastify';
import { ToastTemplate } from 'helpers/generic.methods';

const ChatContainer = ({
  dataKey,
  selected,
  updateChatsAndSaveToLS,
  removeSelectedChats
}) => {
  const dispatch = useDispatch();
  const chatId = selected.id || selected.chatId;
  const { chat } = useSelector(e => selectChat(e, chatId));

  useEffect(() => {
    if (!chat || !Object.keys(chat).length) {
      dispatch(
        getChatAsync({
          params: { id: parseInt(chatId) }
        })
      )
        .then(res => {
          if (!selected.companyName || !selected.companyKey) {
            let temp = JSON.parse(storage.getItem(dataKey)) || [];
            let index = temp.findIndex(st => st.id === Number(chatId));
            temp[index]['companyName'] = res.data.companyName;
            temp[index]['companyKey'] = res.data.companyKey;
            updateChatsAndSaveToLS(temp);
          }
        })
        .catch(err => {
          console.log('chat error', err);
          if (err && typeof err === 'string' && err === 'Invalid chat ID') {
            removeSelectedChats();
            toast.error(
              ToastTemplate.error(`You don't have access to specified chat`)
            );
          }
        });
    }
  }, [chatId, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div>
        <Scrollbars
          style={{ borderRadius: '0 0 0 24px', height: 'calc(100vh - 130px)' }}
        >
          <div className="page">
            <ChatHeader chat={chat} companyId={chatId} />
          </div>
          <div
            className="page"
            // style={{ height: 'calc(100vh - 175px)' }}
          >
            {/* <div className="page-content col px-0"> */}
            <div className="page-body">
              <ChatDetails chatData={chat} />
            </div>
            {/* </div> */}
          </div>
        </Scrollbars>
      </div>
    </>
  );
};

export default ChatContainer;
