import React, { useState, useEffect } from 'react';
import SimpleLabelTextBox from 'helpers/ui-controls/ui-components/SimpleLabelTextbox';
import LeadNotesBox from './LeadNotesBox';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import dropdownEnums, {
  Enums,
  getObjectKeyByValue
} from 'helpers/dropdown-enums';
import ToggleToolTip from 'helpers/ui-controls/toggleToolTip';
import { dateTimerFormat, ToastTemplate } from 'helpers/generic.methods';
import StatusComp from './StatusComp';
import SectionHeader from 'helpers/ui-controls/ui-components/SectionHeader';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { AppointmentStatusVisible } from 'helpers/specific-conditions';
import { selectRoles } from 'features/roles/rolesSlice';
import { leadSection } from 'helpers/schemas/companySchemas/initialValues';
import { leadSchema } from 'helpers/schemas/companySchemas/schemas';
import { useFormik } from 'formik';
import BadgeWithTextFeild from './BadgeWithTextFeild';
import { updateLead, updateLeadToState } from 'features/lead/leadSlice';
import { success } from 'helpers/variables';
import { getUserAsync, getUserById } from 'features/users/userSlice';
import storage from 'helpers/storage';

const LeadDetails = ({ selectedLead }) => {
  const { CompanyRelationship } = JSON.parse(storage.getItem('Profile'));
  const { t } = useTranslation();
  const { roles } = useSelector(selectRoles);
  const [lead, setLead] = useState({});
  const dispatch = useDispatch();
  const [leadValues, setLeadValues] = useState({});
  const [editable, setEditable] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [leadObj, setLeadObj] = useState({
  //   ...leadSection
  // });

  useEffect(() => {
    setLead(selectedLead);
  }, [selectedLead]);

  const leadFormHandler = async formData => {
    console.log(formData);
    try {
      setLoading(true);
      let payload = {
        leadId: selectedLead.id,
        documentVerified: formData.documentVerified
      };
      const response = await updateLead(payload);
      console.log(response?.data?.data);
      dispatch(
        updateLeadToState({ id: selectedLead.id, data: response?.data?.data })
      );
      toast.success(success.saveSuccess('Lead', 'updated'));
      setEditable(false);
      setLoading(false);
    } catch (error) {
      toast.error(ToastTemplate.error(error || error.message));
      setLoading(false);
    }
  };

  const LeadFormIk = useFormik({
    enableReinitialize: true,
    initialValues: lead,
    validationSchema: leadSchema,
    onSubmit: leadFormHandler
  });

  const handleOnChange = (element, key, value) => {
    // setLeadValues({ ...leadValues, [key]: value });
    LeadFormIk.setValues({
      ...LeadFormIk.values,
      [key]: value
    });
    // LeadFormIk.values[key] = value;
    // LeadFormIk.handleChange(element);
  };

  const transcriptConvert = chatString => {
    if (chatString) {
      const convertedString = chatString.split('<br />');
      return convertedString;
    }
  };

  const getAgentOperator = () => {
    if (
      [Enums.Relationship.Customer, Enums.Relationship.Partner].includes(
        CompanyRelationship
      )
    ) {
      if (selectedLead.userType === Enums.UserType.Operator)
        return selectedLead.username;
      else return 'Agent';
    } else return selectedLead.username;
  };

  return (
    <>
      <div className="page-section">
        <h4 className="sub-head">{t('company.leadDetails.heading1')}</h4>{' '}
        <section>
          <div className="section-body">
            <ul className="editable-fields-lists">
              <SimpleLabelTextBox
                heading={t('company.leadDetails.name.label')}
                field="name"
                dataProperty={selectedLead['name']}
                titleText={t('company.leadDetails.name.info')}
              />
              <SimpleLabelTextBox
                heading={t('company.leadDetails.phoneNo.label')}
                field="phoneNo"
                dataProperty={selectedLead['phone'] || '-'}
                titleText={t('company.leadDetails.phoneNo.info')}
              />
              <SimpleLabelTextBox
                heading={t('company.leadDetails.email.label')}
                field="email"
                dataProperty={selectedLead['email'] || '-'}
                titleText={t('company.leadDetails.email.info')}
              />
              <SimpleLabelTextBox
                heading={t('company.leadDetails.createdOn.label')}
                field="createdOn"
                dataProperty={dateTimerFormat(selectedLead['createdOn'])}
                titleText={t('company.leadDetails.createdOn.info')}
              />
              <SimpleLabelTextBox
                heading={t('company.leadDetails.leadType.label')}
                field="leadType"
                dataProperty={selectedLead['leadType']}
                titleText={t('company.leadDetails.leadType.info')}
                dropdownFlag={true}
                dropdownEnum={dropdownEnums.enum_LeadType}
              />
              <SimpleLabelTextBox
                heading={t('company.leadDetails.initiatedBy.label')}
                field="initiatedBy"
                dataProperty={selectedLead['initiatedBy']}
                titleText={t('company.leadDetails.initiatedBy.info')}
                dropdownFlag={true}
                dropdownEnum={dropdownEnums.enum_InitiatedBy}
              />
              <SimpleLabelTextBox
                heading={t('company.leadDetails.notificationState.label')}
                // field="notificationState"
                titleText={t('company.leadDetails.notificationState.info')}
                dataProperty={selectedLead['notificationState']}
                dropdownFlag={true}
                dropdownEnum={dropdownEnums.enum_notificationState}
              />
              {selectedLead['sentBy'] ? (
                <SimpleLabelTextBox
                  heading={t('company.leadDetails.sentBy.label')}
                  field="sentBy"
                  dataProperty={getAgentOperator()}
                  titleText={t('company.leadDetails.sentBy.info')}
                />
              ) : null}
              <StatusComp
                heading={t('company.leadDetails.called.label')}
                toolTipFor="leadFlag"
                flag={
                  selectedLead.attemptedCalls && selectedLead.completedCalls
                    ? 1
                    : selectedLead.attemptedCalls &&
                      !selectedLead.completedCalls
                    ? 3
                    : 2
                }
                titleText={t('company.leadDetails.called.info')}
              />
              <SimpleLabelTextBox
                heading={t('company.leadDetails.attemptedCalls.label')}
                field="attemptedCalls"
                dataProperty={selectedLead['attemptedCalls'] || '0'}
                titleText={t('company.leadDetails.attemptedCalls.info')}
              />
              <SimpleLabelTextBox
                heading={t('company.leadDetails.completedCalls.label')}
                field="completedCalls"
                dataProperty={selectedLead['completedCalls'] || '0'}
                titleText={t('company.leadDetails.completedCalls.info')}
              />
              <SimpleLabelTextBox
                heading={t('company.leadDetails.keywords.label')}
                field="keywords"
                dataProperty={
                  selectedLead['keywords'] ? selectedLead['keywords'] : '-'
                }
                titleText={t('company.leadDetails.keywords.info')}
              />
              {selectedLead['appliedRuleName'] && (
                <SimpleLabelTextBox
                  heading={t('company.leadDetails.appliedRule.label')}
                  field="appliedRuleName"
                  dataProperty={selectedLead['appliedRuleName']}
                  titleText={t('company.leadDetails.appliedRule.info')}
                />
              )}
              {selectedLead['regionName'] && (
                <SimpleLabelTextBox
                  heading={t('company.leadDetails.region.label')}
                  field="regionName"
                  dataProperty={selectedLead['regionName']}
                  titleText={t('company.leadDetails.region.info')}
                />
              )}
              {selectedLead['leadCategoryName'] && (
                <SimpleLabelTextBox
                  heading={t('company.leadDetails.leadCategory.label')}
                  field="leadCategoryName"
                  dataProperty={selectedLead['leadCategoryName']}
                  titleText={t('company.leadDetails.leadCategory.info')}
                />
              )}
            </ul>
          </div>
        </section>
        <section>
          <div className="section-body">
            <>
              {lead['isAppointmentSchedulerEnabled'] ? (
                <>
                  <h5 className="fw-600">
                    {t('company.leadDetails.heading4')}
                  </h5>{' '}
                  <section>
                    <div className="section-body">
                      <ul className="editable-fields-lists">
                        <BadgeWithTextFeild
                          label={t(
                            'company.leadDetails.appointmentStatus.label'
                          )}
                          selected={selectedLead['appointmentStatus']}
                          options={dropdownEnums.enum_appointmentStatus}
                          tooltip={t(
                            'company.leadDetails.appointmentStatus.info'
                          )}
                        />
                        {lead['appointmentStatus'] &&
                        AppointmentStatusVisible.includes(
                          lead['appointmentStatus']
                        ) ? (
                          <>
                            <SimpleLabelTextBox
                              heading={t(
                                'company.leadDetails.appointmentStartTime.label'
                              )}
                              field="appointmentStartTime"
                              dataProperty={dateTimerFormat(
                                selectedLead['appointmentStartTime']
                              )}
                              titleText={t(
                                'company.leadDetails.appointmentStartTime.info'
                              )}
                            />
                            <SimpleLabelTextBox
                              heading={t(
                                'company.leadDetails.appointmentEndTime.label'
                              )}
                              field="appointmentEndTime"
                              dataProperty={dateTimerFormat(
                                selectedLead['appointmentEndTime']
                              )}
                              titleText={t(
                                'company.leadDetails.appointmentEndTime.info'
                              )}
                            />
                          </>
                        ) : null}
                      </ul>
                    </div>
                  </section>
                </>
              ) : null}
            </>
            {lead['isDocusignEnabled'] ? (
              <>
                <Form
                  className="form"
                  onSubmit={LeadFormIk.handleSubmit}
                  autoComplete="off"
                >
                  <SectionHeader
                    sectionHeading={t('company.leadDetails.heading3')}
                    loading={loading}
                    editable={editable}
                    handleEditableState={setEditable}
                  />
                  <section>
                    <div className="section-body">
                      <ul className="editable-fields-lists">
                        <ToggleToolTip
                          heading={t(
                            'company.leadDetails.documentVerified.label'
                          )}
                          toolTipFor="documentVerified"
                          flag={!!LeadFormIk?.values?.documentVerified}
                          titleText={t(
                            'company.leadDetails.documentVerified.info'
                          )}
                          field={'documentVerified'}
                          handleChange={handleOnChange}
                          editable={editable}
                          falgOptions={['Verified', 'Not verified']}
                        />
                        {selectedLead['docuSignNotificationState'] ? (
                          <BadgeWithTextFeild
                            label={t(
                              'company.leadDetails.docuSignNotificationState.label'
                            )}
                            selected={selectedLead['docuSignNotificationState']}
                            options={
                              dropdownEnums.enum_docuSignNotificationState
                            }
                            tooltip={t(
                              'company.leadDetails.docuSignNotificationState.info'
                            )}
                          />
                        ) : (
                          <SimpleLabelTextBox
                            heading={t(
                              'company.leadDetails.docuSignNotificationState.label'
                            )}
                            dataProperty={'---'}
                            titleText={t(
                              'company.leadDetails.docuSignNotificationState.label'
                            )}
                          />
                        )}
                      </ul>
                    </div>
                  </section>
                </Form>
              </>
            ) : null}
            <LeadNotesBox
              title={t('company.leadDetails.heading2')}
              leadNotes={transcriptConvert(selectedLead.notes)}
            />
          </div>
        </section>
      </div>
    </>
  );
};

export default LeadDetails;
