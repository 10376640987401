import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import SimpleLabelTextBox from 'helpers/ui-controls/ui-components/SimpleLabelTextbox';
import SectionHeader from 'helpers/ui-controls/ui-components/SectionHeader';
import { googleAnalyticsTrackingSectionSchema } from 'helpers/schemas/companySchemas/schemas';
import { success } from 'helpers/variables';
import { useTranslation } from 'react-i18next';
import { googleAnalyticsTrackingSection } from 'helpers/schemas/companySchemas/initialValues';
import {
  mapServerValuesToInitialValues,
  setupTrackingPayload,
  ToastTemplate
} from 'helpers/generic.methods';
import {
  updateCompany,
  updateCompanyToState
} from 'features/companies/companiesSlice';
import { useDispatch } from 'react-redux';
import ToggleToolTip from 'helpers/ui-controls/toggleToolTip';
import { Enums } from 'helpers/dropdown-enums';

const GoogleAnalyticsTracking = ({
  company,
  trackingFlags,
  permissionEdit,
  reflectObject
}) => {
  const { t } = useTranslation();

  // 'spa', 'eng' will be dynamic values here

  const [googleAnalyticsTracking_chk, setGoogleAnalyticsTracking_chk] =
    useState(trackingFlags?.googleAnalyticsTrackingEnabled || false);

  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const [
    googleAnalyticsTrackingSectionObj,
    setGoogleAnalyticsTrackingSectionObj
  ] = useState({ ...googleAnalyticsTrackingSection });
  const companyId = company.id;
  const [updatedCompanyData, setUpdatedDataCompany] = useState({});

  const dispatch = useDispatch();

  const trackingFormHandler = async formData => {
    console.log('submit tracking');
    try {
      formData.companyTrackingTypes = setupTrackingPayload(
        company.id,
        googleAnalyticsTracking_chk,
        company.companyTrackingTypes,
        Enums.AnalyticsTracking.GoogleUniversal
      );
      setLoading(true);
      let payload = {
        ...formData,
        id: company.id,
        companyKey: company.companyKey
      };
      const response = await updateCompany(payload);
      // setUpdatedDataCompany(response?.data?.data);
      reflectObject(response?.data?.data);

      toast.success(success.saveSuccess('Google Analytics Tracking'));
      setEditable(false);
      setLoading(false);
    } catch (error) {
      toast.error(ToastTemplate.error(error || error.message));
      setLoading(false);
    }
  };
  /** Init Formik */
  const TrackingFormIk = useFormik({
    enableReinitialize: true,
    initialValues: googleAnalyticsTrackingSectionObj,
    validationSchema: googleAnalyticsTracking_chk
      ? googleAnalyticsTrackingSectionSchema
      : null,
    onSubmit: trackingFormHandler
  });
  /**
   * @name @handleOnChange
   * @description do something on field onChange
   * @requires html input element
   * */
  const handleOnChange = (element, key, value) => {
    TrackingFormIk.values[key] = value;
    TrackingFormIk.handleChange(element);
  };
  /**
   * @handleGoogleAnalyticsTrackingSwitch: callback function needs to send to headerComponent
   */
  const handleGoogleAnalyticsTrackingSwitch = checked => {
    setGoogleAnalyticsTracking_chk(checked);
  };

  useEffect(() => {
    handleGoogleAnalyticsTrackingSwitch(
      !!trackingFlags.googleAnalyticsTrackingEnabled
    );
    mapServerValuesToInitialValues(company, {
      ...googleAnalyticsTrackingSection
    })
      .then(data => {
        setGoogleAnalyticsTrackingSectionObj({
          ...googleAnalyticsTrackingSection,
          ...data
        });
      })
      .catch(err => {
        throw new Error(err);
      });
    dispatch(updateCompanyToState({ id: companyId, data: updatedCompanyData }));
  }, [company, trackingFlags, updatedCompanyData, companyId, dispatch]);
  return (
    <Form
      className="form"
      onSubmit={TrackingFormIk.handleSubmit}
      autoComplete="off"
    >
      <section>
        <SectionHeader
          sectionHeading={t('company.trackingIntegration.heading2')} //"Google Analytics Tracking"
          field="googleAnalyticsTracking"
          id={'googleAnalyticsTracking' + companyId}
          // disabled={loading || !TrackingFormIk.dirty}
          disabled={loading || !permissionEdit}
          loading={loading}
          editable={editable}
          handleEditableState={setEditable}
          hasHeaderProperty={true}
          headerProperty={googleAnalyticsTracking_chk}
          headerPropertyOnChange={handleGoogleAnalyticsTrackingSwitch}
          handleChange={handleOnChange}
        />
        <div className="section-body">
          {googleAnalyticsTracking_chk ? (
            <ul
              className="editable-fields-lists"
              // style={!!googleAnalyticsTracking_chk ? {} : { display: 'none' }}
            >
              <SimpleLabelTextBox
                heading={t(
                  'company.trackingIntegration.googleAnalyticsAccount.label'
                )} //"Google Analytics Account"
                field="googleAnalyticsAccount"
                id={'googleAnalyticsAccount' + companyId}
                dataProperty={TrackingFormIk.values['googleAnalyticsAccount']}
                titleText={t(
                  'company.trackingIntegration.googleAnalyticsAccount.info'
                )} //"Account code for Google Analytics"
                helperText={t(
                  'company.trackingIntegration.googleAnalyticsAccount.info'
                )}
                error={TrackingFormIk.errors['googleAnalyticsAccount']}
                editable={editable}
                handleChange={handleOnChange}
                // for now making false as UI is not ready.
                multiline={false}
              />
              <SimpleLabelTextBox
                heading={t(
                  'company.trackingIntegration.googleAnalyticsDomain.label'
                )}
                field="googleAnalyticsDomain"
                id={'googleAnalyticsDomain' + companyId}
                dataProperty={TrackingFormIk.values['googleAnalyticsDomain']}
                titleText={t(
                  'company.trackingIntegration.googleAnalyticsDomain.info'
                )}
                error={TrackingFormIk.errors['googleAnalyticsDomain']}
                editable={editable}
                handleChange={handleOnChange}
                // for now making false as UI is not ready.
                multiline={false}
              />
              <ToggleToolTip
                heading={t(
                  'company.trackingIntegration.clientSideTracking.label'
                )}
                toolTipFor="clientSideGoogleTracking"
                flag={trackingFlags.clientSideGoogleTracking}
                titleText={t(
                  'company.trackingIntegration.clientSideTracking.info'
                )}
              />
              {/* {!!trackingFlags.clientSideGoogleTracking && ( */}
              <>
                <ToggleToolTip
                  heading={t(
                    'company.trackingIntegration.googleAnalyticsTrackingWithClientId.label'
                  )}
                  toolTipFor="sendClientIdToGoogleAnalytics"
                  flag={trackingFlags.sendClientIdToGoogleAnalytics}
                  titleText={t(
                    'company.trackingIntegration.googleAnalyticsTrackingWithClientId.info'
                  )}
                />
                <ToggleToolTip
                  heading={t(
                    'company.trackingIntegration.googleAnalyticsCrossDomainTracking.label'
                  )}
                  toolTipFor="googleAnalyticsCrossDomainTracking"
                  flag={trackingFlags.googleAnalyticsCrossDomainTracking}
                  titleText={t(
                    'company.trackingIntegration.googleAnalyticsCrossDomainTracking.info'
                  )}
                />
              </>
              {/* )} */}
              <ToggleToolTip
                heading={t(
                  'company.trackingIntegration.dashboardTracking.label'
                )}
                toolTipFor="sendChatsAndLeadsToGaViaDashboard"
                flag={company.sendChatsAndLeadsToGaViaDashboard}
                titleText={t(
                  'company.trackingIntegration.dashboardTracking.info'
                )}
              />

              {!!company.sendChatsAndLeadsToGaViaDashboard && (
                <>
                  <ToggleToolTip
                    heading={t(
                      'company.trackingIntegration.salesLeadType.label'
                    )}
                    toolTipFor="salesLeadTypeForGoogleAnalytics"
                    flag={company.salesLeadTypeForGoogleAnalytics}
                    titleText={t(
                      'company.trackingIntegration.salesLeadType.info'
                    )}
                  />
                  <ToggleToolTip
                    heading={t(
                      'company.trackingIntegration.serviceLeadType.label'
                    )}
                    toolTipFor="serviceLeadTypeForGoogleAnalytics"
                    flag={company.serviceLeadTypeForGoogleAnalytics}
                    titleText={t(
                      'company.trackingIntegration.serviceLeadType.info'
                    )}
                  />
                  <ToggleToolTip
                    heading={t(
                      'company.trackingIntegration.otherLeadType.label'
                    )}
                    toolTipFor="otherLeadTypeForGoogleAnalytics"
                    flag={company.otherLeadTypeForGoogleAnalytics}
                    titleText={t(
                      'company.trackingIntegration.otherLeadType.info'
                    )}
                  />
                  <ToggleToolTip
                    heading={t(
                      'company.trackingIntegration.chatStartNotification.label'
                    )}
                    toolTipFor="chatStartNotificationForGoogleAnalytics"
                    flag={company.chatStartNotificationForGoogleAnalytics}
                    titleText={t(
                      'company.trackingIntegration.chatStartNotification.info'
                    )}
                  />
                </>
              )}
            </ul>
          ) : null}
        </div>
      </section>
    </Form>
  );
};
export default GoogleAnalyticsTracking;
