import React, { useEffect, useState } from 'react';
import './dashboard.scss';
import { ListGroup } from 'react-bootstrap';
import { getBudgetPacingStats } from 'features/analytics/analyticSlice';
import { APIStatus } from 'helpers/dropdown-enums';
import { toast } from 'react-toastify';
import ScrollBar from 'components/Scrollbars';
import { LoadingComponent } from './loadingListComp';
import { BadgeComp } from './badge';

// UIUX-517, Budget Pacing component
const BudgetPacing = ({
  data,
  loading,
  status,
  statusText,
  countWithName = false,
  showYear = false,
  customClass = '',
  componentKey = ''
}) => {
  const [budgetPacing, setBudgetPacing] = useState([]);
  // const [noData, setNoData] = useState(false);
  useEffect(() => {
    getBudgetPacingData();
  }, [data]);

  const getBudgetPacingData = () => {
    // try {
    //   let payload = {
    //     pageSize: 10
    //   };
    //   const response = await getBudgetPacingStats(payload);
    //   if (response.data && response.status === APIStatus.OK) {
    //     const { data } = response.data;
    setBudgetPacing(data || []);
  };

  return (
    <ListGroup
      className="analytic-list-container"
      variant="flush"
      style={{ borderRadius: '10px' }}
    >
      <ScrollBar
        horizontalScroll={false}
        className={'print-preview-scroll ' + customClass}
        style={{ height: '100vh', marginBottom: 10 }}
      >
        {budgetPacing.map((dt, index) => (
          <ListGroup.Item key={index} className="analytics-list">
            {countWithName ? (
              <div className="heading">
                {dt.CompanyName} - {dt.Leads} Lead(s)
              </div>
            ) : (
              <div className="heading">{dt.CompanyName}</div>
            )}
            {/* TODO: when API is giving data replace the active keyword with actual data */}
            <div className="cursor-pointer count-percentage">
              <span
                className={
                  status
                    ? dt.BudgetPauseEnabled
                      ? 'paused-color'
                      : 'active-added-color'
                    : 'inactive-added-color text-align-right'
                }
              >
                {componentKey === 'budget' ? (
                  <>
                    <BadgeComp
                      text={
                        dt.PacingEnabled
                          ? dt.BudgetPauseEnabled
                            ? 'Paused'
                            : 'Active'
                          : 'Inactive'
                      }
                      status={
                        dt.PacingEnabled
                          ? dt.BudgetPauseEnabled
                            ? 'pause'
                            : 'active'
                          : 'inactive'
                      }
                    />
                  </>
                ) : (
                  <div>{statusText}</div>
                )}
                {showYear ? <div>Since {dt.Year}</div> : null}
              </span>
              {!status ? <div className="status-dot"></div> : null}

              {/* <>
                <div style={{ lineHeight: 2.3 }}>
                  <div className="value text-align-right">{statusText}</div>
                </div>
                <div>
                  <div className="status-dot"></div>
                </div>
              </> */}
            </div>
          </ListGroup.Item>
        ))}
        {/* {!budgetPacing.length ? (
          <ListGroup.Item>
            <div className="heading text-center">No data Available</div>
          </ListGroup.Item>
        ) : null} */}
        <LoadingComponent loading={loading} dataLength={budgetPacing.length} />
      </ScrollBar>
    </ListGroup>
  );
};

export default BudgetPacing;
