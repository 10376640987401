import {
  RemoveChannelsFromRTCCleint,
  UnSubscribeChat
} from 'app/communication/services/chat/pub_sub_chat';
import {
  endChatAsync,
  getLastClosedChatFromStack,
  removeFromChatStack,
  removeSelectedChat,
  setSelectedChat
} from 'features/messagingChats/messagingChats';
import { errors, success, toastMsg } from 'helpers/variables';
import react, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { UilSignout } from '@iconscout/react-unicons';
import ButtonWithConfirmLoader from 'components/ButtonWithConfimLoader';
import './ChatEndButton.scss';

const ChatEndButton = ({ selectedChat, isDisabled }) => {
  const [chatEnding, setChatEnding] = useState(false);
  const dispatch = useDispatch();

  const onConfirmEndChat = async () => {
    setChatEnding(true);
    let chatEnded = await dispatch(endChatAsync(selectedChat.getId()));
    if (chatEnded) {
      toast.success(success.chatEnd);
      UnSubscribeChat(selectedChat.getId());
    } else toast.error(toastMsg.errors.failure);
    setChatEnding(false);
  };

  return (
    <>
      {!isDisabled && (
        <ButtonWithConfirmLoader
          title="End Chat"
          loading={chatEnding}
          isDisabled={isDisabled}
          onConfirm={onConfirmEndChat}
          buttonIcon={<UilSignout />}
          loaderDuration={3000}
          loaderMode="determinate"
          classes="btn-end-chat"
          variant={'outline-danger'}
          confirmVariant={'outline-success'}
          confirmButtonType={'button'}
          showConfirmButton={true}
        />
      )}
    </>
  );
};

export default ChatEndButton;
