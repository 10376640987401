import { createSlice } from '@reduxjs/toolkit';
import storage from 'helpers/storage';

export const navBarSlice = createSlice({
  name: 'navbar',
  initialState: {
    // navbar: !!JSON.parse(storage.getItem('navbar-active'))
    navbar: false
  },

  reducers: {
    setNavbarStatus: (state, action) => {
      storage.setItem('navbar-active', action.payload);
      state.navbar = action.payload;
    }
  }
});

export const { setNavbarStatus } = navBarSlice.actions;

export const getNavbarStatus = state => {
  return state.navbar;
};

export default navBarSlice.reducer;
