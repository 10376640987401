import {
  getAllUnreadMsgsCounts,
  getTotalUnreadMessagesCount,
  getUnSubscribedIncomingChatList
} from 'features/messagingChats/messagingChats';
import React, { useEffect } from 'react';
import { OverlayTrigger, Badge } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import renderToolTip from './renderToolTip';
import './renderSidebarTooltip.scss';
import AnimateScrollArrow from './animate-scroll-arrow';
import { Init_Unread_Messages_Count } from 'app/communication/listeners/listener_unread_messages';
import { TitleTab } from 'components/TitleTab';
import CustomBadge from 'components/CustomBadge';
const RenderSidebarTooltip = ({
  showSidebar,
  title,
  icon,
  showTooltip = true
}) => {
  const unSubscribedChat = useSelector(getUnSubscribedIncomingChatList);
  const allUnReadMsgCounts = useSelector(getTotalUnreadMessagesCount);
  let incomingChatsCount = unSubscribedChat.length;
  const location = useLocation();
  useEffect(() => {
    Init_Unread_Messages_Count();
    if (title === 'Messaging') {
      let allCounts = incomingChatsCount + allUnReadMsgCounts;
      TitleTab(allCounts, `(New Message)`);
    }
  }, [window.RTC?.status]);

  // UIUX-1896 stop tab title animation when there is no unread message and incomming chat count
  useEffect(() => {
    if (!incomingChatsCount && !allUnReadMsgCounts) {
      if (window.tabTitleTimer) clearInterval(window.tabTitleTimer);
    }
  }, [incomingChatsCount, allUnReadMsgCounts]);

  return (
    <>
      {!showSidebar && showTooltip ? (
        <OverlayTrigger placement={'bottom'} overlay={renderToolTip(title)}>
          {icon}
        </OverlayTrigger>
      ) : (
        <>{icon}</>
      )}
      {title === 'Messaging' && (
        <>
          {allUnReadMsgCounts > 0 && (
            <CustomBadge
              style={{ right: '5px', lineHeight: '1' }}
              count={allUnReadMsgCounts}
              toolTipLabel={`Unread messages count`}
            />
          )}

          {incomingChatsCount > 0 && (
            <OverlayTrigger
              placement={'auto'}
              overlay={renderToolTip(
                `Incoming chat count: ${incomingChatsCount}`
              )}
            >
              <Badge
                bg="secondary"
                className={`incoming-chat-vertical-indicator ${
                  incomingChatsCount > 9 && 'incoming-count-greater-than-9'
                }`}
              >
                <AnimateScrollArrow />
                {incomingChatsCount}
              </Badge>
            </OverlayTrigger>
          )}
        </>
      )}
    </>
  );
};
export default RenderSidebarTooltip;
