import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import SidebarRight from 'components/Sidebar/sidebar-right';
import {
  exportAllLeads,
  getAllLeads,
  removeAllLeadFromState,
  removeLeadFromState
} from 'features/lead/leadSlice';
import storage from 'helpers/storage';
import { Container, Row, Col, Badge, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchMobileSuggestionsAsync,
  selectMobileSuggestions
} from 'features/fetchGeneralData/fetchGeneralDataOnAuth';
import {
  TabComponent,
  TabsContainer,
  TabNav
} from '../../../components/TabsComponent';
import LeadContainer from './details/index';
import '@inovua/reactdatagrid-community/index.css';
import { leadsTableConfig } from 'utils/tableConfig';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import UILInfoCircle from '@iconscout/react-unicons/icons/uil-info-circle';
import { toast } from 'react-toastify';
import { ToastTemplate, toolTipPlacement } from 'helpers/generic.methods';
import renderToolTip from 'helpers/ui-controls/renderToolTip';
import { GridWrapper } from '../../../components/GridWrapper';
import { useTranslation } from 'react-i18next';
import useQuery from 'routes/searchQueryHook';
import { selectLead } from 'features/lead/leadSlice';
import DynamicColumns from '../report/dynamicColumns';
import { selectRoles } from 'features/roles/rolesSlice';
import UilEye from '@iconscout/react-unicons/icons/uil-eye';

const Lead = props => {
  let query = useQuery();
  const [openColapse, setOpenColapse] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const dispatch = useDispatch();
  const mobileSuggestions = useSelector(selectMobileSuggestions);
  const [loading, setLoading] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [vissibleColumns, setVissibleColumns] = useState(null);

  const { t } = useTranslation();

  // Colapsable panel Open/Close Handler
  const handleOpen = status => {
    setOpenColapse(status);
  };

  // get lead data from selectLead selector for tab title
  let { lead } = useSelector(e => selectLead(e, selectedTab));
  const {
    roles: { enableEncryption }
  } = useSelector(selectRoles);
  // Sppend Close button to TabTitle
  const tabTitle = (index, company) => {
    return (
      <TabNav
        totalTabs={selectedCompanies.length}
        index={index}
        id={company.id}
        title={`${company.name || lead.name || ''} (${company.id})`}
        removeTab={removeCompany}
        textLimit={12}
      />
    );
  };

  // initialize selected Tab Data after Redirect From other screen
  useEffect(() => {
    setSelectedTab(JSON.parse(storage.getItem('leads-selectedTab')));
    let dataLeads = JSON.parse(storage.getItem('leads-selectedLeads')) || [];
    setSelectedCompanies(dataLeads);
    updateSelectedData(dataLeads);
  }, []);

  // get id params from url if exist and add lead id in selectedLeads array and remove previously selected leads
  // and open sidebar
  useEffect(() => {
    let id = query.get('id');
    if (id) {
      updateCompaniesAndSaveToLS([{ id: Number(id) }]);
      updateTabAndSaveToLS(Number(id));
      handleOpen(true);
    }
  }, []);

  // select Company
  const handleSelect = ({ data }) => {
    if (selectedCompanies.filter(sc => sc.id === data[0].id).length === 0) {
      let companies = [data[0], ...selectedCompanies];
      updateCompaniesAndSaveToLS(companies);
    }
    updateTabAndSaveToLS(data[0].id);
    if (!openColapse) handleOpen(true);
  };

  // Select Tab Handler
  const handleTabSelect = tabKey => {
    if (selectedTab !== tabKey) {
      updateTabAndSaveToLS(Number(tabKey));
    }
  };

  // Remove  Tab From Selected Companies
  const removeCompany = (index, id, nextIndex) => {
    let companies = [...selectedCompanies];
    companies.splice(index, 1);
    if (selectedTab === selectedCompanies[index].id) {
      updateTabAndSaveToLS(selectedCompanies[nextIndex].id);
    }
    updateCompaniesAndSaveToLS(companies);
    dispatch(removeLeadFromState({ id: id }));
  };

  //  update Selected Companies and save to Local Storage
  const updateCompaniesAndSaveToLS = companies => {
    setSelectedCompanies(companies);
    storage.setItem('leads-selectedLeads', companies);
    updateSelectedData(companies);
  };

  const updateSelectedData = companies => {
    let checked = {};
    companies.forEach(com => {
      checked[com.id] = com;
    });
    setSelectedData(checked);
  };

  // update Tabs and save To Local Storage
  const updateTabAndSaveToLS = tabKey => {
    setSelectedTab(tabKey);
    storage.setItem('leads-selectedTab', tabKey);
  };

  //  TODO clear all selected tabs
  const clearAll = () => {
    updateCompaniesAndSaveToLS([]);
    updateTabAndSaveToLS('');
    dispatch(removeAllLeadFromState());

    // remove query params from url if exist
    if (query.has('id')) {
      query.delete('id');
      props.history.replace({
        search: ''
      });
    }
  };

  // update Selected Tab Menu For get Back to same menu after Redirecting
  const selectTabMenu = menuKey => {
    let index = selectedCompanies.findIndex(sc => sc.id === selectedTab);
    if (index > -1) {
      let companies = selectedCompanies;
      companies[index] = { ...companies[index], selectedMenu: menuKey };
      updateCompaniesAndSaveToLS(companies);
    }
  };

  const getFilterValue = data => {
    let updatedValue;
    if (data.name === 'createdOn' && data.operator === 'after') {
      updatedValue = moment(data.value).local();
    } else if (data.operator === 'eq') {
      updatedValue = moment(data.value).local();
    } else if (data.name === 'createdOn' && data.operator === 'before') {
      updatedValue = '2000-01-01T08:00:00.000Z';
    } else if (
      data.name === 'leadType' ||
      data.name === 'id' ||
      data.name === 'processedByCustomer' ||
      data.name === 'initiatedBy' ||
      data.name === 'appointmentStatus'
    ) {
      updatedValue = `${data.value || ''}`;
    } else {
      updatedValue = `%${data.value}%`;
    }
    return updatedValue;
  };

  const getDateFilterValue = data => {
    let updatedValue;
    if (data.name === 'createdOn' && data.operator === 'before') {
      updatedValue = moment(data.value).local();
    } else if (data.name === 'createdOn' && data.operator === 'after') {
      updatedValue = moment().local();
    } else {
      updatedValue = data.value;
    }
    return updatedValue;
  };

  const loadData = ({ skip, limit, sortInfo, filterValue }) => {
    try {
      setLoadingMessage('Loading ...');
      const updatedValue = filterValue
        .filter(v => v.value !== '')
        .map(v => ({
          propertyName: v.name,
          propertyValue1: getFilterValue(v),
          propertyValue2:
            v.name === 'createdOn' &&
            (v.operator === 'before' || v.operator === 'after')
              ? getDateFilterValue(v)
              : '',
          propertyValue3: [],
          filterDataType: 2,
          comparison: 2
        }));
      let payload = {
        start: skip,
        limit: limit,
        filter: JSON.stringify(updatedValue),
        dir: !sortInfo ? 'DESC' : sortInfo.dir === 1 ? 'ASC' : 'DESC',
        sort: sortInfo ? sortInfo.name : 'createdOn'
      };
      setSelectedFilters(payload);
      return getAllLeads(payload).then(response => {
        if (response.data.totalSize > 20) {
          setDataLimit(skip + response.data.data.length);
        } else {
          setDataLimit(response.data.data.length);
        }
        const totalCount = response.data.totalSize;
        setTotalRecords(totalCount);
        return { data: response.data.data, count: parseInt(totalCount) };
      });
    } catch (error) {
      toast.error(ToastTemplate.error(error || error.message));
    }
  };

  useEffect(() => {
    !mobileSuggestions && dispatch(fetchMobileSuggestionsAsync());
  }, [mobileSuggestions, dispatch]);

  const handleColumnsChange = data => {
    setVissibleColumns({ ...data });
  };

  const dataSource = useCallback(loadData, []);
  const [dataLimit, setDataLimit] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loadingMessage, setLoadingMessage] = useState('Loading ...');
  const renderColumnContextMenu = useCallback((menuProps, { cellProps }) => {
    menuProps.items = menuProps.items.splice(0, 8);
  }, []);

  const handleView = val => {
    console.log('view', val);
  };

  const ActionButtons = ({ value }) => (
    <>
      <Button
        className="btn-sm btn-flex mr-1"
        variant="lightgray-3"
        onClick={e => handleView(value)}
      >
        <UilEye className="mr-1" />
        View
      </Button>
    </>
  );

  return (
    <>
      <Container fluid>
        <Row>
          <Col className="m-3">
            <h2>
              Leads{' '}
              <Badge variant="primary p-1 badge-align">
                {loading ? '...' : `${dataLimit} / ${totalRecords}`}
              </Badge>
              <span className="ml-2">
                <OverlayTrigger
                  placement={toolTipPlacement}
                  overlay={renderToolTip(t('company.leadDetails.leadTooltip'))}
                >
                  <UILInfoCircle className="list-icon-after-text" />
                </OverlayTrigger>
              </span>
            </h2>
          </Col>
          <div className="d-flex" style={{ margin: '20px', gap: '10px' }}>
            <DynamicColumns
              handleChange={handleColumnsChange}
              menuOptions={leadsTableConfig(enableEncryption)}
              selectedFilters={selectedFilters}
              endPointAPI={exportAllLeads}
              fileNamePrefix="Leads"
            />
          </div>
        </Row>
        <Row>
          <Col className={loading && 'blux-text'}>
            <div className="mobile_view">
              <GridWrapper
                columns={leadsTableConfig(enableEncryption, ActionButtons)}
                totalRecords={totalRecords}
                dataSource={dataSource}
                setLoading={setLoading}
                loadingMessage={loadingMessage}
                renderColumnContextMenu={renderColumnContextMenu}
                selectedData={selectedData}
                handleSelect={handleSelect}
                vissibleColumns={vissibleColumns}
              />
            </div>
          </Col>
        </Row>
      </Container>
      {selectedCompanies.length ? (
        <SidebarRight handleOpen={handleOpen} open={openColapse}>
          <TabsContainer
            activeKey={
              selectedCompanies.filter(sc => sc.id === selectedTab).length
                ? selectedTab
                : selectedCompanies[0].id
            }
            clearAll={clearAll}
            openColapse={openColapse}
            selectedData={selectedCompanies}
            onTabSelect={e => handleTabSelect(e)}
          >
            {selectedCompanies.map((lead, index) => {
              return (
                <TabComponent
                  tabClassName={'tab-ui'}
                  key={index}
                  eventKey={lead.id}
                  title={tabTitle(index, lead)}
                  id={`tab-${lead.id}`}
                >
                  <LeadContainer
                    selectMenu={selectTabMenu}
                    selectedlead={lead}
                    removeSelectedLeads={clearAll}
                  />
                </TabComponent>
              );
            })}
          </TabsContainer>
        </SidebarRight>
      ) : null}
    </>
  );
};

export default Lead;
