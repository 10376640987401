import React from 'react';
import useViewport from 'helpers/viewport-hook';
import IntegrateWidgetCode from './subSections/integrateWidgetCode';
import Collapseable from 'components/Collapseable';
import UilPlug from '@iconscout/react-unicons/icons/uil-plug';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import {
  getHostById,
  selectCompaniesHosts,
  selectHostById,
  selectHosts,
  setCompnaiesHostToState
} from 'features/hosts/hostSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { Spinner } from 'react-bootstrap';

const TabContentWrapper = ({ index, company }) => {
  const screen = useViewport();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const getHostDomain = useSelector(selectHostById);
  const companyHostDomain = getHostDomain(company.hostId);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!companyHostDomain) {
      setLoading(true);
      getHostByHostId();
    }
  }, []);

  const getHostByHostId = async () => {
    const response = await getHostById(company.hostId);
    setLoading(false);
    dispatch(
      setCompnaiesHostToState({ [company.hostId]: response.data.data.domain })
    );
  };

  return (
    <div className={screen.xs ? 'p-3' : 'p-5'}>
      <Collapseable
        name={t('widget.collapse_item1')}
        description={t('widget.collapse_item1_tag1')}
        id={`${index + 1}_`}
        storageKey={'widget-editor-collapse'}
        icon={<UilPlug />}
        defaultOpen={true}
      >
        <IntegrateWidgetCode
          companyKey={company.companyKey}
          companyId={company.id}
          HostDomain={companyHostDomain}
          apiLoading={loading}
        />
      </Collapseable>
    </div>
  );
};

export default TabContentWrapper;
