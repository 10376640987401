import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import SimpleLabelTextBox from 'helpers/ui-controls/ui-components/SimpleLabelTextbox';
import SectionHeader from 'helpers/ui-controls/ui-components/SectionHeader';
import { ga4SectionSchema } from 'helpers/schemas/companySchemas/schemas';
import { success } from 'helpers/variables';
import { useTranslation } from 'react-i18next';
import { ga4Section } from 'helpers/schemas/companySchemas/initialValues';
import {
  mapServerValuesToInitialValues,
  setupTrackingPayload,
  ToastTemplate
} from 'helpers/generic.methods';
import {
  updateCompany,
  updateCompanyToState
} from 'features/companies/companiesSlice';
import { useDispatch } from 'react-redux';
import { Enums } from 'helpers/dropdown-enums';

const GoogleAnalytics4 = ({
  company,
  trackingFlags,
  permissionEdit,
  reflectObject
}) => {
  const { t } = useTranslation();
  const [ga4_chk, setGa4_chk] = useState(
    trackingFlags.googleAnalytics4Tracking
  );
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const [ga4SectionObj, setGa4SectionObj] = useState({
    ...ga4Section
  });
  const companyId = company.id;
  const [updatedCompanyData, setUpdatedDataCompany] = useState({});

  const dispatch = useDispatch();
  const trackingFormHandler = async formData => {
    console.log('submit');
    try {
      formData.companyTrackingTypes = setupTrackingPayload(
        company.id,
        ga4_chk,
        company.companyTrackingTypes,
        Enums.AnalyticsTracking.GoogleAnalytics4
      );
      setLoading(true);
      let payload = {
        ...formData,
        id: company.id,
        companyKey: company.companyKey
      };
      const response = await updateCompany(payload);
      setUpdatedDataCompany(response?.data?.data);
      reflectObject(response?.data?.data);

      toast.success(success.saveSuccess('Google Analytics 4 Tracking'));
      setEditable(false);
      setLoading(false);
    } catch (error) {
      toast.error(ToastTemplate.error(error || error.message));
      setLoading(false);
    }
  };
  /** Init Formik */
  const TrackingFormIk = useFormik({
    enableReinitialize: true,
    initialValues: ga4SectionObj,
    validationSchema: ga4_chk ? ga4SectionSchema : null,
    onSubmit: trackingFormHandler
  });
  /**
   * @name @handleOnChange
   * @description do something on field onChange
   * @requires html input element
   * */
  const handleOnChange = (element, key, value) => {
    TrackingFormIk.values[key] = value;
    TrackingFormIk.handleChange(element);
  };
  /**
   * @handleGa4Switch: callback function needs to send to headerComponent
   */
  const handleGa4Switch = checked => {
    setGa4_chk(checked);
  };
  useEffect(() => {
    handleGa4Switch(!!trackingFlags.googleAnalytics4Tracking);
    mapServerValuesToInitialValues(company, ga4Section)
      .then(data => {
        setGa4SectionObj({ ...ga4Section, ...data });
      })
      .catch(err => {
        throw new Error(err);
      });
    dispatch(updateCompanyToState({ id: companyId, data: updatedCompanyData }));
  }, [trackingFlags, company, updatedCompanyData, companyId, dispatch]);

  return (
    <Form
      className="form"
      onSubmit={TrackingFormIk.handleSubmit}
      autoComplete="off"
    >
      <section>
        <SectionHeader
          sectionHeading={t('company.trackingIntegration.heading5')}
          field="ga4Tracking"
          id={'ga4Tracking' + companyId}
          loading={loading}
          // disabled={loading || !TrackingFormIk.dirty}
          disabled={loading || !permissionEdit}
          error={TrackingFormIk.errors['ga4Tracking']}
          editable={editable}
          handleEditableState={setEditable}
          hasHeaderProperty={true}
          headerProperty={ga4_chk}
          headerPropertyOnChange={handleGa4Switch}
        />
        <div className="section-body">
          {ga4_chk ? (
            <ul
              className="editable-fields-lists"
              // style={!!ga4_chk ? {} : { display: 'none' }}
            >
              <SimpleLabelTextBox
                // Google Analytics 4 Measurement ID
                heading={t('company.trackingIntegration.measurementId.label')} //"Account Code for GA"
                field="googleAnalyticsMeasurementId"
                id={'googleAnalyticsMeasurementId' + companyId}
                dataProperty={company['googleAnalyticsMeasurementId']}
                error={TrackingFormIk.errors['googleAnalyticsMeasurementId']}
                titleText={t('company.trackingIntegration.measurementId.info')}
                helperText={t('company.trackingIntegration.measurementId.info')} //"Account Code for GA"
                editable={editable}
                handleChange={handleOnChange}
              />
            </ul>
          ) : null}
        </div>
      </section>
    </Form>
  );
};
export default GoogleAnalytics4;
