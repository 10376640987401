import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import SimpleLabelTextBox from 'helpers/ui-controls/ui-components/SimpleLabelTextbox';
import SectionHeader from 'helpers/ui-controls/ui-components/SectionHeader';
import { customTrackingSectionSchema } from 'helpers/schemas/companySchemas/schemas';
import { success } from 'helpers/variables';
import { customTrackingSection } from 'helpers/schemas/companySchemas/initialValues';
import {
  mapServerValuesToInitialValues,
  ToastTemplate
} from 'helpers/generic.methods';
import {
  updateCompany,
  updateCompanyToState
} from 'features/companies/companiesSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const CustomTracking = ({ company, trackingFlags, permissionEdit }) => {
  const [customTracking_chk, setCustomTracking_chk] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const [customTrackingSectionObj, setCustomTrackingSectionObj] = useState(
    customTrackingSection
  );
  const companyId = company.id;
  const [updatedCompanyData, setUpdatedDataCompany] = useState({});

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const trackingFormHandler = async formData => {
    try {
      setLoading(true);
      let payload = {
        ...formData,
        id: company.id,
        companyKey: company.companyKey
      };
      const response = await updateCompany(payload);
      setUpdatedDataCompany(response?.data?.data);
      toast.success(success.saveSuccess('Custom Tracking'));
      setEditable(false);
      setLoading(false);
    } catch (error) {
      toast.error(ToastTemplate.error(error || error.message));
      setLoading(false);
    }
  };
  /** Init Formik */
  const TrackingFormIk = useFormik({
    enableReinitialize: true,
    initialValues: customTrackingSectionObj,
    validationSchema: customTrackingSectionSchema,
    onSubmit: trackingFormHandler
  });
  /**
   * @name @handleOnChange
   * @description do something on field onChange
   * @requires html input element
   * */
  const handleOnChange = (element, key, value) => {
    TrackingFormIk.values[key] = value;
    TrackingFormIk.handleChange(element);
  };
  /**
   * @handleCustomTrackingSwitch: callback function needs to send to headerComponent
   */
  const handleCustomTrackingSwitch = checked => {
    setCustomTracking_chk(checked);
  };
  useEffect(() => {
    handleCustomTrackingSwitch(!!trackingFlags.customTrackingEnabled);
    mapServerValuesToInitialValues(company, customTrackingSection)
      .then(data => {
        setCustomTrackingSectionObj({ ...customTrackingSection, ...data });
      })
      .catch(err => {
        throw new Error(err);
      });
    dispatch(updateCompanyToState({ id: companyId, data: updatedCompanyData }));
  }, [trackingFlags, company, updatedCompanyData, companyId, dispatch]);
  return (
    <Form
      className="form"
      onSubmit={TrackingFormIk.handleSubmit}
      autoComplete="off"
    >
      <section>
        <SectionHeader
          sectionHeading={t('company.trackingIntegration.heading4')}
          field="customTracking"
          id={'customTracking' + companyId}
          loading={loading}
          // disabled={loading || !TrackingFormIk.dirty}
          disabled={loading || !permissionEdit}
          editable={editable}
          handleEditableState={setEditable}
          hasHeaderProperty={true}
          headerProperty={customTracking_chk}
          headerPropertyOnChange={handleCustomTrackingSwitch}
          handleChange={handleOnChange}
          readOnlySection={true}
          // Bug-572 turned off the edit button in facebook and custom tracking
          hardTurnOff={true}
        />
        <div className="section-body">
          <ul
            className="editable-fields-lists"
            style={!!customTracking_chk ? {} : { display: 'none' }}
          >
            <SimpleLabelTextBox
              heading={t(
                'company.trackingIntegration.analyticsTrackingCodeForChats.label'
              )}
              field="chatCodeForCustomAnalytics"
              id={'chatCodeForCustomAnalytics' + companyId}
              dataProperty={company['chatCodeForCustomAnalytics']}
              titleText={t(
                'company.trackingIntegration.analyticsTrackingCodeForChats.info'
              )}
              error={TrackingFormIk.errors['chatCodeForCustomAnalytics']}
              editable={editable}
              handleChange={handleOnChange}
              // for now making false as UI is not ready.
              multiline={true}
              readOnly={true}
            />
            <SimpleLabelTextBox
              heading={t(
                'company.trackingIntegration.analyticsTrackingCodeForLeads.label'
              )}
              field="leadCodeForCustomAnalytics"
              id={'leadCodeForCustomAnalytics' + companyId}
              dataProperty={company['leadCodeForCustomAnalytics']}
              titleText={t(
                'company.trackingIntegration.analyticsTrackingCodeForLeads.info'
              )}
              error={TrackingFormIk.errors['leadCodeForCustomAnalytics']}
              editable={editable}
              handleChange={handleOnChange}
              // for now making false as UI is not ready.
              multiline={true}
              readOnly={true}
            />
          </ul>
        </div>
      </section>
    </Form>
  );
};
export default CustomTracking;
