import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import SimpleLabelTextBox from 'helpers/ui-controls/ui-components/SimpleLabelTextbox';
import SectionHeader from 'helpers/ui-controls/ui-components/SectionHeader';
import dropdownEnums from 'helpers/dropdown-enums';
import { success } from 'helpers/variables';
import { leadEmailSection } from 'helpers/schemas/companySchemas/initialValues';
import {
  mapServerValuesToInitialValues,
  ToastTemplate
} from 'helpers/generic.methods';
import { leadEmailSchema } from 'helpers/schemas/companySchemas/schemas';
import StaticFeild from 'helpers/ui-controls/staticFeild';
import {
  updateCompany,
  updateCompanyToState
} from 'features/companies/companiesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ToggleToolTip from 'helpers/ui-controls/toggleToolTip';
import ToggleMenuButton from 'helpers/ui-controls/ui-components/ToggleMenuButton';
import { selectRoles } from 'features/roles/rolesSlice';

const LeadEmails = ({ company, permissionEdit, clickHandlerMenu }) => {
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const [leadEmailObj, setLeadEmailObj] = useState({ ...leadEmailSection });
  const companyId = company.id;
  const [updatedCompanyData, setUpdatedDataCompany] = useState({});
  const { roles } = useSelector(selectRoles);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const leadEmailFormHandler = async formData => {
    try {
      setLoading(true);
      let payload = {
        ...formData,
        id: company.id,
        companyKey: company.companyKey,
        leadEmailAddresses: formData.leadEmailAddresses
          .trim()
          .split(',')
          .filter(a => a)
          .join(','),
        chatTranscriptEmailAddresses: formData.chatTranscriptEmailAddresses
          .trim()
          .split(',')
          .filter(a => a)
          .join(',')
      };

      const response = await updateCompany(payload);
      setUpdatedDataCompany(response?.data?.data);
      toast.success(success.saveSuccess('Lead Email'));
      setEditable(false);
      setLoading(false);
    } catch (error) {
      toast.error(ToastTemplate.error(error || error.message));
      setLoading(false);
    }
  };
  /** Init Formik */
  const LeadEmailFormIk = useFormik({
    enableReinitialize: true,
    initialValues: leadEmailObj,
    validationSchema: leadEmailSchema,
    onSubmit: leadEmailFormHandler
  });

  const handleOnChange = (element, key, value) => {
    LeadEmailFormIk.values[key] = value;
    LeadEmailFormIk.handleChange(element);
  };

  useEffect(() => {
    mapServerValuesToInitialValues(company, leadEmailSection)
      .then(data => {
        setLeadEmailObj({
          ...leadEmailSection,
          ...data
        });
      })
      .catch(err => {
        throw new Error(err);
      });
    dispatch(updateCompanyToState({ id: companyId, data: updatedCompanyData }));
  }, [company, updatedCompanyData]);

  return (
    <>
      <Form
        className="form"
        onSubmit={LeadEmailFormIk.handleSubmit}
        autoComplete="off"
      >
        <div className="page-section">
          <ToggleMenuButton
            heading={t('company.leadEmails.heading1')}
            clickHandlerMenu={clickHandlerMenu}
          />
          <section>
            <SectionHeader
              field="facebook"
              loading={loading}
              // disabled={loading || !LeadEmailFormIk.dirty}
              disabled={!permissionEdit}
              editable={editable}
              error={LeadEmailFormIk.errors['lead email']}
              handleEditableState={setEditable}
              hasHeaderProperty={false}
              handleChange={handleOnChange}
            />
            <div className="section-body">
              <ul className="editable-fields-lists">
                <SimpleLabelTextBox
                  heading={t('company.leadEmails.leadEmailAddresses.label')}
                  field="leadEmailAddresses"
                  dataProperty={company['leadEmailAddresses']}
                  titleText={t('company.leadEmails.leadEmailAddresses.info')}
                  error={LeadEmailFormIk.errors['leadEmailAddresses']}
                  editable={editable}
                  handleChange={handleOnChange}
                  // for now making false as UI is not ready.
                  multiline={true}
                />
                <SimpleLabelTextBox
                  heading={t(
                    'company.leadEmails.invalidLeadEmailAddresses.label'
                  )}
                  titleText={t(
                    'company.leadEmails.invalidLeadEmailAddresses.info'
                  )}
                  field="invalidEmails"
                  dataProperty={company['invalidEmails']}
                  editable={false}
                  handleChange={handleOnChange}
                  readOnly={true}
                  //disabled={true}
                  // for now making false as UI is not ready.
                  multiline={true}
                />
                {!!company.classifyChatsAsSpam && (
                  <SimpleLabelTextBox
                    heading={t(
                      'company.leadEmails.chatTranscriptEmailAddresses.label'
                    )}
                    field="chatTranscriptEmailAddresses"
                    dataProperty={company['chatTranscriptEmailAddresses']}
                    titleText={t(
                      'company.leadEmails.chatTranscriptEmailAddresses.info'
                    )}
                    error={
                      LeadEmailFormIk.errors['chatTranscriptEmailAddresses']
                    }
                    editable={editable}
                    handleChange={handleOnChange}
                    // for now making false as UI is not ready.
                    multiline={true}
                  />
                )}

                {roles.isAdminCompany ? (
                  <ToggleToolTip
                    heading={t('company.leadEmails.classifyChatsAsSpam.label')}
                    toolTipFor="classify chat as spam"
                    flag={company.classifyChatsAsSpam}
                    titleText={t('company.leadEmails.classifyChatsAsSpam.info')}
                  />
                ) : null}

                {/* <ToggleToolTip
                  heading={t('company.leadEmails.verified.label')}
                  toolTipFor="Verified"
                  flag={company.verified}
                  titleText={t('company.leadEmails.verified.info')}
                /> */}

                <ToggleToolTip
                  heading={t('company.leadEmails.verificationEmailSent.label')}
                  toolTipFor="verificationEmailSent"
                  flag={company.verificationEmailSent}
                  titleText={t('company.leadEmails.verificationEmailSent.info')}
                />

                <ToggleToolTip
                  heading={t(
                    'company.leadEmails.hasInvalidEmailAddresses.label'
                  )}
                  toolTipFor="hasInvalidEmailAddresses"
                  flag={company.hasInvalidEmails}
                  titleText={t(
                    'company.leadEmails.hasInvalidEmailAddresses.info'
                  )}
                />
                {roles.isAdminCompany ? (
                  <SimpleLabelTextBox
                    heading={t('company.leadEmails.companyType.label')}
                    titleText={t('company.leadEmails.companyType.info')}
                    field="Company Type"
                    dataProperty={company.companyType}
                    dropdownFlag={true}
                    dropdownEnum={dropdownEnums.enum_CompanyType}
                  />
                ) : null}
                {/* <Form.Control
                    as="select"
                    onChange={() => updateData('companyType', 2)}
                    value={company.companyType}
                  >
                    {dropdownEnums.enum_CompanyType.map((e, key) => {
                      return (
                        <option key={key} value={e.value}>
                          {e.name}
                        </option>
                      );
                    })}
                  </Form.Control> */}
                <ToggleToolTip
                  heading={t('company.leadEmails.secureChats.label')}
                  toolTipFor="secureChats"
                  flag={company.requireSecureConnection}
                  titleText={t('company.leadEmails.secureChats.info')}
                />

                <ToggleToolTip
                  heading={t('company.leadEmails.secureLeads.label')}
                  toolTipFor="secureLeads"
                  flag={company.requireSecureLeads}
                  titleText={t('company.leadEmails.secureLeads.info')}
                />
              </ul>
            </div>
          </section>
        </div>
      </Form>
    </>
  );
};

export default LeadEmails;
