import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LoginForm from 'features/auth/LoginForm';
import storage from 'helpers/storage';
import { selectDynamicMeta } from 'features/auth/authSlice';
import SSO_Redirection from 'app/redirection-SSO';

const Login = props => {
  const [signupURL, setSignupURL] = useState(null);
  const dispatch = useDispatch();
  storage.setItem('AppMetaInfo', { host: props.match.params.hostKey });
  const _dynamicMeta = useSelector(selectDynamicMeta);

  useEffect(() => {
    if (_dynamicMeta) {
      if (_dynamicMeta.ssoEnabled) {
        SSO_Redirection({ isLoginRedirect: true });
      } else {
        document.getElementById('waitMessage').style.visibility = 'hidden';
        document.getElementById('waitMessage').style.display = 'none';
        document.getElementsByClassName('form-container')[0].style.display =
          'inherit';
        let signupURL = {
          allowSignup: _dynamicMeta.allowSignup,
          signupUrl: _dynamicMeta.signupUrl
        };
        setSignupURL(signupURL);
      }
    }
  }, [_dynamicMeta]);

  return (
    <div>
      <div className="form-container" style={{ display: 'none' }}>
        <div className="text-darkgray-1 mb-lg-48 mb-32">
          <h2 className="fz-40 mb-14 login-title">Login to dashboard</h2>
        </div>
        <LoginForm />
        <div
          className={
            signupURL?.allowSignup
              ? 'text-center'
              : 'd-flex justify-content-center align-items-center'
          }
          style={{ minHeight: '62px' }}
        >
          <Link to="/forgot-password">Forgot password?</Link>
          {signupURL?.allowSignup && (
            <p className="m-0 mt-2 text-darkgray-1">
              Don't have an account?{' '}
              <a className="fw-600" href={signupURL?.signupUrl} target="_blank">
                Sign Up
              </a>
            </p>
          )}
        </div>
      </div>
      <div
        id="waitMessage"
        className="justify-content-center align-items-center sso-no-data"
        style={{ textAlign: 'center', visibility: 'visible', display: 'flex' }}
      >
        <p style={{ fontSize: '20px' }}>Please wait. Verifying Details...</p>
      </div>
    </div>
  );
};

export default Login;
