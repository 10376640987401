import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import makeStyles from '@material-ui/styles/makeStyles';
import { formatTime } from 'helpers/generic.methods';
import StaticFeild from 'helpers/ui-controls/staticFeild';

const useStyles = makeStyles(theme => ({
  preventModal: {
    width: '520px !important'
  },
  link: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  detailHidden: {
    maxHeight: '0',
    overflow: 'hidden',
    transition: 'all 0.6s'
  },
  detailVisible: {
    transition: 'all 0.6s',
    maxHeight: '8em',
    overflow: 'hidden'
  }
}));

const PreventChatOpeningModal = ({ showModal, onHide, data = {} }) => {
  const [show, setShow] = React.useState(false);
  const classes = useStyles();
  return (
    <Modal
      show={showModal}
      onHide={onHide}
      size="lg"
      keyboard={false}
      id="preventChatOpeningModal"
      dialogClassName={classes.preventModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <Modal.Title id="contained-modal-title-vcenter">
          {data?.title}
        </Modal.Title>
        <div>
          <p>{data?.displayMessage}</p>
          {/* <span className={classes.link} onClick={() => setShow(!show)}>
            {show ? 'Hide Details' : 'Show details'}
          </span>
          <ul
            className={`editable-fields-list ${
              show ? classes.detailVisible : classes.detailHidden
            }`}
          >
            <StaticFeild
              heading={'Username'}
              field="username"
              dataProperty={data?.username}
            />

            <StaticFeild
              heading={'PickedUpBy'}
              field="pickedUpBy"
              dataProperty={data?.pickedupBy}
            />
            <StaticFeild
              heading={'UserType'}
              field="userType"
              dataProperty={data?.pickupByUser}
            />
            <StaticFeild
              heading={'PickedUpTime'}
              field="pickedUpTime"
              dataProperty={formatTime(
                data?.pickedupTime,
                'MM/DD/YYYY hh:mm:ss a'
              )}
            />
          </ul> */}
        </div>
      </Modal.Body>
      <Modal.Footer className="user-modal-footer">
        <Button
          type="button"
          variant="outline-primary"
          className="passBtn btn-md btn-flex float-right"
          onClick={onHide}
        >
          <span>Close</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PreventChatOpeningModal;
