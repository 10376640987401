import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getProvisionAsync,
  selectProvision,
  setProvisionToState
} from 'features/provisioning/ProvisioningSlice';
import ProvisionHeader from 'components/provision/ProvisionHeader';
import { Scrollbars } from 'react-custom-scrollbars';
import ProvisionDetails from './ProvisionDetails';
import NewOnboarding from './NewOnboarding';

const ProvisionContainer = ({ selectedProvision, handleOpen, setFunction }) => {
  const dispatch = useDispatch();
  const provisionId = selectedProvision.isNewonboarding
    ? null
    : selectedProvision.id;
  const { provision: provisionData } = useSelector(e =>
    selectProvision(e, provisionId)
  );
  const provision = !provisionId ? selectedProvision : provisionData;

  useEffect(() => {
    if (!selectedProvision.isNewonboarding) {
      if (!provision || !Object.keys(provision).length) {
        dispatch(
          getProvisionAsync({
            params: { id: parseInt(provisionId), isCrmRequired: true }
          })
        );
      }
    } else {
      let { provision, id } = selectedProvision;
      dispatch(setProvisionToState({ data: { ...provision, id } }));
    }
  }, [provisionId, dispatch]);

  return (
    <>
      <div>
        <Scrollbars
          style={{ borderRadius: '0 0 0 24px', height: 'calc(100vh - 130px)' }}
        >
          {/* <div className="page has-sidebar">
            <ProvisionHeader
              provision={provision}
              isNewonboarding={selectedProvision.isNewonboarding}
            />
          </div> */}

          <div className="page has-sidebar d-flex">
            <div className="page-content col px-0">
              {selectedProvision.isNewonboarding ? (
                <NewOnboarding
                  handleOpenSidebar={handleOpen}
                  setFunction={setFunction}
                />
              ) : (
                <ProvisionDetails provisioning={provision} />
              )}
            </div>
          </div>
        </Scrollbars>
      </div>
    </>
  );
};

export default ProvisionContainer;
