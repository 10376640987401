import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Badge, Button } from 'react-bootstrap';
import { Tab, Tabs } from 'react-bootstrap';
import SidebarRight from 'components/Sidebar/sidebar-right';
import storage from 'helpers/storage';
import {
  fetchMobileSuggestionsAsync,
  selectMobileSuggestions
} from 'features/fetchGeneralData/fetchGeneralDataOnAuth';
import {
  TabComponent,
  TabsContainer,
  TabNav,
  nextSelectIndex
} from '../../../components/TabsComponent';
import ReportContainer from './details/index';
import MyReports from './myReports/index';
import QueuedReports from './queuedReports';
import {
  exportAllMyReportList,
  exportAllQueuedReportList,
  removeAllReportsFromState,
  removeReportsFromState
} from 'features/report/reportsSlice';
import DeleteModal from '../modal/deleteModal';
import { UilPlus } from '@iconscout/react-unicons';
import { useTranslation } from 'react-i18next';
import CreateReportModal from '../modal/CreateReport';
import DynamicColumns from './dynamicColumns';
import {
  myReportTableConfig,
  queuedReportTableConfig
} from 'utils/tableConfig';
import { toast } from 'react-toastify';
import { errors, success, toastMsg } from 'helpers/variables';
import {
  createOrUpdateReport,
  updateQuReport
} from 'features/report/reportSlice';
import {
  getHosts,
  selectHosts,
  setHostsToState
} from 'features/hosts/hostSlice';

const Report = () => {
  const [openColapse, setOpenColapse] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [selectedReports, setSelectedReports] = useState([]);
  const [selectedModalReport, setSelectedModalReport] = useState({});
  const [selectedData, setSelectedData] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const mobileSuggestions = useSelector(selectMobileSuggestions);
  const { hosts: hostData } = useSelector(selectHosts);
  const [vissibleColumns, setVissibleColumns] = useState(null);
  const [queVissibleColumns, setQueVissibleColumns] = useState(null);
  const [hostList, setHostList] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [myReportsFilters, setMyReportsFilters] = useState(null);
  const [queReportsFilters, setQueReportsFilters] = useState(null);

  // Colapsable panel Open/Close Handler
  const handleOpen = status => {
    setOpenColapse(status);
  };

  const openCloseCreateReportModal = () => {
    setShow(!show);
  };

  // Sppend Close button to TabTitle
  const tabTitle = (index, company) => {
    return (
      <TabNav
        totalTabs={selectedReports.length}
        index={index}
        id={company.id}
        title={company.name || ''}
        removeTab={removeCompany}
        textLimit={12}
      />
    );
  };

  // initialize selected Tab Data after Redirect From other screen
  useEffect(() => {
    getOwnedHostsList();
    setSelectedTab(JSON.parse(storage.getItem('myReports-selectedTab')));
    let reportData =
      JSON.parse(storage.getItem('myReports-selectedMyReports')) || [];
    setSelectedReports(reportData);
    updateSelectedData(reportData);
  }, []);

  // Select Tab Handler
  const handleTabSelect = tabKey => {
    if (selectedTab !== tabKey) {
      updateTabAndSaveToLS(Number(tabKey));
    }
  };

  // Remove Tab From Selected Companies
  const removeCompany = (index, id, nextIndex) => {
    let companies = [...selectedReports];
    companies.splice(index, 1);

    if (selectedReports.length && selectedTab === selectedReports[index].id) {
      updateTabAndSaveToLS(Number(selectedReports[nextIndex].id));
    }

    updateCompaniesAndSaveToLS(companies);
    dispatch(removeReportsFromState({ id: id }));
  };

  const updateSelectedData = companies => {
    let checked = {};
    companies.forEach(com => {
      checked[com.id] = com;
    });
    setSelectedData(checked);
  };

  //  update Selected Companies and save to Local Storage
  const updateCompaniesAndSaveToLS = companies => {
    setSelectedReports(companies);
    updateSelectedData(companies);
    storage.setItem('myReports-selectedMyReports', companies);
  };

  // update Tabs and save To Local Storage
  const updateTabAndSaveToLS = tabKey => {
    setSelectedTab(tabKey);
    storage.setItem('myReports-selectedTab', tabKey);
  };

  //  TODO clear all selected tabs
  const clearAll = () => {
    updateCompaniesAndSaveToLS([]);
    updateTabAndSaveToLS('');
    dispatch(removeAllReportsFromState());
  };
  // delete Report
  const deleteHandler = data => {
    deleteOrDuplicateReportHandler(selectedModalReport, true);
  };

  const duplicateHandler = data => {
    deleteOrDuplicateReportHandler(selectedModalReport, false);
  };
  const onSubmitAndRun = () => {
    showHideInfomodalModal();
  };

  const showHideInfomodalModal = () => {
    setShowInfoModal(!showInfoModal);
  };

  // update Selected Tab Menu For get Back to same menu after Redirecting
  const selectTabMenu = menuKey => {
    let index = selectedReports.findIndex(sc => sc.id === selectedTab);
    if (index > -1) {
      let companies = selectedReports;
      companies[index] = { ...companies[index], selectedMenu: menuKey };
      updateCompaniesAndSaveToLS(companies);
    }
  };

  const handleCloseModal = (refetcData = false) => {
    setShowDeleteModal(false);
    setShowDuplicateModal(false);
    setShow(false);
    setSelectedModalReport({});
    if (refetcData) {
      setRefetch(refetcData);
    }
  };

  const handleDeleteModal = (e, data) => {
    e.stopPropagation();
    setSelectedModalReport(data);
    setShowDeleteModal(true);
  };
  const handleDuplicateModal = (e, data) => {
    e.stopPropagation();
    setSelectedModalReport(data);
    setShowDuplicateModal(true);
  };

  const handleColumnsChange = data => {
    setVissibleColumns({ ...data });
  };
  const handleQueColumnsChange = data => {
    setQueVissibleColumns({ ...data });
  };

  const deleteOrDuplicateReportHandler = (data, isDeleted = false) => {
    setModalLoading(true);
    try {
      let payload = {
        ...data,
        isDeleted
      };
      delete payload['createdOn'];
      delete payload['modifiedOn'];
      if (!payload.isDeleted) {
        payload = { ...payload, name: `Copy of ${payload.name}` };
        delete payload['id'];
      }
      if (key === 'reports') {
        createOrUpdateReport(payload, false).then(response => {
          afterResponse(response, isDeleted);
        });
      } else if (key === 'queue') {
        // Hit API
        updateQuReport(payload, false).then(response => {
          afterResponse({ data: {} }, isDeleted);
        });
      }
    } catch {
      toast.error(toastMsg.errors.createError);
      setModalLoading(false);
      setSelectedModalReport({});
      // handleCloseModal();
    }
  };

  const afterResponse = (res, isDeleted) => {
    if (res && res.data) {
      let response = res.data.data;
      setModalLoading(false);
      setSelectedModalReport({});
      toast.success(
        isDeleted
          ? success.deleteSuccess('Report')
          : success.duplicateSuccess('Report')
      );
      handleCloseModal(true);

      if (isDeleted) {
        if (response) {
          if (key == 'reports') {
            let index = selectedReports.findIndex(sr => sr.id == response.id);
            removeCompany(
              index,
              response.id,
              nextSelectIndex(index, selectedReports.length)
            );
          } else {
            let filteredReports = selectedReports.filter(
              sc => sc.id !== response.id
            );
            updateCompaniesAndSaveToLS(filteredReports);
          }
        }
      }
    }
  };

  const getOwnedHostsList = () => {
    try {
      if (!hostData || !hostData.length) {
        getHosts().then(response => {
          if (response && response.data) {
            const { data } = response.data;
            setHostList(data || []);
            dispatch(setHostsToState(data || []));
          }
        });
      } else {
        setHostList(hostData || []);
      }
    } catch {
      console.log('error');
    }
  };
  const handleSelect = payload => {
    const { data } = payload;
    if (data.length || Object.keys(data).length) {
      let temp = data;
      if (data.length) temp = data[0];
      let selected = selectedReports.filter(sc => sc.id === Number(temp.id));
      if (selected.length === 0) {
        let companies = [
          { ...temp, selectedMenu: 'parameters' },
          ...selectedReports
        ];
        updateCompaniesAndSaveToLS(companies);
      } else if (data instanceof Object && !Array.isArray(data)) {
        if (!Object.keys(selected).includes(data.id)) {
          let index = selectedReports.findIndex(com => com.id === data.id);
          removeCompany(index, data.id);
        }
      }

      updateTabAndSaveToLS(temp.id);
      if (Array.isArray(data)) handleOpen(true);
    } else {
      clearAll();
    }
  };

  const changeTab = key => {
    if (key !== 'reports') {
      setOpenColapse(false);
    }
    setKey(key);
  };

  useEffect(() => {
    !mobileSuggestions && dispatch(fetchMobileSuggestionsAsync());
  }, [mobileSuggestions, dispatch]);

  const [dataLimit, setDataLimit] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [key, setKey] = useState('reports');
  return (
    <>
      <Container fluid>
        <Row>
          <Col className="m-3">
            <h2>
              Reports{' '}
              <Badge variant="primary p-1 badge-align">
                {loading ? '...' : `${dataLimit} / ${totalRecords}`}
              </Badge>
            </h2>
          </Col>
          <div className="d-flex" style={{ margin: '20px', gap: '10px' }}>
            <Button
              className="btn-md d-flex align-items-center"
              variant="primary"
              onClick={openCloseCreateReportModal}
            >
              <UilPlus className="mr-1" style={{ height: 20, width: 20 }} />
              {t('company.reportDetails.createReport')}
            </Button>
            <DynamicColumns
              key={key == 'reports' ? 1 : 2}
              handleChange={
                key === 'reports' ? handleColumnsChange : handleQueColumnsChange
              }
              menuOptions={
                key === 'reports'
                  ? myReportTableConfig()
                  : queuedReportTableConfig()
              }
              selectedFilters={
                key === 'reports' ? myReportsFilters : queReportsFilters
              }
              endPointAPI={
                key === 'reports'
                  ? exportAllMyReportList
                  : exportAllQueuedReportList
              }
              fileNamePrefix={key === 'reports' ? 'Reports' : 'Queued-Reports'}
            />
          </div>
        </Row>
        <Row>
          <Col className="rounded-border" style={{ overflow: 'hidden' }}>
            <Tabs
              className="uncontrolledTabs"
              activeKey={key}
              onSelect={k => changeTab(k)}
              unmountOnExit={true}
            >
              <Tab className="tab-ui" eventKey="reports" title="My Reports">
                <MyReports
                  setDataLimit={setDataLimit}
                  setTotalRecords={setTotalRecords}
                  setLoading={setLoading}
                  loading={loading}
                  selectedReports={[...selectedReports]}
                  // setSelectedReports={setSelectedReports}
                  selectedData={{ ...selectedData }}
                  setOpenColapse={setOpenColapse}
                  updateCompaniesAndSaveToLS={updateCompaniesAndSaveToLS}
                  updateTabAndSaveToLS={updateTabAndSaveToLS}
                  totalRecords={totalRecords}
                  handleDelete={handleDeleteModal}
                  handleDuplicate={handleDuplicateModal}
                  vissibleColumns={vissibleColumns}
                  handleSelect={handleSelect}
                  refetch={refetch}
                  setRefetch={setRefetch}
                  setFilters={setMyReportsFilters}
                />
              </Tab>
              <Tab className="tab-ui" eventKey="queue" title="Reports Queue">
                <QueuedReports
                  setDataLimit={setDataLimit}
                  setTotalRecords={setTotalRecords}
                  setLoading={setLoading}
                  loading={loading}
                  totalRecords={totalRecords}
                  handleDelete={handleDeleteModal}
                  handleDuplicate={handleDuplicateModal}
                  vissibleColumns={queVissibleColumns}
                  // handleSelect={handleSelect}
                  refetch={refetch}
                  setRefetch={setRefetch}
                  setFilters={setQueReportsFilters}
                />
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
      {selectedReports.length ? (
        <SidebarRight
          handleOpen={handleOpen}
          open={openColapse}
          disabled={key !== 'reports'}
          hideButtons={key !== 'reports'}
        >
          {/* <button onClick={() => clearAll()}>Clear All</button> */}
          <TabsContainer
            activeKey={
              selectedTab
              // selectedReports.filter(sc => sc.id === selectedTab).length
              //   ? selectedTab
              //   : selectedReports[0].id
            }
            clearAll={clearAll}
            openColapse={openColapse}
            onTabSelect={e => handleTabSelect(e)}
            selectedData={selectedReports}
          >
            {selectedReports.map((company, index) => (
              <TabComponent
                tabClassName={'tab-ui'}
                key={index}
                eventKey={company.id}
                title={tabTitle(index, company)}
                id={`tab-${company.id}`}
              >
                <ReportContainer
                  selectMenu={selectTabMenu}
                  selectedCompany={company}
                  hostList={hostList}
                  setRefetch={setRefetch}
                />
              </TabComponent>
            ))}
          </TabsContainer>
        </SidebarRight>
      ) : null}
      <DeleteModal
        onSubmit={deleteHandler}
        eventHideModal={handleCloseModal}
        showModal={showDeleteModal}
        loading={modalLoading}
        title="Delete report"
        infoText="Are you sure you want to delete this report?"
        submitButtonTitle="Delete"
      />
      <DeleteModal
        onSubmit={duplicateHandler}
        eventHideModal={handleCloseModal}
        showModal={showDuplicateModal}
        loading={modalLoading}
        title="Duplicate report"
        infoText="Are you sure you want to duplicate this report?"
        submitButtonTitle="Duplicate"
      />
      <DeleteModal
        // onSubmit={deleteHandler}
        eventHideModal={showHideInfomodalModal}
        showModal={showInfoModal}
        loading={modalLoading}
        title="Info"
        infoText='Your report has been submitted. View its progress in the "Report Queue" tab.
        Note: It may take up to 30 seconds for your report to show in the Queue.'
        // submitButtonTitle="Delete"
        cancelButtonTitle="Ok"
        submitBtn={false}
      />
      <CreateReportModal
        title={'New report'}
        eventHideModal={handleCloseModal}
        showModal={show}
        onSubmitAndRun={onSubmitAndRun}
        hostList={hostList}
        // editData={profile}
      />
    </>
  );
};

export default Report;
