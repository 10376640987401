import { UilUserCircle } from '@iconscout/react-unicons';
import AddStatementsIcon from 'static/images/whats-new-section/Add-Statements.png';
import AutoSuggestionsIcon from 'static/images/whats-new-section/Auto-Suggestions.png';
import ImprovedUIIcon from 'static/images/whats-new-section/Improved-UI.png';
import UnreadMsgNotificationIcon from 'static/images/whats-new-section/Unread-Message-Notification.png';
import useViewport from 'helpers/viewport-hook';

const whatsNewItems = [
  {
    id: 1,
    heading: 'Improved UI',
    description:
      'Get the seemless user experience with improved user interface',
    image: ImprovedUIIcon
  },
  {
    id: 2,
    heading: 'Unread Message Notification',
    description:
      'Get unread message and new chat notifications in the Messaging Dashboard',
    image: UnreadMsgNotificationIcon
  },
  {
    id: 3,
    heading: 'Auto Suggestions',
    description:
      'Auto suggestions will assist you to quickly send statements as you type, to save response time',
    image: AutoSuggestionsIcon
  },
  {
    id: 4,
    heading: 'Add Statements',
    description:
      'User can now add statements for themselves inside the script section',
    image: AddStatementsIcon
  }
];

const WhatsNewSection = () => {
  const screen = useViewport();

  return (
    <div className={`${screen.xs ? 'mt-3' : ''}`}>
      <h2 className="fw-500">What's New</h2>
      <div className="row mt-1">
        <div className="col-md-11 px-0">
          <div className="row">
            {whatsNewItems.map(item => {
              return (
                <div className="col-md-6" key={item.id}>
                  <div className="card border-0 bg-transparent">
                    <div className="card-body p-2 d-flex align-items-center">
                      <img src={item.image} height="60" width="60" />
                      <div className="ml-2">
                        <h5 className="fw-500 mb-1">{item.heading}</h5>
                        <p
                          className="m-0 text-darkgray-1 fz-14"
                          style={{ lineHeight: '1' }}
                        >
                          {item.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default WhatsNewSection;
