import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getReportAsync, selectReport } from 'features/report/reportsSlice';
import ReportHeader from 'components/report/ReportHeader';
import { Scrollbars } from 'react-custom-scrollbars';
import NonCollapsabelSidebar from 'components/nonCollapsableSidebar/index';
import { reportSideBarMenu } from './ReportRoutes';
import storage from 'helpers/storage';
import { isMobile } from 'react-device-detect';

const ReportContainer = ({
  match,
  selectMenu,
  selectedCompany,
  hostList,
  setRefetch
}) => {
  const dispatch = useDispatch();
  const selectedReport = selectedCompany;
  const reportId = selectedReport.id;
  const { report } = useSelector(e => selectReport(e, reportId));
  const checkMenu = JSON.parse(storage.getItem('showSubMenu'));
  const [checkSubMenu, setSubMenuCheck] = useState(checkMenu);

  const clickHandlerMenu = () => {
    storage.setItem('showSubMenu', !checkSubMenu);
    setSubMenuCheck(!checkSubMenu);
  };

  useEffect(() => {
    if (!report || !Object.keys(report).length) {
      dispatch(
        getReportAsync({
          params: { id: parseInt(reportId), isCrmRequired: true }
        })
      );
    }
  }, [reportId, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isMobile) {
      storage.setItem('showSubMenu', true);
    } else {
      storage.setItem('showSubMenu', false);
    }
  }, []);

  return (
    <>
      <div>
        <Scrollbars style={{ height: 'calc(100vh - 130px)' }}>
          <div className="page has-sidebar">
            <ReportHeader companyId={reportId} />
          </div>
          <div
            className="page has-sidebar d-flex"
            style={{ height: 'calc(100vh - 225px)' }}
          >
            <div
              className={`page-sidebar border-bottom-0 border-right-0 ${
                isMobile && 'subMenu-visibility'
              } ${!checkMenu && ' display-none'}`}
            >
              <NonCollapsabelSidebar
                selectedMenu={
                  (selectedReport && selectedReport.selectedMenu) || ''
                }
                selectMenu={selectMenu}
                matchedRoute={match}
              />
            </div>
            <div
              className="page-content col px-0"
              style={{
                borderLeft: '1px solid #dfe3e8'
              }}
            >
              <div
                className="page-body"
                style={{
                  borderLeft: 'inherit'
                }}
              >
                {reportSideBarMenu && Object.keys(report).length
                  ? reportSideBarMenu.map((menuItem, key1) =>
                      menuItem.subItems.map((submenuItem, key2) =>
                        submenuItem.id === selectedReport.selectedMenu
                          ? submenuItem.child({
                              report: report,
                              hostList: hostList,
                              key: key1 + key2,
                              clickHandlerMenu: clickHandlerMenu,
                              setRefetch: setRefetch
                            })
                          : null
                      )
                    )
                  : null}
              </div>
            </div>
          </div>
        </Scrollbars>
      </div>
    </>
  );
};

export default ReportContainer;
