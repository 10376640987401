import React, { useRef, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import ScrollBar from 'components/Scrollbars';
import { formatTime } from 'helpers/generic.methods';
import MessageContainer from './MessageContainer';
import { Enums } from 'helpers/dropdown-enums';
import { isNormalChat, responseTypeUserIds } from '../Helper';
import useViewport from 'helpers/viewport-hook';
import { toggleChatDetailsRightPane } from 'features/chat/chatSlice';
import { useDispatch } from 'react-redux';

const AllMessages = ({
  selectedChat,
  chatById,
  isChatEnded,
  userImage,
  inputMessage,
  chatStatus,
  isConsentGiven
}) => {
  const [height, setHeight] = useState(0);
  const messageListEndRef = useRef(null);
  const dispatch = useDispatch();
  const screen = useViewport();

  const scrollToBottom = () => {
    setTimeout(() => {
      messageListEndRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: screen.xs ? 'center' : 'end',
        inline: screen.xs ? 'center' : 'end'
      });
    }, 200);
  };

  useEffect(() => {
    //  if new messages updated then scroll to last message
    setTimeout(scrollToBottom, 200);
  }, [chatById?.messages?.length, selectedChat?.data?.chatId]);

  useEffect(() => {
    let inputElem = document.getElementById('msg-input');
    let inputScrollHeight = inputElem.scrollHeight;
    // if inputScrollHeight is 76 or above, means textarea have 4 or above rows
    // when textarea have 4 or above rows we need to add height on messages container to scroll properly
    // and messages should not be visible from behind textarea
    if (inputScrollHeight >= 76) {
      setHeight(screen.xs ? 45 : 19);
    } else {
      setHeight(0);
    }
  }, [chatById?.messages?.length, inputMessage]);

  const getMessages = () => {
    var messages = [];
    var agentMessages = [];
    var clientMessages = [];
    if (
      chatById &&
      Object.keys(chatById).length &&
      chatById.messages.length > 0
    ) {
      messages = JSON.parse(JSON.stringify(chatById.messages));
      var greyOut = true;
      if (
        (chatStatus == Enums.ChatStatus.ClientHandling ||
          chatStatus == Enums.ChatStatus.Inactive) &&
        selectedChat?.data.isOptInConsentGiven
      ) {
        messages.map(msg => {
          if (greyOut) {
            agentMessages.push(msg);
          } else {
            clientMessages.push(msg);
          }
          if (Enums.ChatEndedMessages.includes(msg.text)) {
            greyOut = false;
          }
        });
      }
    }
    //insert info message before client's messages
    if (
      clientMessages.length > 0 ||
      (chatStatus == Enums.ChatStatus.Inactive &&
        selectedChat?.data.isOptInConsentGiven)
    ) {
      //make a fake system message
      var systemMessage = agentMessages[agentMessages.length - 1];
      if (systemMessage) {
        systemMessage = JSON.parse(JSON.stringify(systemMessage));
        systemMessage.text = 'See Message History with chat center agent above';
        systemMessage.createdOn = '';
        clientMessages.unshift(systemMessage);
      }
    }
    return {
      agentMessages,
      clientMessages
    };
  };

  return (
    <div className="col-md-12 h-100">
      <ScrollBar
        horizontalScroll={false}
        style={{
          height: `calc(100% - ${screen.xs ? 60 + height : 130 + height}px - ${
            isConsentGiven && chatStatus == Enums.ChatStatus.Inactive ? 45 : 0
          }px)`,
          paddingRight: '10px'
        }}
      >
        <div
          className="chat_box touchscroll chat_box_colors_a mr-3"
          style={{ padding: isMobile ? '0px' : '15px 30px 0px' }}
        >
          <div className="chat-date-time">
            {formatTime(selectedChat?.data?.createdOn, 'dddd DD, MMMM')}
          </div>
          {isNormalChat(selectedChat?.data) &&
            chatById &&
            Object.keys(chatById).length &&
            chatById.messages.length > 0 &&
            chatById.messages.map((msg, i) => (
              <MessageContainer
                // showAvatar={
                //   msg.userId !== chatById?.messages[i - 1]?.userId &&
                //   msg.userId > 0
                // }
                showAvatar={
                  (msg.userId > 0 ||
                    responseTypeUserIds.includes(msg.userId)) &&
                  msg.userId !== chatById?.messages[i - 1]?.userId
                }
                alertMsg={
                  msg.userId < 0 && !responseTypeUserIds.includes(msg.userId)
                }
                key={msg.id}
                msg={msg}
                index={i}
                userImage={userImage}
                isChatEnded={isChatEnded}
                messagesLength={chatById.messages.length}
                nextMessage={chatById?.messages[i + 1]}
              />
            ))}
          {(chatStatus == Enums.ChatStatus.ClientHandling ||
            chatStatus == Enums.ChatStatus.Inactive) &&
            selectedChat?.data.isOptInConsentGiven && (
              <div
                style={{
                  display: getMessages().agentMessages ? '' : 'none',
                  background: '#e9e9e966'
                }}
              >
                {getMessages().agentMessages.map((msg, i) => (
                  <>
                    <MessageContainer
                      // showAvatar={
                      //   msg.userId !== chatById?.messages[i - 1]?.userId &&
                      //   msg.userId > 0
                      // }
                      showAvatar={
                        (msg.userId > 0 ||
                          responseTypeUserIds.includes(msg.userId)) &&
                        msg.userId !== chatById?.messages[i - 1]?.userId
                      }
                      alertMsg={
                        msg.userId < 0 &&
                        !responseTypeUserIds.includes(msg.userId)
                      }
                      key={msg.id}
                      msg={msg}
                      index={i}
                      userImage={userImage}
                      isChatEnded={isChatEnded}
                      messagesLength={chatById.messages.length}
                      nextMessage={chatById?.messages[i + 1]}
                      greyout={
                        (chatStatus == Enums.ChatStatus.ClientHandling ||
                          chatStatus == Enums.ChatStatus.Inactive) &&
                        selectedChat?.data.isOptInConsentGiven
                      }
                    />

                    {chatStatus == Enums.ChatStatus.ClientHandling &&
                      Enums.ChatEndedMessages.includes(msg.text) && <hr />}
                  </>
                ))}
              </div>
            )}

          <div style={{ display: getMessages().clientMessages ? '' : 'none' }}>
            {getMessages().clientMessages.map((msg, i) => (
              <>
                <MessageContainer
                  // showAvatar={
                  //   msg.userId !== chatById?.messages[i - 1]?.userId &&
                  //   msg.userId > 0
                  // }
                  showAvatar={
                    (msg.userId > 0 ||
                      responseTypeUserIds.includes(msg.userId)) &&
                    msg.userId !== chatById?.messages[i - 1]?.userId
                  }
                  alertMsg={
                    msg.userId < 0 && !responseTypeUserIds.includes(msg.userId)
                  }
                  key={msg.id}
                  msg={msg}
                  index={i}
                  userImage={userImage}
                  isChatEnded={isChatEnded}
                  messagesLength={chatById.messages.length}
                  nextMessage={chatById?.messages[i + 1]}
                  greyout={false}
                />

                {chatStatus == Enums.ChatStatus.ClientHandling &&
                  Enums.ChatEndedMessages.includes(msg.text) && <hr />}
              </>
            ))}
          </div>

          <div ref={messageListEndRef}></div>
        </div>
      </ScrollBar>
    </div>
  );
};

export default AllMessages;
