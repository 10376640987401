import { useEffect, useState } from 'react';
import { Slide } from '@material-ui/core';
import { Col } from 'react-bootstrap';

import MobileAppContent from './mobile-app-content';
import useViewport from 'helpers/viewport-hook';
import WhatsNewSection from './whats-new-section';
import { AppMetaProvider } from 'components/Sidebar/helper';

const getImages = require.context(
  'static/images/MD-coming-soon-form',
  true,
  /\.(png|jpe?g|svg)$/
);

const ImageSection = ({ isGrid }) => {
  let theme = AppMetaProvider().GetCurrentTheme() || 'blue';

  const screen = useViewport();
  const [desktop, setDesktop] = useState(false);
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    setMobile(true);
    setTimeout(() => {
      setDesktop(true);
    }, 200);
  }, []);

  const getImage = (name = '', type = 'web') => {
    let getFile = getImages;
    try {
      return getFile(`./responsive-${type}/${theme}/${name}.png`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Col
      xs={12}
      md={!isGrid ? 12 : 8}
      className={`blux-text d-md-block ${isGrid ? 'd-none pl-0' : ''}`}
    >
      <div
        className={`MD-signup-image  flex-column ${
          !isGrid ? 'MD-signup-image-no-form position-relative mt-2' : 'm-3'
        } ${
          screen.xs &&
          'justify-content-center align-items-center image-section-on-mobile'
        }`}
      >
        {!screen.xs && (
          <Slide
            direction="right"
            in={desktop}
            mountOnEnter
            unmountOnExit
            timeout={1900}
          >
            <picture className="desktop-img image-rendering">
              <source
                srcSet={getImage('webTabletScreen')}
                media="(max-width: 768px)"
              />
              <source
                srcSet={getImage('webLargeScreen')}
                media="(min-width: 769px) and (max-width: 1440px)"
              />
              <source
                srcSet={getImage('webLargeScreen')}
                media="(min-width: 1444px)"
              />
              <img
                src={getImage('webDesktopScreen')}
                alt="MD-web-screen"
                width={'100%'}
              />
            </picture>
          </Slide>
        )}
        <Slide
          direction="right"
          in={mobile}
          mountOnEnter
          unmountOnExit
          timeout={1600}
        >
          <picture className={`${!screen.xs && 'mobile-img'} image-rendering`}>
            <source
              srcSet={getImage('largeScreen', 'mobile')}
              media="(max-width: 768px)"
            />
            <source
              srcSet={getImage('desktopScreen', 'mobile')}
              media="(min-width: 769px) and (max-width: 1440px)"
            />
            <source
              srcSet={getImage('largeScreen', 'mobile')}
              media="(min-width: 1444px)"
            />
            <img
              src={getImage('tabletScreen', 'mobile')}
              alt="MD-mobile-screen"
              width={'100%'}
            />
          </picture>
        </Slide>

        {isGrid && (
          <Slide
            direction="up"
            in={mobile}
            mountOnEnter
            unmountOnExit
            timeout={1600}
          >
            <div className="mx-5" style={{ position: 'absolute', bottom: '0' }}>
              <WhatsNewSection />
            </div>
          </Slide>
        )}
      </div>

      {!isGrid && (
        <div>
          <Slide
            direction="up"
            in={desktop}
            mountOnEnter
            unmountOnExit
            timeout={1900}
          >
            <div>
              <MobileAppContent
                showText={false}
                linkStyles={{
                  maxWidth: screen.xs ? '100%' : '30%',
                  margin: '0 auto'
                }}
              />
            </div>
          </Slide>
        </div>
      )}
    </Col>
  );
};
export default ImageSection;
