import React, { useState } from 'react';
import { dayAgo, getCookie, truncateText } from 'helpers/generic.methods';
import TypingIndicator from '../TypingIndicator';
import ActionButtons from './ActionButtons';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSelectedChatId,
  getSelectedChatLastMessage,
  getSelectedChatStarted,
  getSelectedChatTypingIndicator,
  getShelvedChatList,
  getVisitorInfoName,
  removeChatFromShelved
} from 'features/messagingChats/messagingChats';
import { getUnreadMsgsCountByChatId } from 'features/messagingChats/messagingChats';
import './index.scss';
import { getPlainStringFromHTMLElementAsString } from 'helpers/validate';
import { Enums } from 'helpers/dropdown-enums';
import IdleCounter from './IdleCounter';
import { showTypingIndicatorInChat } from '../Helper';
import Title from './title';
import TruncateWithToolTip from 'components/TruncateWithToolTip';
import { chatInitiatedByPlatformObj } from 'helpers/messaging/message.methods';
import {
  Badge,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles
} from '@material-ui/core';
import smsIcon from '../../../../assets/images/smsIcon.png';
import webIcon from '../../../../assets/images/webIcon.png';
import { ReactComponent as FacebookIcon } from 'static/images/chat-icons/Facebook.svg';
import { ReactComponent as WebIcon } from 'static/images/chat-icons/Web.svg';
import { ReactComponent as SMSIcon } from 'static/images/chat-icons/SMS.svg';
import { ReactComponent as GoogleIcon } from 'static/images/chat-icons/Google.svg';
import { Dot } from '../UserInfo';
import { GetLeadType } from 'components/lead/leadTypeBadge';
import { selectLogin } from 'features/auth/authSlice';
import useQuery from 'routes/searchQueryHook';
import { Web } from '@material-ui/icons';
import { width } from '@material-ui/system';

const initiatedByObj = { ...chatInitiatedByPlatformObj() };

const chatIcons = {
  Web: <WebIcon className="chat-icon" width={30} height={30} />,
  SMS: <SMSIcon className="chat-icon" width={30} height={30} />,
  Facebook: <FacebookIcon className="chat-icon" width={30} height={30} />,
  GMB: <GoogleIcon className="chat-icon" width={30} height={30} />,
  OptIn: <SMSIcon className="chat-icon" width={30} height={30} />
};

const muiStyling = makeStyles(theme => ({
  activeBackground: {
    background: '#e6e6e6 !important'
  },
  active: {
    backgroundColor: 'var(--primary-4) !important'
  }
}));

const ChatListItem = ({
  chat,
  onChatClickHandler,
  onAccept,
  onIgnore,
  onReject,
  showActionBtns,
  eventKey,
  roomType,
  isOperator = false,
  isAgent = false
}) => {
  const useStyle = muiStyling();
  const query = useQuery();
  const lastMessage = useSelector(getSelectedChatLastMessage);
  const user = useSelector(selectLogin);
  const isTyping = useSelector(getSelectedChatTypingIndicator);
  const isChatStarted = useSelector(getSelectedChatStarted);
  const selectedChatId = useSelector(getSelectedChatId);
  const getVisitorName = useSelector(getVisitorInfoName);
  const unreadMsgCount = useSelector(getUnreadMsgsCountByChatId);
  const shelvedChatList = useSelector(getShelvedChatList);
  let unreadCount = unreadMsgCount(chat.getId());
  let dispatch = useDispatch();
  const [chatInitialTime, setChatInitialTime] = useState('');
  const handleShelvedChatsOnChatEnded = chatId => {
    if (
      shelvedChatList.length &&
      shelvedChatList.some(chat => chat.getId() === chatId)
    ) {
      dispatch(removeChatFromShelved({ id: chatId }));
    }
  };

  // chat?.data?.isSms is checked for transferred chat or SMS chat;
  const isSmsChat =
    chat?.data?.isSms ||
    chat?.data?.isSMS ||
    initiatedByObj[chat?.data?.initiatedBy] === 'SMS';

  const canMarkAsDisabled = chatId => {
    let qChatId = query.get('chatId');
    let disabled = false;
    if (user?.profile?.UserRoles?.includes('MessagingLeadsAccessOnly')) {
      if (!qChatId) {
        qChatId = getCookie('X-Auth-Accessor-Id');
      }
      if (chatId !== Number(qChatId)) {
        disabled = true;
      }
    }
    return disabled;
  };

  const getChatIcon = () => {
    let key = isSmsChat ? 'SMS' : initiatedByObj[chat?.data?.initiatedBy];
    return chatIcons[key];
  };

  const handleChatInitialTime = chat => {
    let lastMessageCreatedOn = lastMessage(chat.getId())?.createdOn;
    if (lastMessageCreatedOn) {
      setChatInitialTime(dayAgo(lastMessageCreatedOn));
    } else {
      setChatInitialTime(dayAgo(chat.getData().createdOn));
    }
  };

  const isOptInChat = chat?.data.status == Enums.ChatStatus.ClientHandling;
  const ChatIdAndLocationText = ({ chat, styles = {}, limit = 21 }) => (
    <span
      style={{
        ...styles,
        display: 'flex',
        alignItems: 'center',
        maxWidth: 'inherit'
      }}
    >
      <span
        style={{
          flex: '1',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        <span className="">
          {chat?.getId()}
          {chat.getLocation() || chat?.data?.visitorInfoPhone ? (
            <Dot size="4" styles={{ backgroundColor: 'var(--darkgray-2)' }} />
          ) : (
            ''
          )}
        </span>
        <TruncateWithToolTip
          truncateEnabled={false}
          style={{ color: 'var(--darkgray-2)' }}
          text={`${
            isSmsChat
              ? `${chat?.data?.visitorInfoPhone}`
              : `${chat.getLocation()}`
          }`}
          limit={limit}
        />
      </span>
    </span>
  );

  var isClientHandlingChat =
    chat?.data.status == Enums.ChatStatus.ClientHandling;
  return (
    <>
      <List style={{ padding: '8px 0 0 0' }}>
        <ListItem
          button
          classes={{
            selected: useStyle.active
          }}
          disabled={canMarkAsDisabled(chat.getId())}
          disableGutters={true}
          style={{
            border: '1px solid #DFE3E8',
            borderRadius: '4px',
            padding: '8px',
            height: '85px'
          }}
          selected={selectedChatId === chat.getId() ? true : false}
          key={chat.getId()}
          onClick={() =>
            !showActionBtns ||
            // (showActionBtns && !chat?.data?.isTransferred) ||
            isChatStarted(chat.getId())
              ? onChatClickHandler(chat)
              : null
          }
        >
          <ListItemText
            className="text-primary"
            style={{ margin: '0' }}
            primary={
              <>
                <Grid container>
                  <Grid item xs={10}>
                    <Box className="d-flex w-100">
                      <Box
                        sx={{ display: 'inline-block', verticalAlign: 'top' }}
                      >
                        {isSmsChat || isClientHandlingChat
                          ? chatIcons.SMS
                          : getChatIcon()}
                      </Box>
                      <Box
                        ml={1}
                        sx={{
                          maxWidth: '80%',
                          display: 'inline-block',
                          fontSize: '16px',
                          fontFamily: 'lato',
                          fontWeight: 700,
                          lineHeight: '18px'
                        }}
                      >
                        <Title
                          companyName={chat.getCompanyName()}
                          visitorName={getVisitorName(chat)}
                          isAgent={isAgent}
                          isOperator={isOperator}
                          chatSectionType={roomType}
                          chatInitiatedPlatform={isSmsChat ? 'SMS' : 'Web'}
                          isOptInChat={isClientHandlingChat}
                        />
                        <div
                          style={{
                            fontSize: '14px',
                            fontWeight: 400,
                            fontFamily: 'lato',
                            maxWidth: '100%'
                          }}
                        >
                          {isChatStarted(chat.getId()) &&
                            !isClientHandlingChat && (
                              <ChatIdAndLocationText chat={chat} />
                            )}
                          {isClientHandlingChat && (
                            <TruncateWithToolTip
                              truncateEnabled={false}
                              text={`${chat?.data?.visitorInfoPhone}`}
                              limit={28}
                            />
                          )}
                        </div>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    {isClientHandlingChat ? (
                      <GetLeadType leadType={chat?.data.leadType} />
                    ) : (
                      <IdleCounter
                        chat={chat}
                        lastMessage={lastMessage}
                        handleShelvedChatsOnChatEnded={
                          handleShelvedChatsOnChatEnded
                        }
                        eventKey={eventKey}
                        handleChatInitialTime={handleChatInitialTime}
                      />
                    )}
                  </Grid>
                </Grid>
              </>
            }
            secondary={
              <Box style={{ marginTop: '3px' }}>
                <Grid container>
                  <Grid item xs={8}>
                    <Box
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: '2',
                        WebkitBoxOrient: 'vertical',
                        color: '#2D2D95 !important',
                        maxWidth: '100%'
                      }}
                    >
                      <span
                        className="text-left text-primary message d-flex align-items-center"
                        style={{ maxWidth: '100%' }}
                      >
                        {isChatStarted(chat.getId()) &&
                        (!isTyping(chat.getId()) ||
                          !showTypingIndicatorInChat(chat?.data)) ? (
                          truncateText(
                            getPlainStringFromHTMLElementAsString(
                              lastMessage(chat.getId()).text
                            ),
                            25
                          )
                        ) : //  typing indicator will be show when visitor is typing
                        showTypingIndicatorInChat(chat?.data) ? (
                          <TypingIndicator
                            msg={'typing'}
                            typing={isTyping(chat.getId())}
                            size={5}
                            containerStyles={{ marginRight: '12px' }}
                          />
                        ) : null}
                        {!isChatStarted(chat.getId()) && !isClientHandlingChat && (
                          <>
                            <ChatIdAndLocationText
                              chat={chat}
                              limit={19}
                              styles={{
                                fontSize: '14px',
                                alignSelf: 'end',
                                color: 'var(--primary-2)'
                              }}
                            />
                          </>
                        )}
                      </span>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box sx={{ textAlign: 'right' }}>
                      {showActionBtns && !isChatStarted(chat.getId()) && (
                        <ActionButtons
                          chat={chat}
                          // onAccept={onChatClickHandler}
                          onAccept={
                            chat?.data?.isTransferred
                              ? onAccept
                              : onChatClickHandler
                          }
                          onIgnore={chat?.data?.isTransferred ? onIgnore : null}
                          // onReject={
                          //   !chat?.data?.isTransferred && !isAgent
                          //     ? onReject
                          //     : null
                          // }
                          onReject={onReject}
                          rejectLoading={chat?.data?.rejectLoading}
                        />
                      )}
                      {unreadCount > 0 && (
                        <Badge
                          color="secondary"
                          badgeContent={unreadCount}
                          classes={{ badge: 'badge-container-color' }}
                          style={{ position: 'relative' }}
                        ></Badge>
                      )}
                      {(isChatStarted(chat.getId()) ||
                        isClientHandlingChat) && (
                        <span
                          className="text-right date-time"
                          style={{ paddingLeft: '15px' }}
                        >
                          {chatInitialTime}
                        </span>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            }
          />
        </ListItem>
      </List>
    </>
  );
};

export default ChatListItem;
