import React, { useEffect, useState } from 'react';
import * as _ from 'lodash.debounce';
import storage from 'helpers/storage';
import MessageSound from '../../assets/sounds/waterdrop.mp3';
import IncomingChatSound from '../../assets/sounds/default-incoming-chat-sound.mp3';
import { useSelector } from 'react-redux';
import {
  getEnableNotificationSound,
  getIncomingChatList,
  getMessageRecived
} from '../../features/messagingChats/messagingChats';
import { getUserFromViewedUser } from 'helpers/generic.methods';

window.inter = null;
const checkEnable = key => {
  let userInfo = getUserFromViewedUser();
  return userInfo && userInfo[key] ? userInfo[key] : null;
};

const NotificationComp = () => {
  // const [msgSound, setMsgSound] = useState(new Audio(sound));
  // const [incommingSound, setIncommingSound] = useState(new Audio(url));
  const [msgSound, setMsgSound] = useState(null);
  const [incommingSound, setIncommingSound] = useState(null);
  let messageRecived = useSelector(getMessageRecived);
  let enableNotificationSound = useSelector(getEnableNotificationSound);
  const incomingChatList = useSelector(getIncomingChatList);

  useEffect(() => {
    setMsgSound(document.getElementById('msg_sound_audio'));
    setIncommingSound(document.getElementById('incoming_sound_audio'));
  }, []);

  // On Message Recive
  useEffect(() => {
    if (messageRecived) {
      playMsgSound();
    }
  }, [messageRecived]);

  //  On Sound Enable or Silent Mode
  useEffect(() => {
    if (incomingChatList.length) {
      clearSound();
      playIncommingSound();
    }
  }, [enableNotificationSound]);

  // on Incomming Chat Recive
  useEffect(() => {
    if (incomingChatList.length) playIncommingSound();
    else clearSound();
  }, [incomingChatList]);

  const playMsgSound = () => {
    const enable = checkEnable('enableSound');
    if (enable && msgSound.paused) {
      msgSound.play();
      _(() => {
        msgSound.pause();
        msgSound.currentTime = 0;
      }, 1500);
    }
  };

  const clearSound = () => {
    clearInterval(window.inter);
    window.inter = null;
    if (incommingSound) incommingSound.pause();
  };

  const playIncommingSound = () => {
    if (incommingSound?.paused && !window.inter) {
      if (enableNotificationSound) {
        incommingSound.play();
      } else {
        incommingSound.pause();
        incommingSound.currentTime = 0;
      }
      window.inter = setInterval(() => {
        if (enableNotificationSound) {
          incommingSound.play();
        } else {
          incommingSound.pause();
          incommingSound.currentTime = 0;
        }
      }, 3000);
    }
  };
  return (
    <>
      <audio id="msg_sound_audio" src={MessageSound}></audio>
      <audio id="incoming_sound_audio" src={IncomingChatSound}></audio>
    </>
  );
};

export default NotificationComp;
